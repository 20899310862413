import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Froalaeditor from "froala-editor";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/js/plugins/word_paste.min.js";
import uuid from "react-uuid";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import "./FroalaEditorStyles.scss";
import BulbIcon from "../../../assets/images/bulbIcon.png";
import { decodeEntities } from "../../utils/services";
import EditOptionsDialog from "./EditOptionsDialog";

const FroalaEditorComponent = (props) => {
  const { editor, id, onChange, editorData, onBlur, minHeight, maxHeight, commentData, handleDropdownList, isEdit } = props;
  const [state, setState] = useState({
    open: false,
    selectedDropdown: null,
    newOption: "",
    hoverOption: null,
    textfield: false,
    editedOption: null,
    dialogOptionList: [],
    dropdownList: [],
    openHelp: false,
  });
  const { openHelp, dropdownList, selectedDropdown, dialogOptionList } = state;
  const location = useLocation();
  // required for future implementtation
  // const handleDropdownChange = (event, list) => {
  //   const newValue = event.target.value;
  //   let updatedDropdownList = list.map((dropdown) => {
  //     if (dropdown.dropdown_uuid === event.target.id) {
  //       return { ...dropdown, selected_value: newValue || "" };
  //     }
  //     return dropdown;
  //   });
  //   setState((prevState) => ({ ...prevState, dropdownList: updatedDropdownList }));
  //   handleDropdownList(id, updatedDropdownList, event.target.value);
  // };

  // let commentDropElements = document.querySelectorAll(".comment-cl-dropdown");
  // commentDropElements.forEach((element) => {
  //   const dropType = element.getAttribute("dropType");
  //   const commentUUId = element.getAttribute("comment_uuid");
  //   const commentDisabled = element.getAttribute("disabled");
  //   if (element) {
  //     element.onchange = (event) => handleDropdownChange(event, commentData?.comment_dropdowns, "comment", commentUUId, commentDisabled);
  //   }
  // });

  // let summaryDropElements = document.querySelectorAll(".summary-cl-dropdown");
  // summaryDropElements.forEach((element) => {
  //   const dropType = element.getAttribute("dropType");
  //   const commentUUId = element.getAttribute("comment_uuid");
  //   const commentDisabled = element.getAttribute("disabled");
  //   if (element) {
  //     element.onchange = (event) => handleDropdownChange(event, commentData?.summary_comment_dropdowns, "summary", commentUUId, commentDisabled);
  //   }
  // });

  useEffect(() => {
    const editorInstance = editor?.current?.editor;
    let editorContent = editorInstance?.html?.get();
    const replacePlaceholdersWithDropdowns = () => {
      const dropdowns = id === "introTextTE" ? commentData?.introductory_text_dropdowns : id === "commentText" ? commentData?.comment_dropdowns : commentData?.summary_comment_dropdowns;
      // Regex to match [dropdown UUID] within a span tag
      const regexSpanDropdown = /<span contenteditable="false">\[dropdown [a-f0-9\-]+\]<\/span>/g;
      // Regex to match [dropdown UUID] without a span tag
      const regexPlainDropdown = /\[dropdown [a-f0-9\-]+\]/g;
      if (Array?.isArray(dropdowns) && dropdowns?.length > 0) {
        dropdowns?.forEach((dropdown) => {
          const placeholder = `\\[dropdown ${dropdown.dropdown_uuid}\\]`;
          const regexSpan = new RegExp(`<span contenteditable="false">${placeholder}<\/span>`, "g");
          const regexPlain = new RegExp(placeholder, "g");
          const selectElement = document.createElement("select");
          const option = document.createElement("option");
          option.text = "-------------";
          option.value = "";
          selectElement.appendChild(option);
          if (Array?.isArray(dropdown?.dropdowns_values?.values)) {
            dropdown?.dropdowns_values?.values?.forEach((value) => {
              const option = document.createElement("option");
              if (value?.length > 40) option.text = `${value?.substring(0, 40)}...`;
              else option.text = value || "";
              option.value = value || "";
              option.title = value || "";
              selectElement.appendChild(option);
              if (dropdown?.selected_value === value) {
                option.setAttribute("selected", "selected");
              }
              selectElement.classList.add(`${id === "introTextTE" ? "intro" : id === "commentText" ? "comment" : "summary"}-cl-dropdown`);
              selectElement.setAttribute("id", `${dropdown.dropdown_uuid}`);
              if (id !== "introTextTE") {
                selectElement.setAttribute("comment_id", commentData?.comment_id);
                selectElement.setAttribute("comment_uuid", commentData?.comment_uuid);
                selectElement.setAttribute("dropType", id === "commentText" ? "comment" : "summary");
              }
            });
          } else if (Array?.isArray(dropdown?.dropdowns_values)) {
            dropdown?.dropdowns_values?.forEach((value) => {
              const option = document.createElement("option");
              if (value?.length > 40) option.text = `${value?.substring(0, 40)}...`;
              else option.text = value || "";
              option.value = value || "";
              option.title = value || "";
              selectElement.appendChild(option);
              if (dropdown?.selected_value === value) {
                option.setAttribute("selected", "selected");
              }
              selectElement.classList.add(`${id === "introTextTE" ? "intro" : id === "commentText" ? "comment" : "summary"}-cl-dropdown`);
              selectElement.setAttribute("id", `${dropdown.dropdown_uuid}`);
              if (id !== "introTextTE") {
                selectElement.setAttribute("comment_id", commentData?.comment_id);
                selectElement.setAttribute("comment_uuid", commentData?.comment_uuid);
                selectElement.setAttribute("dropType", id === "commentText" ? "comment" : "summary");
              }
            });
          }
          editorData.innerHTML = editorData.innerHTML.replace(regexSpan, selectElement.outerHTML).replace(regexPlain, selectElement.outerHTML);
        });
      } else {
        const outputString = editorData?.innerHTML?.replace(regexPlainDropdown, (match, selectId) => {
          return `<select id="${selectId}" class="${id === "introTextTE" ? "intro" : id === "commentText" ? "comment" : "summary"}-cl-dropdown"><option>--------------</option></select>`;
        });
        editorData.innerHTML = outputString;
      }
      return decodeEntities(editorData.innerHTML?.replace(regexSpanDropdown, "").replace(regexPlainDropdown, ""));
    };
    setTimeout(() => {
      if ((!editorContent || editorContent === undefined) && editorInstance && editorData?.innerHTML) {
        const newHtmlData = replacePlaceholdersWithDropdowns();
        editorInstance?.html?.set(commentData?.comment_dropdowns?.length || commentData?.summary_comment_dropdowns?.length ? newHtmlData : editorData?.innerHTML);
      }
    }, 100);
  }, [editorData, editor, commentData, id]);

  const handleModelChange = (model) => {
    onChange(id, model);
  };

  let isHandlingImage = false;

  const insertImageToEditor = (base64String) => {
    const editorInstance = editor.current.editor;
    const cursorPosition = editorInstance.selection.get();
    if (cursorPosition) editorInstance.image.insert(base64String, true);
    isHandlingImage = false;
  };

  const handleImageBeforeUpload = (images) => {
    if (images.length && !isHandlingImage) {
      isHandlingImage = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        insertImageToEditor(e.target.result);
      };
      reader.readAsDataURL(images[0]);
    }
    return false;
  };

  const handlePasteBefore = (e) => {
    if (isHandlingImage) return;
    const clipboardData = e.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        isHandlingImage = true;
        const blob = items[i].getAsFile();
        if (blob) {
          const reader = new FileReader();
          reader.onload = (event) => {
            insertImageToEditor(event.target.result);
          };
          reader.readAsDataURL(blob);
        }
      } else if (items[i].type.indexOf("text/html") !== -1) {
        items[i].getAsString((html) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          const images = doc.querySelectorAll("img");
          images.forEach((img) => {
            const src = img.src;
            if (src.startsWith("data:image")) {
              insertImageToEditor(src);
            }
          });
        });
      }
    }
  };

  const handleOpenDialog = (event) => {
    event.stopPropagation();
    let newButtonId = event?.target?.id?.replace("dropdownButton", "");
    if (event.target.tagName === "BUTTON") {
      const dropdown = dropdownList.find((obj) => obj?.dropdown_uuid === newButtonId);
      setState((prevState) => ({
        ...prevState,
        dialogOptionList: dropdown?.dropdowns_values?.length === 0 ? [...dialogOptionList, "-------------"] : dropdown?.dropdowns_values,
        open: true,
        selectedDropdown: newButtonId,
      }));
    }
  };

  const handleSelectChange = (event) => {
    if (!isEdit) {
      const newValue = event.target.value;
      let updatedDropdownList = dropdownList.map((dropdown) => {
        if (dropdown.dropdown_uuid === selectedDropdown) {
          return { ...dropdown, selected_value: newValue || "" };
        }
        return dropdown;
      });
      setState((prevState) => ({ ...prevState, dropdownList: updatedDropdownList }));
      handleDropdownList(id, updatedDropdownList, event.target.value);
    }
  };

  useEffect(() => {
    const editorInstance = editor.current?.editor;
    if (editorInstance) {
      const contentArea = editorInstance.$el.get(0);
      contentArea.addEventListener("click", handleOpenDialog);
      contentArea.addEventListener("change", handleSelectChange);
      return () => {
        contentArea.removeEventListener("click", handleOpenDialog);
        contentArea.removeEventListener("change", handleSelectChange);
      };
    }
  }, [editorData]);

  useEffect(() => {
    document.addEventListener("openDialog", handleOpenDialog);
    document.addEventListener("changeValue", handleSelectChange);
    return () => {
      document.removeEventListener("openDialog", handleOpenDialog);
      document.removeEventListener("changeValue", handleSelectChange);
    };
  }, []);

  const btnclick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let newDropdownList = [...dropdownList];
    const editorInstance = editor.current.editor;
    const cursorPosition = editorInstance.selection.get();
    const newSelectUuid = uuid();
    if (cursorPosition) {
      editorInstance.html.insert(
        `<span contenteditable="false" id="[dropdown ${newSelectUuid}]">
            <select id="${newSelectUuid}"
                style="width: 104px;
                background-color: #f1f9fe;
                height: 24px;
                overflow-wrap: break-word;
                padding: 1px 17px 3px 6px;
                background-position: right 4px center;
                background-repeat: no-repeat;
                background-size: 22px 20px;
                border-radius: 4px 0px 0px 4px;
                border: 1px solid #0F75BC;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                font-size: 14px !important;
                color: #3B3B3B !important;
                font-style: normal !important;
                font-weight: 600 !important;
                max-width: 200px !important;
                min-width: 104px !important;
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
                width: -webkit-max-content !important;
                width: max-content !important;
                background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M7%2010L12%2015L17%2010H7Z%22%20fill%3D%22%23777777%22/%3E%3C/svg%3E');
                onchange="handleSelectChange(event, '${newSelectUuid}')"
                " >
                <option disabled selected value="-------------">-------------</option>
            </select>
            <button
                style="
                background-color: #f1f9fe;
                width: 24px;
                height: 24px;
                padding: 0px;
                color: #0F75BC;
                border-radius: 4px 0px 0px 4px;
                border: 1px solid #0F75BC;
                top: 2.5px;
                position: relative;
                transform: scaleX(-1);
                font-size: 17px;
                margin-left: -4px;"
                contenteditable="false"
                id="dropdownButton${newSelectUuid}"
                name="${newSelectUuid}" 
                type="button"
                onclick="handleOpenDialog(event, '${newSelectUuid}');"
              >✎</button>
        </span>
    `,
        true
      );
    }
    newDropdownList.push({
      dropdown_uuid: newSelectUuid,
      name: `dropdown ${newSelectUuid}`,
      selected_value: "",
      dropdowns_values: ["------------"],
    });
    handleDropdownList(id, newDropdownList);
    setState((prevState) => ({ ...prevState, dropdownList: newDropdownList }));
    setTimeout(() => {
      const button = editor.current.editor.$el.find(`#dropdownButton${newSelectUuid}`);
      const select = editor.current.editor.$el.find(`#${newSelectUuid}`);
      button.on("click", function (event) {
        const customEvent = new CustomEvent("openDialog", { detail: { id: newSelectUuid, event: event } });
        document.dispatchEvent(customEvent);
      });
      select.on("change", function (event) {
        const customEvent = new CustomEvent("changeValue", { detail: { id: newSelectUuid, event: event } });
        document.dispatchEvent(customEvent);
      });
    }, 0);
  };

  const handleSave = () => {
    const index = dropdownList.findIndex((obj) => obj?.dropdown_uuid === selectedDropdown);
    const editorInstance = editor.current.editor;
    let editorContent = editorInstance.html.get();
    const optionElements = dialogOptionList.map((option) => {
      const truncatedOption = option.length > 40 ? option.substring(0, 40) + "..." : option;
      return `<option title="${option}" value="${option}">${truncatedOption}</option>`;
    });
    const optionsString = optionElements.join("");
    const selectRegex = new RegExp(`<select id="${selectedDropdown}"[\\s\\S]*?<\\/select>`, "g");
    function replaceOptionsInSelect(selectHtml, newOptionsHtml) {
      const optionsRegex = /(<select[^>]*>)[\s\S]*?(<\/select>)/;
      return selectHtml.replace(optionsRegex, `$1${newOptionsHtml}$2`);
    }
    const newOptionsHtml = `<option disabled selected value="-------------">-------------</option>${optionsString}`;
    const replacedContent = editorContent.replace(selectRegex, (match) => replaceOptionsInSelect(match, newOptionsHtml));
    editorInstance.html.set(replacedContent);
    const newDialogOptionList = dialogOptionList.filter((option) => option.trim() !== "");
    let newDropdownOptions = dropdownList;
    if (newDropdownOptions.length === 0) {
      newDropdownOptions.push({ dropdown_uuid: selectedDropdown, selected_value: "", dropdowns_values: newDialogOptionList?.length === 0 ? ["------------"] : newDialogOptionList });
    } else {
      newDropdownOptions[index] = {
        ...newDropdownOptions[index],
        dropdowns_values: newDialogOptionList?.length === 0 ? ["------------"] : newDialogOptionList,
      };
    }
    handleDropdownList(id, newDropdownOptions);
    setState((prevState) => ({ ...prevState, open: false, dialogOptionList: [], dropdownList: newDropdownOptions }));
  };

  const openHelpDialog = () => {
    setState((prevState) => ({ ...prevState, openHelp: !openHelp }));
  };

  Froalaeditor.DefineIcon("aiHelper", { SRC: BulbIcon, ALT: "Image button", height: 50, width: "50px", template: "image" });
  Froalaeditor.RegisterCommand("aiHelper", {
    title: "AI helper",
    focus: false,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      openHelpDialog();
    },
  });
  const handleFocus = () => {
    setState((prevState) => ({ ...prevState, isFocused: true }));
  };

  const handleBlur = () => {
    setState((prevState) => ({ ...prevState, isFocused: false }));
    const editorInstance = editor.current?.editor;
    const editorContent = editorInstance?.html?.get();
    if (onBlur) {
      setTimeout(() => {
        onBlur(id, editorContent);
      }, 100);
    }
  };

  return (
    <div id={id} style={{ zIndex: 100 }}>
      <FroalaEditor
        id={id}
        ref={editor}
        value={editorData}
        onModelChange={handleModelChange}
        config={{
          key: "1CC3kA6C7A5A3B3A3E3cWHNGGDTCWHId1Eb1Oc1Yh1b2Ld1POkE3D3F3C7A4A4A4G3H3B1==",
          placeholderText: " ",
          charCounterCount: false,
          quickInsertEnabled: false,
          dragInline: true,
          spellCheck: true,
          imageInsertButtons: ["imageUpload"],
          imageEditButtons: ["imageAlign"],
          videoInsertButtons: ["videoByURL", "videoUpload"],
          videoEditButtons: ["videoAlign"],
          heightMin: minHeight,
          heightMax: maxHeight,
          toolbarButtons: [
            "bold",
            "italic",
            "underline",
            // "strikeThrough",
            // "subscript",
            // "superscript",
            // "|",
            "fontfamily",
            "fontSize",
            "fontColor",
            // "paragraphStyle",
            "|",
            // "paragraphFormat",
            // "align",
            "formatOL",
            "formatUL",
            // "outdent",
            // "indent",
            // "quote",
            // "|",
            // "insertLink",
            // "insertImage",
            // "insertVideo",
            // "insertTable",
            "|",
            // "emoticons",
            // "specialCharacters",
            // "insertHR",
            // "selectAll",
            // "clearFormatting",
            // "|",
            // "print",
            "undo",
            "redo",
            // "aiHelper",
          ],
          pluginsEnabled: ["wordPaste", "spellChecker", "codeBeautifier", "fontFamily", "fontSize", "colors", "align", "lists"],
          events: {
            "image.beforeUpload": handleImageBeforeUpload,
            "paste.before": handlePasteBefore,
            focus: handleFocus,
            blur: handleBlur,
          },
        }}
      />
      {/* {isEdit !== undefined && isEdit === false && !location.pathname.includes("templates") ? (
        <Grid display={"flex"} className="add-dropdown-box">
          <Button className="add-dropdown-typo" onClick={(event) => btnclick(event)} disableRipple startIcon={<AddCircleOutlineOutlinedIcon className="add-dropdown-circle" />}>
            Add dropdown
          </Button>
        </Grid>
      ) : null} */}
      <EditOptionsDialog id={id} state={state} setState={setState} handleSave={handleSave} handleDropdownList={handleDropdownList} />
    </div>
  );
};

export default FroalaEditorComponent;