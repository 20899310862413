import { API_CONSTANTS, API_METHODS } from '../../config/api-constants';
import { fetchCall } from '../../utils/ajax';

export const RequestDashboardCount = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_DASHBOARD_COUNT}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const RequestDateWiseInspections = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_INSPECTIONS_DATEWISE}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const RequestUpcommingInspections = (callback, payload) => {
    const url = `${API_CONSTANTS.VIEW_UPCOMMING_INSPECTIONS}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const RequestCompanyDetails = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_COMPANY_PROFILE}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const RequestMapBoxDetails = (callback, payload) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${payload.address},${payload.city},${payload.state},${payload.zip}.json?&limit=1&access_token=${payload.token}`;
    const method = API_METHODS.GET;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };