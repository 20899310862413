import React, { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DoneIcon from "@mui/icons-material/Done";
import uuid from "react-uuid";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { timestamp } from "../../../utils/services";

const AddLocationMultiple = (props) => {
  const { open, setOpen, lineName, cmtLocHolder, locationData, isPlaceHolder, item, AllCategoryList, categoryId, lineId, lineInputControlId, updateTemplate, action = "", commentId, locationValue, setAllCategoryList } = props;
  const unique_id = uuid();

  let locationDataArray = [];
  if (locationData !== undefined) {
    locationDataArray =
      locationData.length > 0 &&
      locationData?.map((el, index) => {
        return {
          id: index + 1,
          value: el.value,
          order: el.order,
          uuid: el.uuid,
          col: el.col,
        };
      });
  }

  const [locationTextArray, setLocationTextArray] = useState(locationValue === null ? [] : locationValue);
  const [locationArray, setLocationArray] = useState(locationDataArray);
  const [editLocation, setEditLocation] = useState(false);

  let columnOneData = locationArray.length > 0 && locationArray?.filter((e) => e.col === "1");
  let columnTwoData = locationArray.length > 0 && locationArray?.filter((e) => e.col === "2");
  let columnThreeData = locationArray.length > 0 && locationArray?.filter((e) => e.col === "3");

  const [columnOne, setColumnOne] = useState(columnOneData);
  const [columnTwo, setColumnTwo] = useState(columnTwoData);
  const [columnThree, setColumnThree] = useState(columnThreeData);

  // generating unique id values on each changes
  const updateLocationData = () => {
    let locationCopyArray = [...locationArray];
    let updatedLocationData = locationCopyArray?.map((location, index) => {
      return {
        id: index + 1,
        value: location.value,
        order: location.order,
        uuid: location.uuid,
        col: location.col,
      };
    });
    setLocationArray(updatedLocationData);
  };

  const addLocationTextToArray = (id, name) => {
    let tempTextArray = [...locationTextArray];
    let idsArray = tempTextArray?.map(obj => obj.id);
    if(idsArray?.includes(id)){
      let selectedLocationIndex = idsArray?.findIndex((el)=>el==id)
      if(selectedLocationIndex !== -1)
        tempTextArray.splice(selectedLocationIndex, 1)
    }else
      tempTextArray.push({ id, name });
    setLocationTextArray(tempTextArray);
  };

  const handleClick = (id) => {
    let tempTextArray = [...locationTextArray];
    let deleteIndex = tempTextArray?.findIndex((e) => e.id === id);
    deleteIndex !== -1 && tempTextArray.splice(deleteIndex, 1);
    setLocationTextArray(tempTextArray);
  };

  const hasUUID = (array, id) => {
    return array.some((item) => item.id === id);
  };

  
  const locationEdithandle = (value, id) => {
    let editTempArray = [...locationArray];
    let editObj = editTempArray.find((e) => e.uuid === id);
    editObj.value = value;
    let validArray = editTempArray.filter((temp) => {
      return temp.value?.trim().length !== 0;
    });
    setLocationArray(editTempArray);
  };

  const deleteLocationHandle = (id) => {
    let deleteTempArray = [...locationArray];
    let deleteObjIndex = deleteTempArray.findIndex((e) => e.uuid === id);
    if (deleteObjIndex !== -1) deleteTempArray.splice(deleteObjIndex, 1);
    setLocationArray(deleteTempArray);
    setColumnOne(deleteTempArray.filter((e) => e.col === "1"));
    setColumnTwo(deleteTempArray.filter((e) => e.col === "2"));
    setColumnThree(deleteTempArray.filter((e) => e.col === "3"));
    // updateLocationData();
  };

  const addNewLocationHandle = (column, row) => {
    updateLocationData();
    let addLocTempArray = [...columnOne, ...columnTwo, ...columnThree];
    addLocTempArray.push({
      id: addLocTempArray.length + 1,
      value: "",
      uuid: unique_id,
      order: String(row + 1),
      col: String(column),
    });
    setLocationArray(addLocTempArray);
    setColumnOne(addLocTempArray.filter((e) => e.col === "1"));
    setColumnTwo(addLocTempArray.filter((e) => e.col === "2"));
    setColumnThree(addLocTempArray.filter((e) => e.col === "3"));
  };

  const handleEditLocationComplete = () => {
    const ValArray = locationArray.filter((val) => {
      return val.value?.trim().length !== 0;
    });
    setLocationArray(ValArray);
    setEditLocation(false);
  };

  const handleEditLocationStart = () => {
    const ValArray = locationArray.filter((val) => {
      return val.value?.trim().length !== 0;
    });
    setColumnOne(
      columnOne.filter((value) => {
        return value.value?.trim().length !== 0;
      })
    );
    setColumnTwo(
      columnTwo.filter((value) => {
        return value.value?.trim().length !== 0;
      })
    );
    setColumnThree(
      columnThree.filter((value) => {
        return value.value?.trim().length !== 0;
      })
    );
    setLocationArray(ValArray);
    setEditLocation(true);
  };
  const reorder = (data, startIndex, endIndex) => {
    const result = Array.from(data);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEndHandle = (result, column) => {
    if (column === "colOne") setColumnOne(reorder(columnOne, result.source.index, result.destination.index));
    else if (column === "colTwo") setColumnTwo(reorder(columnTwo, result.source.index, result.destination.index));
    else if (column === "colThree") setColumnThree(reorder(columnThree, result.source.index, result.destination.index));
  };

  const handleLocationChanges = () => {
    let finalLocationArray = [...columnOne, ...columnTwo, ...columnThree];
    const removeEmpty = finalLocationArray.filter((location) => {
      return location.value !== ""
    })
    let lineIndex = null;
    let locationIndex = null;
    const catList = AllCategoryList?.template?.categories;
    const globalLoc = AllCategoryList?.template;
    const categoriesDataIndex = catList.findIndex((cat) => cat.category_id === categoryId);
    let locData = [];
    locationTextArray.map((locs) => {
      locData.push(locs.name);
    });
    let removeBracs = locData.toString();
    if (action === "comment") {
      if (categoriesDataIndex !== -1) {
        lineIndex = catList[categoriesDataIndex].lines.findIndex((line) => line.line_id === lineId);
        const isLocAvailable = catList[categoriesDataIndex].lines[lineIndex].comments.find((item) => item.comment_id === commentId).comment_input_controls.some((item) => item.type === "Location")
        if(!isLocAvailable){
          cmtLocHolder[0].data = removeBracs;
          globalLoc.location_data = removeEmpty;
          catList[categoriesDataIndex].lines[lineIndex].comments.find((item) => item.comment_id === commentId).default_location = locationTextArray;
          catList[categoriesDataIndex].lines[lineIndex].comments.find((item) => item.comment_id === commentId).updated_at = timestamp();
          catList[categoriesDataIndex].lines[lineIndex].comments.find((item) => item.comment_id === commentId).comment_input_controls.push(cmtLocHolder[0])
          catList[categoriesDataIndex].lines[lineIndex].comments.find((item) => item.comment_id === commentId).updated_at = timestamp();
        } else {
          globalLoc.location_data = removeEmpty;
          catList[categoriesDataIndex].lines[lineIndex].comments.find((item) => item.comment_id === commentId).comment_input_controls.find((item) => item.type === "Location").data = removeBracs;
          catList[categoriesDataIndex].lines[lineIndex].comments.find((item) => item.comment_id === commentId).comment_input_controls.find((item) => item.type === "Location").updated_at = timestamp();
          catList[categoriesDataIndex].lines[lineIndex].comments.find((item) => item.comment_id === commentId).default_location = locationTextArray;
          catList[categoriesDataIndex].lines[lineIndex].comments.find((item) => item.comment_id === commentId).updated_at = timestamp();
        }
      }
    } else {
      if (categoriesDataIndex !== -1) {
        lineIndex = catList[categoriesDataIndex].lines.findIndex((line) => line.line_id === lineId);
        if(isPlaceHolder){
          item.data = removeBracs;
          globalLoc.location_data = removeEmpty;
          catList[categoriesDataIndex].lines[lineIndex].default_location = locationTextArray;
          catList[categoriesDataIndex].lines[lineIndex].updated_at = timestamp();
          locationIndex = catList[categoriesDataIndex].lines[lineIndex].line_input_controls.push(item)
          locationIndex = catList[categoriesDataIndex].lines[lineIndex].updated_at = timestamp()
        } else {
          locationIndex = catList[categoriesDataIndex].lines[lineIndex].line_input_controls.findIndex((info) => info.line_input_control_id === lineInputControlId);
          catList[categoriesDataIndex].lines[lineIndex].line_input_controls[locationIndex].data = removeBracs;
          globalLoc.location_data = removeEmpty;
          catList[categoriesDataIndex].lines[lineIndex].default_location = locationTextArray;
          catList[categoriesDataIndex].lines[lineIndex].updated_at = timestamp();
        }

      }
    }
    setOpen(false);
    updateTemplate("noToast");
  };

  return (
    <Dialog className="dialog-container-location" open={open} onClose={() => setOpen(false)} fullWidth={true}>
      <div className="close-wrapper">
        <CloseIcon onClick={() => setOpen(false)} />
      </div>
      <DialogTitle>
        {!editLocation ? (
          <>
            {`Location - ${lineName}`}
            <Typography>This will edit locations for the entire template.</Typography>
          </>
        ) : (
          <>
            Edit template location list
            <Typography>This will edit locations for the entire template.</Typography>
          </>
        )}
      </DialogTitle>
      {!editLocation && (
        <Grid className="input-dialog">
          <Grid className="input-controll">
            <p>DEFAULT LOCATION</p>
            <div className="location-input-text">
              {locationTextArray && locationTextArray.length !== 0
                ? locationTextArray?.map((el, index) => {
                    return (
                      <span key={index} style={{ marginRight: "4px" }} className="location-display-word" onClick={() => handleClick(el.id)}>
                        {el.name}
                      </span>
                    );
                  })
                : ""}
            </div>
          </Grid>
        </Grid>
      )}
      <DialogContent className="dialog-content-location">
        {!editLocation ? (
          <Grid container>
            <Grid item xs={12} sm={4} md={4} className="loc-item-wrapper">
              {columnOne.length > 0 &&
                columnOne?.map((item, index) => {
                  return (
                    <div
                    style={{
                      display: item.value === "" && "none",
                      backgroundColor:
                        hasUUID(locationTextArray, item.uuid) && "#eaf5ff",
                    }}
                      className="loc-item"
                      onClick={() => addLocationTextToArray(item.uuid, item.value)}
                      // style={{ background:`${item.status?'#EAF5FF':''}`}} key={index}
                    >
                      {item.value}
                    </div>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={4} md={4} className="loc-item-wrapper">
              {columnTwo.length > 0 &&
                columnTwo?.map((item, index) => {
                  return (
                    <div
                    style={{
                      display: item.value === "" && "none",
                      backgroundColor:
                        hasUUID(locationTextArray, item.uuid) && "#eaf5ff",
                    }}
                      className="loc-item"
                      onClick={() => addLocationTextToArray(item.uuid, item.value)}
                      // style={{ background:`${item.status?'#EAF5FF':''}`}} key={index}
                    >
                      {item.value}
                    </div>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={4} md={4} className="loc-item-wrapper">
              {columnThree.length > 0 &&
                columnThree?.map((item, index) => {
                  return (
                    <div
                    style={{
                      display: item.value === "" && "none",
                      backgroundColor:
                        hasUUID(locationTextArray, item.uuid) && "#eaf5ff",
                    }}
                      className="loc-item"
                      onClick={() => addLocationTextToArray(item.uuid, item.value)}
                      // style={{ background:`${item.status?'#EAF5FF':''}`}} key={index}
                    >
                      {item.value}
                    </div>
                  );
                })}
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <DragDropContext
              onDragEnd={(result) => {
                onDragEndHandle(result, "colOne");
              }}
            >
              <Droppable droppableId="colOne">
                {(provided) => (
                  <Grid item xs={12} sm={4} md={4} className="loc-item-wrapper" ref={provided.innerRef}>
                    {columnOne.map((item, index) => {
                      return (
                        <Draggable draggableId={String(item.id)} key={item.id} index={index}>
                          {(provided) => (
                            <div className="edit-loc-wrapper" key={index} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                              <DragHandleIcon className="drag-handle-icon" />
                              <input type="text" value={item.value} onChange={(e) => locationEdithandle(e.target.value, item.uuid)} />
                              <DeleteIcon className="loc-delete-icon" onClick={() => deleteLocationHandle(item.uuid)} />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                    <div className="isFlex add-cta">
                      <span onClick={() => addNewLocationHandle("1", columnOne.length)}>
                        <AddCircleOutlineIcon /> Add new location
                      </span>
                    </div>
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
            <DragDropContext
              onDragEnd={(result) => {
                onDragEndHandle(result, "colTwo");
              }}
            >
              <Droppable droppableId="colTwo">
                {(provided) => (
                  <Grid item xs={12} sm={4} md={4} className="loc-item-wrapper" ref={provided.innerRef}>
                    {columnTwo.map((item, index) => {
                      return (
                        <Draggable draggableId={String(item.id)} key={item.id} index={index}>
                          {(provided) => (
                            <div className="edit-loc-wrapper" key={index} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                              <DragHandleIcon className="drag-handle-icon" />
                              <input type="text" value={item.value} onChange={(e) => locationEdithandle(e.target.value, item.uuid)} />
                              <DeleteIcon className="loc-delete-icon" onClick={() => deleteLocationHandle(item.uuid)} />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                    <div className="isFlex add-cta">
                      <span onClick={() => addNewLocationHandle("2", columnTwo.length)}>
                        <AddCircleOutlineIcon /> Add new location
                      </span>
                    </div>
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
            <DragDropContext
              onDragEnd={(result) => {
                onDragEndHandle(result, "colThree");
              }}
            >
              <Droppable droppableId="colThree">
                {(provided) => (
                  <Grid item xs={12} sm={4} md={4} className="loc-item-wrapper" ref={provided.innerRef}>
                    {columnThree.map((item, index) => {
                      return (
                        <Draggable draggableId={String(item.id)} key={item.id} index={index}>
                          {(provided) => (
                            <div className="edit-loc-wrapper" key={index} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                              <DragHandleIcon className="drag-handle-icon" />
                              <input type="text" value={item.value} onChange={(e) => locationEdithandle(e.target.value, item.uuid)} />
                              <DeleteIcon className="loc-delete-icon" onClick={() => deleteLocationHandle(item.uuid)} />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                    <div className="isFlex add-cta">
                      <span onClick={() => addNewLocationHandle("3", columnThree.length)}>
                        <AddCircleOutlineIcon /> Add new location
                      </span>
                    </div>
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        )}
      </DialogContent>

      <Grid>
        {!editLocation ? (
          <Button className="location-edit-btn" variant="outlined" startIcon={<EditIcon />} onClick={() => handleEditLocationStart()}>
            Edit template location list
          </Button>
        ) : (
          <Button className="location-edit-btn" variant="outlined" startIcon={<DoneIcon />} onClick={() => handleEditLocationComplete()} style={{ marginBottom: "10px" }}>
            Done editing
          </Button>
        )}
      </Grid>
      {!editLocation && (
        <DialogActions className="dialog-action-location">
          <Button
            className="back-btn"
            onClick={() => {
              setOpen(false);
              setLocationTextArray(locationValue === null ? [] : locationValue);
            }}
          >
            Cancel
          </Button>
          <Button className="default-btn" onClick={handleLocationChanges}>
            Apply
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AddLocationMultiple;
