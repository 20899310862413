import { API_CONSTANTS, API_METHODS } from "../../config/api-constants";
import { fetchCall } from "../../utils/ajax";

  export const RequestTemplateList = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_TEMPLATE_LIST_VIEW}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const GetTemplateList = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_TEMPLATE_LIST_VIEW}`;
    const method = API_METHODS.GET;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const RequestTemplateDetails = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_TEMPLATE_DETAILS}`;
    const method = API_METHODS.GET;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const RequestTemplateDetails2 = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_TEMPLATE_DETAILS2}`;
    const method = API_METHODS.GET;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  

  export const CreateNewTemplate = (callback, payload) => {
    const url = `${API_CONSTANTS.POST_NEW_TEMPLATE}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const UpdateTemplateReq = (callback, payload) => {
    const url = `${API_CONSTANTS.UPDATE_TEMPLATE_DETAILS}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const GetExistingTemplates = (callback, payload) => {
    const url = `${API_CONSTANTS.TEMPLATE_DETAILS}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const RemoveTemplate = (callback, payload) => {
    const url = `${API_CONSTANTS.DELETE_TEMPLATE}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const EditTemplateReq = (callback, payload) => {
    const url = `${API_CONSTANTS.EDIT_TEMPLATE}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };
