//file imports**************************************************************
import axios from 'axios';
import validator from 'validator';
import { Box } from '@mui/system';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import Preloader from '../../helpers/Preloader';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Heading from '../../common/cardHeading/Heading'; //reusable component
import { API_CONSTANTS } from '../../config/api-constants';
import { formatPhoneNumberDashes } from '../../utils/services';
import CollapseSection from '../../common/collapse/CollapseSection'; //reusable component
import { Grid, Modal, Paper, Button, TextField, Typography, FormControl, InputLabel, Chip, Autocomplete, Divider } from '@mui/material'; //mui imports
//render function ***********************************************************
export const Contacts = (props) => {
    //client data for edit details of client
    const [clientData, setClientData] = useState({
        firstName0: '',
        firstName1: '',
        lastName0: '',
        lastName1: '',
        phone0: '',
        phone1: '',
        email0: '',
        email1: '',
    });
    const [newClientData, setNewClientData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    });
    const [newClientEmail, setNewClientEmail] = useState('');
    const [newlyAddedClientValue, setNewlyAddedClientValue] = useState('');
    //intialization of client data
    useEffect(
        () =>
            setClientData({
                ...clientData,
                firstName0: props.selectedclientValue[0]?.first_name,
                firstName1: props.selectedclientValue[1]?.first_name,
                lastName0: props.selectedclientValue[0]?.last_name,
                lastName1: props.selectedclientValue[1]?.last_name,
                phone0: props.selectedclientValue[0]?.phone,
                phone1: props.selectedclientValue[1]?.phone,
                email0: props.selectedclientValue[0]?.email,
                email1: props.selectedclientValue[1]?.email,
            }),
        [props.selectedclientValue],
    );
    //newagentdata
    const [newagentdata, setnewagentdata] = useState({
        first_name: '',
        last_name: '',
        email: '',
        agency: '',
        phone: '',
    });
    // modal open and close functionality
    const handleOpen = () => setOpen(true);
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    //button loading
    const [loading, setLoading] = useState(false);
    //isnewclient
    const [newclient, setnewclient] = useState(false);
    const handlenew = () => {
        setnewclient(!newclient);
        setselectedclient(null);
    };
    //isnewclientsecond
    const [newclientSec, setnewclientSec] = useState(false);
    const [clientChanged, setClientChanged] = useState(false);
    const [selectedClientValueChange, setSelectedClientValueChange] = useState(false);
    const handlenewSec = () => setnewclientSec(!newclientSec);
    const [expanded, setisExpanded] = useState({
        expanded0: true,
        expanded1: false,
    });
    const [agentexpanded, setagentExpanded] = useState(false);
    const [agentChanged, setAgentChanged] = useState(false);
    //isnewagent
    const [isNewAgent, setIsNewAgent] = useState(false);
    const handlenewagent = () => setIsNewAgent(!isNewAgent);
    //preloader
    const [showpreloader, setShowpreloader] = useState(false);
    //selectedclients
    const [selectedclients, setselectedclient] = useState(null);
    //selectedagents
    const [selectedagents, setselectedagents] = useState([]);
    //validations constants
    const [errors, setErrors] = useState({});
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    //validations function
    const validation = () => {
        let errors = {};
        // if (selectedagents.length < 1) {
        //     errors.selectedagents = 'Agent is required';
        // }
        if (selectedagents.length > 2) {
            errors.selectedagents = 'Cannot select more than 2 client';
        }
        if (props.selectedclientValue.length === 1) {
            if (!clientData.firstName0) {
                errors.clientDataFirstName0 = 'First name is required';
            }
            if (!clientData.lastName0) {
                errors.clientDataLastName0 = 'Last name is required';
            }
            if (!clientData.phone0) {
                // errors.clientDataPhone0 = 'Phone number is required';
            } else if (clientData.phone0.length < 12) {
                errors.clientDataPhone0 = 'Phone number is invalid';
            }
            if (!clientData.email0) {
                errors.clientDataEmail0 = 'Email is required';
            } else if (!validator.isEmail(clientData.email0)) {
                errors.clientDataEmail0 = 'Email is invalid';
            }
        }
        if (selectedClientValueChange) {
            if (!selectedclients.first_name) {
                errors.selectedClientFirstName = 'First name is required';
            }
            if (!selectedclients.last_name) {
                errors.selectedClientLastName = 'Last name is required';
            }
            if (!selectedclients.phone) {
            } else if (selectedclients.phone.length < 12) {
                errors.selectedClientPhone = 'Phone number is invalid';
            }
            if (!selectedclients.email) {
                errors.selectedClientEmail = 'Email is required';
            } else if (!validator.isEmail(selectedclients.email)) {
                errors.selectedClientEmail = 'Email is invalid';
            }
        }
        if (props.selectedclientValue.length === 2) {
            if (!clientData.firstName0) {
                errors.clientDataFirstName0 = 'First name is required';
            }
            if (!clientData.lastName0) {
                errors.clientDataLastName0 = 'Last name is required';
            }
            if (!clientData.phone0) {
            } else if (clientData.phone0.length < 12) {
                errors.clientDataPhone0 = 'Phone number is invalid';
            }
            if (!clientData.email0) {
                errors.clientDataEmail0 = 'Email is required';
            } else if (!validator.isEmail(clientData.email0)) {
                errors.clientDataEmail0 = 'Email is invalid';
            }
            if (!clientData.firstName1) {
                errors.clientDataFirstName1 = 'First name is required';
            }

            if (!clientData.lastName1) {
                errors.clientDataLastName1 = 'Last name is required';
            }

            if (!clientData.phone1) {
            } else if (clientData.phone1.length < 12) {
                errors.clientDataPhone1 = 'Phone number is invalid';
            }

            if (!clientData.email1) {
                errors.clientDataEmail1 = 'Email is required';
            } else if (!validator.isEmail(clientData.email1)) {
                errors.clientDataEmail1 = 'Email is invalid';
            }
        }

        if (newclient) {
            if (!newClientData.firstName) {
                errors.NewClientFirstName = 'First name is required';
            }
            if (!newClientData.lastName) {
                errors.NewClientLastName = 'Last name is required';
            }
            if (!newClientData.email) {
                errors.NewClientEmail = 'Email is required';
            } else if (!validator.isEmail(newClientData.email)) {
                errors.NewClientEmail = 'Email is invalid';
            }
            if (!newClientData.phone) {
                // errors.NewClientPhone = 'Phone number is required';
            } else if (newClientData.phone.length < 12) {
                errors.NewClientPhone = 'Phone number is invalid';
            }
        }
        // if (newclientSec) {
        //     if (!newclientdata.first_name2) {
        //         errors.first_name2 = 'First name is required';
        //     }
        //     if (!newclientdata.last_name2) {
        //         errors.last_name2 = 'Last name is required';
        //     }
        //     if (!newclientdata.email2) {
        //         errors.email2 = 'Email is required';
        //     } else if (!validator.isEmail(newclientdata.email2)) {
        //         errors.email2 = 'Email is invalid';
        //     }
        //     if (!newclientdata.phone2) {
        //         errors.phone2 = 'Phone number is required';
        //     } else if (newclientdata.phone2.length < 12) {
        //         errors.phone2 = 'Phone number is invalid';
        //     }
        // }
        if (isNewAgent) {
            if (!newagentdata.first_name) {
                errors.agent_first_name = 'First name is required';
            }
            if (!newagentdata.last_name) {
                errors.agent_last_name = 'Last name is required';
            }
            if (!newagentdata.email) {
                errors.agent_email = 'Email is required';
            } else if (!validator.isEmail(newagentdata.email)) {
                errors.agent_email = 'Email is invalid';
            }
            if (!newagentdata.phone) {
                // errors.agent_phone = 'Phone number is required';
            } else if (newagentdata.phone.length < 12) {
                errors.agent_phone = 'Phone number is invalid';
            }
        }
        return errors;
    };
    //handle change
    const handleChange = (event) => {
        setClientData({
            ...clientData,
            [event.target.name]: event.target.value,
        });
        setClientChanged(true);
    };
    const handleChangeNewClient = (event) => {
        setNewClientData({
            ...newClientData,
            [event.target.name]: event.target.value,
        });
    };
    const handleNewClientPhoneChange = (event) => {
        const formattedPhoneNumber = formatPhoneNumberDashes(event.target.value);
        setNewClientData({
            ...newClientData,
            [event.target.name]: formattedPhoneNumber,
        });
    };
    //handle change agent
    const handleAgentChange = (event) => {
        setnewagentdata({
            ...newagentdata,
            [event.target.name]: event.target.value,
        });
    };
    //handle phone change
    const handlePhoneChange = (event) => {
        const formattedPhoneNumber = formatPhoneNumberDashes(event.target.value);
        setClientData({
            ...clientData,
            [event.target.name]: formattedPhoneNumber,
        });
        setClientChanged(true);
    };
    //handle phone change agent
    const handleAgentPhoneChange = (event) => {
        const formattedPhoneNumber = formatPhoneNumberDashes(event.target.value);
        setnewagentdata({
            ...newagentdata,
            [event.target.name]: formattedPhoneNumber,
        });
    };
    //handle submit function
    const handleformSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(clientData));
        setDataIsCorrect(true);
        validation();
    };
    //handle form cancel
    const handleformCancel = (event) => {
        event.preventDefault();
        setIsNewAgent(false);
        setnewclient(false);
        setnewclientSec(false);
        setClientChanged(false);
        setSelectedClientValueChange(false);
        setOpen(false);
        setErrors({});
        setDataIsCorrect(false);
        setAgentChanged(false);
        setClientData({
            ...clientData,
            firstName0: props.selectedclientValue[0]?.first_name,
            firstName1: props.selectedclientValue[1]?.first_name,
            lastName0: props.selectedclientValue[0]?.last_name,
            lastName1: props.selectedclientValue[1]?.last_name,
            phone0: props.selectedclientValue[0]?.phone,
            phone1: props.selectedclientValue[1]?.phone,
            email0: props.selectedclientValue[0]?.email,
            email1: props.selectedclientValue[1]?.email,
        });
    };
    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            if (clientChanged) {
                if (Object.keys(errors).length === 0 && dataIsCorrect) {
                    editContact();
                }
            }
            if (selectedclients !== null) {
                updateclientandagent();
                if (selectedClientValueChange) {
                    if (Object.keys(errors).length === 0 && dataIsCorrect) {
                        editContact();
                    }
                }
            }
            if (selectedclients === null && newclient) {
                addnewClient();
            }
            if (agentChanged) {
                updateAgent();
            }
            if (isNewAgent) {
                addnewAgent();
            }
        }

        // if (Object.keys(errors).length === 0 && dataIsCorrect) {
        //     editContact();
        //     if (agentChanged) {
        //         updateclientandagent();
        //     }
        //     if (selectedclients !== null) {
        //         updateclientandagent();
        //     }
        //     if (newclient) {
        //         addnewClient();
        //     }
        //     if (isNewAgent) {
        //         addnewAgent();
        //     }
        // }
    }, [errors]);
    // useEffect(() => {

    //     setselectedclient(props.selectedclientValue);
    // }, [props.selectedclientValue]);
    useEffect(() => {
        setselectedagents(props.selectedagentValue);
    }, [props.selectedagentValue]);
    //add new client
    const addnewClient = () => {
        setLoading(true);
        const firstNameArray = [];
        const lastNameArray = [];
        const emailArray = [];
        const phoneArray = [];
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            if (newclient) {
                firstNameArray.push(newClientData.firstName);
                lastNameArray.push(newClientData.lastName);
                emailArray.push(newClientData.email);
                phoneArray.push(newClientData.phone);
            }
            // if (newclient && newclientSec) {
            //     firstNameArray.push(newclientdata.first_name, newclientdata.first_name2);
            //     lastNameArray.push(newclientdata.last_name && newclientdata.last_name2);
            //     emailArray.push(newclientdata.email, newclientdata.email2);
            //     phoneArray.push(newclientdata.phone, newclientdata.phone2);
            // }
        }
        const params = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_name_id: sessionStorage.getItem('role_id'),
            first_name: firstNameArray,
            last_name: lastNameArray,
            email: emailArray,
            phone: phoneArray,
        };
        axios
            .post(`${API_CONSTANTS.ADD_CLIENT}`, params)
            .then((response) => {
                // Request with success code true
                toast.success(response.data.message);
                // setLoading(false);
                // setOpen(false);
                // props.setRefresh(!props.refresh);
                setnewclient(false);
                setnewclientSec(false);
                setIsNewAgent(false);
                addNewClientInInsp();
                // setNewClientEmail(newClientData.email);
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    if (error.response.data.success === false) {
                        toast.error(error.response.data.message);
                        setLoading(false);
                    }
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('no response', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };
    const addNewClientInInsp = () => {
        const formdata = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
        };
        axios
            .post(`${API_CONSTANTS.NEW_INSPECTION_DETAILS}`, formdata)
            .then((response) => {
                // Request with success code true
                let obj = response.data.data.clients.find((o) => o.email === newClientData.email);
                // setNewlyAddedClientValue(obj);
                
                updateClientInInsp(obj);
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    if (error.response.data.success === false) {
                    }
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('no response', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };
    const updateClientInInsp = (obj) => {
        setLoading(true);
        var clientIdArray = [];
        // if (selectedclients !== null) {
        //     for (let i = 0; i < 2; i++) {
        //         clientIdArray.push(i === 0 ? props.selectedclientValue[i].id : selectedclients.id);
        //     }
        // }
        for (let i = 0; i < 2; i++) {
            clientIdArray.push(i === 0 ? props.selectedclientValue[i].id : obj.id);
        }
        const params = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
            inspection_id: props.inspectionId,
            client_id: clientIdArray,
            activity: 'Client details added',
        };
        axios
            .post(`${API_CONSTANTS.UPDATE_INSPECTION_DETAILS}`, params)
            .then((response) => {
                // Request with success code true
                toast.success(response.data.message);
                props.setRefresh(!props.refresh);
                setLoading(false);
                setOpen(false);
                setselectedclient(null);
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    if (error.response.data.success === false) {
                        toast.error(error.response.data.message);
                        setLoading(false);
                    }
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('no response', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };
    //add new agent
    const addnewAgent = () => {
        setLoading(true);
        const firstNameArray = [];
        const lastNameArray = [];
        const emailArray = [];
        const phoneArray = [];
        const agencyArray = [];
        firstNameArray.push(newagentdata.first_name);
        lastNameArray.push(newagentdata.last_name);
        emailArray.push(newagentdata.email);
        phoneArray.push(newagentdata.phone);
        agencyArray.push(newagentdata.agency);
        const params = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_name_id: sessionStorage.getItem('role_id'),
            first_name: firstNameArray,
            last_name: lastNameArray,
            agency: agencyArray,
            email: emailArray,
            phone: phoneArray,
        };
        axios
            .post(`${API_CONSTANTS.ADD_NEW_AGENT}`, params)
            .then((response) => {
                // Request with success code true
                toast.success(response.data.message);
                setLoading(false);
                setOpen(false);
                setIsNewAgent(false);
                if (!newclient) {
                    props.setRefresh(!props.refresh);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response, 'err');
                    toast.error(error.response.data.message);
                    setLoading(false);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('no response', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };
    const editContact = () => {
        setLoading(true);
        const data = [];

        if (props.selectedclientValue.length === 1 && selectedclients === null) {
            for (let i = 0; i < 1; i++) {
                data.push({
                    id: props.selectedclientValue[0].id,
                    first_name: clientData.firstName0,
                    last_name: clientData.lastName0,
                    email: clientData.email0,
                    phone: clientData.phone0,
                });
            }
        }
        if (props.selectedclientValue.length === 2) {
            for (let i = 0; i < 2; i++) {
                data.push({
                    id: props.selectedclientValue[i].id,
                    first_name: i === 0 ? clientData.firstName0 : clientData.firstName1,
                    last_name: i === 0 ? clientData.lastName0 : clientData.lastName1,
                    email: i === 0 ? clientData.email0 : clientData.email1,
                    phone: i === 0 ? clientData.phone0 : clientData.phone1,
                });
            }
        }
        if (selectedclients !== null) {
            for (let i = 0; i < 2; i++) {
                data.push({
                    id: i === 0 ? props.selectedclientValue[i].id : selectedclients.id,
                    first_name: i === 0 ? clientData.firstName0 : selectedclients.first_name,
                    last_name: i === 0 ? clientData.lastName0 : selectedclients.last_name,
                    email: i === 0 ? clientData.email0 : selectedclients.email,
                    phone: i === 0 ? clientData.phone0 : selectedclients.phone,
                });
            }
        }
        const params = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_name_id: sessionStorage.getItem('role_id'),
            inspection_id: props.inspectionId,
            data: data,
        };
        axios
            .post(`${API_CONSTANTS.EDIT_USER_DETAIL}`, params)
            .then((response) => {
                // Request with success code true
                toast.success(response.data.message);
                setLoading(false);
                setOpen(false);
                setIsNewAgent(false);
                setClientChanged(false);
                setSelectedClientValueChange(false);
                props.setRefresh(!props.refresh);
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    toast.error(error.response.data.message);
                    setLoading(false);
                    setOpen(true);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('no response', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };
    //update selected clients
    const updateclientandagent = () => {
        setLoading(true);
        var clientIdArray = [];
        if (selectedclients !== null) {
            for (let i = 0; i < 2; i++) {
                clientIdArray.push(i === 0 ? props.selectedclientValue[i].id : selectedclients.id);
            }
        }
        var agentIdArray = [];
        selectedagents.map((option) => agentIdArray.push(option.id));
        const params = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
            inspection_id: props.inspectionId,
            client_id: clientIdArray,
            agent_id: agentIdArray,
            activity: 'Client details added',
        };
        axios
            .post(`${API_CONSTANTS.UPDATE_INSPECTION_DETAILS}`, params)
            .then((response) => {
                // Request with success code true
                toast.success(response.data.message);
                setLoading(false);
                setOpen(false);
                setselectedclient(null);
                props.setRefresh(!props.refresh);
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    if (error.response.data.success === false) {
                        toast.error(error.response.data.message);
                        setLoading(false);
                    }
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('no response', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };
    const updateAgent = () => {
        setLoading(true);
        // var clientIdArray = [];
        // if (selectedclients !== null) {
        //     for (let i = 0; i < 2; i++) {
        //         clientIdArray.push(i === 0 ? props.selectedclientValue[i].id : selectedclients.id);
        //     }
        // }
        var agentIdArray = [];
        selectedagents.map((option) => agentIdArray.push(option.id));
        const params = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
            inspection_id: props.inspectionId,
            agent_id: agentIdArray,
            activity: 'Agent details edited',
        };
        axios
            .post(`${API_CONSTANTS.UPDATE_INSPECTION_DETAILS}`, params)
            .then((response) => {
                // Request with success code true
                // toast.success(response.data.message);
                setLoading(false);
                setOpen(false);
                setselectedclient(null);
                setAgentChanged(false);
                props.setRefresh(!props.refresh);
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    if (error.response.data.success === false) {
                        toast.error(error.response.data.message);
                        setLoading(false);
                    }
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('no response', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };

    const useStyles = styled((theme) => ({
        inputRoot: {
            '& .MuiOutlinedInput-notchedOutline': {
                height: props.contacts.selectedclient.length === 0 && '45px',
                paddingTop: props.contacts.selectedclient.length === 0 && '10px',
            },
        },
    }));
    const PaperComponentCustom = (options) => {
        const classes = useStyles();
        const { containerProps, children } = options;

        return (
            <Paper className={classes.paper} {...containerProps}>
                {children}
                <Divider />
                <Button fullWidth className="add-select" onMouseDown={handlenew}>
                    + Add New
                </Button>
            </Paper>
        );
    };
    const PaperComponentAgent = (options) => {
        const classes = useStyles();
        const { containerProps, children } = options;

        return (
            <Paper className={classes.paper} {...containerProps}>
                {children}
                <Divider />
                <Button fullWidth className="add-select" onMouseDown={handlenewagent}>
                    + Add New
                </Button>
            </Paper>
        );
    };

    // return function starts from here ***************************************
    return (
        <>
            <Preloader showPreloader={showpreloader} />
            {/* contact screen in inspection details********************* */}
            <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                    <Paper
                        elevation={0}
                        sx={{
                            minHeight: '169px',
                            border: '1px solid #E7E7E9',
                            width: { xs: '100%', md: '65.46%', lg: '65.46%' },
                        }}>
                        <Heading
                            CancelledStatus={props.CancelledStatus}
                            title={'Contacts'}
                            icon={<EditIcon className="details__icon" onClick={handleOpen} />}
                            iconDesc={
                                <span className="edit_button" onClick={handleOpen}>
                                    Edit
                                </span>
                            }
                        />
                        {/* mapping function for the contact screen (CLIENT)******************** */}
                        <Grid container spacing={0} sx={{ mt: '14px', pl: '25px' }}>
                            {props.selectedclientValue.map((value, index) => {
                                return (
                                    <>
                                        <Grid item xs={6} lg={4} sx={{ mb: { xs: 2, lg: 3 }, color: '#333333', wordWrap:"break-word !important" }}>
                                            <span style={{ fontSize: '12px', color: '#888888' }}>
                                                {`Client ${index + 1}`} {/* client's role */}
                                            </span>
                                            <br />
                                            {value.first_name} {value.last_name} {/* client's name */}
                                            <br />
                                            {value.email} {/* client's email */}
                                            <br />
                                            {value.phone} {/* client's phone */}
                                        </Grid>
                                    </>
                                );
                            })}
                            {/* mapping function for the contact screen (BUYER)******************** */}
                            {props.contacts.buyerAgent.map((value, index) => {
                                return (
                                    <>
                                        <Grid item xs={value.agent.email.length < 25 ? 6 : 12} lg={4} sx={{ mb: { xs: 2, lg: 2 }, color: '#333333', wordWrap:"break-word !important" }}>
                                            <span
                                                style={{
                                                    fontSize: '12px',
                                                    color: '#888888',
                                                }}>
                                                {`Agent ${index + 1}`} {/* buyers's role */}
                                            </span>
                                            <br />
                                            <span style={{ color: '#333333' }}>
                                                {value.agent.first_name} {value.agent.last_name}
                                            </span>{' '}
                                            {/* buyer's name */}
                                            <br />
                                            <span style={{ color: '#333333' }}>{value.agent.email}</span> {/* buyer's email */}
                                            <br />
                                            <span style={{ color: '#333333' }}>{value.phone}</span>
                                            {/* buyer's phone */}
                                        </Grid>
                                    </>
                                );
                            })}
                        </Grid>
                    </Paper>
                </Grid>
            </Box>
            {/* edit screen starts from here********************************* */}
            <Modal open={open} onClose={handleClose} className={'inspection-filter-modal-main'}>
                <Box
                    className={'inspection-filter-modal'}
                    sx={{
                        top: { xs: '70% !important', md: '65% !important', lg: '50% !important' },
                        flexGrow: 1,
                    }}>
                    <Grid container spacing={2} sx={{ p: { xs: '20px', md: '30px', lg: '30px' } }}>
                        <Grid item xs={8}>
                            <Typography
                                sx={{
                                    height: '30px',
                                    width: '350px',
                                    fontSize: '22px',
                                    letterSpacing: 0,
                                    color: '#333333',
                                    lineHeight: '30px',
                                    fontFamily: 'Open Sans',
                                }}>
                                Edit Contacts
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <CloseIcon onClick={handleClose} className={'inspection-filter-modal-close-icon'} />
                        </Grid>
                        <Grid container sx={{ p: '25px 0px 0px 27px' }}>
                            {props.contacts.selectedclient.map((value, index) => (
                                <Grid item xs={12} md={12} lg={12} className="forms-control">
                                    <CollapseSection
                                        title={
                                            <p
                                                style={{ fontSize: '15px' }}
                                                onClick={
                                                    index === 0
                                                        ? () =>
                                                              setisExpanded({
                                                                  ...expanded,
                                                                  expanded0: !expanded.expanded0,
                                                              })
                                                        : () =>
                                                              setisExpanded({
                                                                  ...expanded,
                                                                  expanded1: !expanded.expanded1,
                                                              })
                                                }>{`CLIENT ${index + 1}`}</p>
                                        }
                                        IsExpanded={index === 0 ? true : false}
                                        description={
                                            <>
                                                {' '}
                                                <Grid sx={{ p: '0px 0px 25px 5px' }} item xs={12} md={12} lg={12}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                            letterSpacing: 0,
                                                            lineHeight: '20px',
                                                            fontFamily: 'Open Sans',
                                                            mb: 0,
                                                            ml: 0.5,
                                                        }}>
                                                        The agent will receive a confirmation of the date/time of the inspection, as well as automated <br />
                                                        reminders/follow-ups according to your{' '}
                                                        <span
                                                            style={{
                                                                color: '#4282E2',
                                                                cursor: 'pointer',
                                                            }}>
                                                            automation settings.
                                                        </span>
                                                    </Typography>
                                                    <Grid container>
                                                        <Grid item lg={6} xs={12} md={6} sx={{ marginTop: -3, ml: 1 }}>
                                                            <FormControl variant="standard">
                                                                <InputLabel
                                                                    htmlFor="component-simple"
                                                                    sx={{
                                                                        color: '#777777',
                                                                        letterSpacing: 0,
                                                                        lineHeight: '21px',
                                                                        fontFamily: 'Open Sans',
                                                                        top: {
                                                                            xs: '18px',
                                                                            md: '17px',
                                                                            lg: '17px',
                                                                        },
                                                                        fontSize: {
                                                                            xs: '14px',
                                                                            md: '12px',
                                                                            lg: '12px',
                                                                        },
                                                                    }}>
                                                                    FIRST NAME*
                                                                </InputLabel>
                                                                <TextField
                                                                    margin="none"
                                                                    size="small"
                                                                    placeholder="First Name"
                                                                    name={`firstName${index}`}
                                                                    onChange={handleChange}
                                                                    error={index === 0 ? errors.clientDataFirstName0 : errors.clientDataFirstName1}
                                                                    helperText={
                                                                        index === 0
                                                                            ? errors.clientDataFirstName0 && errors.clientDataFirstName0
                                                                            : errors.clientDataFirstName1 && errors.clientDataFirstName1
                                                                    }
                                                                    value={index === 0 ? clientData.firstName0 : clientData.firstName1}
                                                                    sx={{
                                                                        letterSpacing: 0,
                                                                        marginTop: '63px',
                                                                        fontColor: '#3b3b3b',
                                                                        fontFamily: 'Open Sans',
                                                                        width: {
                                                                            xs: '350px',
                                                                            md: '400px',
                                                                            lg: '400px',
                                                                        },
                                                                        zIndex: 9,
                                                                    }}></TextField>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sx={{
                                                                marginTop: -3,
                                                                ml: { xs: 1, md: 1, lg: 0 },
                                                            }}>
                                                            <FormControl variant="standard">
                                                                <InputLabel
                                                                    htmlFor="component-simple"
                                                                    sx={{
                                                                        color: '#777777',
                                                                        letterSpacing: 0,
                                                                        lineHeight: '21px',
                                                                        fontFamily: 'Open Sans',
                                                                        top: {
                                                                            xs: '18px',
                                                                            md: '17px',
                                                                            lg: '17px',
                                                                        },
                                                                        fontSize: {
                                                                            xs: '14px',
                                                                            md: '12px',
                                                                            lg: '12px',
                                                                        },
                                                                    }}>
                                                                    LAST NAME*
                                                                </InputLabel>
                                                                <TextField
                                                                    margin="none"
                                                                    size="small"
                                                                    placeholder="Last Name"
                                                                    name={`lastName${index}`}
                                                                    onChange={handleChange}
                                                                    error={index === 0 ? errors.clientDataLastName0 : errors.clientDataLastName1}
                                                                    helperText={
                                                                        index === 0
                                                                            ? errors.clientDataLastName0 && errors.clientDataLastName0
                                                                            : errors.clientDataLastName1 && errors.clientDataLastName1
                                                                    }
                                                                    value={index === 0 ? clientData.lastName0 : clientData.lastName1}
                                                                    sx={{
                                                                        letterSpacing: 0,
                                                                        marginTop: '63px',
                                                                        fontColor: '#3b3b3b',
                                                                        fontFamily: 'Open Sans',
                                                                        width: {
                                                                            xs: '350px',
                                                                            md: '400px',
                                                                            lg: '400px',
                                                                        },
                                                                        zIndex: 9,
                                                                    }}></TextField>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item lg={6} xs={12} md={6} sx={{ marginTop: -3, ml: 1 }}>
                                                            <FormControl variant="standard">
                                                                <InputLabel
                                                                    htmlFor="component-simple"
                                                                    sx={{
                                                                        color: '#777777',
                                                                        letterSpacing: 0,
                                                                        lineHeight: '21px',
                                                                        fontFamily: 'Open Sans',
                                                                        top: {
                                                                            xs: '18px',
                                                                            md: '17px',
                                                                            lg: '17px',
                                                                        },
                                                                        fontSize: {
                                                                            xs: '14px',
                                                                            md: '12px',
                                                                            lg: '12px',
                                                                        },
                                                                    }}>
                                                                    EMAIL*
                                                                </InputLabel>
                                                                <TextField
                                                                    margin="none"
                                                                    size="small"
                                                                    placeholder="Email"
                                                                    name={`email${index}`}
                                                                    onChange={handleChange}
                                                                    error={index === 0 ? errors.clientDataEmail0 : errors.clientDataEmail1}
                                                                    helperText={index === 0 ? errors.clientDataEmail0 && errors.clientDataEmail0 : errors.clientDataEmail1 && errors.clientDataEmail1}
                                                                    value={index === 0 ? clientData.email0 : clientData.email1}
                                                                    sx={{
                                                                        letterSpacing: 0,
                                                                        marginTop: '63px',
                                                                        fontColor: '#3b3b3b',
                                                                        fontFamily: 'Open Sans',
                                                                        width: {
                                                                            xs: '350px',
                                                                            md: '400px',
                                                                            lg: '400px',
                                                                        },
                                                                        zIndex: 9,
                                                                    }}></TextField>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sx={{
                                                                marginTop: -3,
                                                                ml: { xs: 1, md: 1, lg: 0 },
                                                            }}>
                                                            <FormControl variant="standard">
                                                                <InputLabel
                                                                    htmlFor="component-simple"
                                                                    sx={{
                                                                        color: '#777777',
                                                                        letterSpacing: 0,
                                                                        lineHeight: '21px',
                                                                        fontFamily: 'Open Sans',
                                                                        top: {
                                                                            xs: '18px',
                                                                            md: '17px',
                                                                            lg: '17px',
                                                                        },
                                                                        fontSize: {
                                                                            xs: '14px',
                                                                            md: '12px',
                                                                            lg: '12px',
                                                                        },
                                                                    }}>
                                                                    PHONE
                                                                </InputLabel>
                                                                <TextField
                                                                    margin="none"
                                                                    size="small"
                                                                    type="text"
                                                                    placeholder="Phone"
                                                                    error={index === 0 ? errors.clientDataPhone0 : errors.clientDataPhone1}
                                                                    helperText={index === 0 ? errors.clientDataPhone0 && errors.clientDataPhone0 : errors.clientDataPhone1 && errors.clientDataPhone1}
                                                                    name={`phone${index}`}
                                                                    onChange={handlePhoneChange}
                                                                    value={index === 0 ? clientData.phone0 : clientData.phone1}
                                                                    sx={{
                                                                        letterSpacing: 0,
                                                                        marginTop: '63px',
                                                                        fontColor: '#3b3b3b',
                                                                        fontFamily: 'Open Sans',
                                                                        width: {
                                                                            xs: '350px',
                                                                            md: '400px',
                                                                            lg: '400px',
                                                                        },
                                                                        zIndex: 9,
                                                                    }}></TextField>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }></CollapseSection>
                                </Grid>
                            ))}
                            {props.contacts.selectedclient.length === 1 ? (
                                <Grid item xs={12} md={12} lg={12} className="forms-control">
                                    <CollapseSection
                                        title={`CLIENT 2`}
                                        IsExpanded={expanded[1]}
                                        description={
                                            <>
                                                {' '}
                                                <Grid sx={{ p: '0px 0px 25px 5px' }} item xs={12} md={12} lg={12}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                            letterSpacing: 0,
                                                            lineHeight: '20px',
                                                            fontFamily: 'Open Sans',
                                                            mb: 0,
                                                            ml: 0.5,
                                                        }}>
                                                        The client will receive a confirmation of the date/time of the inspection, as well as automated <br />
                                                        reminders/follow-ups according to your{' '}
                                                        <span
                                                            style={{
                                                                color: '#4282E2',
                                                                cursor: 'pointer',
                                                            }}>
                                                            automation settings.
                                                        </span>
                                                    </Typography>
                                                    <Grid container>
                                                        <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                                                            <Autocomplete
                                                                className="forms-control custom-color"
                                                                // multiple
                                                                fullWidth
                                                                sx={{
                                                                    ml: '-10px',
                                                                    width: '100% !important',
                                                                    pr: '7px !important',
                                                                }}
                                                                options={props.allclient}
                                                                getOptionLabel={(option) => `${option.last_name} ${option.first_name}`}
                                                                id="tags-outlined"
                                                                size="small"
                                                                PaperComponent={PaperComponentCustom}
                                                                onChange={(e, value) => {
                                                                    setselectedclient(value);
                                                                    setnewclient(false);
                                                                }}
                                                                // defaultValue={props.defaultagentArray.map((option) => props.allagents[option])}
                                                                // filterSelectedOptions
                                                                // renderTags={(value, getTagProps) =>
                                                                //     value.map((option, index) => (
                                                                //         <Chip
                                                                //             size="small"
                                                                //             deleteIcon={<CloseIcon />}
                                                                //             variant="outlined"
                                                                //             label={option.first_name}
                                                                //             {...getTagProps({
                                                                //                 index,
                                                                //             })}
                                                                //         />
                                                                //     ))
                                                                // }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        placeholder="Client"
                                                                        error={errors.selectedagents}
                                                                        helperText={errors.selectedagents && errors.selectedagents}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        {selectedclients != null ? (
                                                            <>
                                                                <Grid item lg={6} xs={12} md={6} sx={{ marginTop: -3, ml: 1 }}>
                                                                    <FormControl variant="standard">
                                                                        <InputLabel
                                                                            htmlFor="component-simple"
                                                                            sx={{
                                                                                color: '#777777',
                                                                                letterSpacing: 0,
                                                                                lineHeight: '21px',
                                                                                fontFamily: 'Open Sans',
                                                                                top: {
                                                                                    xs: '18px',
                                                                                    md: '17px',
                                                                                    lg: '17px',
                                                                                },
                                                                                fontSize: {
                                                                                    xs: '14px',
                                                                                    md: '12px',
                                                                                    lg: '12px',
                                                                                },
                                                                            }}>
                                                                            FIRST NAME*
                                                                        </InputLabel>
                                                                        <TextField
                                                                            margin="none"
                                                                            size="small"
                                                                            value={selectedclients.first_name}
                                                                            error={errors.selectedClientFirstName}
                                                                            helperText={errors.selectedClientFirstName && errors.selectedClientFirstName}
                                                                            onChange={(e) => {
                                                                                setselectedclient({ ...selectedclients, first_name: e.target.value });
                                                                                setSelectedClientValueChange(true);
                                                                            }}
                                                                            placeholder="First Name"
                                                                            sx={{
                                                                                letterSpacing: 0,
                                                                                marginTop: '63px',
                                                                                fontColor: '#3b3b3b',
                                                                                fontFamily: 'Open Sans',
                                                                                width: {
                                                                                    xs: '350px',
                                                                                    md: '350px',
                                                                                    lg: '400px',
                                                                                },
                                                                                zIndex: 9,
                                                                            }}></TextField>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item sx={{ marginTop: -3 }}>
                                                                    <FormControl variant="standard">
                                                                        <InputLabel
                                                                            htmlFor="component-simple"
                                                                            sx={{
                                                                                color: '#777777',
                                                                                letterSpacing: 0,
                                                                                lineHeight: '21px',
                                                                                fontFamily: 'Open Sans',
                                                                                top: {
                                                                                    xs: '18px',
                                                                                    md: '17px',
                                                                                    lg: '17px',
                                                                                },
                                                                                fontSize: {
                                                                                    xs: '14px',
                                                                                    md: '12px',
                                                                                    lg: '12px',
                                                                                },
                                                                            }}>
                                                                            LAST NAME*
                                                                        </InputLabel>
                                                                        <TextField
                                                                            margin="none"
                                                                            size="small"
                                                                            value={selectedclients.last_name}
                                                                            error={errors.selectedClientLastName}
                                                                            helperText={errors.selectedClientLastName && errors.selectedClientLastName}
                                                                            onChange={(e) => {
                                                                                setselectedclient({ ...selectedclients, last_name: e.target.value });
                                                                                setSelectedClientValueChange(true);
                                                                            }}
                                                                            placeholder="Last Name"
                                                                            sx={{
                                                                                letterSpacing: 0,
                                                                                marginTop: '63px',
                                                                                fontColor: '#3b3b3b',
                                                                                fontFamily: 'Open Sans',
                                                                                width: {
                                                                                    xs: '350px',
                                                                                    md: '350px',
                                                                                    lg: '400px',
                                                                                },
                                                                                zIndex: 9,
                                                                            }}></TextField>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item lg={6} xs={12} md={6} sx={{ marginTop: -3, ml: 1 }}>
                                                                    <FormControl variant="standard">
                                                                        <InputLabel
                                                                            htmlFor="component-simple"
                                                                            sx={{
                                                                                color: '#777777',
                                                                                letterSpacing: 0,
                                                                                lineHeight: '21px',
                                                                                fontFamily: 'Open Sans',
                                                                                top: {
                                                                                    xs: '18px',
                                                                                    md: '17px',
                                                                                    lg: '17px',
                                                                                },
                                                                                fontSize: {
                                                                                    xs: '14px',
                                                                                    md: '12px',
                                                                                    lg: '12px',
                                                                                },
                                                                            }}>
                                                                            EMAIL*
                                                                        </InputLabel>
                                                                        <TextField
                                                                            margin="none"
                                                                            placeholder="Email"
                                                                            value={selectedclients.email}
                                                                            error={errors.selectedClientEmail}
                                                                            helperText={errors.selectedClientEmail && errors.selectedClientEmail}
                                                                            onChange={(e) => {
                                                                                setselectedclient({ ...selectedclients, email: e.target.value });
                                                                                setSelectedClientValueChange(true);
                                                                            }}
                                                                            size="small"
                                                                            sx={{
                                                                                letterSpacing: 0,
                                                                                marginTop: '63px',
                                                                                fontColor: '#3b3b3b',
                                                                                fontFamily: 'Open Sans',
                                                                                width: {
                                                                                    xs: '350px',
                                                                                    md: '350px',
                                                                                    lg: '400px',
                                                                                },
                                                                                zIndex: 9,
                                                                            }}></TextField>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item sx={{ marginTop: -3 }}>
                                                                    <FormControl variant="standard">
                                                                        <InputLabel
                                                                            htmlFor="component-simple"
                                                                            sx={{
                                                                                color: '#777777',
                                                                                letterSpacing: 0,
                                                                                lineHeight: '21px',
                                                                                fontFamily: 'Open Sans',
                                                                                top: {
                                                                                    xs: '18px',
                                                                                    md: '17px',
                                                                                    lg: '17px',
                                                                                },
                                                                                fontSize: {
                                                                                    xs: '14px',
                                                                                    md: '12px',
                                                                                    lg: '12px',
                                                                                },
                                                                            }}>
                                                                            PHONE
                                                                        </InputLabel>
                                                                        <TextField
                                                                            margin="none"
                                                                            size="small"
                                                                            value={selectedclients.phone}
                                                                            error={errors.selectedClientPhone}
                                                                            helperText={errors.selectedClientPhone && errors.selectedClientPhone}
                                                                            onChange={(e) => {
                                                                                const formattedPhoneNumber = formatPhoneNumberDashes(e.target.value);
                                                                                setselectedclient({
                                                                                    ...selectedclients,
                                                                                    phone: formattedPhoneNumber,
                                                                                });
                                                                                // setselectedclient({ ...selectedclients, phone: e.target.value });
                                                                                setSelectedClientValueChange(true);
                                                                            }}
                                                                            placeholder="Phone"
                                                                            sx={{
                                                                                letterSpacing: 0,
                                                                                marginTop: '63px',
                                                                                fontColor: '#3b3b3b',
                                                                                fontFamily: 'Open Sans',
                                                                                width: {
                                                                                    xs: '350px',
                                                                                    md: '350px',
                                                                                    lg: '400px',
                                                                                },
                                                                                zIndex: 9,
                                                                            }}></TextField>
                                                                    </FormControl>
                                                                </Grid>
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {newclient ? (
                                                            <>
                                                                <Grid item xs={12} className="client-title" sx={{ ml: 1, fontSize: '18px' }}>
                                                                    Add a new client below
                                                                </Grid>
                                                                <Grid item lg={6} xs={12} md={6} sx={{ marginTop: -3, ml: 1 }}>
                                                                    <FormControl variant="standard">
                                                                        <InputLabel
                                                                            htmlFor="component-simple"
                                                                            sx={{
                                                                                color: '#777777',
                                                                                letterSpacing: 0,
                                                                                lineHeight: '21px',
                                                                                fontFamily: 'Open Sans',
                                                                                top: {
                                                                                    xs: '18px',
                                                                                    md: '17px',
                                                                                    lg: '17px',
                                                                                },
                                                                                fontSize: {
                                                                                    xs: '14px',
                                                                                    md: '12px',
                                                                                    lg: '12px',
                                                                                },
                                                                            }}>
                                                                            FIRST NAME*
                                                                        </InputLabel>
                                                                        <TextField
                                                                            margin="none"
                                                                            size="small"
                                                                            placeholder="First Name"
                                                                            name="firstName"
                                                                            onChange={handleChangeNewClient}
                                                                            error={errors.NewClientFirstName}
                                                                            value={newClientData.firstName}
                                                                            helperText={errors.NewClientFirstName && errors.NewClientFirstName}
                                                                            sx={{
                                                                                letterSpacing: 0,
                                                                                marginTop: '63px',
                                                                                fontColor: '#3b3b3b',
                                                                                fontFamily: 'Open Sans',
                                                                                width: {
                                                                                    xs: '340px',
                                                                                    md: '317px',
                                                                                    lg: '400px',
                                                                                },
                                                                                zIndex: 9,
                                                                            }}></TextField>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item sx={{ marginTop: -3 }}>
                                                                    <FormControl variant="standard">
                                                                        <InputLabel
                                                                            htmlFor="component-simple"
                                                                            sx={{
                                                                                color: '#777777',
                                                                                letterSpacing: 0,
                                                                                lineHeight: '21px',
                                                                                fontFamily: 'Open Sans',
                                                                                top: {
                                                                                    xs: '18px',
                                                                                    md: '17px',
                                                                                    lg: '17px',
                                                                                },
                                                                                fontSize: {
                                                                                    xs: '14px',
                                                                                    md: '12px',
                                                                                    lg: '12px',
                                                                                },
                                                                            }}>
                                                                            LAST NAME*
                                                                        </InputLabel>
                                                                        <TextField
                                                                            margin="none"
                                                                            size="small"
                                                                            placeholder="Last Name"
                                                                            name="lastName"
                                                                            onChange={handleChangeNewClient}
                                                                            error={errors.NewClientLastName}
                                                                            helperText={errors.NewClientLastName && errors.NewClientLastName}
                                                                            value={newClientData.lastName}
                                                                            sx={{
                                                                                letterSpacing: 0,
                                                                                marginTop: '63px',
                                                                                fontColor: '#3b3b3b',
                                                                                fontFamily: 'Open Sans',
                                                                                width: {
                                                                                    xs: '340px',
                                                                                    md: '317px',
                                                                                    lg: '400px',
                                                                                },
                                                                                zIndex: 9,
                                                                            }}></TextField>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item lg={6} xs={12} md={6} sx={{ marginTop: -3, ml: 1 }}>
                                                                    <FormControl variant="standard">
                                                                        <InputLabel
                                                                            htmlFor="component-simple"
                                                                            sx={{
                                                                                color: '#777777',
                                                                                letterSpacing: 0,
                                                                                lineHeight: '21px',
                                                                                fontFamily: 'Open Sans',
                                                                                top: {
                                                                                    xs: '18px',
                                                                                    md: '17px',
                                                                                    lg: '17px',
                                                                                },
                                                                                fontSize: {
                                                                                    xs: '14px',
                                                                                    md: '12px',
                                                                                    lg: '12px',
                                                                                },
                                                                            }}>
                                                                            EMAIL*
                                                                        </InputLabel>
                                                                        <TextField
                                                                            margin="none"
                                                                            placeholder="email"
                                                                            size="small"
                                                                            name="email"
                                                                            onChange={handleChangeNewClient}
                                                                            error={errors.NewClientEmail}
                                                                            helperText={errors.NewClientEmail && errors.NewClientEmail}
                                                                            value={newClientData.email}
                                                                            sx={{
                                                                                letterSpacing: 0,
                                                                                marginTop: '63px',
                                                                                fontColor: '#3b3b3b',
                                                                                fontFamily: 'Open Sans',
                                                                                width: {
                                                                                    xs: '340px',
                                                                                    md: '317px',
                                                                                    lg: '400px',
                                                                                },
                                                                                zIndex: 9,
                                                                            }}></TextField>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item sx={{ marginTop: -3 }}>
                                                                    <FormControl variant="standard">
                                                                        <InputLabel
                                                                            htmlFor="component-simple"
                                                                            sx={{
                                                                                color: '#777777',
                                                                                letterSpacing: 0,
                                                                                lineHeight: '21px',
                                                                                fontFamily: 'Open Sans',
                                                                                top: {
                                                                                    xs: '18px',
                                                                                    md: '17px',
                                                                                    lg: '17px',
                                                                                },
                                                                                fontSize: {
                                                                                    xs: '14px',
                                                                                    md: '12px',
                                                                                    lg: '12px',
                                                                                },
                                                                            }}>
                                                                            PHONE
                                                                        </InputLabel>
                                                                        <TextField
                                                                            margin="none"
                                                                            size="small"
                                                                            placeholder="Phone"
                                                                            name="phone"
                                                                            type={'text'}
                                                                            value={newClientData.phone}
                                                                            error={errors.NewClientPhone}
                                                                            helperText={errors.NewClientPhone && errors.NewClientPhone}
                                                                            onChange={handleNewClientPhoneChange}
                                                                            sx={{
                                                                                letterSpacing: 0,
                                                                                marginTop: '63px',
                                                                                fontColor: '#3b3b3b',
                                                                                fontFamily: 'Open Sans',
                                                                                width: {
                                                                                    xs: '340px',
                                                                                    md: '317px',
                                                                                    lg: '400px',
                                                                                },
                                                                            }}></TextField>
                                                                    </FormControl>
                                                                </Grid>
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }></CollapseSection>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                        {/* mapping for the user details ************************************* */}
                        {/* <Grid item lg={9} md={9} xs={12}>
              <Typography
                sx={{
                  fontSize: "14px",
                  letterSpacing: 0,
                  lineHeight: "20px",
                  fontFamily: "Open Sans",
                  mb: -7,
                }}
              >
                The client will receive a confirmation of the date/time of the
                inspection, as well as automated reminders/follow-ups according
                to your{" "}
                <span style={{ color: "#4282E2", cursor: "pointer" }}>
                  automation settings.
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 5 }}>
              <FormControl fullWidth margin="none">
                <label
                  style={{
                    fontSize: "12px",
                    fontFamily: "Open Sans",
                    color: "#777777",
                    marginBottom: "7px",
                    lineHeight: "21px",
                    marginLeft: "5px",
                  }}
                >
                  ADD CLIENTS
                </label>
                <Autocomplete
                  multiple
                  sx={{ ml: "-10px" }}
                  className="forms-control custom-color"
                  id="tags-outlined"
                  size="small"
                  PaperComponent={PaperComponentCustom}
                  onChange={(e, value) => {
                    setselectedclient(value);
                  }}
                  options={selectedclients.length < 2 ? props.allclient : []}
                  defaultValue={props.defaultArray.map(
                    (option) => props.allclient[option]
                  )}
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.last_name}`
                  }
                  filterSelectedOptions
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        size="small"
                        deleteIcon={<CloseIcon />}
                        variant="outlined"
                        label={option.first_name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={errors.selectedclient}
                      helperText={
                        errors.selectedclient && errors.selectedclient
                      }
                      placeholder="Clients"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {newclient ? (
              <>
                <Grid container className="clients-controll input-controll">
                  <Grid item xs={12} className="client-title">
                    Add a new client below
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" className="forms-control">
                      <label className="input-form-label">First Name*</label>
                      <TextField
                        className="input-textfield"
                        size="small"
                        variant="outlined"
                        placeholder="First Name"
                        type="text"
                        name="first_name"
                        onChange={handleChange}
                        error={errors.first_name}
                        value={newclientdata.first_name}
                        helperText={errors.first_name && errors.first_name}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" className="forms-control">
                      <label className="input-form-label">Last Name*</label>
                      <TextField
                        className="input-textfield"
                        size="small"
                        variant="outlined"
                        placeholder="Last Name"
                        type="text"
                        name="last_name"
                        onChange={handleChange}
                        error={errors.last_name}
                        value={newclientdata.last_name}
                        helperText={errors.last_name && errors.last_name}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" className="forms-control">
                      <label className="input-form-label">Email*</label>
                      <TextField
                        className="input-textfield"
                        size="small"
                        variant="outlined"
                        placeholder="Enter Email"
                        type="email"
                        name="email"
                        error={errors.email}
                        onChange={handleChange}
                        value={newclientdata.email}
                        helperText={errors.email && errors.email}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" className="forms-control">
                      <label className="input-form-label">Phone</label>
                      <TextField
                        className="input-textfield"
                        size="small"
                        variant="outlined"
                        placeholder="Phone"
                        name="phone"
                        error={errors.phone}
                        value={newclientdata.phone}
                        onChange={handlePhoneChange}
                        helperText={errors.phone && errors.phone}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} className="add-client pl-15 ml-0">
                    <Typography
                      sx={{ opacity: newclientSec && "0.5" }}
                      onClick={handlenewSec}
                    >
                      <AddCircleIcon />
                      &nbsp; Add another client
                    </Typography>
                  </Grid>
                </Grid>
                {newclientSec ? (
                  <>
                    <Grid item xs={12} sm={12} className="modal-sub-title2">
                      <Typography>
                        Client 2{" "}
                        <span onClick={handlenewSec}>
                          <IndeterminateCheckBoxIcon />
                          Remove
                        </span>
                      </Typography>
                    </Grid>
                    {newclientSec ? (
                      <>
                        <Grid
                          container
                          className="clients-controll input-controll"
                        >
                          <Grid item xs={12} className="client-title">
                            Add a new client below
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl
                              variant="standard"
                              className="forms-control"
                            >
                              <label
                                className="input-form-label"
                                htmlFor="first-name"
                                id="demo-simple-select-label"
                              >
                                First Name*
                              </label>
                              <TextField
                                className="input-textfield"
                                size="small"
                                variant="outlined"
                                placeholder="First Name"
                                type="text"
                                name="first_name2"
                                onChange={handleChange}
                                error={errors.first_name2}
                                value={newclientdata.first_name2}
                                helperText={
                                  errors.first_name2 && errors.first_name2
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl
                              variant="standard"
                              className="forms-control"
                            >
                              <label
                                className="input-form-label"
                                htmlFor="last-name"
                                id="demo-simple-select-label"
                              >
                                Last Name
                              </label>
                              <TextField
                                className="input-textfield"
                                size="small"
                                variant="outlined"
                                placeholder="Last Name"
                                type="text"
                                name="last_name2"
                                onChange={handleChange}
                                error={errors.last_name2}
                                value={newclientdata.last_name2}
                                helperText={
                                  errors.last_name2 && errors.last_name2
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl
                              variant="standard"
                              className="forms-control"
                            >
                              <label
                                className="input-form-label"
                                htmlFor="email"
                                id="demo-simple-select-label"
                              >
                                Email*
                              </label>
                              <TextField
                                className="input-textfield"
                                size="small"
                                variant="outlined"
                                placeholder="Enter Email"
                                type="email"
                                name="email2"
                                error={errors.email2}
                                onChange={handleChange}
                                value={newclientdata.email2}
                                helperText={errors.email2 && errors.email2}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl
                              variant="standard"
                              className="forms-control"
                            >
                              <label
                                className="input-form-label"
                                htmlFor="phone"
                                id="demo-simple-select-label"
                              >
                                Phone
                              </label>
                              <TextField
                                className="input-textfield"
                                size="small"
                                variant="outlined"
                                placeholder="Phone"
                                name="phone2"
                                error={errors.phone2}
                                value={newclientdata.phone2}
                                onChange={handlePhoneChange}
                                helperText={errors.phone2 && errors.phone2}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )} */}
                        {/* mapping data for buyer's agent****************************** */}
                        <>
                            <Grid container sx={{ p: '25px 0px 0px 27px' }}>
                                <Grid item xs={12} md={12} lg={12} className="forms-control" sx={{ mb: '-10px !important' }}>
                                    <CollapseSection
                                        title={
                                            <p style={{ fontSize: '15px' }} onClick={() => setagentExpanded(!agentexpanded)}>
                                                {"BUYER'S AGENT"}
                                            </p>
                                        }
                                        IsExpanded={false}
                                        description={
                                            <>
                                                {' '}
                                                <Grid sx={{ p: '0px 0px 25px 5px' }} item xs={12} md={12} lg={12}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                            letterSpacing: 0,
                                                            lineHeight: '20px',
                                                            fontFamily: 'Open Sans',
                                                            mb: 0,
                                                            ml: 0.5,
                                                        }}>
                                                        The agent will receive a confirmation of the date/time of the inspection, as well as automated <br />
                                                        reminders/follow-ups according to your{' '}
                                                        <span
                                                            style={{
                                                                color: '#4282E2',
                                                                cursor: 'pointer',
                                                            }}>
                                                            automation settings.
                                                        </span>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={12} sx={{ ml: 1 }}>
                                                    <FormControl variant="outlined" margin="none" fullWidth>
                                                        <label
                                                            style={{
                                                                fontSize: '12px',
                                                                fontFamily: 'Open Sans',
                                                                color: '#777777',
                                                                marginBottom: '7px',
                                                                lineHeight: '21px',
                                                                marginLeft: '5px',
                                                            }}>
                                                            BUYER'S AGENT
                                                        </label>
                                                        <Autocomplete
                                                            multiple
                                                            fullWidth
                                                            sx={{
                                                                ml: '-10px',
                                                                width: '100% !important',
                                                                pr: '7px !important',
                                                            }}
                                                            className="forms-control custom-color"
                                                            id="tags-outlined"
                                                            size="small"
                                                            PaperComponent={PaperComponentAgent}
                                                            onChange={(e, value) => {
                                                                setselectedagents(value);
                                                                setAgentChanged(true);
                                                            }}
                                                            options={selectedagents.length < 2 ? props.allagents : []}
                                                            defaultValue={props.defaultagentArray.map((option) => props.allagents[option])}
                                                            getOptionLabel={(option) => `${option.last_name} ${option.first_name}`}
                                                            filterSelectedOptions
                                                            renderTags={(value, getTagProps) =>
                                                                value.map((option, index) => (
                                                                    <Chip size="small" deleteIcon={<CloseIcon />} variant="outlined" label={option.first_name} {...getTagProps({ index })} />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    placeholder="Agents"
                                                                    error={errors.selectedagents}
                                                                    helperText={errors.selectedagents && errors.selectedagents}
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                {isNewAgent ? (
                                                    <>
                                                        <Grid container sx={{ ml: 0.7, pr: 1 }}>
                                                            <Grid item xs={12} className="client-title" sx={{ ml: 1, fontSize: '18px' }}>
                                                                Add a new agent below
                                                            </Grid>
                                                            <Grid item lg={6} xs={12} md={6} sx={{ marginTop: -3, ml: 1 }}>
                                                                <FormControl variant="standard">
                                                                    <InputLabel
                                                                        htmlFor="component-simple"
                                                                        sx={{
                                                                            color: '#777777',
                                                                            letterSpacing: 0,
                                                                            lineHeight: '21px',
                                                                            fontFamily: 'Open Sans',
                                                                            top: {
                                                                                xs: '18px',
                                                                                md: '17px',
                                                                                lg: '17px',
                                                                            },
                                                                            fontSize: {
                                                                                xs: '14px',
                                                                                md: '12px',
                                                                                lg: '12px',
                                                                            },
                                                                        }}>
                                                                        FIRST NAME*
                                                                    </InputLabel>
                                                                    <TextField
                                                                        margin="none"
                                                                        size="small"
                                                                        placeholder="First Name"
                                                                        type="text"
                                                                        name="first_name"
                                                                        onChange={handleAgentChange}
                                                                        value={newagentdata.first_name}
                                                                        error={errors.agent_first_name}
                                                                        helperText={errors.agent_first_name && errors.agent_first_name}
                                                                        sx={{
                                                                            letterSpacing: 0,
                                                                            marginTop: '63px',
                                                                            fontColor: '#3b3b3b',
                                                                            fontFamily: 'Open Sans',
                                                                            width: {
                                                                                xs: '350px',
                                                                                md: '400px',
                                                                                lg: '400px',
                                                                            },
                                                                            zIndex: 9,
                                                                        }}></TextField>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    marginTop: -3,
                                                                    ml: { xs: 1, md: 1, lg: 0 },
                                                                }}>
                                                                <FormControl variant="standard">
                                                                    <InputLabel
                                                                        htmlFor="component-simple"
                                                                        sx={{
                                                                            color: '#777777',
                                                                            letterSpacing: 0,
                                                                            lineHeight: '21px',
                                                                            fontFamily: 'Open Sans',
                                                                            top: {
                                                                                xs: '18px',
                                                                                md: '17px',
                                                                                lg: '17px',
                                                                            },
                                                                            fontSize: {
                                                                                xs: '14px',
                                                                                md: '12px',
                                                                                lg: '12px',
                                                                            },
                                                                        }}>
                                                                        LAST NAME*
                                                                    </InputLabel>
                                                                    <TextField
                                                                        margin="none"
                                                                        size="small"
                                                                        placeholder="Last Name"
                                                                        type="text"
                                                                        name="last_name"
                                                                        onChange={handleAgentChange}
                                                                        error={errors.agent_last_name}
                                                                        helperText={errors.agent_last_name && errors.agent_last_name}
                                                                        sx={{
                                                                            letterSpacing: 0,
                                                                            marginTop: '63px',
                                                                            fontColor: '#3b3b3b',
                                                                            fontFamily: 'Open Sans',
                                                                            width: {
                                                                                xs: '350px',
                                                                                md: '400px',
                                                                                lg: '400px',
                                                                            },
                                                                            zIndex: 9,
                                                                        }}></TextField>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item lg={6} xs={12} md={6} sx={{ marginTop: -3, ml: 1 }}>
                                                                <FormControl variant="standard">
                                                                    <InputLabel
                                                                        htmlFor="component-simple"
                                                                        sx={{
                                                                            color: '#777777',
                                                                            letterSpacing: 0,
                                                                            lineHeight: '21px',
                                                                            fontFamily: 'Open Sans',
                                                                            top: {
                                                                                xs: '18px',
                                                                                md: '17px',
                                                                                lg: '17px',
                                                                            },
                                                                            fontSize: {
                                                                                xs: '14px',
                                                                                md: '12px',
                                                                                lg: '12px',
                                                                            },
                                                                        }}>
                                                                        EMAIL*
                                                                    </InputLabel>
                                                                    <TextField
                                                                        margin="none"
                                                                        size="small"
                                                                        placeholder="Enter Email"
                                                                        value={newagentdata.email}
                                                                        onChange={handleAgentChange}
                                                                        error={errors.agent_email}
                                                                        helperText={errors.agent_email && errors.agent_email}
                                                                        type="email"
                                                                        name="email"
                                                                        sx={{
                                                                            letterSpacing: 0,
                                                                            marginTop: '63px',
                                                                            fontColor: '#3b3b3b',
                                                                            fontFamily: 'Open Sans',
                                                                            width: {
                                                                                xs: '350px',
                                                                                md: '400px',
                                                                                lg: '400px',
                                                                            },
                                                                            zIndex: 9,
                                                                        }}></TextField>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    marginTop: -3,
                                                                    ml: { xs: 1, md: 1, lg: 0 },
                                                                }}>
                                                                <FormControl variant="standard">
                                                                    <InputLabel
                                                                        htmlFor="component-simple"
                                                                        sx={{
                                                                            color: '#777777',
                                                                            letterSpacing: 0,
                                                                            lineHeight: '21px',
                                                                            fontFamily: 'Open Sans',
                                                                            top: {
                                                                                xs: '18px',
                                                                                md: '17px',
                                                                                lg: '17px',
                                                                            },
                                                                            fontSize: {
                                                                                xs: '14px',
                                                                                md: '12px',
                                                                                lg: '12px',
                                                                            },
                                                                        }}>
                                                                        PHONE
                                                                    </InputLabel>
                                                                    <TextField
                                                                        margin="none"
                                                                        size="small"
                                                                        placeholder="Phone"
                                                                        // type="number"
                                                                        onChange={handleAgentPhoneChange}
                                                                        value={newagentdata.phone}
                                                                        error={errors.agent_phone}
                                                                        helperText={errors.agent_phone && errors.agent_phone}
                                                                        name="phone"
                                                                        sx={{
                                                                            letterSpacing: 0,
                                                                            marginTop: '63px',
                                                                            fontColor: '#3b3b3b',
                                                                            fontFamily: 'Open Sans',
                                                                            width: {
                                                                                xs: '350px',
                                                                                md: '400px',
                                                                                lg: '400px',
                                                                            },
                                                                            zIndex: 9,
                                                                        }}></TextField>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item lg={6} xs={12} md={6} sx={{ marginTop: -3, ml: 1 }}>
                                                                <FormControl variant="standard">
                                                                    <InputLabel
                                                                        htmlFor="component-simple"
                                                                        sx={{
                                                                            color: '#777777',
                                                                            letterSpacing: 0,
                                                                            lineHeight: '21px',
                                                                            fontFamily: 'Open Sans',
                                                                            top: {
                                                                                xs: '18px',
                                                                                md: '17px',
                                                                                lg: '17px',
                                                                            },
                                                                            fontSize: {
                                                                                xs: '14px',
                                                                                md: '12px',
                                                                                lg: '12px',
                                                                            },
                                                                        }}>
                                                                        COMPANY
                                                                    </InputLabel>
                                                                    <TextField
                                                                        margin="none"
                                                                        size="small"
                                                                        placeholder="Company"
                                                                        value={newagentdata.agency}
                                                                        onChange={handleAgentChange}
                                                                        // error={errors.agent_email}
                                                                        // helperText={errors.agent_email && errors.agent_email}
                                                                        type="email"
                                                                        name="agency"
                                                                        sx={{
                                                                            letterSpacing: 0,
                                                                            marginTop: '63px',
                                                                            fontColor: '#3b3b3b',
                                                                            fontFamily: 'Open Sans',
                                                                            width: {
                                                                                xs: '350px',
                                                                                md: '400px',
                                                                                lg: '400px',
                                                                            },
                                                                            zIndex: 9,
                                                                        }}></TextField>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        {/* <Grid
                              container
                              className="clients-controll input-controll"
                              sx={{ paddingBottom: "0px !important" }}
                            >
                              
                              <Grid item xs={12} className="client-title">
                                Add a new agent below
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl
                                  variant="standard"
                                  className="forms-control"
                                >
                                  <label
                                    className="input-form-label"
                                    htmlFor="first-name"
                                    id="demo-simple-select-label"
                                  >
                                    First Name*
                                  </label>
                                  <TextField
                                    className="input-textfield"
                                    size="small"
                                    variant="outlined"
                                    placeholder="First Name"
                                    type="text"
                                    name="first_name"
                                    onChange={handleAgentChange}
                                    value={newagentdata.first_name}
                                    error={errors.agent_first_name}
                                    helperText={
                                      errors.agent_first_name &&
                                      errors.agent_first_name
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl
                                  variant="standard"
                                  className="forms-control"
                                >
                                  <label
                                    className="input-form-label"
                                    htmlFor="last-name"
                                    id="demo-simple-select-label"
                                  >
                                    Last Name*
                                  </label>
                                  <TextField
                                    className="input-textfield"
                                    variant="outlined"
                                    size="small"
                                    placeholder="Last Name"
                                    type="text"
                                    name="last_name"
                                    onChange={handleAgentChange}
                                    error={errors.agent_last_name}
                                    helperText={
                                      errors.agent_last_name &&
                                      errors.agent_last_name
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl
                                  variant="standard"
                                  className="forms-control"
                                >
                                  <label
                                    className="input-form-label"
                                    htmlFor="email"
                                    id="demo-simple-select-label"
                                  >
                                    Email*
                                  </label>
                                  <TextField
                                    className="input-textfield"
                                    size="small"
                                    variant="outlined"
                                    placeholder="Enter Email"
                                    value={newagentdata.email}
                                    onChange={handleAgentChange}
                                    error={errors.agent_email}
                                    helperText={
                                      errors.agent_email && errors.agent_email
                                    }
                                    type="email"
                                    name="email"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormControl
                                  variant="standard"
                                  className="forms-control"
                                >
                                  <label
                                    className="input-form-label"
                                    htmlFor="phone"
                                    id="demo-simple-select-label"
                                  >
                                    Phone
                                  </label>
                                  <TextField
                                    className="input-textfield"
                                    size="small"
                                    // id="phone-number"
                                    variant="outlined"
                                    placeholder="Phone"
                                    // type="number"
                                    onChange={handleAgentPhoneChange}
                                    value={newagentdata.phone}
                                    error={errors.agent_phone}
                                    helperText={
                                      errors.agent_phone && errors.agent_phone
                                    }
                                    name="phone"
                                  />
                                </FormControl>
                              </Grid> */}
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        }></CollapseSection>
                                </Grid>
                            </Grid>

                            {/* <CollapseSection
                title={"BUYER'S AGENT"}
                IsExpanded
                description={
                  <Grid sx={{ p: "0px 0px 25px 23px" }} container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          letterSpacing: 0,
                          lineHeight: "20px",
                          fontFamily: "Open Sans",
                          mb: 0,
                        }}
                      >
                        The agent will receive a confirmation of the date/time
                        of the inspection, as well as automated <br />
                        reminders/follow-ups according to your{" "}
                        <span style={{ color: "#4282E2", cursor: "pointer" }}>
                          automation settings.
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl variant="outlined">
                        <FormControl margin="none">
                          <label
                            style={{
                              fontSize: "12px",
                              fontFamily: "Open Sans",
                              color: "#777777",
                              marginBottom: "7px",
                              lineHeight: "21px",
                              marginLeft: "5px",
                            }}
                          >
                            BUYER'S AGENT
                          </label>
                          {console.log(props.defaultagentArray, "xxx")}
                          {console.log(props.allagents, "ggg")}
                          <Autocomplete
                            multiple
                            fullWidth
                            sx={{ ml: "-10px", width: "100% !important" }}
                            className="forms-control custom-color"
                            id="tags-outlined"
                            size="small"
                            PaperComponent={PaperComponentAgent}
                            onChange={(e, value) => setselectedagents(value)}
                            options={
                              selectedagents.length < 2 ? props.allagents : []
                            }
                            defaultValue={props.defaultagentArray.map(
                              (option) => props.allagents[option]
                            )}
                            getOptionLabel={(option) =>
                              `${option.first_name} ${option.last_name}`
                            }
                            filterSelectedOptions
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  size="small"
                                  deleteIcon={<CloseIcon />}
                                  variant="outlined"
                                  label={option.first_name}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Agents"
                                error={errors.selectedagents}
                                helperText={
                                  errors.selectedagents && errors.selectedagents
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </FormControl>
                    </Grid>
                  </Grid>
                }
              /> */}
                        </>
                    </Grid>
                    <Grid container spacing={2} className="inspection-filter-modal-buttons" sx={{ pb: 2 }}>
                        <Button variant="outlined" className="inspection-filter-modal-buttons-cancel" onClick={handleformCancel}>
                            Cancel
                        </Button>
                        <LoadingButton variant="contained" loading={loading} className="inspection-filter-modal-buttons-apply" onClick={handleformSubmit}>
                            Done
                        </LoadingButton>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};
