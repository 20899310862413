import React from 'react'
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const SettingsCard = ({navigatePath,title,description,icon,permission,id}) => {  
  let navigate = useNavigate();
  return (
    <Grid item xs={12} sm={4}>
    <Card className="settings-card" id={`${id}_settings`} name={`${id}_settings`} variant="outlined" onClick={()=>{permission && navigate(`${navigatePath}`)}} style={{cursor:`${!permission && 'not-allowed'}`}}>     
      <Grid container className="details-cards">
        <Grid>
          <div className='icon-container'>{icon}</div>
        </Grid>
        <Grid className='name-wrapper'>
          <span className='setting-name'>{title}</span>
          <span> <KeyboardArrowRightIcon /></span>
        </Grid>
        <Grid className='setting-name-desc'>{description}</Grid>       
      </Grid>
    </Card>
  </Grid>
  )
}

export default SettingsCard