import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import uuid from "react-uuid";
import { Base64 } from "js-base64";
import parse from "html-react-parser";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import SearchIcon from "@mui/icons-material/Search";
import PublicIcon from "@mui/icons-material/Public";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableContainer from "@mui/material/TableContainer";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import TableSortLabel from "@mui/material/TableSortLabel";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CLAddCommentModal from "./CLAddCommentModal";
import CommentTags from "./CommentTags";
import "../../../assets/styles/containers/_commentLibraryModal.scss";
import { timestamp, decodeEntities } from "../../utils/services";
import { setShowCommentLibraryModal } from "../../store/action/CommentLibraryAction";
import { descendingComparator, filterSelectTag, preprocessHTMLContent, stableSort } from "./CommentLibraryHelper";
import { getCommentLibraryList, updateDropDownValues } from "../../store/action/CommentLibraryAction";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//   library table
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

const CommentLibraryModal = ({ updateTemplate, AllCategoryList }) => {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.commentLibrary);
  const rowsPerPage = 10;
  const showCommentLibraryModal = storeData?.showClModal;

  const [state, setState] = useState({
    value: 0,
    order: "asc",
    orderBy: "",
    selected: [],
    page: 0,
    expandedRows: [],
    commentListData: null,
    initialTableData: null,
    search: false,
    commentTitle: "",
    commentTag: [],
    commentSummaryTag: [],
    addToLibrary: false,
    commentText: { innerHTML: "" },
    commentSummary: { innerHTML: "" },
    isLoading: false,
    searchText: "",
    commentDropdownList: [],
    summaryDropdownList: [],
  });

  const {
    value,
    order,
    orderBy,
    selected,
    page,
    expandedRows,
    commentListData,
    initialTableData,
    search,
    commentTitle,
    commentTag,
    commentSummaryTag,
    addToLibrary,
    commentText,
    commentSummary,
    isLoading,
    searchText,
    commentDropdownList,
    summaryDropdownList,
  } = state;

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox"></TableCell>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} align={"left"} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false} className="cl-table-head">
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={() => (headCell.id !== "name" && headCell.id !== "global" ? <UnfoldMoreIcon className="cl-sort-icon" /> : "")}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: <LastPageIcon className="cl-table-exp-icon" onClick={toggleAllRowsExpansion} />,
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
      width: 217,
    },
    {
      id: "comment",
      numeric: false,
      disablePadding: false,
      label: "Comment",
    },
    {
      id: "summary",
      numeric: false,
      disablePadding: false,
      label: "Summary",
    },
    {
      id: "rating",
      numeric: false,
      disablePadding: false,
      label: "Summary Section",
    },
    {
      id: "global",
      numeric: false,
      disablePadding: true,
      label: "",
    },
  ];

  const toggleRowExpansion = (rowId) => {
    setState((prevState) => ({ ...prevState, expandedRows: expandedRows.includes(rowId) ? expandedRows.filter((id) => id !== rowId) : [...expandedRows, rowId] }));
  };

  const isRowExpanded = (rowId) => expandedRows.includes(rowId);

  function toggleAllRowsExpansion() {
    const allRowIds = commentListData?.map((row) => row.id);
    setState((prevState) => ({ ...prevState, expandedRows: expandedRows.length === allRowIds.length ? [] : allRowIds }));
  }

  const handleChange = (event, newValue) => {
    setState((prevState) => ({ ...prevState, value: newValue }));
  };

  const handleClose = async () => {
    setState((prevState) => ({
      ...prevState,
      selected: [],
      page: 0,
      expandedRows: [],
      search: false,
      commentTitle: "",
      commentTag: [],
      commentSummaryTag: [],
      addToLibrary: false,
      commentText: { innerHTML: "" },
      commentSummary: { innerHTML: "" },
      commentListData: storeData?.commentLibraryList,
    }));
    await dispatch(getCommentLibraryList(1, ""));
    dispatch(setShowCommentLibraryModal({ open: false }));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setState((prevState) => ({ ...prevState, order: isAsc ? "desc" : "asc", orderBy: property }));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const selectedComment = commentListData?.map((n) => n.id);
      setState((prevState) => ({ ...prevState, selected: selectedComment }));
      return;
    }
    setState((prevState) => ({ ...prevState, selected: [] }));
  };

  const handleClickChangeDropdown = (event, id, row) => {
    !isRowExpanded(id) && toggleRowExpansion(row.id);
    const selectedIndex = selected.indexOf(row.uuid);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.uuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setState((prevState) => ({ ...prevState, selected: newSelected }));
  };

  const handleSearchFilter = (event) => {
    setState((prevState) => ({ ...prevState, searchText: event }));
    const query = event.toLowerCase();
    if (query === "") {
      setState((prevState) => ({ ...prevState, page: 0, search: false, commentListData: storeData?.commentLibraryList || [] }));
    } else {
      const searchData = initialTableData?.filter((item) => {
        return item?.comment_text?.toLowerCase()?.includes(query) || item?.title?.toLowerCase()?.includes(query);
      });
      setState((prevState) => ({ ...prevState, page: 0, search: true, commentListData: searchData }));
    }
  };

  const handleChangePage = async (event, newPage) => {
    let page = newPage + 1;
    setState((prevState) => ({ ...prevState, page: newPage, isLoading: true }));
    const response = await dispatch(getCommentLibraryList(page));
    setState((prevState) => ({ ...prevState, isLoading: false, commentListData: response.payload.data }));
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = useMemo(() => stableSort(commentListData, getComparator(order, orderBy)), [order, orderBy, page, rowsPerPage, commentListData]);
  const handleAddSelectedComment = () => {
    let newCommentList = [];
    storeData?.commentLibraryWholeList?.forEach((item) => {
      if (selected?.indexOf(item.uuid) !== -1) {
        const newUuid = uuid();
        const modifiedTag = item?.tags.map((obj) => ({
          id: obj.tag?.id,
          name: obj.tag?.name,
          color: obj.tag?.color,
        }));
        const modifiedSummary = item?.summaries.map((obj) => ({
          id: obj.summary?.id,
          name: obj.summary?.name,
          color: obj.summary?.color,
        }));
        const modifiedCommentDropdown = item?.comment_dropdowns.map((obj) => ({
          dropdown_uuid: obj?.dropdown_uuid,
          dropdowns_values: obj?.dropdowns_values?.values,
          selected_value: obj?.selected_value,
        }));
        const modifiedSummaryCommentDropdown = item?.summary_comment_dropdowns.map((obj) => ({
          dropdown_uuid: obj?.dropdown_uuid,
          dropdowns_values: obj?.dropdowns_values?.values,
          selected_value: obj?.selected_value,
        }));
        const dropdownPattern = /\[dropdown ([a-f0-9-]+)\]/g;
        const updatedCommentText = item?.comment_text.replace(dropdownPattern, (match, id) => {
          return `<span contenteditable="false">${match}</span>`;
        });
        const updatedSummaryText = item?.summary_comment_text.replace(dropdownPattern, (match, id) => {
          return `<span contenteditable="false">${match}</span>`;
        });
        newCommentList.push({
          comment_id: newUuid,
          uuid: newUuid,
          comment_uuid: item?.uuid,
          updated_at: timestamp(),
          title: item?.title,
          comment_text: updatedCommentText,
          summary_comment_text: updatedSummaryText,
          comment_dropdowns: modifiedCommentDropdown,
          summary_comment_dropdowns: modifiedSummaryCommentDropdown,
          tags: modifiedTag,
          summaries: modifiedSummary,
          is_deleted: 0,
          is_selected: 1,
          is_expanded: 1,
          is_selected_updated_at: timestamp(),
          comment_input_controls: [],
        });
      }
    });
    const catList = AllCategoryList?.template?.categories;
    const categoriesData = catList.findIndex((cat) => cat.category_id === showCommentLibraryModal?.selectedCategory?.id);
    let lineIndex = catList[categoriesData].lines.findIndex((line) => line.line_id === showCommentLibraryModal?.selectedLine?.uuid);
    catList[categoriesData].lines[lineIndex].comments = [...catList[categoriesData].lines[lineIndex].comments, ...newCommentList];
    catList[categoriesData].lines[lineIndex].updated_at = timestamp();
    updateTemplate("noToast");
    handleClose();
  };

  const replacePlaceholdersWithDropdowns = (htmlString, dropdowns, type, commentId, commentUUId, rowDisabled) => {
    const regexSpecialSymbol = /Ã|Â|ÃÂ/g;
    dropdowns.forEach((dropdown) => {
      const placeholder = `\\[dropdown ${dropdown.dropdown_uuid}\\]`;
      const regex = new RegExp(placeholder, "g");
      const selectElement = document.createElement("select");
      const option = document.createElement("option");
      option.text = "-------------";
      option.value = "";
      selectElement.appendChild(option);
      if (Array?.isArray(dropdown?.dropdowns_values?.values)) {
        dropdown?.dropdowns_values?.values?.forEach((value) => {
          const option = document.createElement("option");
          if (value?.length > 40) option.text = `${value.substring(0, 40)}...`;
          else option.text = value;
          option.value = value;
          option.title = value;
          selectElement.appendChild(option);
          if (dropdown.selected_value === value) {
            option.setAttribute("selected", "selected");
          }
          selectElement.classList.add(`${type}-cl-dropdown`);
          selectElement.setAttribute("id", `${dropdown.dropdown_uuid}`);
          selectElement.setAttribute("comment_id", commentId);
          selectElement.setAttribute("comment_uuid", commentUUId);
          selectElement.setAttribute("dropType", type);
          if (rowDisabled) {
            selectElement.setAttribute("disabled", "true");
          } else {
            selectElement.removeAttribute("disabled");
          }
        });
      } else if (Array?.isArray(dropdown?.dropdowns_values)) {
        dropdown?.dropdowns_values?.forEach((value) => {
          const option = document.createElement("option");
          if (value?.length > 40) option.text = `${value.substring(0, 40)}...`;
          else option.text = value;
          option.value = value;
          option.title = value;
          selectElement.appendChild(option);
          if (dropdown.selected_value === value) {
            option.setAttribute("selected", "selected");
          }
          selectElement.classList.add(`${type}-cl-dropdown`);
          selectElement.setAttribute("id", `${dropdown.dropdown_uuid}`);
          selectElement.setAttribute("comment_id", commentId);
          selectElement.setAttribute("comment_uuid", commentUUId);
          selectElement.setAttribute("dropType", type);
          if (rowDisabled) {
            selectElement.setAttribute("disabled", "true");
          } else {
            selectElement.removeAttribute("disabled");
          }
        });
      }
      htmlString = htmlString.replace(regex, selectElement.outerHTML);
    });
    return parse(decodeEntities(htmlString?.replace(regexSpecialSymbol, "")));
  };

  const dropdownCollapsedPlaceHolder = (htmlString, dropdowns, type, commentId, commentUUId) => {
    const regexSpecialSymbol = /Ã|Ã|ÃÂ/g;
    dropdowns.forEach((dropdown) => {
      const placeholder = `\\[dropdown ${dropdown.dropdown_uuid}\\]`;
      const regex = new RegExp(placeholder, "g");
      const spanElement = document.createElement("span");
      if (Array?.isArray(dropdown?.dropdowns_values?.values)) {
        dropdown?.dropdowns_values?.values?.forEach((value) => {
          if (dropdown.selected_value === "") spanElement.innerHTML = "------------";
          else spanElement.innerHTML = dropdown.selected_value;
          spanElement.classList.add(`${type}-collapsed-dropdownbox`);
          spanElement.setAttribute("id", `${dropdown.dropdown_uuid}`);
        });
      } else if (Array?.isArray(dropdown?.dropdowns_values)) {
        dropdown?.dropdowns_values?.forEach((value) => {
          if (dropdown.selected_value === "") spanElement.innerHTML = "------------";
          else spanElement.innerHTML = dropdown.selected_value;
          spanElement.classList.add(`${type}-collapsed-dropdownbox`);
          spanElement.setAttribute("id", `${dropdown.dropdown_uuid}`);
        });
      }
      htmlString = htmlString.replace(regex, spanElement.outerHTML);
    });
    return parse(decodeEntities(htmlString?.replace(regexSpecialSymbol, "")));
  };

  let commentDropElements = document.querySelectorAll(".comment-cl-dropdown");
  commentDropElements?.forEach((element) => {
    const commentId = element.getAttribute("comment_id");
    const dropType = element.getAttribute("dropType");
    const commentUUId = element.getAttribute("comment_uuid");
    const commentDisabled = element.getAttribute("disabled");
    if (element !== null && element !== undefined) {
      element.onchange = (event) => handleChangeDropdown(event, element.id, commentId, dropType, commentUUId, commentDisabled);
    }
  });
  let summaryDropElements = document.querySelectorAll(".summary-cl-dropdown");
  summaryDropElements?.forEach((element) => {
    const commentId = element.getAttribute("comment_id");
    const dropType = element.getAttribute("dropType");
    const commentUUId = element.getAttribute("comment_uuid");
    const commentDisabled = element.getAttribute("disabled");
    if (element !== null && element !== undefined) {
      element.onchange = (event) => handleChangeDropdown(event, element.id, commentId, dropType, commentUUId, commentDisabled);
    }
  });

  const handleChangeDropdown = (event, dropdownUuid, commentId, type, commentUUId, commentDisabled) => {
    commentDisabled === "false" && handleClickChangeDropdown(event, commentId, { id: commentId, uuid: commentUUId });
    const selectedValue = event.target.value;
    const dropdownValuesArrays = commentListData?.map((item) => {
      const commentDropdowns = item.comment_dropdowns || [];
      const summaryDropdowns = item.summary_comment_dropdowns || [];
      const allDropdowns = [...commentDropdowns, ...summaryDropdowns];
      return allDropdowns.filter((dropdown) => dropdown.dropdown_uuid === dropdownUuid).map((dropdown) => dropdown.dropdowns_values.values);
    });
    const dropdownValues = dropdownValuesArrays?.flat()?.find((values) => values.length > 0);
    let payload = {
      comment_id: commentId,
      dropdown_uuid: dropdownUuid,
      dropdown_type: type === "comment" ? "1" : "2",
      selected_value: selectedValue,
      dropdown_values: dropdownValues,
    };
    updateDropDownValues(dispatch, payload);
  };
  const handleAddNewComment = () => {
    const modifiedTag = commentTag.map((obj) => ({
      id: obj?.id,
      name: obj?.name || obj?.title,
      color: obj?.color,
    }));
    const modifiedSummary = commentSummaryTag?.map((obj) => ({
      id: obj?.id,
      name: obj?.name || obj?.title,
      color: obj?.color,
    }));
    const newUuid = uuid();
    let newCommentList = {
      comment_id: newUuid,
      uuid: newUuid,
      updated_at: timestamp(),
      title: commentTitle,
      comment_text: filterSelectTag(commentText?.innerHTML) || "",
      summary_comment_text: filterSelectTag(commentSummary?.innerHTML) || "",
      comment_dropdowns: commentDropdownList || [],
      summary_comment_dropdowns: summaryDropdownList || [],
      tags: modifiedTag,
      summaries: modifiedSummary,
      is_deleted: 0,
      is_selected: 1,
      is_expanded: 1,
      is_selected_updated_at: timestamp(),
      comment_input_controls: [],
    };
    const catList = AllCategoryList?.template?.categories;
    const categoriesData = catList.findIndex((cat) => cat.category_id === showCommentLibraryModal?.selectedCategory?.id);
    let lineIndex = catList[categoriesData].lines.findIndex((line) => line.line_id === showCommentLibraryModal?.selectedLine?.uuid);
    catList[categoriesData].lines[lineIndex]?.comments.push(newCommentList);
    catList[categoriesData].lines[lineIndex].updated_at = timestamp();
    updateTemplate("noToast");
    handleClose();
  };

  useEffect(() => {
    async function callApi() {
      await dispatch(getCommentLibraryList(1));
    }
    if (showCommentLibraryModal && !storeData?.commentLibraryList) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      callApi();
      setState((prevState) => ({ ...prevState, value: 0, isLoading: false }));
    }
    if (storeData?.totalListCount && !storeData.commentLibraryWholeList) {
      dispatch(getCommentLibraryList(null, storeData?.totalListCount));
    }
  }, [showCommentLibraryModal, storeData?.totalListCount, storeData?.commentLibraryList, dispatch]);

  useEffect(() => {
    if (storeData.commentLibraryWholeList && !initialTableData) {
      setState((prevState) => ({ ...prevState, initialTableData: storeData.commentLibraryWholeList || [] }));
    }
    if (storeData.commentLibraryList && !commentListData) {
      setState((prevState) => ({ ...prevState, commentListData: storeData.commentLibraryList || [] }));
    }
  }, [storeData.commentLibraryWholeList, storeData?.commentLibraryList]);

  return (
    <Dialog className="dialog-container-comment-library" open={showCommentLibraryModal?.open} onClose={handleClose} aria-labelledby="category-modal" aria-describedby="category-modal-description">
      <DialogTitle>
        <Grid container display={"flex"}>
          <Grid item xs={11} display={"flex"} alignItems={"center"}>
            <Typography className="comment-library-dialog-title">{showCommentLibraryModal?.selectedCategory?.name || ""} - Add comment</Typography>
          </Grid>
          <Grid item xs={1} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
            <CloseIcon className="checkbox_container" onClick={handleClose} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Box className="lineDetails__tab cl_tabs" sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange} aria-label="line_details_tab">
                <Tab className={`tab__label cl_tab_panel ${value === 0 ? "active" : "inactive"}`} label="Comment Library" {...a11yProps(0)} />
                <Tab className={`tab__label cl_tab_panel captitalize ${value === 1 ? "active" : "inactive"}`} label={"New comment"} {...a11yProps(1)} />
              </Tabs>
            </Box>
            <Grid></Grid>
            <Box className="dialog-content-comment-library">
              {/* Comment library tab */}
              <TabPanel value={value} index={0} className="tab_panel_cLibrary">
                {isLoading ? (
                  <Box className="loader-container-comment-library" sx={{ width: "100%" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box sx={{ width: "100%", paddingBottom: "0px !important" }}>
                    <Grid container className="cl-search-container">
                      <Grid item xs={12} md={9} className="cl-search-bar">
                        <div className="cl-search">
                          <TextField
                            id="search-bar"
                            onChange={(e) => handleSearchFilter(e.target.value)}
                            variant="outlined"
                            value={searchText}
                            placeholder="Search"
                            size="small"
                            InputProps={{
                              style: { height: "36px", paddingRight: "30px" },
                              inputProps: { style: { height: "36px", padding: "0 14px" } },
                            }}
                            style={{ height: "36px", width: "100%" }}
                          />
                          <SearchIcon className="cl-search-icon" />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={1.5}></Grid>
                      <Grid item xs={12} md={1.5}></Grid>
                    </Grid>
                    <TableContainer>
                      <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                        <EnhancedTableHead
                          numSelected={selected?.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={commentListData?.length}
                        />
                        <TableBody>
                          {visibleRows?.map((row, index) => {
                            const isItemSelected = isSelected(row.uuid);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.uuid} selected={isItemSelected} sx={{ cursor: "pointer" }} className="cl_row">
                                <TableCell
                                  padding="checkbox"
                                  onClick={(event) => handleClickChangeDropdown(event, row.id, row)}
                                  className={`cl-checkbox-col ${isRowExpanded(row.id) ? (isItemSelected ? "cl-check-active" : "") : ""}`}
                                >
                                  <Checkbox
                                    sx={{
                                      height: "37.5px !important",
                                      width: "37.5px !important",
                                      "& .MuiSvgIcon-root": { fontSize: "20px !important" },
                                    }}
                                    color="primary"
                                    checked={isItemSelected}
                                  />
                                </TableCell>
                                <TableCell id={labelId} scope="row" padding="none" className="cl-row-status cl-exp-col">
                                  <IconButton sx={{ height: "19.5px", width: "19.5px", "& .MuiSvgIcon-root": { fontSize: "19.5px !important" } }} onClick={() => toggleRowExpansion(row.id)}>
                                    {isRowExpanded(row.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                  </IconButton>
                                </TableCell>
                                <TableCell className="cl-title-col" align="left">
                                  <div className={`cl-comment-text ${isRowExpanded(row.id) ? "expanded" : ""}`} onClick={() => toggleRowExpansion(row.id)}>
                                    {row?.title}
                                  </div>
                                </TableCell>
                                <TableCell className="cl-comment-col" align="left">
                                  <div className={`cl-comment-text ${isRowExpanded(row.id) ? "expanded" : ""}`} onClick={() => (!isRowExpanded(row.id) ? toggleRowExpansion(row.id) : undefined)}>
                                    {row?.comment_text !== null && replacePlaceholdersWithDropdowns(row?.comment_text, row?.comment_dropdowns, "comment", row?.id, row?.uuid)}
                                    {row?.comment_text !== null && dropdownCollapsedPlaceHolder(row?.comment_text, row?.comment_dropdowns, "comment", row?.id, row?.uuid)}
                                  </div>
                                  <CommentTags tags={row?.tags} />
                                </TableCell>
                                <TableCell className="cl-summary-col" align="left" onClick={() => (!isRowExpanded(row.id) ? toggleRowExpansion(row.id) : undefined)}>
                                  <div style={{ fontSize: "14px !important" }} className={`comment-summary ${isRowExpanded(row.id) ? "expanded" : ""}`}>
                                    {row?.summary_comment_text !== null && replacePlaceholdersWithDropdowns(row?.summary_comment_text, row?.summary_comment_dropdowns, "summary", row?.id, row?.uuid)}
                                    {row?.summary_comment_text !== null && dropdownCollapsedPlaceHolder(row?.summary_comment_text, row?.summary_comment_dropdowns, "summary", row?.id, row?.uuid)}
                                  </div>
                                </TableCell>
                                <TableCell className="cl-rating-col" align="left">
                                  {row?.summaries?.length !== 0
                                    ? row?.summaries?.map((element) => (
                                        <div className="cl_rating_square_container">
                                          <div
                                            className="rating_square"
                                            style={{
                                              background: `${element?.summary?.color}`,
                                              padding: "4px",
                                            }}
                                          ></div>
                                          <div className="cl_rating_name_text">{element?.summary?.name}</div>
                                        </div>
                                      ))
                                    : ""}
                                </TableCell>
                                <TableCell className="cl-global-col" align="left">
                                  <div>
                                    <PublicIcon />
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[rowsPerPage]}
                      component="div"
                      count={search ? storeData?.commentLibraryList?.length : storeData?.totalListCount}
                      rowsPerPage={search ? storeData?.commentLibraryList?.length : rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      ActionsComponent={TablePaginationActions}
                    />
                    {/* </Paper> */}
                  </Box>
                )}
              </TabPanel>
              {/* New comments tab */}

              <TabPanel value={value} index={1} className="tab_panel_cLibrary">
                <CLAddCommentModal state={state} setState={setState} />
              </TabPanel>
            </Box>
          </Box>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className="comment-library-action">
        <div className={`comment-library-checkbox-container ${value === 0 ? "hidden" : "visible"}`}>
          {/* This is required for future implementation */}
          {/* <FormGroup>
            <FormControlLabel className="cl_checkbox" control={<Checkbox />} label="Push to template" />
          </FormGroup> */}
          {/* {value === 1 ? (
            <FormGroup>
              <FormControlLabel
                className="cl_checkbox"
                control={
                  <Checkbox
                    checked={addToLibrary}
                    onChange={() => setState((prevState) => ({ ...prevState, addToLibrary: !addToLibrary }))}
                    sx={{
                      height: "24px !important",
                      width: "24px !important",
                      marginRight: "8px",
                      "& .MuiSvgIcon-root": { height: "24px !important", width: "24px !important" },
                    }}
                  />
                }
                label="Save to comment  library"
              />
            </FormGroup>
          ) : null} */}
        </div>
        <div className="comment-library-action-btn">
          <Button className="cl-dialog-cancel-btn" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={
              !storeData?.commentLibraryWholeList ||
              (value === 1
                ? commentTag.length === 0 ||
                  (commentTitle?.trim() === "" && (commentText?.innerHTML?.trim() === "" || commentText?.innerHTML?.trim() === "<p><br></p>" || preprocessHTMLContent(commentText?.innerHTML) === ""))
                : selected?.length === 0)
            }
            className="cl-dialog-add-btn"
            onClick={value === 1 ? handleAddNewComment : selected?.length !== 0 ? handleAddSelectedComment : undefined}
          >
            {value === 1 ? "Add new comment" : `Add ${selected?.length} comments`}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CommentLibraryModal;
