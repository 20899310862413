import React from "react";
import Typography from "@mui/material/Typography";
import EditButton from "../../../common/TemplateUtils/EditButton";
import TemplateAdd from "../../../common/TemplateUtils/TemplateAdd";
import Checks from "./Checks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid } from "@mui/material";
import TemplateAccord from "../../../common/TemplateUtils/TemplateAccord";
import ChecksEdit from "./ChecksEdit";
import { timestamp } from "../../../utils/services";

const CheckDescription = (props) => {
  const {
    type,
    prompt,
    values,
    AllCategoryList,
    name,
    item,
    HandleCreateSite,
    updateTemplate,
    TriggerClick,
    selectedID,
    isPlaceHolder,
    lineId,
    renId,
    categoryId,
    renamingId,
    innerTemplate,
  } = props;

  const [expanded, setExpanded] = React.useState("");
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [accordOpen, setAccordOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [descArr, setDescArr] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : "inspections");
    setAccordOpen(!accordOpen);
  };

  const SelectDescriptions = (name) => {
    if (name === "Description" || name === "CheckBox") {
      return "Answer choices. Selected items will appear checked in new inspections by default.";
    }
  };

  const handleOpen = (event) => {
    event.stopPropagation();
  };

  const EditList = (id) => {
    setIsEdit(!isEdit);
    if (isEdit) {
      if (isPlaceHolder) {
        let lineIndex = null;
        let locationIndex = null;
        let res = descArr.map(
          ({ value, is_selected, uuid, created_at, updated_at,order ,is_deleted }) => ({
            value,
            is_selected,
            uuid,
            created_at,
            updated_at,
            order,
            is_deleted,
          })
        );
        const totalRes = res.filter((val) => {
          return val.value !== "" && (val.is_deleted === 0 || val.is_deleted === undefined);
        });
        item.values = totalRes;
        item.updated_at = timestamp();
        const catList = AllCategoryList?.template?.categories;
        const categoriesDataIndex = catList.findIndex(
          (cat) => cat.category_id === categoryId
        );
        lineIndex = catList[categoriesDataIndex].lines.findIndex(
          (line) => line.line_id === lineId
        );
        locationIndex =
          catList[categoriesDataIndex].lines[
            lineIndex
          ].line_input_controls.push(item);
          catList[categoriesDataIndex].lines[
            lineIndex
          ].updated_at = timestamp();
        updateTemplate("noToast");
      } else {
        let lineIndex = null;
        let locationIndex = null;
        let res = descArr.map(
          ({ value, is_selected, uuid, created_at, updated_at,order,is_deleted }) => ({
            value,
            is_selected,
            uuid,
            created_at,
            updated_at,
            order,
            is_deleted,
          })
        );
        const totalRes = res.filter((val) => {
          return val.value !== "";
        });
        const catList = AllCategoryList?.template?.categories;
        const categoriesDataIndex = catList.findIndex(
          (cat) => cat.category_id === categoryId
        );
        lineIndex = catList[categoriesDataIndex].lines.findIndex(
          (line) => line.line_id === lineId
        );
        locationIndex = catList[categoriesDataIndex].lines[
          lineIndex
        ].line_input_controls.findIndex(
          (info) => info.line_input_control_id === selectedID
        );
        catList[categoriesDataIndex].lines[lineIndex].line_input_controls[
          locationIndex
        ].values = totalRes;
        catList[categoriesDataIndex].lines[lineIndex].updated_at = timestamp();
        catList[categoriesDataIndex].lines[lineIndex].line_input_controls[
          locationIndex
        ].updated_at = timestamp();
        updateTemplate("noToast");
      }
    }
  };

  return (
    <>
      <Typography
        sx={{ pb: "30px" }}
        style={{ minWidth: "40px", minHeight: "40px" }}
        className="section"
      >
        Answer choices. Selected items will appear checked in new inspections by
        default.
      </Typography>
      {!isEdit ? (
        <Grid
          sx={{ flexDirection: isMobile && "column" }}
          display={"flex"}
          container
          className="section__checks"
        >
          {values.map((label, i) => {
            return (
              label.is_deleted !==1 &&
              <Checks
                item={item}
                from={"Subcategory"}
                isPlaceHolder={isPlaceHolder}
                labels={label}
                index={i}
                lineId={lineId}
                categoryId={categoryId}
                selectedID={selectedID}
                AllCategoryList={AllCategoryList}
                updateTemplate={updateTemplate}
              />
            );
          })}
        </Grid>
      ) : (
        <ChecksEdit
          from={"subcategory"}
          isPlaceHolder={isPlaceHolder}
          item={item}
          setDescArr={setDescArr}
          lineId={lineId}
          values={values}
          categoryId={categoryId}
          selectedID={selectedID}
          AllCategoryList={AllCategoryList}
        />
      )}
      <EditButton EditList={() => EditList()} isEdit={isEdit} />
    </>
  );
};

export default CheckDescription;
