import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { Grid, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { NavLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { apiCall } from "../../utils/action";
import SearchIcon from "@mui/icons-material/Search";
import { defaultTemplateValue } from "./DefaultTemplate";
import Placeholder from '../../common/placeholder/Placeholder'
// editor components for draft js module
import { EditorState, Modifier, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import Preloader from "../../helpers/Preloader";
import InlineEdit from "../../common/inlineEdit/InlineEdit";

const AgreementEditor = () => {
  const location = useLocation();
  let agreementId = location.state?.id;
  // const initialState = () => EditorState.createEmpty();
  const [agreementBody, setAgreementBody] = useState(defaultTemplateValue);
  const initialState = () =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(agreementBody))
    );
  const [editorState, setEditorState] = useState(initialState);
  const [convertedContent, setConvertedContent] = useState(null);
  const [placeholderValues, setPlaceHolderValues] = useState([]);
  const [storedText, setStoredText] = useState("");
  const [showLoader, setShowLoader] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [isDefaultAgreement, setIsDefaultAgreement] = useState(0);
  const dataFiltered = filterData(searchQuery, placeholderValues);
  let navigate = useNavigate();
  // session datas
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  const uuId = sessionStorage.getItem("user_id");
  let permission = roleId === "7" ? false : true;
  // const handleEditorChange = (state) => {
  //   setEditorState(state);
  //   convertContentToHTML();
  // };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
  // const createMarkup = (html) => {
  //   return {
  //     __html: DOMPurify.sanitize(html),
  //   };
  // };
  const onChange = async (value) => {
    setEditorState(value);
    convertContentToHTML();
  };

  // reset the agreement
  const resetAgreement = () => {
    setEditorState(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(defaultTemplateValue))
      )
    );
    setConvertedContent(defaultTemplateValue);
  };
  // search for placeholder
  function filterData(query, data) {
    if (!query) {
      return data;
    } else {
      return data.filter((d) =>
        d.placeholder_key.toLowerCase().includes(query.toLowerCase())
      );
    }
  }

  // merge contents functions
  const sendTextToEditor = (text) => {
    setEditorState(insertText(text, editorState));
  };
  const insertText = (text, editorValue) => {
    const currentContent = editorValue.getCurrentContent();
    console.log("curr selection", editorValue.getSelection());
    const currentSelection = editorValue.getSelection();
    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorValue,
      newContent,
      "insert-characters"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };
   // merge content end

   //validation
   const validation = (id,action)=>{
    let errorSpanEl = document.getElementById('agreement-error-message');
    errorSpanEl.innerHTML = ""
    if(convertedContent === '<p></p>') errorSpanEl.innerHTML = "Agreement content is required"
    else{
      if(action === "edit") editAgreement(id);
      else addAgreement();
    }
   }

  //get agreement details
  const GetAgreementDetails = () => {
    setShowLoader(true);
    // same api for getting agreement list and agreement Details... only difference in passed parameters
    const formdata = { company_id: companyId, user_id:uuId, role_id:roleId, agreement_id: agreementId };
    apiCall(
      (response) => {
        console.log("GET_AGREEMENTS_LIST", response);
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          setEditorState(() =>
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(data?.agreement_body)
              )
            )
          );
          setConvertedContent(data?.agreement_body);
          setAgreementBody(data?.agreement_body);
          setIsDefaultAgreement(data?.is_default)
          setStoredText(data?.name);
          setShowLoader(false);
        } else {
          toast.error(message);
          console.log("else ", message);
        }
      },
      formdata,
      "GET_AGREEMENTS_LIST"
    );
  };
  // get placeholder values
  const getPlaceHolderValues = () => {
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
    };
    apiCall(
      (response) => {
        console.log("GET_AGREEMENT_PLACEHOLDERS", response);
        const { data, message, success } = response.data;
        if (success) {
          setPlaceHolderValues(data);
          setShowLoader(false);
        } else {
          toast.error(message);
          setShowLoader(false);
          console.log("else ", response.data.message);
        }
      },
      formdata,
      "GET_AGREEMENT_PLACEHOLDERS"
    );
  };

  const getNewAgreementName = () => {
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        console.log("GET_NEW_AGREEMENT_NAME", response);
        const { data, message, success } = response.data;
        if (success) {
          setStoredText(data.name);
          setShowLoader(false);
        } else {
          toast.error(message);
          setShowLoader(false);
          console.log("else ", response.data.message);
        }
      },
      formdata,
      "GET_NEW_AGREEMENT_NAME"
    );
  };
  // add agreement api call
  const addAgreement = () => {
    toast.dismiss();
    setShowLoader(true);
    const formdata = {
      company_id: companyId,user_id:uuId, role_id:roleId,
      name: storedText,
      agreement_body: convertedContent,
    };
    apiCall(
      (response) => {
        console.log("ADD_AGREEMENT", response);
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setShowLoader(false);
          navigate(`/settings/agreements`);
        } else {
          toast.error(message);
          setShowLoader(false);
          console.log("else ", response.data.message);
        }
      },
      formdata,
      "ADD_AGREEMENT"
    );
  };
  // edit agreement
  const editAgreement = (id) => {
    toast.dismiss();
    setShowLoader(true);
    const formdata = {
      company_id: companyId, 
      user_id:uuId, 
      role_id:roleId,
      agreement_id: id,
      name: storedText,
      agreement_body: convertedContent,
    };
    apiCall(
      (response) => {
        console.log("Edit_AGREEMENT", response);
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setShowLoader(false);
          navigate(`/settings/agreements`);
        } else {
          toast.error(message);
          setShowLoader(false);
          console.log("else ", response.data.message);
        }
      },
      formdata,
      "EDIT_AGREEMENT"
    );
  };
  useEffect(() => {
    let currentDefaultContentAsHTML = convertToHTML(editorState.getCurrentContent());
    !location.state?.edit && setConvertedContent(currentDefaultContentAsHTML);
    // getPlaceHolderValues();
    !location.state?.edit && getNewAgreementName();
    location.state?.edit && GetAgreementDetails();
  }, []);

  const placeholders =  <div className="sticky-mergeField">
  <div className="placeholder-head">Insert placeholders</div>
  <div className="placeholder-search">
    <TextField
      id="search-bar"
      onChange={(e) => {
        setSearchQuery(e.target.value);
      }}
      variant="outlined"
      placeholder="Search merge field"
      size="small"
    />
    <SearchIcon className="search-merge" />
  </div>
  <ul className="placeholder">
    {dataFiltered?.map((placeholder) => (
      <li
        className="placeholder-list draggable"
        key={placeholder}
        onClick={() =>
          sendTextToEditor(placeholder.placeholder_value)
        }
      >
        <span id={placeholder.placeholder_value} draggable="true">
          <AddIcon /> {placeholder.placeholder_key}
        </span>
      </li>
    ))}
  </ul>
</div>

  return (
    <>
      <Box sx={{width: "100%",px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },}}
        className="teams-container">
        <Preloader showPreloader={showLoader} />
        <Grid container>
        <Grid item xs={12}  sm={9} className="editor-title-wrapper">
          <div>
            <h3 className="editor-title">
              {isDefaultAgreement === 1 || !permission?storedText:
              <InlineEdit
                text={storedText}
                onSetText={(text) => setStoredText(text)}
              />}
            </h3>
            <p className="editor_details_subtitle">
              {location.state?.edit
                ? <span className="agreement_editor_title_text">Edit the template using the placeholders on the right.  
                  Agreements are automatically sent to your customer after the appointment is scheduled. 
                  Go to <span className="navigate_text" onClick={()=>navigate("/settings/services")}>Services</span> to assign agreements to services. Change what is automatically sent in <span className="navigate_text" onClick={()=>navigate("/notifications")}>Notification settings</span>.  </span>
                : <span className="agreement_editor_title_text">Add new template using the placeholders on the right.  
                Agreements are automatically sent to your customer after the appointment is scheduled. 
                Go to <span className="navigate_text" onClick={()=>navigate("/settings/services")}>Services</span> to assign agreements to services. Change what is automatically sent in <span className="navigate_text" onClick={()=>navigate("/notifications")}>Notification settings</span>. </span>}
            </p>
          </div>
        </Grid>
        <Grid item xs={12}  sm={3}></Grid>
        </Grid>
        {/* commented due to design change */}
        {/* <Grid item xs={12} className="agreement_consent">
          <FormControlLabel
            control={
              <Checkbox
                disabled={isDefaultAgreement === 1 || !permission}
                id="agreement_checkbox"
                defaultChecked
                className="agreement_checkbox"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
            }
            label="This agreement is automatically sent to your customer after the appointment is created and all needed information is added. "
          />
          <p style={{position:'relative',zIndex:'10'}}><NavLink
            to={{ pathname: `/notifications` }}
          >
            <span>Change notification settings.</span>
          </NavLink></p>
          
        </Grid> */}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={9}>
            <Editor
              editorState={editorState}
              defaultEditorState={editorState}
              // onEditorStateChange={handleEditorChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              onEditorStateChange={(value) => {
                onChange(value);
              }}
              toolbar={
                // {
                //   options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                // }
                {
                  options: ["inline", "fontSize", "fontFamily","list","textAlign","colorPicker","link","embedded","image", "remove",],
                  inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline"],
                  },
                }
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Placeholder sendTextToEditor={sendTextToEditor} setShowLoader={setShowLoader}/>
          </Grid>
          <Grid item xs={12} className="agreement-action" style={{paddingTop:'10px'}}><span id="agreement-error-message"> </span></Grid>
         {isDefaultAgreement === 0 && permission && 
         <Grid item xs={12} className="agreement-action">
            <Button
              className="agreement-btn"
              onClick={
                location?.state?.edit
                  ? () => validation(location.state.id,"edit")
                  // : addAgreement
                  :validation
              }
            >
              Save agreement
            </Button>
            <span className="navlink" onClick={resetAgreement}>
              <span>Reset to default</span>
            </span>
          </Grid>}        
        </Grid>
      </Box>
      {/* <div
        className="preview"
        dangerouslySetInnerHTML={createMarkup(convertedContent)}
      ></div> */}
    </>
  );
};

export default AgreementEditor;
