import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// material ui imports
import { Grid, Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
// dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";

// styles imports
import "../../../assets/styles/containers/_companyProfile.scss";

// component imports
import Preloader from "../../helpers/Preloader";
import {
  onKeyPressed,
  formatPhoneNumberDashes,
  formatZip,BootstrapTooltip,
  formatState
} from "../../utils/services";
import { apiCall } from "../../utils/action";
import validator from "validator";
import Header from "../../common/header/Header";
import Breadcrumb from "../../common/header/Breadcrumb";

const CompanyProfileView = () => {
  const [companyDetails, setCompanyDetails] = useState([]);
  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState(false);
  const [companyErrorText, setCompanyErrorText] = useState("");
  const [website, setWebsite] = useState("");
  const [websiteError, setWebsiteError] = useState(false);   
  const [websiteErrorText, setWebsiteErrorText] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [contact, setContact] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  // const [contactError, setContactError] = useState(false);
  // const [contactErrorText, setContactErrorText] = useState('');
  const [address, setAddress] = useState("");
  // const [addressError, setAddressError] = useState(false);
  // const [addressErrorText, setAddressErrorText] = useState('');
  const [city, setCity] = useState("");
  // const [cityError, setCityError] = useState(false);
  // const [cityErrorText, setCityErrorText] = useState('');
  const [state, setState] = useState("");
  // const [stateError, setStateError] = useState(false);
  // const [stateErrorText, setStateErrorText] = useState('');
  const [zip, setZip] = useState("");
  // const [zipError, setZipError] = useState(false);
  // const [zipErrorText, setZipErrorText] = useState('');
  const [statement, setStatement] = useState("");
  // const [statementError, setStatementError] = useState(false);
  // const [statementErrorText, setStatementErrorText] = useState('');
  const [billingPlanName, setBillingPlanName] = useState("Standard Plan");
  const [billingRenewalDueDate, setBillingRenewalDueDate] = useState();
  const [teamData, setTeamData] = useState([]);
  const [image, setImage] = useState("");
  const [showLoader, setShowLoader] = useState(false);  
  const [deleteId, setDeleteId] = useState("");
  const [deleteControl, setDeleteControl] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const fileInput = React.useRef();
  // session datas
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  const uuId = sessionStorage.getItem("user_id");
  let permission = roleId === "7" ? false : true;  
  const navigate = useNavigate();

  const dateFormat=(date)=>{
    if(date === null) setBillingRenewalDueDate(date)
    else{
    let date_availiable = new Date(date);
    let date_month = date_availiable.getMonth() + 1;
    let date_day_without_0 = date_availiable.getDate();
    let date_day = date_day_without_0.toString().padStart(2, '0');
    let date_year = date_availiable.getFullYear().toString().substr(-2);
    setBillingRenewalDueDate(`${date_month}/${date_day}/${date_year}`)
    }
  }


  // get companyy data from api
  const getCompanyProfile = () => {
    setShowLoader(true);
    const formdata = { company_id: companyId ,user_id:uuId, role_id:roleId };
    apiCall(
      (response) => {
        console.log("GET_COMPANY_PROFILE", response);
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          setCompanyDetails(data.company_details);
          setTeamData(data.team_members);
          setCompany(data.company_details.name);
          setWebsite(data.company_details.website);
          setEmail(data.company_details.email);
          setContact(data.company_details.phone);
          setAddress(data.company_details.address_1);
          setCity(data.company_details.city);
          setState(data.company_details.state);
          setZip(data.company_details.zip);
          setStatement(data.company_details.company_statement);
          setImage(data.company_details.avatar);
          setBillingPlanName(data?.company_details?.billing_plan)
          dateFormat(data?.company_details?.billing_renewal_due_date)
          setContactPerson(data?.company_details.owner_name)
          setShowLoader(false);
        } else {
          console.log("else ", message);
          toast.error(message);
        }
      },
      formdata,
      "GET_COMPANY_PROFILE"
    );
  };

  // get comapny info (anil)*****************************************
  const getCompanyInfo=(company_id,user_id,role_id)=>{
    const formdata = { company_id: companyId,
      user_id:uuId,
      role_id:roleId,};
    apiCall((response)=>{
      console.log("GET_COMPANY_INFO",response)
      if(response.data.success){
        sessionStorage.setItem("company_name", response.data.data.company_details.name);
        setDeleteControl(!deleteControl);
      }else {
        console.log("else", response.data.message);
      }
    },formdata,"GET_COMPANY_INFO")
  }

  // edit company data
  const editCompanyProfile = () => {
    // setShowLoader(true);
    toast.dismiss();
    let formData = new FormData();
    const params = {
      company_id: companyId,
      user_id:uuId,
      role_id:roleId,
      avatar: typeof image === "string" || image === null ? "" : image,
      name: company,website,email,
      phone: contact,
      address_1: address,
      city, state,zip,
      company_statement: statement,
    };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    apiCall(
      (response) => {
        console.log("EDIT_COMPANY_DETAILS", response);
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setShowLoader(false);
          getCompanyInfo()
        } else {
          toast.error(message);
          setShowLoader(false);
          console.log("else ", message);
        }
      },
      formData,
      "EDIT_COMPANY_DETAILS"
    );
  };

  // get role list function and API call
  const getRoleListsAndEdit = (id,name) => {
    setShowLoader(true);
    const formdata = { user_id: uuId, role_id: roleId, company_id:companyId};
    apiCall(
      (response) => {
        console.log("GET ROLE LIST", response);
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          // setShowLoader(false);
          navigate(`/settings/team/${name}`,{
              state: {id,edit:true,roleList:data,},
            })
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      "GET_ROLE_LIST"
    );
  };

  // delete member function and API call
  const deleteTeamMember = (id) => {
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      member_id: id,
    };
    handleCloseAlert();
    apiCall(
      (response) => {
        console.log("REMOVE MEMBER RESPONSE", response);
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setDeleteControl(!deleteControl);
          setShowLoader(false);
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      "REMOVE_MEMBER"
    );
  };

  const validate=()=>{
    if (company === "" || company === null) {
      setCompanyError(true);
      setCompanyErrorText("company name is required");
    }  else setCompanyError(false);
    if (email === "" || email === null) {
      setEmailError(true);
      setEmailErrorText("Email is required");
    } else if ( !validator.isEmail(email)) {
      setEmailError(true);
      setEmailErrorText("Enter correct email format");
    } 
    else if (
      !/^[A-Z0-9._%+]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))
     {
      setEmailError(true);
      setEmailErrorText("Enter correct email format");
    } 
    else setEmailError(false);
    if (website === "" || website === null) {
      setWebsiteError(true);
      setWebsiteErrorText("Website is required");
    } else if (!/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i.test(website)) {
      setWebsiteError(true);
      setWebsiteErrorText("Enter correct url format");
    }else
    setWebsiteError(false);
    if (
      company!==""&& company!==null && email !== '' && email !== null && /^[A-Z0-9._%+]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)&& website !== "" || website !== null && (/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g).test(website)  && 
      validator.isEmail(email) 
      ) 
      editCompanyProfile();
  
  }
 
  const handleZipChange=(e)=>{
    const formattedZip = formatZip(e.target.value);
    setZip(formattedZip);
  }
  const handleContactChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumberDashes(e.target.value);
    setContact(formattedPhoneNumber);
  };
  const handleClickOpenAlert = (id) => {
    setDeleteId(id);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  
  const card = (
    <>
    <div className="profile-header">
      <span >Logo</span>
      {permission && <span className="edit-section" onClick={() => fileInput.current.click()}><EditIcon /><span>Edit</span></span>}
    </div>
      {/* <CardHeader
        action={
          <EditIcon onClick={() => fileInput.current.click()}>Edit</EditIcon>
        }
        title="Logo"
        className="team-profile-header"
      /> */}
      <input
        ref={fileInput}
        type="file"
        accept="image/*"
        onChange={(e) => setImage(e.target.files[0])}
        style={{ display: "none" }}
      />
      {image && (
        <div className="prof-img-wrapper">
          <img
            className="team-prof-img"
            style={{ maxHeight: "320px", maxWidth: "100%" }}
            src={typeof image === "string" ? image : URL.createObjectURL(image)}
            alt="Thumb"
          />
          {/* <span onClick={()=>setImage()} style={{ cursor: "pointer", padding: 5, background: "red", color: "white", border: "none" }}>
                Remove Image
              </span> */}
        </div>
      )}
      {!image && (
        <CardMedia
          component="img"
          className="team-prof-img"
          alt="/broken-image.jpg"
          src={
            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
          }
        />
      )}
    </>
  );
  const permissionCard = (
    <>
      <Grid container className="company-permission-card">
        <Grid item xs={12}>
          Billing plan
        </Grid>
        <p>{billingPlanName}</p>
        <Grid item xs={12} sx={{ marginTop: "7px" }}>
          <span className="team-permision-text">
            {billingRenewalDueDate !== null ?
            <>
             Will renew on <br />  <span className="date-placeholder">{billingRenewalDueDate}</span>
             </>:'No expiry date.'
          }
           
          </span>{" "}
        </Grid>
        <Button className="default-btn teams-detail-btn"> Change plan</Button>
      </Grid>
    </>
  );
  const detailsCard = (
    <>
      <Grid container className="team-details-card">
        <Grid
          item
          xs={12}
          className="team-details-cardTitle"
          style={{ marginBottom: "16px" }}
        >
          <span>Contact Info</span>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="Company"
              >
                Company*
              </label>
              <TextField
                disabled={!permission}
                id="Company"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                error={companyError}
                helperText={`${companyError ? companyErrorText: ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="website"
                id="demo-simple-select-label"
              >
                Website
              </label>
              <TextField
                disabled={!permission}
                id="website"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Website"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                error={websiteError}
                helperText={`${websiteError ? websiteErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="standard"
              className="forms-control field-reset"
            >
              <label
                className="input-form-label"
                htmlFor="email"
                id="demo-simple-select-label"
              >
                Email Address*
              </label>
              <TextField
                disabled={!permission}
                id="email"
                className="input-textfield "
                size="small"
                variant="outlined"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                // error={emailError}
                // helperText={`${emailError ? emailErrorText : ""}`}
                error={emailError}
                helperText={`${emailError ? emailErrorText : ""}`}
                type="email"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="phone"
                id="demo-simple-select-label"
              >
                Phone
              </label>
              <TextField
                disabled={!permission}
                id="phone"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Contact"
                value={contact}
                onChange={(e)=>handleContactChange(e)}
                // onChange={(e) => setContact(e.target.value)}
                // error={contactError}
                // helperText={`${contactError ? contactErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="contactPerson"
              >
                Contact
              </label>
              <TextField
                disabled={true}
                id="contactPerson"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Contact"
                value={contactPerson}
                // onChange={(e) => setContactPerson(e.target.value)}
                // error={addressError}
                // helperText={`${addressError ? addressErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="Address1"
                id="demo-simple-select-label"
              >
                Address
              </label>
              <TextField
                disabled={!permission}
                id="Address1"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                // error={addressError}
                // helperText={`${addressError ? addressErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="city"
                id="demo-simple-select-label"
              >
                City
              </label>
              <TextField
                disabled={!permission}
                id="city"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                // error={cityError}
                // helperText={`${cityError ? cityErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="state"
                id="demo-simple-select-label"
              >
                State
              </label>
              <TextField
                disabled={!permission}
                id="state"
                className="input-textfield"
                size="small"
                variant="outlined"
                inputProps={{ maxLength:2}}
                placeholder="State"
                value={state}
                onChange={(e) => setState(formatState(e.target.value.toUpperCase()))}
                // error={stateError}
                // helperText={`${stateError ? stateErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="zip"
                id="demo-simple-select-label"
              >
                Zip Code
              </label>
              <TextField
                disabled={!permission}
                id="zip"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Zip"
                value={zip}
                onKeyDown={onKeyPressed}
                // onChange={(e) => setZip(e.target.value)}
                onChange={(e)=>handleZipChange(e)}
                // error={zipError}
                // helperText={`${zipError ? zipErrorText : ""}`}
                type="number"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="statement"
                id="demo-simple-select-label"
              >
                Our company statement
              </label>
              <TextField
                disabled={!permission}
                className="textArea"
                id="statement"
                aria-label="minimum height"
                minRows={3}
                placeholder=""
                style={{ width: "100%" }}
                onChange={(e) => setStatement(e.target.value)}
                // error={statementError}
                // helperText={statementError ? statementErrorText : ""}
                value={statement}
                InputProps={{
                  inputComponent: TextareaAutosize,
                }}
              />
            </FormControl>
          </Grid>
          {permission && (
            <Grid item xs={12} className="team-detail-cta">
              <Button
                className="back-btn"
                style={{ visibility: "hidden" }}
                id="cancel_company"
                //   onClick={() => navigate("/settings/team")}
              >
                Cancel
              </Button>
              <Button
                onClick={validate}
                className="default-btn teams-detail-btn"
                id="update_company"
                
              >
                {/* {editTeam ? "Update" : "Finish"} */} Update
                
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
  const teamsCard = (
    <>
      <Grid
        container
        //   className="team-details-card"
      >
        <Grid
          item
          xs={12}
          className="profile-details-cardTitle"
          style={{ marginBottom: "16px" }}
        >
          <span>Team</span>
        </Grid>
        <hr className="bottom-border" />
        {teamData.length !== 0 ? (
          teamData.map((teamlist,key) => {
            let currentUser = teamlist.id===Number(uuId)?true:false;
            return (
              <>
                <Grid key={key} container className="profile-details-cardDetail p-15">
                  <Grid
                    item
                    xs={2} sm={2} md={1}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Avatar alt="Remy Sharp" className="teamlist-avatar" src={teamlist.avatar} />
                  </Grid>
                  <Grid item xs={10} sm={10} md={11} className="team-avatar">
                    <Grid container>
                      <Grid item xs={12} className="member-row">
                        <div className="member-name flex-center team-role">{`${teamlist.first_name} ${teamlist.last_name === null ? '':teamlist.last_name}`}
                        {teamlist.role_name.includes("Owner") ||
                              teamlist.role_name.includes("Admin") ? (
                                <VerifiedUserIcon className="owner-icon" style={{marginLeft:'10px'}} />
                              ) : (
                                <BusinessCenterIcon className="insp-icon" style={{marginLeft:'10px'}}/>
                              )}
                        </div>
                        <div className="member-action flex-center">
                          {roleId === "6" && teamlist.role_name ==="Owner" ?
                          <>
                          <BootstrapTooltip title={<h1 className="font-13">You cannot edit this record</h1>}>
                            <EditIcon sx={{ opacity:'0.25', fontSize: 18,cursor:'pointer'}}/>
                          </BootstrapTooltip>
                          <BootstrapTooltip title={<h1>You cannot delete this record</h1>}>
                            <DeleteIcon
                              sx={{ opacity:'0.25', color: "red", fontSize: 18,cursor:'pointer'}} />
                          </BootstrapTooltip>
                          </>
                          :
                          <>                          
                          <BootstrapTooltip title={<h1>Edit details</h1>}>
                            <EditIcon
                              className="edit-icon"
                              onClick={() => getRoleListsAndEdit(teamlist.id,teamlist.first_name)}
                            />
                          </BootstrapTooltip>
                          <BootstrapTooltip title={<h1>{!currentUser?'Delete record':'You cannot delete yourself'}</h1>}>
                            <DeleteIcon
                              className={!currentUser && 'delete-icon'}
                              sx={{opacity:'0.25',color: "red", fontSize: 18, cursor:'pointer'}}
                              onClick={!currentUser? () => handleClickOpenAlert(teamlist.id):''}
                            />
                          </BootstrapTooltip></>
                          }
                          
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <span className="member-detail">{teamlist.email}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <hr className="bottom-border" />
              </>
            );
          })
        ) : (
          <Grid container className="profile-details-cardDetail p-15">
            <Alert
              style={{ alignItems: "center", width: "100%" }}
              severity="error"
            >
              No team members
            </Alert>
          </Grid>
        )}
      </Grid>
    </>
  );

  useEffect(() => {
    getCompanyProfile();
  }, [deleteControl]);

  return (
    <>
    <Header/>
    <Breadcrumb/>
    <Preloader showPreloader={showLoader} />
    {showLoader ? (""):(
    <Grid container className="teams-container">
      <Grid item xs={12} className="details-title">
        <span className="team-user-name">Company Profile</span>
        <p>
          Keep your company information up-to-date. Your clients will use this
          to get in touch with you.{" "}
        </p>
      </Grid>
      <Box>
        
          {/* ------------------delete alert dialog------------------------ */}
          <Dialog
          open={openAlert}
          onClose={handleCloseAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="delete-alert-modal"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete team member?"}
            <CloseIcon className="close-icon" onClick={handleCloseAlert} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="delete-modal-cancel-btn"
              onClick={handleCloseAlert}
            >
              Cancel
            </Button>
            <Button
              className="default-btn"
              variant="contained"
              color="error"
              onClick={() => {
                deleteTeamMember(deleteId);
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3}>
            <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
              <Card variant="outlined">{card}</Card>
            </Box>
            <Box sx={{ minWidth: 145 }}>
              <Card variant="outlined">{permissionCard}</Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
              <Card variant="outlined">{detailsCard}</Card>
            </Box>
            {permission && <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
              <Card variant="outlined">{teamsCard}</Card>
            </Box>}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )}
  </>
  );
};

export default CompanyProfileView;
