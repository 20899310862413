import React from 'react'
import Box from "@mui/material/Box";

// common components imports
import CompanyProfileView from '../../../components/CompanyProfileView/CompanyProfileView';


const CompanyProfile = () => {
  return (
    <>    
    {window.scrollTo(0, 0)}    
      <Box sx={{ flexGrow: 1 }} className="page-background">
         <CompanyProfileView />
      </Box>
    </>
  )
}

export default CompanyProfile