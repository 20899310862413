import Paper from "@mui/material/Paper";
import { useMediaQuery } from "react-responsive";
import SearchIcon from "@mui/icons-material/Search";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Grid, InputBase, IconButton } from "@mui/material";

const EnhancedTableToolbar = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });
  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{ width: { xs: "371.5px", sm: "100%", md: "100%", lg: "100%" } }}
        className="inspection-toolbar-main"
      >
        <Paper
          component="form"
          className={"inspection-search-field"}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: { xs: "241px", sm: "29.64%", md: "29.64%", lg: "29.64%" },
          }}
        >
          <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon className="inspection-search-icon" />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1, fontSize: "16px" }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            onChange={props.handleSearchFilter}
          />
        </Paper>
        {isMobile ? (
          <p
            className="inspections-export-content"
            onClick={() => window.print()}
          >
            <ExitToAppIcon
              sx={{ fontSize: "38px" }}
              className="inspections-export-icon"
            />{" "}
            {isMobile ? "" : "Export"}
          </p>
        ) : (
          <p
            sx={{ width: "7.6%" }}
            className="inspections-export-content"
            onClick={() => window.print()}
          >
            <ExitToAppIcon className="inspections-export-icon" />
            {isMobile ? "" : "Export"}
          </p>
        )}
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

export default EnhancedTableToolbar;
