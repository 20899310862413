//file imports
import { Box } from '@mui/system';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import Preloader from '../../helpers/Preloader';
import SelectedCharges from './SelectedCharges';
import CloseIcon from '@mui/icons-material/Close';
import Heading from '../../common/cardHeading/Heading'; //reusable component
import '../../../assets/styles/containers/_inspDetails.scss'; //SCSS file imports
import { apiCall } from '../../pages/settings/Services/action';
import { Autocomplete, Button, Chip, FormControl, Grid, Modal, Paper, TextField, useMediaQuery, Typography } from '@mui/material';
//render function****************************************************************
export const Servicepage = (props) => {
    //modal function
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    //responsive trigger
    const matches = useMediaQuery('(min-width:900px)');
    //add discount or charge
    const [addedInput, setAddedInput] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [addedPrice, setAddedPrice] = useState(0);
    const handleTotalPrice = (price) => setTotalPrice(price);
    const [showCustomAddInput, setShowCustomAddInput] = useState(false);
    const [editingData, setEditingData] = useState([]);
    const [editingIndex, setEditingIndex] = useState('');
    const [servicevaluechange, setServiceValueChange] = useState(false);
    const [addedServices, setAddedServices] = useState([...props.customCharge, ...props.customDiscount]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedservices, setSelectedServices] = useState(props.selectedServicesValue);
    let customCharge = [...selectedservices];
    let updatedServices = [...customCharge, ...addedServices];
    const [isDesc, setIsDesc] = useState(false);
    const [isPrice, setIsPrice] = useState(false);
    const [showDiscountInput, setShowDiscountInput] = useState(false);
    //validations constants
    const [errors, setErrors] = useState({});
    const [descError, setDescError] = useState(false);
    const [submitButtonId, setSubmitButtonId] = useState('');
    const [priceError, setPriceError] = useState(false);
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const [buttonType, setButtonType] = useState('');
    const handleClose = () => {
        setOpen(false);
        setErrors({});
        setDataIsCorrect(false);
        setSelectedServices(props.selectedServicesValue);
        setServiceValueChange(false);
        setAddedServices([...props.customCharge, ...props.customDiscount]);
        setShowDiscountInput(false);
        setShowCustomAddInput(false);
        setLoadingButton(false);
    };
    //validations function
    const validation = () => {
        let errors = {};
        if (selectedservices.length === 0) {
            errors.selectedServices = 'Please select at least one service';
        }
        if (showCustomAddInput || showDiscountInput) {
            if (!addedInput) {
                errors.descError = 'please enter';
            }
            if (!addedPrice) {
                errors.priceError = 'please enter';
            }
            if (addedInput.trim().length === 0) {
                errors.descError = 'please enter';
            }
        }
        return errors;
    };
    let rows = updatedServices?.map((service) => service);
    let totalRate = rows?.reduce((previousVal, currentVal) => previousVal + currentVal.amount, 0);
    // props.handleTotalPrice(totalRate);
    rows.push({ name: 'Total', amount: totalRate, type: 'total' });
    //edit service
    const editService = (index, type) => {
        setEditingData(addedServices[index - customCharge.length]);
        setEditingIndex(index);
        addedServices.splice(index - customCharge.length, 1);
        if (type === 'discount') addCustomDiscount(index, true);
        else addCustomCharge(index, true);
    };
    //delete service
    const deleteService = (index) => {
        setEditingData(addedServices[index - customCharge.length]);
        addedServices.splice(index - customCharge.length, 1);
        setServiceValueChange(true);
    };
    //adding input
    const addInput = (evt) => {
        setIsDesc(true);
        setAddedInput(evt.target.value);
    };
    //add custom charge
    const addCustomCharge = (index, edit) => {
        if (!edit) setEditingData([]);
        setShowCustomAddInput(!showCustomAddInput);
        setShowDiscountInput(false);
        setAddedInput('');
        setAddedPrice('');
    };
    //add custom discount
    const addCustomDiscount = (index, edit) => {
        if (!edit) setEditingData([]);
        setShowDiscountInput(!showDiscountInput);
        setShowCustomAddInput(false);
        setAddedInput('');
        setAddedPrice('');
    };
    //add price
    const addPrice = (evt) => {
        setIsPrice(true);
        setAddedPrice(parseInt(evt.target.value));
    };
    //intialization of the selected services values
    useEffect(() => {
        setSelectedServices(props.selectedServicesValue);
        setAddedServices([...props.customCharge, ...props.customDiscount]);
    }, [props.selectedServicesValue]);
    //api call for request payment email
    const requestPayment = () => {
        setShowLoader(true);
        const formdata = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
            inspection_id: props.inspectionId,
        };
        apiCall(
            (response) => {
                console.log('REQUEST_PAYMENT', response);
                if (response.data.success) {
                    toast.success(response.data.message);
                    setShowLoader(false);
                } else {
                    console.log('else', response.data.message);
                    toast.error(response.data.message);
                    setShowLoader(false);
                }
            },
            formdata,
            'REQUEST_PAYMENT',
        );
    };
    // useEffect(() => {
    //     if (addedPrice.length === 0 ) {
    //         setPriceChanged(false);
    //         setDescChanged(false);
    //     }
    // }, [addedPrice]);
    //custom charges
    let addedTempData = [];
    let concatedData = [];
    const addChargeOrDiscount = (showCustomAddInput, showDiscountInput) => {
        setShowCustomAddInput(false);
        setShowDiscountInput(false);
        let price;
        let type;
        if (showDiscountInput) {
            price = Math.sign(addedPrice) !== -1 ? addedPrice * -1 : addedPrice;
            type = 'discount';
        } else if (showCustomAddInput) {
            price = addedPrice;
            type = 'charge';
        }
        addedTempData.push({ name: !isDesc ? editingData?.name : addedInput, amount: !isPrice ? editingData?.amount : price, type });
        if (editingData.length === 0) concatedData = [...addedServices, ...addedTempData];
        else {
            concatedData = [...addedServices];
            concatedData.splice(editingIndex - selectedservices.length, 0, ...addedTempData);
        }
        setAddedServices(concatedData);
        setIsDesc(false);
        setIsPrice(false);
        setAddedInput('');
        setAddedPrice('');
        setDescError(false);
        setPriceError(false);
        setServiceValueChange(true);
    };

    // const handleDoneButton = (event) => {
    //     event.preventDefault();
    //     handleformSubmit();
    //     setSubmitButtonId('DONE');
    // };
    //done onClick function
    const handleformSubmit = (event, type) => {
        event.preventDefault();
        setErrors(validation(selectedservices));
        setDataIsCorrect(true);
        validation();
        setButtonType(type);
        // if(selectedservices.length === 0){
        //   setError(true);
        //   setHelperText("Please select one service");

        // }
        // else {
        //     if (selectedservices) {
        //       updateservices();
        //   } else setOpen(false);
        //   }
    };

    //useEffect
    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            if (servicevaluechange && !showCustomAddInput && !showDiscountInput && buttonType === 'DONE') {
                updateservices();
            } else return;
        }
    }, [errors]);
    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (showDiscountInput || showCustomAddInput) {
                if (addedInput !== '' && addedPrice !== 0 && addedPrice !== '' && buttonType === 'ADD') {
                    addChargeOrDiscount(showCustomAddInput, showDiscountInput);
                }
            }
        }
    }, [errors]);
    //api call for Update Services
    const updateservices = () => {
        setLoadingButton(true);
        var serviceid = [];
        var serviceDuration = [];
        selectedservices.map((value) => serviceid.push(value.id));
        selectedservices.map((value) => serviceDuration.push(value.minutes));
        let total = 0;
        Object.keys(selectedservices).forEach((index) => {
            total += selectedservices[index].amount;
        });
        var orderCharges = [];
        // props.orderCharge.map((value) => orderCharges.push({ description: value.description, price: value.price }));
        updatedServices.map((value) => (value.type === 'charge' ? orderCharges.push({ description: value.name, price: value.amount }) : ''));
        var orderDiscount = [];
        // props.orderDiscount.map((value) => orderDiscount.push({ description: value.description, discount: value.discount }));
        updatedServices.map((value) => (value.type === 'discount' ? orderDiscount.push({ description: value.name, discount: value.amount * -1 }) : ''));
        const params = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
            inspection_id: props.inspectionId,
            service_id: serviceid,
            service_duration: serviceDuration,
            charges: totalRate,
            order_charges: orderCharges,
            order_discounts: orderDiscount,
        };
        apiCall(
            (response) => {
                console.log('UPDATE_INSPECTION_DETAILS', response);
                if (response.data.success) {
                    toast.success(response.data.message);
                    setLoadingButton(false);
                    setOpen(false);
                    props.setRefresh(!props.refresh);
                    setSelectedServices([]);
                    setAddedServices([]);
                    setServiceValueChange(false);
                    setAddedServices([]);
                    setShowDiscountInput(false);
                    setShowCustomAddInput(false);
                } else {
                    console.log('else', response.data.message);
                    toast.error(response.data.message);
                    setLoadingButton(false);
                }
            },
            params,
            'UPDATE_INSPECTION_DETAILS',
        );
    };
    const clearInputField = () => {
        document.querySelector('.input-value input').value = '';
        document.querySelector('.input-price input').value = '';
        setEditingData([]);
        setIsPrice(true);
        setIsDesc(true);
        setAddedInput('');
        setAddedPrice('');
    };
    //render function
    return (
        <>
            <Preloader showPreloader={showLoader} />
            <Box sx={{ flexGrow: 1 }}>
                {/* inspection details "Services" screen starts from here... */}
                <Grid container>
                    <Paper elevation={0} sx={{ width: { xs: '100%', md: '65.46%', lg: '65.46%' } }} className={'service_component'}>
                        <Heading
                            CancelledStatus={props.CancelledStatus}
                            title={`${matches ? 'Services' : 'Fees'}`}
                            icon={<EditIcon className="details__icon" onClick={handleOpen} />}
                            iconDesc={
                                <span className="edit_button" onClick={handleOpen}>
                                    Edit
                                </span>
                            }
                        />
                        <Grid container spacing={0} direction="row" sx={{ mt: '14px', pl: '25px', pr: '15px' }}>
                            {props.services?.map((value) => {
                                return (
                                    <>
                                        <Grid item xs={6}>
                                            <Typography sx={{ color: '#333333', fontSize: { xs: '16px', md: '14px', lg: '14px' } }}>{value.service.name}</Typography>
                                            <Typography
                                                sx={{
                                                    mb: '22px',
                                                    color: '#333333',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '16px', md: '14px', lg: '14px' },
                                                }}>
                                                ${value.service?.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                sx={{
                                                    float: 'right',
                                                    color: '#333333',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '16px', md: '14px', lg: '14px' },
                                                }}>
                                                {/* {value.status} */}
                                                {/* {value.order_payment_status === 0 ? 'Unpaid' : 'Paid'} */}
                                            </Typography>
                                        </Grid>
                                    </>
                                );
                            })}
                            {props.orderCharge?.map((value) => {
                                return (
                                    <>
                                        <Grid item xs={6}>
                                            <Typography sx={{ color: '#333333', fontSize: { xs: '16px', md: '14px', lg: '14px' } }}>{value.description}</Typography>
                                            <Typography
                                                sx={{
                                                    mb: '22px',
                                                    color: '#333333',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '16px', md: '14px', lg: '14px' },
                                                }}>
                                                ${value?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                sx={{
                                                    float: 'right',
                                                    color: '#333333',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '16px', md: '14px', lg: '14px' },
                                                }}>
                                                {/* {value.status} */}
                                                {/* {value.order_payment_status === 0 ? 'Unpaid' : 'Paid'} */}
                                            </Typography>
                                        </Grid>
                                    </>
                                );
                            })}
                            {props.orderDiscount?.map((value) => {
                                return (
                                    <>
                                        <Grid item xs={6}>
                                            <Typography sx={{ color: '#333333', fontSize: { xs: '16px', md: '14px', lg: '14px' } }}>{value.description}</Typography>
                                            <Typography
                                                sx={{
                                                    mb: '22px',
                                                    color: '#333333',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '16px', md: '14px', lg: '14px' },
                                                }}>
                                                $-{value?.discount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                sx={{
                                                    float: 'right',
                                                    color: '#333333',
                                                    fontFamily: 'Open Sans',
                                                    fontSize: { xs: '16px', md: '14px', lg: '14px' },
                                                }}>
                                                {/* {value.status} */}
                                                {/* {value.order_payment_status === 0 ? 'Unpaid' : 'Paid'} */}
                                            </Typography>
                                        </Grid>
                                    </>
                                );
                            })}
                            <>
                                <Grid item xs={6}>
                                    <Typography sx={{ color: '#333333', fontSize: { xs: '16px', md: '14px', lg: '14px' }, fontWeight: '600' }}>Total</Typography>
                                    <Typography
                                        sx={{
                                            mb: '22px',
                                            color: '#333333',
                                            fontFamily: 'Open Sans',
                                            fontSize: { xs: '16px', md: '14px', lg: '14px' },
                                            fontWeight: '600',
                                        }}>
                                        ${totalRate?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{ float: 'right' }}>
                                        <Typography sx={{ color: '#333333', fontSize: { xs: '16px', md: '14px', lg: '14px' }, fontWeight: '600' }}>Payment Status</Typography>
                                        <Typography
                                            component="div"
                                            sx={{
                                                float: 'right',
                                                fontWeight: '600',
                                                color: '#333333',
                                                fontFamily: 'Open Sans',
                                                fontSize: { xs: '16px', md: '14px', lg: '14px' },
                                            }}>
                                            {/* {value.status} */}
                                            {props.status.paid === 0 ? 'Unpaid' : 'Paid'}
                                        </Typography>
                                    </div>
                                </Grid>
                            </>

                            <Grid item xs={12}>
                                {' '}
                                <Button
                                    onClick={() => requestPayment()}
                                    sx={{
                                        'height': { xs: 42, md: 34, lg: 34 },
                                        'borderRadius': '2px',
                                        'backgroundColor': '#239D80',
                                        'color': '#fff',
                                        'mb': 3,
                                        'fontSize': { xs: 16, md: 13, lg: 13 },
                                        'fontFamily': 'Open Sans',
                                        'letterSpacing': 0.5,
                                        'textTransform': 'none !important',
                                        'width': { xs: 180, md: 152, lg: 152 },
                                        ':hover': {
                                            backgroundColor: '#1E8970',
                                        },
                                        'display': props.CancelledStatus && 'none'
                                    }}>
                                    Request payment
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Box>
            {/* ************************edit screen starts from here***************************** */}
            <Modal open={open} onClose={handleClose} className={'inspection-filter-modal-main'} sx={{backgroundColor: "#D8D8D8 !important"}}>
                <Box className={'inspection-filter-modal'} sx={{ top: '30% !important' }}>
                    <Grid sx={{ p: { xs: '20px', md: '30px', lg: '30px' } }} container spacing={2}>
                        <Grid item xs={8}>
                            <Typography
                                sx={{
                                    height: '30px',
                                    width: '350px',
                                    color: '#333333',
                                    fontFamily: 'Open Sans',
                                    fontSize: '22px',
                                    letterSpacing: 0,
                                    lineHeight: '30px',
                                }}>
                                Services
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <CloseIcon onClick={handleClose} className={'inspection-filter-modal-close-icon'} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="none">
                                <label
                                    style={{
                                        fontSize: '12px',
                                        fontFamily: 'Open Sans',
                                        color: '#777777',
                                        marginBottom: '7px',
                                        lineHeight: '21px',
                                    }}>
                                    ADD SERVICES
                                </label>
                                <Autocomplete
                                    multiple
                                    sx={{ p: '0px !important' }}
                                    className="forms-control custom-color"
                                    id="tags-outlined"
                                    size="small"
                                    options={props.serviceArray}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, value) => {
                                        setSelectedServices(value);
                                        setServiceValueChange(true);
                                    }}
                                    // defaultValue={props.services.map((option)=> option.service.name)}
                                    defaultValue={props.defaultserviceArrary.map((value) => props.serviceArray[value])}
                                    filterSelectedOptions
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => <Chip size="small" deleteIcon={<CloseIcon />} variant="outlined" label={option.name} {...getTagProps({ index })} />)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Services"
                                            fullWidth
                                            error={errors.selectedServices}
                                            helperText={errors.selectedServices && errors.selectedServices}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} sx={{ mt: "27px" }}>
              <FormControl fullWidth margin="none">
                <label
                  style={{
                    fontSize: "12px",
                    fontFamily: "Open Sans",
                    color: "#777777",
                    marginBottom: "7px",
                    lineHeight: "21px",
                  }}
                >
                  ADD TEMPLATES
                </label>
                <Autocomplete
                  multiple
                  className="forms-control custom-color"
                  id="tags-outlined"
                  size="small"
                  options={props.services.map((index, option) =>
                      index.service.servicetemplate[option]?.template?.name
)}
                  defaultValue={props.services.map(
                    (index, option) =>
                      index.service.servicetemplate[option]?.template?.name
                  )}
                  filterSelectedOptions
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        size="small"
                        deleteIcon={<CloseIcon />}
                        // sx={{
                        //   color: "#0EAD7C",
                        //   border: "1px solid #0EAD7C",
                        //   borderRadius: "4px",
                        // }}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Templates"
                      margin="none"
                    />
                  )}
                />
              </FormControl>
            </Grid> */}
                        <Grid item xs={12} lg={12} md={12}  sx={{ mt: '10px', p: '0px !important' }}>
                            <SelectedCharges
                                SelectedService={SelectedCharges}
                                rows={rows}
                                addInput={addInput}
                                addPrice={addPrice}
                                errors={errors}
                                setAddedInput={setAddedInput}
                                setAddedPrice={setAddedPrice}
                                handleformSubmit={handleformSubmit}
                                descError={descError}
                                priceError={priceError}
                                setSubmitButtonId={setSubmitButtonId}
                                showDiscountInput={showDiscountInput}
                                showCustomAddInput={showCustomAddInput}
                                editService={(index, type) => editService(index, type)}
                                addChargeOrDiscount={(showCustomAddInput, showDiscountInput) => addChargeOrDiscount(showCustomAddInput, showDiscountInput)}
                                clearInputField={clearInputField}
                                editingData={editingData}
                                deleteService={deleteService}
                                addCustomCharge={(index, edit) => addCustomCharge(index, edit)}
                                addCustomDiscount={(index, edit) => addCustomDiscount(index, edit)}
                            />
                            {/* <Table
                sx={{ border: "0.5px solid #C4C4C4", width: "338px" }}
                padding="none"
              >
                {selectedservices.map((value) => (
                  <>
                    <TableRow>
                      <TableCell
                        sx={{
                          height: "36px",
                          width: "249px",
                          borderRight: "0.5px solid #C4C4C4",
                          pl: 2,
                        }}
                      >
                        {" "}
                        <Typography
                          sx={{
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            color: "#333333",
                          }}
                        >
                          {value.name}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: "89px" }}>
                        <Typography
                          sx={{
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            color: "#333333",
                            textAlign: "center",
                          }}
                        >
                          ${value.amount}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
                <TableRow>
                  <TableCell
                    sx={{
                      height: "36px",
                      width: "249px",
                      borderRight: "0.5px solid #C4C4C4",
                    }}
                  >
                    {" "}
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        color: "#333333",
                        fontWeight: "bold",
                        pl: 2,
                      }}
                    >
                      TOTAL
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: "89px" }}>
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        color: "#333333",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {handleTotal()}
                    </Typography>
                  </TableCell>
                </TableRow>
              </Table> */}
                        </Grid>
                        {/* <Grid item xs={12} md={12} className="add-action pl-15">
              <Typography
                onClick={() => setShowDiscountInput(!showDiscountInput)}
              >
                Add charge or discount <AddCircleIcon />
              </Typography>
            </Grid> */}
                        {/* {showDiscountInput ? (
              <>
                <Grid
                  container
                  sx={{ alignItems: "end", marginBottom: "50px" }}
                >
                  <Grid
                    item
                    xs={7}
                    md={5}
                    className="pl-15"
                    style={{ marginRight: "10px" }}
                  >
                    <FormControl variant="standard" style={{ width: "100%" }}>
                      <label
                        className="input-form-label"
                        htmlFor=" Inspection ID"
                        id="demo-simple-select-label"
                      >
                        Description
                      </label>
                      <TextField
                        className="input-textfield"
                        size="small"
                        variant="outlined"
                        placeholder="Description"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} md={2} style={{ marginRight: "10px" }}>
                    <FormControl variant="standard" style={{ width: "100%" }}>
                      <label
                        className="input-form-label"
                        htmlFor=" Inspection ID"
                        id="demo-simple-select-label"
                      >
                        Price
                      </label>
                      <TextField
                        className="input-textfield"
                        size="small"
                        variant="outlined"
                        placeholder="price"
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} md={4} style={{ marginBottom: "2px" }}>
                    <Button className="add-btn" variant="contained">
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )} */}
                    </Grid>
                    {/* buttons for the edit screen**************************************** */}
                    <Grid container spacing={2} className="inspection-filter-modal-buttons" sx={{ mb: '15px' }}>
                        <Button variant="outlined" className="inspection-filter-modal-buttons-cancel" onClick={handleClose}>
                            Cancel
                        </Button>
                        <LoadingButton loading={loadingButton} disabled={!servicevaluechange} variant="contained" className="inspection-filter-modal-buttons-apply" onClick={(event) => handleformSubmit(event, 'DONE')}>
                            Done
                        </LoadingButton>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};
