// Files imports
import SearchIcon from "@mui/icons-material/Search";
import {
  Grid,
  Paper,
  InputBase,
  IconButton,
} from "@mui/material";

/**
 * EnhancedTableToolbar component
 *
 * Renders the toolbar for the comment library table
 *
 * @param {object} props - The props from parent component
 * @param {function} props.handleSearchFilter - The function to handle search filter input
 *
 * @returns {ReactElement}
 */
export const EnhancedTableToolbar = (props) => {
  // Render the toolbar for the comment library table
  return (
    <Grid
      // Use a grid layout for the toolbar
      sx={{
        flexGrow: 1,
      }}
      container
      spacing={2}
    >
      <Grid
        // Make the toolbar item take up 12 columns on small screens
        item
        xs={12}
        // Set the width of the toolbar to 100% on small screens
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%",
          },
        }}
        className="inspection-toolbar-main"
      >
        <Paper
          // Remove the paper elevation
          elevation={0}
          // Set the className for the toolbar
          className={"inspection-search-field"}
          // Use the following styles for the toolbar
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            // Set the width of the toolbar to 241px on medium screens and above
            width: {
              xs: "241px",
              sm: "29.64%",
              md: "29.64%",
              lg: "29.64%",
            },
          }}
        >
          <IconButton
            // Set the padding for the icon button
            sx={{ p: "10px" }}
            // Set the aria label for the icon button
            aria-label="search"
          >
            <SearchIcon
              // Set the className for the search icon
              className="inspection-search-icon"
            />
          </IconButton>
          <InputBase
            // Use the following styles for the input field
            sx={{
              ml: 1,
              flex: 1,
              fontSize: "16px",
            }}
            // Set the placeholder text for the input field
            placeholder="Search template library"
            // Set the aria label for the input field
            inputProps={{
              "aria-label": "search",
            }}
            // Call the handleSearchFilter function when the input changes
            onChange={props.handleSearchFilter}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export function stableSort(commentListArray, comparator) {
  const stabilizedThis = commentListArray?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

