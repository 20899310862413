import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import { Grid, StepLabel } from "@mui/material";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import validator from "validator";
// component imports
import BasicDetails from "../../components/ScheduleInspection/BasicDetails";
import PropertyDetails from "../../components/ScheduleInspection/PropertyDetails";
import AgentDetails from "../../components/ScheduleInspection/AgentDetails";
import ClientDetails from "../../components/ScheduleInspection/ClientDetails";
import Services from "../../components/ScheduleInspection/Services";
import Preloader from "../../helpers/Preloader";
import { apiCall } from "../../utils/action";
//custom style import
import {
  AddNewClientRequest,
  ScheduleNewInspection,
  AddNewAgentRequest,
  EditInspectionAPI,
} from "../../pages/scheduleInspection/action";
import ScheduleMessage from "../../common/schedule/Schedule";
import ScheduleButtons from "../../common/schedule/ScheduleButtons";
import {
  formatPhoneNumber,
  formatPhoneNumberDashes,
  Alert,
  formatZip,
} from "../../utils/services";
import { RemoveShoppingCartRounded } from "@mui/icons-material";
// pdf converts
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import moment from "moment";
// pdf converts

const ScheduleLayout = (props) => {
  const {
    inspList,
    navigate,
    clientList,
    agentsLists,
    servicesLists,
    uuId,
    inspectionId,
    location,
    companyId,
    roleId,
    getDetailsFetch,
    ScheduleInspectionSteps,
    isMobile,
    isTaborMobile,
    queryId,
    Type,
    editValues,
    agreementsList,
    allTemplateList,
  } = props;
  let agreementIdPDF = agreementsList.map((e) => e.id);
  const [activeStep, setActiveStep] = useState(0);
  const [address, setAddress] = React.useState({
    location_address: "",
    location_address1: "",
    location_city: "",
    location_state: "",
    location_zip: "",
  });
  const [dateTime, setDateTime] = React.useState();
  const [clientName, setClientName] = useState([]);
  //usestate for adding seller & buyer agent (anil)
  const [sellerAgent, setSellerAgent] = useState([]);
  const [sellerAgent2, setSellerAgent2] = useState([]);
  const [buyerAgent, setBuyerAgent] = useState([]);
  const [buyerAgent2, setBuyerAgent2] = useState([]);
  //ending
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [status, setStatus] = useState(0);
  const [isNewAgent, setIsNewAgent] = useState(false);
  const [isNewClient, setIsNewClient] = useState(false);
  const [isNewClient2, setIsNewClient2] = useState(false);
  const [selectedInspectors, setSelectedInspectors] = useState([]);

  // validations
  const [addressValid, setAddressValid] = useState(null);
  const [cityValid, setCityValid] = useState(null);
  const [stateValid, setStateValid] = useState(null);
  const [zipValid, setZipValid] = useState(null);
  const [clientValid, setClientValid] = useState(0);
  const [servicesValid, setServicesValid] = useState(2);
  const [disableSchedule, setDisableSchedule] = useState(false);
  const [disableSchedule2, setDisableSchedule2] = useState(false);
  const [disableSchedule3, setDisableSchedule3] = useState(false);
  const [ChargeValid, setChargeValid] = useState(false);
  // client
  const [clientFName, setClientFName] = useState(null);
  const [clientEmail, setClientEmail] = useState(null);
  const [clientLName, setClientLName] = useState(null);

  const [clientFName2, setClientFName2] = useState(null);
  const [clientEmail2, setClientEmail2] = useState(null);
  const [clientLName2, setClientLName2] = useState(null);

  const [clientFName3, setClientFName3] = useState(null);
  const [clientEmail3, setClientEmail3] = useState(null);
  const [clientLName3, setClientLName3] = useState(null);

  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [ordercharge, setOrderCharge] = useState([]);
  const [orderdiscounts, setOrderDiscounts] = useState([]);
  // given url string

  const [user, setUser] = React.useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
  });

  const [user2, setUser2] = React.useState({
    first_name2: "",
    last_name2: "",
    phone2: "",
    email2: "",
  });

  const IsCustomCharges = (status) => {
    if (status) {
      setChargeValid(true);
    } else {
      setChargeValid(false);
    }
  };
  //  const agentArray=[]

  // variables for unsigned agreement upload
  let convertedPDFArray = [];
  let agreementNameArray = [];

  const [showLoader, setShowLoader] = useState(false);
  // snackbar for subcription message show
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleInput = (e) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setInputValue(formattedPhoneNumber);
  };

  const handleInput2 = (e) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setInputValue2(formattedPhoneNumber);
  };

  const handleUser = (evt) => {
    const value =
      evt.target.name === "phone"
        ? formatPhoneNumberDashes(evt.target.value)
        : evt.target.value;
    setUser({
      ...user,
      [evt.target.name]: value,
    });
  };

  const handleUser2 = (evt) => {
    const value =
      evt.target.name === "phone2"
        ? formatPhoneNumberDashes(evt.target.value)
        : evt.target.value;
    setUser2({
      ...user2,
      [evt.target.name]: value,
    });
  };

  var fname = [];
  var lname = [];
  var emai = [];
  var ph = [];

  fname.push(user.first_name);
  lname.push(user.last_name);
  emai.push(user.email);
  ph.push(user.phone);

  const formdata = {
    company_id: companyId,
    user_id: uuId,
    role_id: roleId,
    first_name: fname,
    last_name: lname,
    phone: ph,
    email: emai,
    // company : ''
  };

  var fname2 = [];
  var lname2 = [];
  var emai2 = [];
  var ph2 = [];

  fname2.push(user2.first_name2);
  lname2.push(user2.last_name2);
  emai2.push(user2.email2);
  ph2.push(user2.phone2);

  const formdata2 = {
    company_id: companyId,
    user_id: uuId,
    role_id: roleId,
    first_name: fname2,
    last_name: lname2,
    phone: ph2,
    email: emai2,
    // company : ''
  };

  const [inputValue3, setInputValue3] = useState("");

  const [user3, setUser3] = React.useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    company: "",
  });

  const handleOrders = (charges, discounts) => {
    setOrderCharge(charges);
    setOrderDiscounts(discounts);
  };

  const handleUser3 = (evt) => {
    const value =
      evt.target.name === "phone"
        ? formatPhoneNumberDashes(evt.target.value)
        : evt.target.value;
    setUser3({
      ...user3,
      [evt.target.name]: value,
    });
  };

  const handleInput3 = (e) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setInputValue3(formattedPhoneNumber);
  };

  var fname3 = [];
  var lname3 = [];
  var emai3 = [];
  var ph3 = [];
  let agency = [];

  fname3.push(user3.first_name);
  lname3.push(user3.last_name);
  emai3.push(user3.email);
  ph3.push(user3.phone);
  agency.push(user3.company);

  const formdata3 = {
    company_id: companyId,
    user_id: uuId,
    role_id: roleId,
    first_name: fname3,
    last_name: lname3,
    phone: ph3,
    email: emai3,
    agency 
  };

  const InspectorChange = (val) => {
    setSelectedInspectors(val);
  };

  const getDateTime = (timestramp) => {
    setDateTime(timestramp);
  };

  const ServicesChange = (val) => {
    setSelectedServices(val);
  };

  const TemplateChange = (val) => {
    setSelectedTemplate(val);
  };

  const handleSellerAgent = (val) => {
    setSellerAgent(val);
  };
  const handleSellerAgent2 = (val) => {
    setSellerAgent2(val);
  };

  const handleChangeAgent = (val) => {
    setBuyerAgent2(val);
  };

  const handleBuyerAgent = (val) => {
    setBuyerAgent(val);
  };

  const handleChangeClient = (val) => {
    setClientName(val);
  };

  const handleNewClient = () => {
    setIsNewClient(!isNewClient);
  };

  const handleNewClient2 = () => {
    setIsNewClient2(!isNewClient2);
  };

  const handleNewAgent = () => {
    // clear the when click on the cancel button(anil)
    setUser3({
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      company: "",
    });
    setIsNewAgent(!isNewAgent);
  };

  var Inspectors = [];
  selectedInspectors.length > 0 &&
    selectedInspectors.map((ins) => Inspectors.push(ins.id));

  // var agentIds = [];
  // agentName.length > 0 && agentName.map((agent) => agentIds.push(agent.id));

  //adding of the buyeragent (anil)
  var buyerAgentId = [];
  // uncomment
  var buyerArr = buyerAgent.concat(buyerAgent2);
  buyerArr.length > 0 && buyerArr.map((agent) => buyerAgentId.push(agent.id));

  //adding of the selleragent(anil)
  let sellerAgentIds = [];

  // uncomment
  var sellerArr = sellerAgent.concat(sellerAgent2);
  sellerArr.length > 0 &&
    sellerArr.map((agent) => sellerAgentIds.push(agent.id));

  //adding of the buyer & seller(anil)
  let buyer_seller_id = [...buyerAgentId, ...sellerAgentIds];
  let filteragents = agentsLists.filter(
    (item) => !buyer_seller_id.includes(item.id)
  );

  var clientIds = [];
  clientName.length > 0 &&
    clientName.map((client) => clientIds.push(client.id));

  var services = [];
  selectedServices.length > 0 &&
    selectedServices.map((ser) => services.push(ser.id));

  var templateId = [];
  selectedTemplate.length > 0 &&
    selectedTemplate.map((temp) => templateId.push(temp.id));
  var clientIdList = [];
  clientList?.map(
    (client) =>
      clientName.length > 0 &&
      clientName.map((id) => {
        client.name === id && clientIdList.push(client.id);
      })
  );

  const handleAddress = (evt) => {
    const value =
      evt.target.name === "location_zip"
        ? formatZip(evt.target.value)
        : evt.target.value;

    setAddress({
      ...address,
      [evt.target.name]: value,
    });
  };

  const handleTotalPrice = (price) => {
    setTotalPrice(price);
  };

  var templateList = [];
  const template =
    selectedServices?.length > 0
      ? selectedServices.map(
          (services) =>
            services.template.length > 0 &&
            services.template.map((template) =>
              templateList.push({
                services_id: template?.service_id,
                template_id: template?.template_id,
                title: template?.template?.Name,
              })
            )
        )
      : [];

  let selectedAgreementList = [];
  selectedServices.forEach((value) =>
    agreementIdPDF?.includes(value.agreementid)
      ? selectedAgreementList.push(value.agreementid)
      : ""
  );
  let selectedAgreementListValue = [];
  agreementsList.forEach((value) =>
    selectedAgreementList.includes(value.id)
      ? selectedAgreementListValue.push({ id: value.id, title: value.title })
      : ""
  );
  let selectedAgreementsIDArray = selectedAgreementListValue.map((i) => i.id);
  var minutes = [];
  const AddMinutes = selectedServices.map((services) =>
    minutes.push(services.minutes)
  );

  useEffect(() => {
    if (Type === "edit") {
      let editInsp = [];
      let editClient = [];
      let editAgent = [];
      let editService = [];
      let editTemplate=[];

      editValues.orderinspector &&
        editValues.orderinspector.length > 0 &&
        editValues.orderinspector.map(
          (insp) => (
            editInsp.push({
              id: insp.inspector_id,
              name: insp.inspector.last_name + " " + insp.inspector.first_name,
            }),
            setSelectedInspectors(editInsp)
          )
        );
      setAddress({
        // ...address,
        location_address: editValues.location_address,
        location_address1: editValues.location_address_2,
        location_city: editValues.location_city,
        location_state: editValues.location_state,
        location_zip: editValues.location_zip,
      });
      
      editValues.ordertemplate &&
        editValues.ordertemplate.length > 0 &&
        editValues.ordertemplate.map(
          (temp) => (
            editTemplate.push({
              id: temp.template.id,
              name: temp.template.name,
            }),
            setSelectedTemplate(editTemplate)
          )
        );

      editValues.orderclient &&
        editValues.orderclient.length > 0 &&
        editValues.orderclient.map(
          (clin) => (
            editClient.push({
              id: clin.client_id,
              name: clin.client.last_name + " " + clin.client.first_name,
            }),
            setClientName(editClient)
          )
        );

      // buyer&seller agent dropdown(anil)
      // agentType=1  :buyer agent
      // agentType=2  :seller agent
      let agentType1 =
        // editValues.orderagent &&
        // editValues.orderagent.length > 0 &&
        editValues?.orderagent?.filter((agent) => agent.agent_type === 1);
      let agentType2 =
        // editValues.orderagent &&
        // editValues.orderagent.length > 0 &&
        editValues?.orderagent?.filter((agent) => agent.agent_type === 2);
      if (agentType1.length !== 0) {
        
        if (agentType1.length === 1) {
          setBuyerAgent([
            {
              id: agentType1[0]?.agent?.id,
              name:
                agentType1[0]?.agent?.last_name +
                " " +
                agentType1[0]?.agent?.first_name,
            },
          ]);
        } else {
          setBuyerAgent([
            {
              id: agentType1[0]?.agent?.id,
              name:
                agentType1[0]?.agent?.last_name +
                " " +
                agentType1[0]?.agent?.first_name,
            },
            
          ]);
          setBuyerAgent2([
            {
              id: agentType1[1]?.agent?.id,
              name:
                agentType1[1]?.agent?.last_name +
                " " +
                agentType1[1]?.agent?.first_name,
            },
          ]);
        }
      }
      // else {
      //   setBuyerAgent(agentType1);
      // }
      if (agentType2.length !== 0) {
        if (agentType2.length === 1) {
          setSellerAgent([
            {
              id: agentType2[0]?.agent?.id,
              name:
                agentType2[0]?.agent?.last_name +
                " " +
                agentType2[0]?.agent?.first_name,
            },
          ]);
        } else {
          setSellerAgent([
            {
              id: agentType2[0]?.agent?.id,
              name:
                agentType2[0]?.agent?.last_name +
                " " +
                agentType2[0]?.agent?.first_name,
            },
            
          ]);
        
          setSellerAgent2([
            {
              id: agentType2[1]?.agent?.id,
              name:
                agentType2[1]?.agent?.last_name +
                " " +
                agentType2[1]?.agent?.first_name,
            },
          ]);
        }
      } 
      // else {
      //   setSellerAgent(agentType2);
      // }

     //---------------------- buyer&seller agent dropdown ending(anil)----------------
   

      // editValues.orderagent && editValues.orderagent.length > 0 && editValues.orderagent.map((age) => (
      //   editAgent.push({id : age.agent_id, name : age.agent.last_name + ' ' + age.agent.first_name}),
      //   setBuyerAgent(editAgent),
      //   setBuyerAgent2(editAgent),
      //   setSellerAgent(editAgent),
      //   setSellerAgent2(editAgent)
      //   )
      //   )
      editValues.orderservice &&
        editValues.orderservice.length > 0 &&
        editValues.orderservice.map((ser) =>
          servicesLists.map((ser1) => {
            if (ser.service.id === ser1.id) {
              editService.push(ser1);
              setSelectedServices(editService);
            }
          })
        );
    }
  }, [props.editRefresh]);

  const validate = (activeStep) => {
    if (activeStep === 0) {
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 1) {
      // if(address){
      if (
        !(
          document.getElementById("PropertyAddressId").value === "" ||
          document.getElementById("PropertyCityId").value === "" ||
          document.getElementById("PropertyStateId").value === "" ||
          document.getElementById("PropertyZipId").value === ""
        )
      ) {
        setActiveStep(activeStep + 1);
        setDisableSchedule(true);
      } else {
        setActiveStep(activeStep + 1);
        setDisableSchedule(false);
      }
    }

    if (activeStep === 2 && !isNewClient) {
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 2 && !isNewClient) {
      if (clientName.length === 0) {
        // setClientValid(1);
        setActiveStep(activeStep);
        setDisableSchedule2(false);
        setActiveStep(activeStep + 1);
      } else {
        // setClientValid(0);
        setDisableSchedule2(true);
        setActiveStep(activeStep + 1);
      }
    }
    

    if (activeStep === 2 && isNewClient) {
      if (document.getElementById("clientFirstId").value === "") {
        setClientFName("Name cannot be blank");
      } else  {
        setClientFName(undefined);
      }
      if (document.getElementById("clientEmailId").value === "") {
        setClientEmail("Email cannot be blank");
      } 
      // email validation (anil)
      else if (
        !validator.isEmail(document.getElementById("clientEmailId").value)
      ) {
        setClientEmail("Please enter a valid email");
      } 
      else if (
        !/^[A-Z0-9._%+]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(document.getElementById("clientEmailId").value)
      ) {
        setClientEmail("Please enter a valid email");
      } 
      else {
        setClientEmail(undefined);
      }
      if (document.getElementById("clientLastId").value === "") {
        setClientLName("Last name cannot be blank");
      } else {
        setClientLName(undefined);
      }
      // email validation (anil)
      if (
        !(
          document.getElementById("clientFirstId").value === "" ||
          document.getElementById("clientEmailId").value === "" ||
          !/^[A-Z0-9._%+]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            document.getElementById("clientEmailId").value
          ) ||
          !validator.isEmail(document.getElementById("clientEmailId").value)||
          document.getElementById("clientLastId").value === ""
        )
      ) {
        AddNewClientRequest((response) => {
          const { data, message, success } = response.data;
          if (success) {
            toast.success(message);
            getDetailsFetch();
            if (data) {
              const ci = data[0].client_id;
              const cn = data[0].client_name;

              const total = { id: ci, name: cn };
              clientName.push(total);
            }
            if (isNewClient && !isNewClient2) {
              // setActiveStep(activeStep + 1);
              setIsNewClient(false);
            } else {
              setActiveStep(activeStep);
            }
          } else if (!success) {
            toast.error(response.message);
          }
        }, formdata);
      }
    }

    if (activeStep === 2 && isNewClient2) {
      if (document.getElementById("clientFirstId2").value === "") {
        setClientFName2("Name cannot be blank");
      } else {
        setClientFName2(undefined);
      }
      if (document.getElementById("clientEmailId2").value === "") {
        setClientEmail2("Email cannot be blank");
      } else if (
        !/\S+@\S+\.\S+/.test(document.getElementById("clientEmailId2").value)
      ) {
        setClientEmail2("Please enter a valid email");
      } else {
        setClientEmail2(undefined);
      }
      if (document.getElementById("clientLastId2").value === "") {
        setClientLName2("Last name cannot be blank");
      } else {
        setClientLName2(undefined);
      }
      if (
        !(
          document.getElementById("clientFirstId2").value === "" ||
          document.getElementById("clientEmailId2").value === "" ||
          !/\S+@\S+\.\S+/.test(
            document.getElementById("clientEmailId2").value
          ) ||
          document.getElementById("clientLastId2").value === ""
        )
      ) {
        AddNewClientRequest((response) => {
          const { data, message, success } = response.data;
          if (response.data.success) {
            toast.success(message);
            getDetailsFetch();
            if (data) {
              const ci = data[0].client_id;
              const cn = data[0].client_name;

              const total = { id: ci, name: cn };
              clientName.push(total);
            }
            if (isNewClient2 && isNewClient) {
              setActiveStep(activeStep + 1);
              handleNewClient2();
              setIsNewClient2(false);
              setIsNewClient(false);
            } else {
              setActiveStep(activeStep);
            }
          } else if (!response.success) {
            toast.error(response.message);
          } else {
            toast.error(response.message);
          }
        }, formdata2);
      }
    }

    if (activeStep === 3 && !isNewAgent) {
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 3 && isNewAgent) {
      if (document.getElementById("clientFirstId3").value === "") {
        setClientFName3("Name cannot be blank");
      } else {
        setClientFName3(undefined);
      }
      if (document.getElementById("clientEmailId3").value === "") {
        setClientEmail3("Email cannot be blank");
      } else if (
        !/\S+@\S+\.\S+/.test(document.getElementById("clientEmailId3").value)
      ) {
        setClientEmail3("Please enter a valid email");
      } else {
        setClientEmail3(undefined);
      }
      if (document.getElementById("clientLastId3").value === "") {
        setClientLName3("Last name cannot be blank");
      } else {
        setClientLName3(undefined);
      }
      if (
        !(
          document.getElementById("clientFirstId3").value === "" ||
          document.getElementById("clientEmailId3").value === "" ||
          !/\S+@\S+\.\S+/.test(
            document.getElementById("clientEmailId3").value
          ) ||
          document.getElementById("clientLastId3").value === ""
        )
      ) {
        AddNewAgentRequest((response) => {
          const { data, message, success } = response.data;
          if (response.data.success) {
            toast.success(message);
            getDetailsFetch();
            if (data) {
              const ci = data[0].agent_id;
              const cn = data[0].agent_name;

              const total = { id: ci, name: cn };

              buyerAgent2.push(total);
            }
            if (isNewAgent) {
              setActiveStep(activeStep + 1);
              setIsNewAgent(false);
            } else {
              setActiveStep(activeStep);
            }
          } else if (!response.success) {
            toast.error(response.message);
          } else {
            toast.error(response.message);
          }
        }, formdata3);
      }
    }
    if (activeStep === 4) {
      if (selectedServices.length === 0) {
        setDisableSchedule3(false);
        setActiveStep(activeStep);
      } else if (selectedServices.length > 0) {
        setDisableSchedule3(true);
        // setActiveStep(activeStep + 1);
      }
    }
  };
  function makePdfAndUploadAgreement(pdfData, name, orderId, index, length) {
    convertedPDFArray.push(pdfData);
    agreementNameArray.push(name);
    length === index + 1 &&
      uploadUnsignedAgreementPDF(
        convertedPDFArray,
        agreementNameArray,
        orderId
      );
  }

  // converting agreement unsigned html to pdf
  const HTMLtoPDF = (data, inspOrderId) => {
    data.map((agreement, index) => {
      var html = htmlToPdfmake(agreement);
      const documentDefinition = { content: html };
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
      pdfDocGenerator.getBase64((pdfdata) => {
        makePdfAndUploadAgreement(
          pdfdata,
          agreement.name,
          inspOrderId,
          index,
          data.length
        );
      });
    });
  };

  // agreement details API
  const GetAgreementDetails = (inspOrderId) => {
    // setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      agreement_id: selectedAgreementsIDArray,
      order_id: inspOrderId,
    };
    apiCall(
      (response) => {
        console.log("GET_AGREEMENT_CONTENT", response);
        const { data, message, success } = response.data;
        if (success) {
          HTMLtoPDF(data, inspOrderId);
          setShowLoader(false);
        } else {
          toast.error(message);
          console.log("else ", message);
        }
      },
      formdata,
      "GET_AGREEMENT_CONTENT"
    );
  };

  // uploading agreementpdf on inspection schedule
  const uploadUnsignedAgreementPDF = (
    convertedPdf,
    agreementNameArray,
    inspOrderId
  ) => {
    const formdata = {
      user_id: uuId,
      company_id: companyId,
      role_id: roleId,
      inspection_id: inspOrderId,
      agreement_file: convertedPdf,
      agreement_id: selectedAgreementsIDArray,
      agreement_file_name: agreementNameArray,
    };
    // Object.keys(params).forEach((key) => formData.append(key, params[key]));
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          // toast.success(message);
          setShowLoader(false);
          convertedPDFArray = [];
          agreementNameArray = [];
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "UPLOAD_UNSIGNED_AGREEMENT"
    );
  };

  var forDate = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
  // console.log(forDate, "current Time");
  var date = moment.utc(forDate).format("YYYY-MM-DD HH:mm:ss");
  const newDateing = new Date(date).toUTCString();
  // console.log(moment.utc(newDateing).format("YYYY-MM-DD HH:mm:ss"),"converted to UTC")
  var newDate = moment(moment.utc(newDateing).local());
  // console.log(newDate.format("YYYY-MM-DD HH:mm:ss"), "converted to local")
  const handleNext = (data) => {
    data.preventDefault();
    if (
      activeStep === ScheduleInspectionSteps.length - 1 &&
      Type === "schedule"
    ) {
      if (selectedServices.length === 0) {
        
        setServicesValid(1);
        setActiveStep(activeStep);
      } else if (selectedServices.length > 0) {
        setServicesValid(0);
        setShowLoader(true);
        let customDiscount = [];
        orderdiscounts.forEach((value) =>
          customDiscount.push({
            description: value.description,
            discount: value.price * -1,
          })
        );
        const formdata = {
          company_id: companyId,
          user_id: uuId,
          role_id: roleId,
          inspector_id: Inspectors,
          inspection_datetime: moment
            .utc(new Date(date).toUTCString())
            .format("YYYY-MM-DD HH:mm:ss"),
          location_address: address.location_address,
          location_address_2: address.location_address1,
          location_city: address.location_city,
          location_state: address.location_state,
          local_starttime: date,
          location_zip: address.location_zip,
          // agent_id: buyerAgentId,
          buyer_agent_id: buyerAgentId, //buyer agent id (anil)
          seller_agent_id: sellerAgentIds, //seller agent id (anil)
          client_id: clientIds,
          service_id: services,
          order_templates: templateId,
          charges: totalPrice,
          service_duration: minutes,
          order_charges: ordercharge,
          order_discounts: customDiscount,
          status: 2,
        };
        ScheduleNewInspection((response) => {
          toast.dismiss()
          const { data, message, success, status_code } = response.data;
          if (success) {
            GetAgreementDetails(response?.data?.data); //uploading unsigned agreement starts here
            console.log(response);
            setShowLoader(false);
            setActiveStep(activeStep + 1);
          } else {
            // toast.error(message);
            setShowLoader(false);
            if (
              status_code === "422" &&
              message ===
                "You can't schedule the inspection as your total inspections exceeded to 5. Please upgrade your subscription."
            )
              setOpenSnackbar(true);
            else toast.error(message);
          }
        }, formdata);
        // setActiveStep(activeStep + 1);
      }
    } else if (
      activeStep === ScheduleInspectionSteps.length - 1 &&
      Type === "edit"
    ) {
      if (selectedServices.length === 0) {
        setServicesValid(1);
        setActiveStep(activeStep);
      } else if (selectedServices.length > 0) {
        setServicesValid(0);
        setShowLoader(true);
        let customDiscount = [];
        orderdiscounts.forEach((value) =>
          customDiscount.push({
            description: value.description,
            discount: value.price * -1,
          })
        );
        const formdata = {
          company_id: companyId,
          user_id: uuId,
          role_id: roleId,
          inspection_id: queryId,
          
          inspector_id: Inspectors,
          start_time: moment
            .utc(new Date(date).toUTCString())
            .format("YYYY-MM-DD HH:mm:ss"),
          location_address: address.location_address,
          location_city: address.location_city,
          location_state: address.location_state,
          inspection_datetime: moment
          .utc(new Date(date).toUTCString())
          .format("YYYY-MM-DD HH:mm:ss"),
          location_zip: address.location_zip,
          // agent_id: buyerAgentId,
          buyer_agent_id: buyerAgentId, //buyer agent id (anil)
          seller_agent_id: sellerAgentIds, // seller agent id (anil)
          local_starttime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
          // agent_id: agentIds,
          client_id: clientIds,
          service_id: services,
          order_templates: templateId,
          charges: totalPrice,
          service_duration: minutes,
          order_charges: ordercharge,
          order_discounts: customDiscount,
          status: 2,
        };
        EditInspectionAPI((response) => {
          toast.dismiss()
          const { data, message, success, status_code } = response.data;
          //api
          // console.log("edit agent api ",data)
          if (success) {
            GetAgreementDetails(response?.data?.data); //uploading unsigned agreement starts here
            console.log(response);
            setShowLoader(false);
            setActiveStep(activeStep + 1);
          } else {
            console.log("else ", message);
            setShowLoader(false);
            toast.error(message)
            if (
              status_code === "422" &&
              message ===
                "You can't schedule the inspection as your total inspections exceeded to 5. Please upgrade your subscription."
            )
              setOpenSnackbar(true);
            else toast.error(message);
          }
        }, formdata);
        // setActiveStep(activeStep + 1);
      }
    }

    validate(activeStep);

    // !validate(activeStep) ? setActiveStep(activeStep) : setActiveStep(activeStep + 1)
    // setActiveStep(activeStep + 1);
  };
  const handleDraft = () => {
    setShowLoader(true);
    let customDiscount = [];
    orderdiscounts.forEach((value) =>
      customDiscount.push({
        description: value.description,
        discount: value.price * -1,
      })
    );
    if (Type === "schedule") {
      const formdata = {
        company_id: companyId,
        user_id: uuId,
        role_id: roleId,
        inspector_id: Inspectors,
        inspection_datetime: moment
          .utc(new Date(date).toUTCString())
          .format("YYYY-MM-DD HH:mm:ss"),
        location_address: address.location_address,
        location_address_2: address.location_address1,
        location_city: address.location_city,
        location_state: address.location_state,
        location_zip: address.location_zip,

        agent_id: buyerAgentId,
        buyer_agent_id: buyerAgentId,
        seller_agent_id: sellerAgentIds,
        client_id: clientIds,
        local_starttime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
        service_id: services,
        order_templates: templateId,
        charges: totalPrice,
        service_duration: minutes,
        order_charges: ordercharge,
        order_discounts: customDiscount,
        status: 1,
      };
      ScheduleNewInspection((response) => {
        toast.dismiss()
        const { data, message, success, status_code } = response.data;
        if (success) {
          console.log(response);
          setShowLoader(false);
          setActiveStep(ScheduleInspectionSteps?.length + 1);
        } else {
          toast.error(message)
          setShowLoader(false);
          if (
            status_code === "422" &&
            message ===
              "You can't schedule the inspection as your total inspections exceeded to 5. Please upgrade your subscription."
          )
            setOpenSnackbar(true);
        }
      }, formdata);
    } else if (Type === "edit") {
      const formdata = {
        company_id: companyId,
        user_id: uuId,
        role_id: roleId,
        inspection_id: queryId,
        inspector_id: Inspectors,
        inspection_datetime: moment
          .utc(new Date(date).toUTCString())
          .format("YYYY-MM-DD HH:mm:ss"),
        start_time: moment
          .utc(new Date(date).toUTCString())
          .format("YYYY-MM-DD HH:mm:ss"),
        location_address: address.location_address,
        location_city: address.location_city,
        location_state: address.location_state,
        location_zip: address.location_zip,
        //
        agent_id: buyerAgentId,
        buyer_agent_id: buyerAgentId,
        seller_agent_id: sellerAgentIds,

        local_starttime: moment(date).format("YYYY-MM-DD HH:mm:ss"),
        // agent_id: agentIds,

        client_id: clientIds,
        service_id: services,
        template_id: templateId,
        charges: totalPrice,
        service_duration: minutes,
        order_charges: ordercharge,
        order_discounts: customDiscount,
        status: 1,
      };
      EditInspectionAPI((response) => {
        toast.dismiss()
        const { data, message, success, status_code } = response.data;
        // console.log("edit inspection api",data.orderagent)
        if (success) {
          console.log(response);
          setShowLoader(false);
          setActiveStep(ScheduleInspectionSteps?.length + 1);
        } else {
          console.log("else ", message);
          setShowLoader(false);
          toast.error(message)
          if (
            status_code === "422" &&
            message ===
              "You can't schedule the inspection as your total inspections exceeded to 5. Please upgrade your subscription."
          )
            setOpenSnackbar(true);
        }
      }, formdata);
    }
  };

  return (
    <>
      <Snackbar
        className="snackbar"
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          Your 5 free trial inspection schedule has ended..Please subscribe to
          schedule unlimited inspections
        </Alert>
      </Snackbar>
      <Preloader showPreloader={showLoader} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          className={
            isTaborMobile
              ? "schedule-inspection-container"
              : "schedule-inspection-container-light"
          }
        >
          <Grid item xs="none" md={1}></Grid>
          <Grid item xs={12} md={10} className="modal-container">
            <Typography align="right" className="close-icon">
              <CloseIcon
                onClick={() =>
                  location.state?.calendar
                    ? navigate("/calendar")
                    : navigate("/inspections")
                }
              />
            </Typography>

            <div
              className={
                !isTaborMobile
                  ? "inspection-data-modal-light"
                  : "inspection-data-modal"
              }
            >
              {activeStep === ScheduleInspectionSteps?.length ||
              activeStep === ScheduleInspectionSteps?.length + 1 ? (
                <ScheduleMessage
                  navigate={navigate}
                  location={location}
                  activeStep={activeStep}
                  ScheduleInspectionSteps={ScheduleInspectionSteps}
                />
              ) : (
                <>
                  <Typography
                    className={
                      isTaborMobile
                        ? "modal-title global-font"
                        : "modal-title-light global-font"
                    }
                  >
                    Schedule an Inspection
                  </Typography>
                  <Stepper
                    nonLinear
                    activeStep={activeStep}
                    style={{ padding: "58px 19px" }}
                  >
                    {ScheduleInspectionSteps?.map((label, index) => (
                      <Step key={label}>
                        <StepButton
                          onClick={() => {
                            setActiveStep(index);
                          }}
                        >
                          <StepLabel className="step-label">{label}</StepLabel>
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                  <form onSubmit={handleNext} style={{ minHeight: "150px" }}>
                    <div
                      style={{ display: activeStep === 0 ? "block" : "none" }}
                    >
                      <BasicDetails
                        selectedInspectors={selectedInspectors}
                        inspectionId={inspectionId}
                        inspList={inspList}
                        Type={Type}
                        editRefresh={props.editRefresh}
                        InspectorChange={InspectorChange}
                        queryId={queryId}
                        editValues={editValues}
                        getDateTime={(timestramp) => getDateTime(timestramp)}
                      />
                    </div>
                    <div
                      style={{ display: activeStep === 1 ? "block" : "none" }}
                    >
                      <PropertyDetails
                        address={address}
                        addressValid={addressValid}
                        cityValid={cityValid}
                        Type={Type}
                        editRefresh={props.editRefresh}
                        editValues={editValues}
                        stateValid={stateValid}
                        zipValid={zipValid}
                        handleAddress={handleAddress}
                      />
                    </div>
                    <div
                      style={{ display: activeStep === 2 ? "block" : "none" }}
                    >
                      <ClientDetails
                        clientName={clientName}
                        clientFName={clientFName}
                        isNewClient2={isNewClient2}
                        clientFName2={clientFName2}
                        clientEmail2={clientEmail2}
                        clientLName2={clientLName2}
                        handleInput={handleInput}
                        handleNewClient2={handleNewClient2}
                        clientEmail={clientEmail}
                        inputValue={inputValue}
                        inputValue2={inputValue2}
                        handleUser2={handleUser2}
                        user={user}
                        handleInput2={handleInput2}
                        user2={user2}
                        handleUser={handleUser}
                        clientValid={clientValid}
                        clientLName={clientLName}
                        isNewClient={isNewClient}
                        clientList={clientList}
                        handleNewClient={handleNewClient}
                        handleChangeClient={handleChangeClient}
                      />
                    </div>
                    <div
                      style={{ display: activeStep === 3 ? "block" : "none" }}
                    >
                      <AgentDetails
                        uuId={uuId}
                        user3={user3}
                        inputValue3={inputValue3}
                        handleUser3={handleUser3}
                        handleInput3={handleInput3}
                        clientFName2={clientFName3}
                        clientEmail2={clientEmail3}
                        clientLName3={clientLName3}
                        handleNewAgent={handleNewAgent}
                        isNewAgent={isNewAgent}
                        roleId={roleId}
                        agentsLists={filteragents}
                        handleChangeAgent={handleChangeAgent}
                        handleBuyerAgent={handleBuyerAgent}
                        handleSellerAgent={handleSellerAgent}
                        handleSellerAgent2={handleSellerAgent2}
                        buyerAgent2={buyerAgent2}
                        buyerAgent={buyerAgent}
                        sellerAgent={sellerAgent}
                        sellerAgent2={sellerAgent2}
                        AddNewAgentRequest={AddNewAgentRequest}
                        setBuyerAgent2={setBuyerAgent2}
                        setSellerAgent2={setSellerAgent2}
                        setSellerAgent={setSellerAgent}
                        getDetailsFetch={getDetailsFetch}
                        formdata3={formdata3}
                        setBuyerAgent={setBuyerAgent}
                      />
                    </div>
                    <div
                      style={{ display: activeStep === 4 ? "block" : "none" }}
                    >
                      <Services
                        IsCustomCharges={(status) => IsCustomCharges(status)}
                        servicesValid={servicesValid}
                        handleTotalPrice={(price) => handleTotalPrice(price)}
                        servicesLists={servicesLists}
                        handleOrders={(charges, discounts) =>
                          handleOrders(charges, discounts)
                        }
                        templateList={templateList}
                        allTemplateList={allTemplateList}
                        ServicesChange={ServicesChange}
                        selectedTemplate={selectedTemplate}
                        selectedServices={selectedServices}
                        TemplateChange={TemplateChange}
                        selectedAgreementListValue={selectedAgreementListValue}
                      />
                    </div>

                    {/* mobile button section */}
                    <ScheduleButtons
                      isMobile={isMobile}
                      navigate={navigate}
                      location={location}
                      handleNext={handleNext}
                      disableSchedule={disableSchedule}
                      disableSchedule2={disableSchedule2}
                      disableSchedule3={
                        selectedServices.length === 0 ? false : true
                      }
                      ChargeValid={ChargeValid}
                      activeStep={activeStep}
                      ScheduleInspectionSteps={ScheduleInspectionSteps}
                      handleBack={() => setActiveStep(activeStep - 1)}
                      handleDraft={handleDraft}
                    />
                  </form>
                </>
              )}
            </div>
          </Grid>
          <Grid item xs="none" md={1}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ScheduleLayout;
