//file imports
import moment from 'moment';
import { Box } from '@mui/system';
import toast from 'react-hot-toast';
import { useState, useEffect } from 'react';
import Preloader from '../../helpers/Preloader';
import RedoIcon from '@mui/icons-material/Redo';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import Heading from '../../common/cardHeading/Heading';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { apiCall } from '../../pages/settings/Services/action';
import { Grid, Paper, Button, Divider, Alert, Modal, Typography } from '@mui/material';
//export function
const Notifications = (props) => {
    //modal toggle
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [loadingButton, setLoadingButton] = useState(false);
    //notification data
    const [data, setData] = useState([]);
    //resend data
    const [resendData, setResendData] = useState();
    //error message
    const [message, setMessage] = useState('');
    //preloader flag
    const [showLoader, setShowLoader] = useState(false);
    //get notification api call
    const GetNotification = () => {
        setShowLoader(true);
        const formdata = { order_id: props.inspectionId };
        apiCall(
            (response) => {
                console.log('GET_INSP_NOTIFICATIONS', response);
                if (response.data.success) {
                    setData(response.data.data);
                    setShowLoader(false);
                } else {
                    console.log('else', response.data.message);
                    setMessage(response.data.message);
                    setShowLoader(false);
                }
            },
            formdata,
            'GET_INSP_NOTIFICATIONS',
        );
    };
    //intialization
    useEffect(() => {
        GetNotification();
    }, []);
    //open alert message
    const OpenAlert = (value) => {
        setResendData(value);
        setOpen(true);
    };
    //resend email
    const resendEmail = (value) => {
        setLoadingButton(true);
        var emailCC = [];
        var emailTO = [];
        if (value.email_cc) {
            if (value.email_cc.includes(',')) {
                var email_cc = value.email_cc.split(',');
                email_cc.forEach((value) => emailCC.push(value));
            } else emailCC.push(value.email_cc);
        }
        if (!value.email_to.includes(',')) {
            emailTO.push(value.email_to);
        }
        const formdata = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
            inspection_id: props.inspectionId,
            email_to: value.email_to.includes(',') ? value.email_to.split(',') : emailTO,
            subject: value.notification_for,
            email_cc: emailCC,
            message_body: value.notification_body,
        };
        apiCall(
            (response) => {
                console.log('RESEND_EMAIL_NOTIFICATION', response);
                if (response.data.success) {
                    setLoadingButton(false);
                    toast.success(response.data.message);
                    setOpen(false);
                } else {
                    setLoadingButton(false);
                    toast.error(response.data.message);
                    console.log('else', response.data.message);
                }
            },
            formdata,
            'RESEND_EMAIL_NOTIFICATION',
        );
    };
    //read more/less function
    const ReadMore = (children) => {
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <>
                <span>
                    {children.body.length > 350 ? isReadMore ? <p className="notification-email-body" dangerouslySetInnerHTML={{ __html: children.body.slice(0, 350) }}></p> : <p className="notification-email-body" dangerouslySetInnerHTML={{ __html: children.body }}></p> : children.body}
                    {children.body.length > 350 ? (
                        <span onClick={toggleReadMore}>
                            {isReadMore ? (
                                <Button className="notification-read-moreless-button" variant="none" endIcon={<ExpandMoreIcon />}>
                                    Load More
                                </Button>
                            ) : (
                                <>
                                    <br />
                                    <Button className="notification-read-moreless-button" variant="none" endIcon={<ExpandLessIcon />}>
                                        Collapse
                                    </Button>
                                </>
                            )}
                        </span>
                    ) : (
                        ''
                    )}
                </span>
            </>
        );
    };
    //email format
    const emailFormatter = (email, valueText) => {
        if (email?.includes(',')) {
            var str = email;
            var str_array = str.split(',');
            return str_array.map((value) => (
                <>
                    <p className="notification-email-tocc">
                        {valueText}: {value}
                    </p>
                </>
            ));
        } else
            return (
                <p className="notification-email-tocc">
                    {valueText}: {email}
                </p>
            );
    };
    //render function
    return (
        <>
            {showLoader ? (
                <Preloader showPreloader={true} />
            ) : (
                <Box sx={{ flexGrow: 1, mt: 3 }}>
                    <Grid container>
                        <Paper elevation={0} className="notification-paper">
                            <Heading title={'Emails'} icon={''} iconDesc={''} />
                            {data.length > 0 ? (
                                <>
                                    {data.map((value) => (
                                        <>
                                            {' '}
                                            <Grid container>
                                                <Grid item lg={3} md={3} xs={3} sx={{ mt: '26px' }}>
                                                    <div style={{ paddingLeft: '18px' }}>
                                                        <span>
                                                            <p className="notification-send-status">
                                                                Sent <CheckIcon className="notification-send-status-icon" />
                                                            </p>
                                                            <p className="notification-date-format">{moment(moment?.utc(value?.sent_on)?.local())?.format('M/DD/YY')}</p>
                                                            <p className="notification-time-format">{moment(moment?.utc(value?.sent_on)?.local())?.format('LT')}</p>
                                                        </span>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={9} md={9} xs={9} className="notification-divider">
                                                    <div>
                                                        <span>
                                                            <p className="notification-subject">{value.notification_for}</p>
                                                            {emailFormatter(value.email_to, 'To')}
                                                            {value?.email_cc?.trim()?.length !== 0 && value?.email_cc !== null ? emailFormatter(value.email_cc, 'CC') : ''}
                                                            <ReadMore body={value.notification_body}></ReadMore>
                                                            <p className="notification-resend-margin">
                                                                <Button sx={{display : props.CancelledStatus && 'none'}} onClick={() => OpenAlert(value)} className="notification-resend-button" startIcon={<RedoIcon />}>
                                                                    Resend
                                                                </Button>
                                                            </p>
                                                        </span>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Divider className="notification-divider" />
                                        </>
                                    ))}
                                </>
                            ) : (
                                <Alert severity="error" className="notification-alert-message">
                                    {message}
                                </Alert>
                            )}
                        </Paper>
                    </Grid>
                </Box>
            )}
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={'inspection-filter-modal-main'}>
                <Box className="notification-resend-alert">
                    <Grid container>
                        <Grid item xs={12}>
                            <CloseIcon className="notification-resend-close-icon" onClick={handleClose} />
                        </Grid>
                        <Grid item xs={12} className="notification-align">
                            <Typography className="notification-resend-confirm-heading">Resend Confirmation</Typography>
                        </Grid>
                        <Grid item xs={12} className="notification-align">
                            <Typography className="notification-resend-confirm">This action cannot be undone.</Typography>
                        </Grid>
                        <Grid container className="notification-resend-button-container">
                            <Button variant="outlined" className="notification-resend-cancel-button" onClick={handleClose}>
                                Cancel
                            </Button>
                            <LoadingButton loading={loadingButton} variant="contained" className="inspection-filter-modal-buttons-apply" sx={{ width: '119px', ml: 3 }} onClick={() => resendEmail(resendData)}>
                                Send
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};

export default Notifications;
