import React from "react";
import TemplateAccord from "../../../common/TemplateUtils/TemplateAccord";
import { TemplateConstants } from "../../../config/template-constants";
import MainCategory from "../EditSection/MainCategory";
import MainSection from "../EditSection/MainSection";
import MainTemplateModal from "../TemplateModals/MainTemplateModal";
import NewSiteDetail from "../TemplateModals/NewSiteDetail";
import { apiCall } from "../../../utils/action";
import TemplateAdd from "../../../common/TemplateUtils/TemplateAdd";
import uuid from "react-uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";
import { timestamp } from "../../../utils/services";
import { AnimatePresence, motion } from "framer-motion";
import { Skeleton } from "@mui/material";

const CategoryLists = (props) => {
  const {
    CategoryList,
    id,
    disableDnD,
    category,
    duplicateCategoryId,
    setDuplicateCategoryId,
    isInspection,
    AllCategoryList,
    updateTemplate,
    checkActiveFocus,
    skeletonLoad,
    setSkeletonLoad,
    isFocused,
    renamingId,
    duplicateIndex,
    renId,
    isChanged,
    activeRename,
    TriggerClick,
    outsideClick,
    LoadList,
  } = props;

  const categorydata = category?.lines;

  // Accordtion
  const [expanded, setExpanded] = React.useState("");
  const [accordOpen, setAccordOpen] = React.useState(false);
  const [menu, setMenu] = React.useState(false);
  const [ActiveId, setActiveId] = React.useState(null);
  const [catoutput, setCatOutput] = React.useState([]);
  const [duplicateLineId, setduplicateLineId] = React.useState(false);
  const [lineLoader, setlineLoader] = React.useState(false);

  React.useEffect(() => {
    setCatOutput(categorydata);
  }, [categorydata]);

  const uuids1 = uuid();
  const uuids2 = uuid();
  const uuids3 = uuid();
  const uuids4 = uuid();
  const uuids5 = uuid();
  const uuids6 = uuid();
  const uuids7 = uuid();

  // Create site

  const [createNewSite, setCreateNewSite] = React.useState(false);
  const [createNewSite1, setCreateNewSite1] = React.useState(false);

  const [siteName, setSiteName] = React.useState("");
  const [siteId, setSiteId] = React.useState(null);
  const HandleCreateSite = () => setCreateNewSite(!createNewSite);

  const stopActive = (event) => event.stopPropagation();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : "categories");
    setAccordOpen(!accordOpen);
  };

  const OpenMenu = (event, id) => {
    stopActive(event);
    setMenu(!menu);
    setActiveId(id);
  };

  const CloseMenu = (event) => {
    stopActive(event);
    setMenu(!menu);
  };

  const CreateBlankLine = (id) => {
    const datas = {
      line_id: uuids1 /*uuid */,
      uuid: uuids1,
      prompt: null,
      comments: [],
      defaultRating_id: 1,
      order: null,
      default_location: null,
      updated_at: timestamp(),
      is_deleted: 0,
      optional: 0,
      line_input_controls: [],
    };
    const catList = AllCategoryList?.template?.categories;
    const categoriesData = catList.findIndex((cat) => cat.category_id === id);
    if (catList[categoriesData]?.lines?.length >= 0)
      catList[categoriesData].lines.push(datas);
    else catList[categoriesData].lines = [datas];
    updateTemplate("noToast");
  };

  const HandleCreateSite1 = () => {
    setCreateNewSite1(!createNewSite1);
    setSiteName("");
    setSiteId(null);
  };

  const AddSiteDetaill = () => {
    const addSite = AllCategoryList.template.categories;
    let insp = addSite.find((insp) => insp.is_inspection_details === 1);
    // console.log(insp);
    // let dds = addSite.indexOf(is_inspection_details === 1)
    // console.log(dds)
    const checkboxIp =
      siteId.id === 1
        ? {
          type: "CheckBox",
          uuid: uuids1,
          prompt: siteName,
          line_id: uuids2,
          comments: [],
          created_at: timestamp(),
          is_deleted: 0,
          updated_at: timestamp(),
          default_location: null,
          line_input_controls: [
            {
              type: "CheckBox",
              uuid: uuids6,
              prompt: siteName,
              data: [],
              values: [
                {
                  uuid: uuids3,
                  value: "check 1",
                  updated_at: timestamp(),
                  is_selected: false,
                  is_deleted: 0,
                  order: 1,
                },
                {
                  uuid: uuids4,
                  value: "check 2",
                  updated_at: timestamp(),
                  is_selected: false,
                  is_deleted: 0,
                  order: 2,
                },
                {
                  uuid: uuids5,
                  value: "check 3",
                  updated_at: timestamp(),
                  is_selected: false,
                  is_deleted: 0,
                  order: 3,
                },
              ],
              created_at: timestamp(),
              updated_at: timestamp(),
              line_input_control_id: uuids7,
            },
          ],
        }
        : siteId.id === 2
          ? {
            type: "TextBox",
            uuid: uuids1,
            prompt: siteName,
            line_id: uuids2,
            comments: [],
            created_at: timestamp(),
            is_deleted: 0,
            updated_at: timestamp(),
            default_location: null,
            line_input_controls: [
              {
                type: "TextBox",
                uuid: uuids6,
                prompt: siteName,
                data: "",

                values: [],
                created_at: timestamp(),
                updated_at: timestamp(),
                line_input_control_id: uuids7,
              },
            ],
          }
          : siteId.id === 3 && {
            type: "Temperature",
            uuid: uuids1,
            prompt: siteName,
            line_id: uuids2,
            comments: [],
            created_at: timestamp(),
            is_deleted: 0,
            updated_at: timestamp(),
            default_location: null,
            line_input_controls: [
              {
                type: "Temperature",
                uuid: uuids6,
                prompt: siteName,
                data: { "type": null, "value": null },
                values: [{ type: null, value: null }],
                created_at: timestamp(),
                updated_at: timestamp(),
                line_input_control_id: uuids7,
              },
            ],
          };
    insp.lines.push(checkboxIp);
    // addSite.prompt === "Inspection Details" && addSite.lines[0].line_input_controls.push(dd)
    updateTemplate("noToast");
    HandleCreateSite1();
  };

  const reorder = (data, startIndex, endIndex) => {
    const result = Array.from(data);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      catoutput,
      result.source.index,
      result.destination.index
    );

    reorderedItems.forEach((x, i) => {
      x.order = i + 1;
      x.updated_at = timestamp();
      return x;
    });
    setCatOutput(reorderedItems);

    let catList = AllCategoryList?.template?.categories;
    catList.map((cat, index) => {
      if (cat.category_id === id) {
        // cat.lines = catoutput;
        updateTemplate("noToast");
      }
    });
  };

  const [actives, setActives] = React.useState(false);
  const [ids, setIds] = React.useState("");

  const disableDnD2 = (isOpen, id) => {
    setActives(isOpen);
    setIds(id);
  };

  return (
    <>
      <TemplateAccord
        disableDnD={(isOpen, id) => disableDnD(isOpen, id)}
        isFocused={isFocused}
        checkActiveFocus={checkActiveFocus}
        expanded={expanded}
        handleChange={(panel) => handleChange(panel)}
        accordOpen={accordOpen}
        selectedID={category.category_id}
        categoryId={category.category_id}
        skeletonLoad={skeletonLoad}
        setSkeletonLoad={setSkeletonLoad}
        duplicateIndex={duplicateIndex}
        linedata={category}
        AllCategoryList={AllCategoryList}
        isInspection={isInspection}
        duplicateCategoryId={duplicateCategoryId}
        setDuplicateCategoryId={setDuplicateCategoryId}
        from={`CATEGORY_${category.category_id}`}
        isChanged={isChanged}
        renId={renId}
        renamingId={(id) => renamingId(id)}
        activeRename={activeRename}
        TriggerClick={TriggerClick}
        outsideClick={outsideClick}
        updateTemplate={updateTemplate}
        HeaderTitle={category.prompt}
        LoadList={LoadList}
        innerTemplate={
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="DefaultDetails">
                {(provided) => (
                  <div ref={provided.innerRef}>
                    <AnimatePresence>
                      {catoutput?.map((items, index) => {
                        return (

                          <motion.div
                            key={items?.line_id}
                            onAnimationStart={() => setlineLoader(false)}
                            initial={{ opacity: 0, scale: 1, y: -50 }}
                            animate={{ opacity: 1, scale: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                            exit={{ opacity: 0, scale: 1, x: -50 }}
                          >
                            <Draggable
                              draggableId={String(items.line_id)}
                              isDragDisabled={actives && items.line_id === ids}
                              key={items.line_id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >

                                  <TemplateAccord
                                    disableDnD={(isOpen, id) =>
                                      disableDnD2(isOpen, id)
                                    }
                                    expanded={expanded}
                                    handleChange={(panel) => handleChange(panel)}
                                    accordOpen={accordOpen}
                                    selectedID={items.line_id}
                                    lineIndex={index}
                                    AllCategoryList={AllCategoryList}
                                    setduplicateLineId={setduplicateLineId}
                                    duplicateLineId={duplicateLineId}
                                    isChanged={isChanged}
                                    setlineLoader={setlineLoader}
                                    renId={renId}
                                    checkActiveFocus={checkActiveFocus}
                                    isInspectionLines={items.is_inspection_detail_line}
                                    linedata={items}
                                    renamingId={(id) => renamingId(id)}
                                    activeRename={activeRename}
                                    from={`SUBCATEGORY_${items.line_id}`}
                                    HeaderTitle={items.prompt}
                                    TriggerClick={TriggerClick}
                                    outsideClick={outsideClick}
                                    updateTemplate={updateTemplate}
                                    LoadList={LoadList}
                                    categoryId={category.category_id}
                                    innerTemplate={
                                      <MainCategory
                                        renId={renId}
                                        checkActiveFocus={checkActiveFocus}
                                        isChanged={isChanged}
                                        renamingId={(id) => renamingId(id)}
                                        updateTemplate={updateTemplate}
                                        TriggerClick={TriggerClick}
                                        activeRename={activeRename}
                                        selectedID={items.line_id}
                                        lineItem={items}
                                        isInspection={isInspection}
                                        categoryId={category.category_id}
                                        categoryName={category.prompt}
                                        lineId={items.line_id}
                                        AllCategoryList={AllCategoryList}
                                      />
                                    }
                                    actions={{
                                      CloseMenu: (event) => CloseMenu(event),
                                      stopActive: (event) => stopActive(event),
                                      OpenMenu: (event, id) => OpenMenu(event, id),
                                      ActiveId,
                                      menu,
                                    }}
                                  />

                                </div>
                              )
                              }
                            </Draggable>
                            {lineLoader && index === duplicateLineId - 1 ? <motion.div initial={{ opacity: 0, scale: 1, y: -50 }}
                              animate={{ opacity: 1, scale: 1, y: 0 }}
                              transition={{ duration: 0.5 }}>
                              <Skeleton sx={{ height: "69px", marginTop: "-15px" }} />
                            </motion.div> : ""}
                          </motion.div>

                        );
                      })}
                    </AnimatePresence>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <TemplateAdd
              from={"subcat"}
              HandleCreateSite={() =>
                isInspection === 1
                  ? HandleCreateSite1()
                  : CreateBlankLine(category.category_id)
              }
              name={isInspection === 1 ? "Add New" : "Add line"}
            />
          </>
        }
        actions={{
          CloseMenu: (event) => CloseMenu(event),
          stopActive: (event) => stopActive(event),
          OpenMenu: (event, id) => OpenMenu(event, id),
          ActiveId,
          menu,
        }}
      />

      <MainTemplateModal
        createNewSite={createNewSite1}
        title={"New site detail"}
        type={"NewSite"}
        HandleCreateSite={HandleCreateSite1}
        actions={{
          AddSiteDetaill: () => AddSiteDetaill(),
        }}
        innerTemplate={
          <NewSiteDetail
            siteValues={{
              name: (name) => setSiteName(name),
              value: (value) => setSiteId(value),
              siteName,
              siteId,
            }}
          />
        }
      />

      <MainTemplateModal
        createNewSite={createNewSite}
        title={"New site detail"}
        type={"NewSite"}
        HandleCreateSite={HandleCreateSite}
        innerTemplate={<NewSiteDetail />}
      />
    </>
  );
};

export default CategoryLists;