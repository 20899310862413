// File Import(s)
import { useState, useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../../../assets/styles/containers/_commentFilter.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Paper, Popper, Button, ButtonGroup, ClickAwayListener, Grow, MenuList, MenuItem } from "@mui/material";

/**
 * FilterButtonGroup component
 *
 * Renders a group of buttons that the user can use to filter
 * the comment library.
 */

const options = ["All", "Used", "Unused", "Summary", "Deleted"];
export default function FilterButtonGroup({ GetCommentList, selectedFilterTagID, setState }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery("(max-width:640px)");
  const [selectedId, setSelectedId] = useState("All");
  const [selectedIndex, setSelectedIndex] = useState(1);
  const handleFilterButtonChange = (id, index) => {
    setSelectedId(id);
    setSelectedIndex(index);
    setState((prevState) => ({
      ...prevState,
      filterType: id?.toLowerCase(),
      page: 0,
    }));
    GetCommentList({ filterByType: id?.toLowerCase(), filterByTagId: selectedFilterTagID });
  };
  const handleMenuItemClick = (event, index, option) => {
    setSelectedIndex(index);
    setSelectedId(option);
    setState((prevState) => ({
      ...prevState,
      filterType: option?.toLowerCase(),
      page: 0,
    }));
    GetCommentList({ filterByType: option?.toLowerCase(), filterByTagId: selectedFilterTagID });
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return matches ? (
    <>
      <ButtonGroup variant="none" ref={anchorRef} aria-label="Button group with a nested menu" className="button-grp-mobile">
        <Button>
          <span style={{ marginLeft: "5px" }}>{options[selectedIndex]}</span>
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 99,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <>
                        <MenuItem key={option} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index, option)}>
                          {option}
                        </MenuItem>
                        <br />
                      </>
                    ))}
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  ) : (
    <ButtonGroup variant="none" className="filter-button-group-container">
      <Button variant="none" className={selectedId === "All" ? "filter-button-tab-selected" : "filter-button-tab"} id="All" onClick={() => handleFilterButtonChange("All", 0)}>
        <span className={selectedId === "All" ? "filter-buttongrp-selected-textfield" : "filter-buttongrp-textfield"}>All</span>
      </Button>

      <Button variant="none" className={selectedId === "Used" ? "filter-button-tab-selected" : "filter-button-tab"} id="Used" onClick={() => handleFilterButtonChange("Used", 1)}>
        <span className={selectedId === "Used" ? "filter-buttongrp-selected-textfield" : "filter-buttongrp-textfield"}>Used</span>
      </Button>

      <Button variant="none" className={selectedId === "Unused" ? "filter-button-tab-selected" : "filter-button-tab"} id="Unused" onClick={() => handleFilterButtonChange("Unused", 2)}>
        <span className={selectedId === "Unused" ? "filter-buttongrp-selected-textfield" : "filter-buttongrp-textfield"}>Unused</span>
      </Button>

      <Button variant="none" className={selectedId === "Summary" ? "filter-button-tab-selected" : "filter-button-tab"} id="Summary" onClick={() => handleFilterButtonChange("Summary", 3)}>
        <span className={selectedId === "Summary" ? "filter-buttongrp-selected-textfield" : "filter-buttongrp-textfield"}>Summary</span>
      </Button>

      <Button variant="none" className={selectedId === "Deleted" ? "filter-button-tab-selected" : "filter-button-tab"} id="Deleted" onClick={() => handleFilterButtonChange("Deleted", 4)}>
        <span className={selectedId === "Deleted" ? "filter-buttongrp-selected-textfield" : "filter-buttongrp-textfield"}>Deleted</span>
      </Button>
    </ButtonGroup>
  );
}
