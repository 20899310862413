import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// material ui imports
import { Grid, Button, InputAdornment, IconButton } from "@mui/material";
import { Base64 } from "js-base64";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import moment from "moment";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FormHelperText from '@mui/material/FormHelperText'; 
import Visibility from "@mui/icons-material/Visibility"; 
import VisibilityOff from "@mui/icons-material/VisibilityOff"; 
// dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";

// styles imports
import "../../../../assets/styles/containers/_companyProfile.scss";

// component imports
import Preloader from "../../../helpers/Preloader";
import {subscriptionCancelOptions} from '../../../config/app-constants';
import {
  onKeyPressed,
  formatPhoneNumberDashes,
  formatZip,BootstrapTooltip
} from "../../../utils/services";
import { apiCall } from "../../../utils/action";

const PTCompanyDetailsView = (props) => {
    const [companyDetails, setCompanyDetails] = useState([]);
    const [company, setCompany] = useState("");
    // const [companyError, setCompanyError] = useState(false);
    // const [companyErrorText, setCompanyErrorText] = useState("");
    const [website, setWebsite] = useState("");
    // const [websiteError, setWebsiteError] = useState(false);
    // const [websiteErrorText, setWebsiteErrorText] = useState("");
    const [email, setEmail] = useState("");
    // const [emailError, setEmailError] = useState(false);
    // const [emailErrorText, setEmailErrorText] = useState("");
    const [contact, setContact] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    // const [contactError, setContactError] = useState(false);
    // const [contactErrorText, setContactErrorText] = useState('');
    const [address, setAddress] = useState("");
    // const [addressError, setAddressError] = useState(false);
    // const [addressErrorText, setAddressErrorText] = useState('');
    const [city, setCity] = useState("");
    // const [cityError, setCityError] = useState(false);
    // const [cityErrorText, setCityErrorText] = useState('');
    const [state, setState] = useState("");
    // const [stateError, setStateError] = useState(false);
    // const [stateErrorText, setStateErrorText] = useState('');
    const [zip, setZip] = useState("");
    // const [zipError, setZipError] = useState(false);
    // const [zipErrorText, setZipErrorText] = useState('');
    // const [statement, setStatement] = useState("");
    // const [statementError, setStatementError] = useState(false);
    // const [statementErrorText, setStatementErrorText] = useState('');
    const [billingPlanName, setBillingPlanName] = useState("Standard Plan");
    const [billingRenewalDueDate, setBillingRenewalDueDate] = useState();
    const [companyID, setCompanyID] = useState();
    const [customerType, setCustomerType] = useState('');
    const [customerTypeError, setCustomerTypeError] = useState(false);
    const [customerStatus, setCustomerStatus] = useState(1);
    const [customerStatusError, setCustomerStatusError] = useState(false);
    const [subStartDate, setSubStartDate] = useState(moment());
    const [subCancelDate, setSubCancelDate] = useState(moment());
    const [subCancelReason, setSubCancelReason] = useState('');
    const [subCancelReasonError, setSubCancelReasonError] = useState(false);
    const [subType, setSubType] = useState('');
    const [subTypeError, setSubTypeError] = useState(false);
    const [usePorchViaISN, setUsePorchViaISN] = useState(2);
    const [usePorchViaISNError, setUsePorchViaISNError] = useState(false);
    const [isOneTimePurchase, setIsOneTimePurchase] = useState(2);
    const [isOneTimePurchaseError, setIsOneTimePurchaseError] = useState(false);
    const [isTrialCompany, setIsTrialCompany] = useState(0);
    const [isTrialCompanyError, setIsTrialCompanyError] = useState(false);
    const [teamData, setTeamData] = useState([]);
    const [image, setImage] = useState("");
    const [showLoader, setShowLoader] = useState(false);  
    const [deleteId, setDeleteId] = useState("");
    const [deleteControl, setDeleteControl] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [merchantID, setMerchantID] = useState();
    const [APIuserName, setAPIuserName] = useState('');
    const [APIpassword, setAPIpassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    // const [showAPIuser, setShowAPIuser] = useState(false);
    const fileInput = React.useRef();
    // session datas
    const companyId = sessionStorage.getItem("company_id");
    const roleId = sessionStorage.getItem("role_id");
    const uuId = sessionStorage.getItem("user_id");
    let permission = roleId === "4" ? false : true;  
    const navigate = useNavigate();
  
    const dateFormat=(date)=>{
      if(date === null) setBillingRenewalDueDate(null)
      else{
      let date_availiable = new Date(date);
      let date_month = date_availiable.getMonth() + 1;
      let date_day_without_0 = date_availiable.getDate();
      let date_day = date_day_without_0.toString().padStart(2, '0');
      let date_year = date_availiable.getFullYear().toString().substr(-2);
      setBillingRenewalDueDate(`${date_month}/${date_day}/${date_year}`)
      }
    }
  

    // get companyy data from api
    const getCompanyDetails = () => {
      setShowLoader(true);
      toast.dismiss();
      const formdata = { company_id:companyId ,get_company_id:props.companyID ,user_id:uuId, role_id:roleId };
      apiCall(
        (response) => {
          const { data, message, success } = response.data;
          if (success) {
            // toast.success(message);
            setCompanyDetails(data);
            setTeamData(data.team_members);
            setCompany(data.name);
            setContactPerson(data?.owner_name)
            setWebsite(data.website);
            setEmail(data.email);
            setContact(data.phone);
            setAddress(data.address_1);
            setCity(data.city);
            setState(data.state);
            setZip(data.zip);
            setImage(data.avatar);
            setBillingPlanName(data?.billing_plan)
            dateFormat(data?.billing_renewal_due_date)
            setCompanyID(data.id);
            setCustomerType(data.customer_type)
            setCustomerStatus(data.active)
            setSubStartDate(data.subscription_start_date)
            setSubCancelDate(data.subscription_cancel_date)
            setSubCancelReason(data.subscription_cancel_reason)
            setSubType(data.subscription_type)
            setUsePorchViaISN(data.is_using_porchGold_via_isn)
            setIsOneTimePurchase(data.is_onetimepurchase)
            setIsTrialCompany(data.is_trial_company)
            setMerchantID(data.merchant_id);
            setAPIuserName(data.rest_api_username);
            setAPIpassword(data.rest_api_password !== null?Base64.decode(data.rest_api_password):'');
            setShowLoader(false);
          } else {
            toast.error(message)
          }
        },
        formdata,
        "GET_PT_COMPANY_DETAILS"
      );
    };
    // edit company data
    const editCompanyProfile = () => {
      setShowLoader(true);
      toast.dismiss();
      let formData = new FormData();
      const params = {
        company_id: companyId,
        user_id:uuId,
        role_id:roleId,
        edit_company_id:props.companyID,
        avatar: typeof image === "string" || image === null ? "" : image,
        name: company,website,email,
        phone: contact,
        address_1: address,
        city, state,zip,
        customer_type:customerType,
        active:customerStatus,
        subscription_start_date:subStartDate === null? '':subStartDate,
        subscription_cancel_date:subCancelDate===null ?'':subCancelDate,
        subscription_cancel_reason:subCancelReason,
        subscription_type:subType,
        is_using_porchGold_via_isn:usePorchViaISN,
        is_onetimepurchase:isOneTimePurchase,
        is_trial_company:isTrialCompany,
        merchant_id:merchantID,
        rest_api_username:APIuserName,
        rest_api_password:APIpassword!==''?Base64.encode(APIpassword):APIpassword
        // billing_renewal_due_date:billingRenewalDueDate===null?'':billingRenewalDueDate,
      };
      Object.keys(params).forEach((key) => formData.append(key, params[key]));
      apiCall(
        (response) => {
          const { message, success } = response.data;
          if (success) {
            toast.success(message);
            setShowLoader(false);
          } else {
            toast.error(message);
            setShowLoader(false);
          }
        },
        formData,
        "EDIT_PT_COMPANY_DETAILS"
      );
    };
  
    // get role list function and API call
    const getRoleListsAndEdit = (id,name) => {
      setShowLoader(true);
      const formdata = { user_id: uuId, role_id: roleId, company_id:companyId};
      apiCall(
        (response) => {
          const { data, message, success } = response.data;
          if (success) {
            // toast.success(message);
            setShowLoader(false);
            navigate(`/teams/${name}`,{
                state: {id,edit:true,roleList:data,},
              })
          } else {
            toast.error(message)
          }
        },
        formdata,
        "GET_PT_ROLE_LIST"
      );
    };
  
    // delete member function and API call
    const deleteTeamMember = (id) => {
      setShowLoader(true);
      const formdata = {
        company_id: companyId,
        user_id: uuId,
        role_id: roleId,
        member_id: id,
      };
      handleCloseAlert();
      apiCall(
        (response) => {
          const { message, success } = response.data;
          if (success) {
            toast.success(message);
            setDeleteControl(!deleteControl);
            setShowLoader(false);
          } else {
           toast.error(message)
          }
        },
        formdata,
        "REMOVE_MEMBER"
      );
    };
  
    const validate=()=>{  
      if(customerType === '') setCustomerTypeError(true)
      else setCustomerTypeError(false)
      if(customerStatus === '') setCustomerStatusError(true)
      else setCustomerStatusError(false)
      if(subCancelReason === '') setSubCancelReasonError(true)
      else setSubCancelReasonError(false)
      if(subType === '') setSubTypeError(true)
      else setSubTypeError(false)
      if(usePorchViaISN === '') setUsePorchViaISNError(true)
      else setUsePorchViaISNError(false)
      if(isOneTimePurchase === '') setIsOneTimePurchaseError(true)
      else setIsOneTimePurchaseError(false)
      if(isTrialCompany === '') setIsTrialCompanyError(true)
      else setIsTrialCompanyError(false)
      if(customerType !== '' && customerStatus !== '' && subCancelReason !== '' &&
      subType !== '' && usePorchViaISN !== '' && isOneTimePurchase !== '' &&
      isTrialCompany !== '') editCompanyProfile();
    }
    const handleZipChange=(e)=>{
      const formattedZip = formatZip(e.target.value);
      setZip(formattedZip);
    }
    const handleContactChange = (e) => {
      const formattedPhoneNumber = formatPhoneNumberDashes(e.target.value);
      setContact(formattedPhoneNumber);
    };
    const handleClickOpenAlert = (id) => {
      setDeleteId(id);
      setOpenAlert(true);
    };
  
    const handleCloseAlert = () => {
      setOpenAlert(false);
    };
  
    
    const card = (
      <>
      <div className="profile-header">
        <span >Logo</span>
        {permission && <span className="edit-section"
         onClick={() => fileInput.current.click()}
         >
            <EditIcon /><span>Edit</span></span>}
      </div>
        {/* <CardHeader
          action={
            <EditIcon onClick={() => fileInput.current.click()}>Edit</EditIcon>
          }
          title="Logo"
          className="team-profile-header"
        /> */}
        <input
          ref={fileInput}
          type="file"
          id="profile_image"
          accept="image/*"
          onChange={(e) => setImage(e.target.files[0])}
          style={{ display: "none" }}
        />
        {image && (
          <div className="prof-img-wrapper">
            <img
              className="team-prof-img"
              style={{ maxHeight: "320px", maxWidth: "100%" }}
              src={typeof image === "string" ? image : URL.createObjectURL(image)}
              alt="Thumb"
            />
            {/* <span onClick={()=>setImage()} style={{ cursor: "pointer", padding: 5, background: "red", color: "white", border: "none" }}>
                  Remove Image
                </span> */}
          </div>
        )}
        {!image && (
          <CardMedia
            component="img"
            className="team-prof-img"
            alt="/broken-image.jpg"
            src={
              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
            }
          />
        )}
      </>
    );
    const permissionCard = (
      <>
        <Grid container className="company-permission-card">
          <Grid item xs={12}>
            Billing plan
          </Grid>
          <p>{billingPlanName}</p>
          <Grid item xs={12} sx={{ marginTop: "7px" }}>
            <span className="team-permision-text">
              {billingRenewalDueDate !== null ?
              <>
               Will renew on <br />  <span className="date-placeholder">{billingRenewalDueDate}</span>
               </>:'No expiry date.'
            }
             
            </span>{" "}
          </Grid>
          {permission &&<Button className="default-btn teams-detail-btn"> Change plan</Button>}
        </Grid>
      </>
    );
    const detailsCard = (
      <>
        <Grid container className="team-details-card">
          <Grid
            item
            xs={12}
            className="team-details-cardTitle"
            style={{ marginBottom: "16px" }}
          >
            <span>Contact Info</span>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" className="forms-control">
                <label
                  className="input-form-label"
                  htmlFor="Company"
                >
                  Company
                </label>
                <TextField
                  disabled={!permission}
                  id="Company"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="Company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  // error={companyError}
                  // helperText={`${companyError ? companyErrorText: ""}`}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" className="forms-control">
                <label
                  className="input-form-label"
                  htmlFor="website"
                >
                  Website
                </label>
                <TextField
                  disabled={!permission}
                  id="website"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="Website"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  // error={websiteError}
                  // helperText={`${websiteError ? websiteErrorText : ""}`}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="standard"
                className="forms-control field-reset"
              >
                <label
                  className="input-form-label"
                  htmlFor="email_address"
                >
                  Email Address
                </label>
                <TextField
                  disabled={!permission}
                  id="email_address"
                  className="input-textfield "
                  size="small"
                  variant="outlined"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  // error={emailError}
                  // helperText={`${emailError ? emailErrorText : ""}`}
                  type="email"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" className="forms-control">
                <label
                  className="input-form-label"
                  htmlFor="contact"
                >
                  Phone
                </label>
                <TextField
                  disabled={!permission}
                  id="contact"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="Contact"
                  value={contact}
                  onChange={(e)=>handleContactChange(e)}
                  // onChange={(e) => setContact(e.target.value)}
                  // error={contactError}
                  // helperText={`${contactError ? contactErrorText : ""}`}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="contactPerson"
              >
                Contact
              </label>
              <TextField
                disabled={true}
                id="contactPerson"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder=""
                value={contactPerson}
                // onChange={(e) => setContactPerson(e.target.value)}
                // error={addressError}
                // helperText={`${addressError ? addressErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" className="forms-control">
                <label
                  className="input-form-label"
                  htmlFor="Address"
                >
                  Address
                </label>
                <TextField
                  disabled={!permission}
                  id="Address"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  // error={addressError}
                  // helperText={`${addressError ? addressErrorText : ""}`}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="standard" className="forms-control">
                <label
                  className="input-form-label"
                  htmlFor="city"
                >
                  City
                </label>
                <TextField
                  disabled={!permission}
                  id="city"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  // error={cityError}
                  // helperText={`${cityError ? cityErrorText : ""}`}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="standard" className="forms-control">
                <label
                  className="input-form-label"
                  htmlFor="state"
                >
                  State
                </label>
                <TextField
                  disabled={!permission}
                  id="state"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  // error={stateError}
                  // helperText={`${stateError ? stateErrorText : ""}`}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="standard" className="forms-control">
                <label
                  className="input-form-label"
                  htmlFor="zip"
                >
                  Zip Code
                </label>
                <TextField
                  disabled={!permission}
                  id="zip"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="Zip"
                  value={zip}
                  onKeyDown={onKeyPressed}
                  // onChange={(e) => setZip(e.target.value)}
                  onChange={(e)=>handleZipChange(e)}
                  // error={zipError}
                  // helperText={`${zipError ? zipErrorText : ""}`}
                  type="number"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
    const paymentCard =(
      <Grid container className="team-details-card" style={{padding:'18px 0px'}}>
         <Grid
            item
            xs={12}
            className="team-details-cardTitle"
            style={{ marginBottom: "16px" }}
          >
            <span>Payment Configuration</span>
          </Grid>
          <Grid item xs={12} sm={12}>
              <FormControl variant="standard" className="forms-control">
                <label
                  className="input-form-label"
                  htmlFor="merchantID"
                >
                  Merchant ID number
                </label>
                <TextField
                  disabled={!permission}
                  id="merchantID"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="Merchant ID"
                  value={merchantID}
                  onChange={(e) => setMerchantID(e.target.value)}
                  // error={companyIDError}
                  // helperText={`${companyIDError ? companyIDErrorText: ""}`}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" className="forms-control">
                <label
                  className="input-form-label"
                  htmlFor="restAPIusername"
                >
                  API user name
                </label>
                <TextField
                  disabled={!permission}
                  id="restAPIusername"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="Rest api user name"
                  value={APIuserName}
                  onChange={(e)=>setAPIuserName(e.target.value)}
                  type="text"
                  // type={showAPIuser? "text" : "password"}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <IconButton sx={{ mr: "-9px" }} onClick={()=>setShowAPIuser(!showAPIuser)} onMouseDown={()=>setShowAPIuser(!showAPIuser)}>
                  //         {showAPIuser ? <Visibility /> : <VisibilityOff />}
                  //       </IconButton>
                  //     </InputAdornment>
                  //   ),
                  //   className: "input_props_field", sx: { height: { xs: "55px", md: "40px", lg: "40px" }, fontSize: { xs: "18px", md: "14px", lg: "14px" }},
                  // }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" className="forms-control">
                <label
                  className="input-form-label"
                  htmlFor="restAPIpassword"
                >
                  API password
                </label>
                <TextField
                  disabled={!permission}
                  id="restAPIpassword"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="Rest api password"
                  value={APIpassword}
                  onChange={(e) => setAPIpassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (permission?
                      <InputAdornment position="end">
                        <IconButton sx={{ mr: "-9px" }} onClick={()=>setShowPassword(!showPassword)} onMouseDown={()=>setShowPassword(!showPassword)}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>:''
                    ),
                    className: "input_props_field", sx: { height: { xs: "55px", md: "40px", lg: "40px" }, fontSize: { xs: "18px", md: "14px", lg: "14px" }},
                  }}
                />
              </FormControl>
            </Grid>
      </Grid>
    )
    const CompanyDetailsCard =(
        <>
        <Grid container className="team-details-card" style={{padding:'18px 0px'}}>
        <Grid
            item
            xs={12}
            className="team-details-cardTitle"
            style={{ marginBottom: "16px" }}
          >
            <span>Additional Info</span>
          </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" className="forms-control">
                <label
                  className="input-form-label"
                  htmlFor="companyID"
                >
                  Company ID
                </label>
                <TextField
                  disabled={true}
                  id="companyID"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="Company"
                  value={companyID}
                  onChange={(e) => setCompanyID(e.target.value)}
                  // error={companyIDError}
                  // helperText={`${companyIDError ? companyIDErrorText: ""}`}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} >
            <label
              className="input-form-label pl-15 mb-5"
              htmlFor="Role"
            >
              Customer Type
            </label>
            <FormControl fullWidth className="forms-control field-reset">
              <Select
                labelId="demo-simple-select-label"
                id="Role"
                size="small"
                displayEmpty
                value={customerType}
                onChange={(e)=>setCustomerType(e.target.value)}
                disabled={!permission}
                error={customerTypeError}
              >
                <MenuItem value="">Customer Type</MenuItem>               
                    <MenuItem key="New" value="1">
                      New
                    </MenuItem>
                    <MenuItem key="Upgrade" value="2">
                      Upgrade
                    </MenuItem>
                    <MenuItem key="Others" value="3">
                      Others
                    </MenuItem>
              </Select>
              {customerTypeError && <FormHelperText style={{color:'#d32f2f'}}>Select an option</FormHelperText>}
            </FormControl>          
          </Grid>
          <Grid item xs={12} sm={6}>
            <label
              className="input-form-label pl-15 mb-5"
              htmlFor="customer_status"
            >
              Customer Status
            </label>
            <FormControl fullWidth className="forms-control field-reset">
              <Select
                labelId="customer_status"
                id="customer_status"
                size="small"
                displayEmpty
                value={customerStatus}
                onChange={(e)=>setCustomerStatus(e.target.value)}
                error={customerStatusError}
                disabled={!permission}
              >
                <MenuItem value="">Customer Status</MenuItem>               
                    <MenuItem key="Active" value="1">
                      Active
                    </MenuItem>
                    <MenuItem key="Inactive" value="0">
                      Inactive
                    </MenuItem>
              </Select>
              {customerStatusError && <FormHelperText style={{color:'#d32f2f'}}>Select an option</FormHelperText>}
            </FormControl>          
          </Grid>
          <Grid item xs={12} sm={6} >
            <label
              className="input-form-label pl-15 mb-5"
              htmlFor="sub_type"
              id="subscription_type"
            >
              Subscription Type
            </label>
            <FormControl fullWidth className="forms-control field-reset">
              <Select
                disabled={!permission}
                labelId="subscription-type"
                id="sub_type"
                size="small"
                value={subType}
                onChange={(e)=>setSubType(e.target.value)}
                displayEmpty
                error={subTypeError}
              >
                <MenuItem value={""}>Select subscription Type</MenuItem>              
                   
                    <MenuItem key={"Paid"} value="1">
                      Paid
                    </MenuItem>
                    <MenuItem key={"Sponsored"} value="2">
                      Sponsored
                    </MenuItem>
                    <MenuItem key={"Gold"} value="3">
                      Gold
                    </MenuItem>
                    <MenuItem key={"NA"} value="NA">
                      NA
                    </MenuItem>
              </Select>
              {subTypeError && <FormHelperText style={{color:'#d32f2f'}}>Select an option</FormHelperText>}
            </FormControl>          
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: "2px" }}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="sub_start-date"
                id="sub_start-date"
              >
                Subscription Start Date
              </label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="sub_start-date"
              disabled={!permission}
              value={subStartDate || null}
              format="DD-MM-YYYY"
              minDate={new Date()}
              className={"inspection-filter-modal-select"}
              onChange={(newValue) => {
                setSubStartDate(moment(newValue).format('YYYY-MM-DD'));
              }}                
              // error={date1Error}
              // helperText={`${date1Error ? date1ErrorText : ""}`}
              renderInput={(params) => <TextField {...params} size="small"  error={false} />}
              // error={Boolean(errors.date)}
              // helperText={errors.date?.message}
            />
          </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="sub_cancel-date"
                id="sub_cancel-date"
              >
                Subscription Cancel Date
              </label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={!permission}
              id="sub_cancel-date"
              value={subCancelDate}
              format="DD-MM-YYYY"
              minDate={new Date()}
              className={"inspection-filter-modal-select"}
              onChange={(newValue) => {
                setSubCancelDate(moment(newValue).format('YYYY-MM-DD'));
              }}                
              // error={date1Error}
              // helperText={`${date1Error ? date1ErrorText : ""}`}
              renderInput={(params) => <TextField {...params} size="small" error={false}/>}
              // error={Boolean(errors.date)}
              // helperText={errors.date?.message}
            />
          </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>             
              <label
                className="input-form-label pl-15 mb-5"
                htmlFor="sub_cancel_reason"
                id="sub_cancel"
              >
                Subscription Cancel Reason
              </label>
              <FormControl fullWidth className="forms-control field-reset">
                <Select
                disabled={!permission}
                labelId="subscription-cancel-reason"
                id="sub_cancel_reason"
                size="small"
                value={subCancelReason}
                onChange={(e)=>setSubCancelReason(e.target.value)}
                displayEmpty
                error={subCancelReasonError}
              >
                <MenuItem value={""}>Select options</MenuItem>   
                    {subscriptionCancelOptions.map((items,index)=>
                    <MenuItem key={index} value={items.value}>
                  {items.key}
                    </MenuItem> 
                    )}                                
              </Select>
              {subCancelReasonError && <FormHelperText style={{color:'#d32f2f'}}>Select an option</FormHelperText>}
            </FormControl>
          </Grid>           
          <Grid item xs={12} sm={6}>              
              <label
                className="input-form-label pl-15 mb-5"
                htmlFor="porch_gold_ISN"
                id="porchISN"
              >
                Using Porch Gold Through ISN
              </label>
              <FormControl fullWidth className="forms-control field-reset">
                <Select
                disabled={!permission}
                labelId="porch_gold_ISN"
                id="porch_gold_ISN"
                size="small"
                value={usePorchViaISN}
                onChange={(e)=>setUsePorchViaISN(e.target.value)}
                displayEmpty
                error={usePorchViaISNError}
              >
                <MenuItem value={""}>Select options</MenuItem>   
                                
                    <MenuItem key="yes" value="1">
                      Yes
                    </MenuItem>    
                    <MenuItem key="no" value="2">
                      No
                    </MenuItem>                                      
              </Select>
              {usePorchViaISNError && <FormHelperText style={{color:'#d32f2f'}}>Select an option</FormHelperText>}
            </FormControl>
          </Grid> 
          <Grid item xs={12} sm={6}>
          <label
            className="input-form-label pl-15 mb-5"
            htmlFor="onetime_purchase"
          >
            One Time Purchased
          </label>
          <FormControl fullWidth className="forms-control field-reset">
            <Select
              labelId="onetime_purchase"
              id="onetime_purchase"
              size="small"
              displayEmpty
              value={isOneTimePurchase}
              onChange={(e)=>setIsOneTimePurchase(e.target.value)}
              disabled={!permission}
              error={isOneTimePurchaseError}
            >
              <MenuItem value="">Select options</MenuItem>               
                  <MenuItem key="Yes" value="1">
                    Yes
                  </MenuItem>
                  <MenuItem key="No" value="2">
                    No
                  </MenuItem>
            </Select>
            {isOneTimePurchaseError && <FormHelperText style={{color:'#d32f2f'}}>Select an option</FormHelperText>}
          </FormControl>          
          </Grid>
          <Grid item xs={12} sm={6}>
          <label
            className="input-form-label pl-15 mb-5"
            htmlFor="trial_company"
          >
            Trial Company
          </label>
          <FormControl fullWidth className="forms-control field-reset">
            <Select
              labelId="trial_company"
              id="trial_company"
              size="small"
              displayEmpty
              value={isTrialCompany}
              onChange={(e)=>setIsTrialCompany(e.target.value)}
              disabled={!permission}
              error={isTrialCompanyError}
            >
              <MenuItem value="">Select options</MenuItem>               
                  <MenuItem key="Yes" value="1">
                    Yes
                  </MenuItem>
                  <MenuItem key="No" value="0">
                    No
                  </MenuItem>
            </Select>
            {isTrialCompanyError && <FormHelperText style={{color:'#d32f2f'}}>Select an option</FormHelperText>}
          </FormControl>          
          </Grid>
            {permission && (
              <Grid item xs={12} className="team-detail-cta">
                {/* <Button
                  className="back-btn"                 
                  //   onClick={() => navigate("/settings/team")}
                >
                  Cancel
                </Button> */}
                <Button
                  onClick={validate}
                  className="default-btn teams-detail-btn"
                  id="submit_data"
                >
                  {/* {editTeam ? "Update" : "Finish"} */} Update
                </Button>
              </Grid>
            )}          
          </Grid>
      </>
    )
    const teamsCard = (
      <>
        <Grid
          container
          //   className="team-details-card"
        >
          <Grid
            item
            xs={12}
            className="profile-details-cardTitle"
            style={{ marginBottom: "16px" }}
          >
            <span>Team</span>
          </Grid>
          <hr className="bottom-border" />
          {teamData.length !== 0 ? (
            teamData.map((teamlist,key) => {
              let currentUser = teamlist.id===Number(uuId)?true:false;
              return (
                <>
                  <Grid key={key} container className="profile-details-cardDetail p-15">
                    <Grid
                      item
                      xs={2} sm={2} md={1}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Avatar alt="Remy Sharp" className="teamlist-avatar" src={teamlist.avatar} />
                    </Grid>
                    <Grid item xs={10} sm={10} md={11} className="team-avatar">
                      <Grid container>
                        <Grid item xs={12} className="member-row">
                          <div className="member-name flex-center team-role">{`${teamlist.first_name} ${teamlist.last_name}`}
                          {teamlist.role_name.includes("Owner") ||
                                teamlist.role_name.includes("Admin") ? (
                                  <VerifiedUserIcon className="owner-icon" style={{marginLeft:'10px'}} />
                                ) : (
                                  <BusinessCenterIcon className="insp-icon" style={{marginLeft:'10px'}}/>
                                )}
                          </div>
                          {/* <div className="member-action flex-center">
                            {roleId === "6" && teamlist.role_name ==="Owner" ?
                            <>
                            <BootstrapTooltip title={<h1 className="font-13">You cannot edit this record</h1>}>
                              <EditIcon sx={{ opacity:'0.25', fontSize: 18,cursor:'pointer'}}/>
                            </BootstrapTooltip>
                            <BootstrapTooltip title={<h1>You cannot delete this record</h1>}>
                              <DeleteIcon
                                sx={{ opacity:'0.25', color: "red", fontSize: 18,cursor:'pointer'}} />
                            </BootstrapTooltip>
                            </>
                            :
                            <>                          
                            <BootstrapTooltip title={<h1>Edit details</h1>}>
                              <EditIcon
                                className="edit-icon"
                                // onClick={() => getRoleListsAndEdit(teamlist.id,teamlist.first_name)}
                              />
                            </BootstrapTooltip>
                            <BootstrapTooltip title={<h1>{!currentUser?'Delete record':'You cannot delete yourself'}</h1>}>
                              <DeleteIcon
                                className={!currentUser && 'delete-icon'}
                                sx={{opacity:'0.25',color: "red", fontSize: 18, cursor:'pointer'}}
                                // onClick={!currentUser? () => handleClickOpenAlert(teamlist.id):''}
                              />
                            </BootstrapTooltip></>
                            }
                            
                          </div> */}
                        </Grid>
                        <Grid item xs={12}>
                          <span className="member-detail">{teamlist.email}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <hr className="bottom-border" />
                </>
              );
            })
          ) : (
            <Grid container className="profile-details-cardDetail p-15">
              <Alert
                style={{ alignItems: "center", width: "100%" }}
                severity="error"
              >
                No team members
              </Alert>
            </Grid>
          )}
        </Grid>
      </>
    );
  
    useEffect(() => {
      props.editCompany && getCompanyDetails();
    }, [deleteControl]);
  return (
    <>
    <Preloader showPreloader={showLoader} />
    {showLoader ? (""):(
    <Grid container className="teams-container">
      <Grid item xs={12} className="details-title">
        <span className="team-user-name">Company Profile</span>
        <p>
          Keep your company information up-to-date. Your clients will use this
          to get in touch with you.{" "}
        </p>
      </Grid>
      <Box style={{width:'100%'}}>
        
          {/* ------------------delete alert dialog------------------------ */}
          <Dialog
          open={openAlert}
          onClose={handleCloseAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="delete-alert-modal"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete team member?"}
            <CloseIcon className="close-icon" onClick={handleCloseAlert} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="delete-modal-cancel-btn"
              onClick={handleCloseAlert}
              id="cancel_action"
            >
              Cancel
            </Button>
            <Button
              className="default-btn"
              id="delete_action"
              variant="contained"
              color="error"
              onClick={() => {
                deleteTeamMember(deleteId);
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3}>
            <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
              <Card variant="outlined">{card}</Card>
            </Box>
            <Box sx={{ minWidth: 145 }}>
              <Card variant="outlined">{permissionCard}</Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
              <Card variant="outlined">
                {detailsCard}
                </Card>
            </Box>  
            <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
              <Card variant="outlined">
                {paymentCard}
                </Card>
            </Box>          
            <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
              <Card variant="outlined">
                {CompanyDetailsCard}
                </Card>
            </Box>
            {permission && <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
              <Card variant="outlined">
                {teamsCard}
                </Card>
            </Box>}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )}
    </>
  )
}

export default PTCompanyDetailsView