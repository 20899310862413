import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Autocomplete, Box, Chip, FormControl, Grid, SvgIcon, TextField } from "@mui/material";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Checkbox from "@mui/material/Checkbox";
import { arrowDown } from "../../../../assets/svgIcons/Svg";
import FroalaEditorComponent from "../../../common/froalaHtmlEditor/FroalaEditorComponent";

const EditCategorys = (props) => {
  const { linedata, from, editableData, setCommentTags, commentTags, AllCategoryList } = props;
  const editor = useRef();
  const storeData = useSelector((state) => state.commentLibrary);
  const isNarrative = AllCategoryList?.template?.is_narrative_template;
  const [name, setName] = useState(linedata.prompt);
  const [isOptional, setIsOptional] = useState(linedata.optional);
  const [introductory, setIntroductory] = useState(isNarrative ? { innerHTML: linedata.introductory_text?.replace(/<[^>]+>\s*<\/[^>]+>/g, "") } : linedata.introductory_text);
  const [introductoryDropdowns, setIntroductoryDropdowns] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOptional = (event) => {
    setIsOptional(event.target.checked ? 1 : 0);
  };
  const handleIntroductry = (content) => {
    setIntroductory(content);
  };

  editableData(name, isNarrative ? introductory?.innerHTML : introductory, isOptional, introductoryDropdowns);

  const handleTagChange = (event, selectedTag) => {
    setCommentTags(selectedTag);
  };

  const isSelected = (tagId) => {
    return commentTags.some((tag) => tag.id === tagId);
  };
  const handleEditorChange = (name, content) => {
    setIntroductory({ innerHTML: content });
  };
  const handleChangeDropdown = (event, dropdownUuid, type, commentUUId, commentDisabled) => {
    const selectedValue = event.target.value;
    let textDropdowns = linedata.introductory_text_dropdowns;
    let textDropdownIndex = linedata.introductory_text_dropdowns.findIndex((dropdown) => dropdown.dropdown_uuid === dropdownUuid);
    textDropdowns[textDropdownIndex] = {
      ...textDropdowns[textDropdownIndex],
      selected_value: selectedValue,
    };
    setIntroductoryDropdowns(textDropdowns);
  };
  let introDropElements = document.querySelectorAll(".intro-cl-dropdown");
  introDropElements.forEach((element) => {
    const commentUUId = element.getAttribute("comment_uuid");
    const commentDisabled = element.getAttribute("disabled");
    if (element) {
      element.onchange = (event) => handleChangeDropdown(event, element.id, "intro", commentUUId, commentDisabled);
    }
  });

  return (
    <>
      <Grid container style={{ paddingTop: "34px" }} spacing={2} className="inspection-filter-modal-margins">
        <Grid item lg={11.4} xs={11.4} sm={11.4}>
          <FormControl fullWidth>
            <label className="inspection-filter-modal-label" sx={{ mb: "12px" }}>
              NAME *
            </label>
            <TextField style={{ paddingTop: "0px !important" }} type="text" size="small" name="name" placeholder="Detail name" onChange={(e) => setName(e.target.value)} value={name} />
          </FormControl>
        </Grid>
        {AllCategoryList?.template?.is_narrative_template === 1 && (
          <Grid item lg={11.4} xs={11.4} sm={11.4}>
            {from.split("_")[0] === "CATEGORY" && (
              <FormControl fullWidth>
                <label className="inspection-filter-modal-label" sx={{ mb: "12px" }}>
                  LIBRARY TAGS
                </label>
                <Autocomplete
                  className="forms-control autoHover autocompleteCustom"
                  id="tags-outlined"
                  multiple
                  size="small"
                  options={storeData?.clTagList || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.title}
                  openOnFocus
                  value={commentTags}
                  open={open}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                    },
                    marginBottom: "-22px !important",
                    padding: "0px 0px 0px 0px !important",
                  }}
                  onChange={handleTagChange}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        deleteIcon={<CloseOutlinedIcon />}
                        label={option?.title}
                        {...getTagProps({ index })}
                        sx={{
                          padding: "2px 2px 2px 10px !important",
                          fontSize: "10px !important",
                        }}
                      />
                    ))
                  }
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      key={props?.key + props?.id}
                      onClick={() => {
                        const isAlreadySelected = isSelected(option.id);
                        const newTags = isAlreadySelected ? commentTags.filter((tag) => tag.id !== option.id) : [...commentTags, option];
                        handleTagChange(null, newTags);
                      }}
                      style={{ height: "36px" }}
                    >
                      <Checkbox style={{ borderRadius: "50%", fontSize: "14x", borderColor: "#CED2DA", paddingRight: "6px" }} checked={isSelected(option.id)} />
                      <Box
                        sx={{
                          width: "12px",
                          height: "12px",
                          backgroundColor: option?.color,
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        style={{
                          marginRight: 8,
                        }}
                      />
                      {option?.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={commentTags?.length === 0 ? "Tag name" : ""}
                      onClick={!open ? handleOpen : handleClose}
                      onBlur={handleClose}
                      InputProps={{
                        ...params.InputProps,
                        style: { fontFamily: "Open Sans!important", fontSize: "14px", fontWeight: 400 },
                        endAdornment: (
                          <>
                            <SvgIcon className={`customArrowDown ${open ? "arrowActive" : ""}`} viewBox="" onClick={!open ? handleOpen : handleClose}>
                              {arrowDown}
                            </SvgIcon>
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            )}
            <p style={{ marginTop: "28px", fontSize: "12px", color: "#6D7278" }}>Select one or more tags to use as the default filter when you open this category in the report writer. </p>
          </Grid>
        )}
        <Grid sx={{ mt: "12px" }} item lg={11.4} xs={11.4} sm={11.4}>
          {from.split("_")[0] === "CATEGORY" && (
            <FormControl fullWidth>
              <div
                className="te-editor-container"
                style={{
                  width: "100%",
                  borderBottom: "1px solid #dadada",
                }}
              >
                <p className="line-sub-head">Introductory text</p>
                {isNarrative ? (
                  <FroalaEditorComponent editor={editor} commentData={linedata} id={"introTextTE"} onChange={handleEditorChange} editorData={introductory} minHeight={100} maxHeight={110} />
                ) : (
                  <SunEditor
                    setDefaultStyle="font-size: 14px"
                    height="190px"
                    setOptions={{
                      buttonList: [["bold", "italic", "underline", "list", "fontColor", "fontSize"]],
                    }}
                    onChange={handleIntroductry}
                    defaultValue={introductory}
                  />
                )}
              </div>
            </FormControl>
          )}
          <Grid sx={{ mt: "12px", paddingTop: "10px" }} item lg={11.4} xs={11.4} sm={11.4}>
            <FormControl fullWidth>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={isOptional} onChange={handleOptional} />} label={<p style={{ fontSize: "14px" }}>Optional</p>} />
              </FormGroup>
              <div style={{ fontSize: "12px" }}>
                If you mark the category as optional, the category will not be automatically included in your report. You will be able to manually add it to an inspection when needed.
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EditCategorys;
