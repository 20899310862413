import React from 'react';
import MapBox from './MapBox';
import PropertyDetails from './PropertyDetails';
import '../../../assets/styles/containers/_inspDetails.scss';
import Box from '@mui/material/Box';
import Status from './Status';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { color } from '@mui/system';
import Report from './Report';
import Templates from './Templates';
import Notifications from './Notification';
import Activity from './Activity';

const Layout = (props) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // const matches = useMediaQuery("(min-width:1200px)");
    // console.log(matches)

    return (
        <div className="detail-card">
            <div className="layout">
                <Typography className="layout__header" component="div">
                    <Box className="layout__title">{`Inspection ID#: ${props.address.inspectionid}`}</Box>
                    <Box className="layout__description">{`${props.address.address} ${props.address.address2} ${props.address.city} ${props.address.state} ${props.address.zip}`}</Box>
                </Typography>
                <Box className="layout__tabs">
                    <Tabs
                        className="layout__tabs__select"
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: '#239d80',
                            },
                        }}
                        value={value}
                        onChange={handleChange}
                        centered>
                        <Tab className="layout__tabs__selected" label="Inspection Details" sx={{ opacity: value !== 0 && 0.6 }} onClick={() => props.settabvalue(0)} />
                        <Tab className="layout__tabs__selected" sx={{ opacity: value !== 1 && 0.6 }} label="Report" onClick={() => props.settabvalue(1)} />
                        <Tab className="layout__tabs__selected" sx={{ opacity: value !== 2 && 0.6 }} label="Activity" onClick={() => props.settabvalue(2)} />
                        <Tab className="layout__tabs__selected" sx={{ opacity: value !== 3 && 0.6 }} label="Notifications" onClick={() => props.settabvalue(3)} />
                    </Tabs>
                </Box>
                {props.tabvalue === 0 ? (
                    <>
                        <PropertyDetails CancelledStatus={props.CancelledStatus} property={props.property} address={props.address} />
                        <MapBox address={props.address} />
                    </>
                ) : (
                    ''
                )}
                {props.tabvalue === 1 ? <Report CancelledStatus={props.CancelledStatus} guid={props.guid} reportData={props.reportData} statusValue={props.statusValue} setRefresh={props.setRefresh} refresh={props.refresh} /> : ''}
                {props.tabvalue === 2 ? <Activity CancelledStatus={props.CancelledStatus} inspectionId={props.address.inspectionid} /> : ''}
                {props.tabvalue === 3 ? <Notifications CancelledStatus={props.CancelledStatus} inspectionId={props.address.inspectionid} /> : ''}
            </div>
            <div className="layout2">
                <Typography className="layout__header1" component="div">
                    <Box className="layout__title">{`Inspection ID#: ${props.address.inspectionid}`}</Box>
                    <Box className="layout__description">{`${props.address.address} ${props.address.city} ${props.address.state} ${props.address.zip}`}</Box>
                </Typography>
                <Status CancelledStatus={props.CancelledStatus} status={props.status} orderId={props.orderId} setRefresh={props.setRefresh} />
            </div>
        </div>
    );
};

export default Layout;
