export const ROUTES = {
    HOME: '/',
    SIGNUP: '/signup',
    FORGOT: '/forgot-password',
    RESET: '/reset-password/:token',
    CREATE: '/create-password/:token',
    CHANGE: 'change-password',
    DASHBOARD_SCREEN: '/dashboard',
    INSPECTION_LISTING: '/inspections',
    NOTIFICATION_MENU: '/notifications',
    APPOINTMENT_CONFIRMATION: '/notifications/appointment-confirmation',
    RESCHEDULE_CONFIRMATION: '/notifications/reschedule-confirmation',
    AGREEMENT_TO_SIGN: '/notifications/agreement-to-sign',
    SIGNATURE_NEEDED: '/notifications/signature-needed',
    AGREEMENT_SIGNED_CONFIRMATION: '/notifications/agreement-signed-confirmation',
    PAYMENT_MADE: '/notifications/payment-made',
    PAYMENT_REQUEST: '/notifications/payment-request',
    PAYMENT_REMINDER: '/notifications/payment-reminder',
    PAYMENT_SIGNATURE: '/notifications/payment-signature',
    INSPECTION_PUBLISHED: '/notifications/inspection-published',
    AGENT_SCREEN: '/agents',
    MY_PROFILE: '/my-profile',
    METRICS_SCREEN: '/metrics',
    INSPECTION_DETAILS: '/inspections/inspection',
    CALENDER_SCREEN: '/calendar',
    TEMPLATES: '/templates',
    SCHEDULE_INSPECTION: '/schedule-inspection',
    SETTINGS: '/settings',
    TEAMS: '/settings/team',
    TEAMS_DETAILS: '/settings/team/:name',
    EDIT_AGENT: 'agents/:name',
    EDIT_TEMPLATE: 'templates/:name',
    SERVICES: '/settings/services',
    PAYMENTS: '/settings/payments',
    IMPORT: 'settings/import',
    COMPANY_PROFILE: 'settings/company-profile',
    AGREEMENTS: 'settings/agreements',
    REPORT_SETTINGS: '/report-settings',
    AGREEMENTS_DETAILS: 'settings/agreements/:name',
    VIEW_AGREEMENT: 'view-agreement/:name',
    ACCEPT_AGREEMENT: 'accept-agreement',
    REJECT_AGREEMENT: 'reject-agreement',
    REQUEST_PAYMENT: 'request-payment',
    AGREEMENT_ALREADY_SIGNED: 'agreement-signed',
    AGREEMENT_INVALID: 'agreement-invalid',
    PRIVACY_CONTENT: 'privacy',
    MAP_VIEW: 'mapview',
    BILLING_PLAN: 'settings/billing-plan',
    IMPORT_DATA_V9: '/settings/import-data',

    // PT Admin routes
    PT_COMPANY: 'company',
    PT_COMPANY_DETAILS: 'company/:name',
    PT_TEAMS: '/teams',
    PT_TEAMS_DETAILS: '/teams/:name',
    PT_SUPPORT_PROFILE: 'profile',
    COMMENT_LIBRARY: '/library',
    FALLBACK: '*',
};

export const colors = {
    SCHEDULED: '#1F2F9C',
    SCHEDULED_BG: '#C7DBF6',
    IN_PROGRESS: '#F50057',
    IN_PROGRESS_BG: '#FFEBF2',
    COMPLETE: '#5A1F9C',
    COMPLETE_BG: '#F1DDFF',
    PUBLISHED: '#1F9C7B',
    PUBLISHED_BG: '#C7F6E0',
    DRAFT: '#F35F01',
    DRAFT_BG: '#FFECD2',
    CANCELLED: '#E30303',
    CANCELLED_BG: '#FFD7D2',
    ERROR: '#611A15',
    ERROR_BG: '#fdeceb',
    ERROR_ICON: '#F44336',
    INSPECTION_SCHEDULED: '#4282E2',
    INSPECTION_COMPLETED: '#239D80',
    COLLECTED_PAYMENTS: '#3B3B3B',
    FEES_OWED: '#FF9800',
    UNPUBLISHED_CHANGES_BG: '#FFECD2',
    UNPUBLISHED_CHANGES: '#F35F01',
};

export const ScheduleInspectionSteps = ['Basic Details', 'Property Details', 'Client Details', 'Agent Details', 'Services'];

export const FilterHeader = ['All time', 'Specific date', 'Date range'];

export const FilterStatus = [
    {
        status: 'Scheduled',
        checked: false,
    },
    {
        status: 'Draft',
        checked: false,
    },
    {
        status: 'In progress',
        checked: false,
    },
    {
        status: 'Complete',
        checked: false,
    },
    {
        status: 'Published',
        checked: false,
    },
    {
        status: 'Cancelled',
        checked: false,
    },
];

export const subscriptionCancelOptions = [
    {
        value: 1,
        key: 'Palmtech is missing features that I need',
    },
    {
        value: 2,
        key: 'I’m closing my business',
    },
    {
        value: 3,
        key: 'I’m placing my business on hold and won’t be inspecting for awhile',
    },
    {
        value: 4,
        key: 'Using competitor',
    },
    {
        value: 5,
        key: 'I decided to use another software program',
    },
    {
        value: 6,
        key: 'I’m using another program now and am too busy to learn the program',
    },
    {
        value: 7,
        key: 'I’m working for another company that uses another software',
    },
    {
        value: 8,
        key: 'Palmtech is too expensive',
    },
    {
        value: 9,
        key: 'Working for another company',
    },
    {
        value: 10,
        key: 'Other',
    },
];

export const companyTableHead = [
    {
        id: 'logo',
        numeric: false,
        disablePadding: false,
        label: '',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'COMPANY NAME',
    },
    { id: 'email', numeric: false, disablePadding: false, label: 'EMAIL' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'PHONE' },
    { id: 'active', numeric: false, disablePadding: false, label: 'STATUS' },
    { id: 'actions', numeric: false, disablePadding: false, label: 'ACTIONS' },
];
