import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Preloader(props) {

  return (
    <div>
      {
        props.showPreloader ? 
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 99999 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop> : ''
      }
    </div>
  );
}
