import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Header from "../../common/header/Header";
import Breadcrumb from "../../common/header/Breadcrumb";
import { useSearchParams } from "react-router-dom";
import TemplateListView from "../../components/templateListView/TemplateListView";

const Templates = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  
  useEffect(() => {
    if (sessionStorage.getItem("company-key") === null || sessionStorage.getItem("company-key") === "null") {
      sessionStorage.setItem("company-key", searchParams.get("key"));
      searchParams.delete("key");
      setSearchParams(searchParams);
    }
    
  }, [])
  
  
  const [narrativeCompany, setNarrativeCompany] = useState(false);


  return (
    <>
      {window.scrollTo(0, 0)}
      <Box sx={{ flexGrow: 1, backgroundColor: "#f7f9fa", minHeight: "100vh" }}>
        <Header narrativeCompany={narrativeCompany} />
        <div style={{ height: "100px" }}></div>
        {/* <Breadcrumb /> */}
        <TemplateListView setNarrativeCompany={setNarrativeCompany} />
      </Box>
    </>
  );
};

export default Templates;
