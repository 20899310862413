import React, { createRef, useEffect, useRef, useState } from "react";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import "../../../assets/styles/containers/_inspDetails.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Stepper from "../../common/stepper/Stepper";
import { colors } from "../../config/app-constants";
import Chip from "@mui/material/Chip";
import moment from "moment";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { apiCall } from "../../pages/settings/Services/action";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";

const Status = (props) => {
  const [status, setStatus] = useState();
  const [value, setValue] = React.useState();
  const [value1, setValue1] = React.useState();
  const [deleteId, setDeleteId] = useState();
  let navigate = useNavigate();
  // delete pop use state(anil)
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [cancelopen, setCancelOpen] = useState(false);
  const handleCancelClose = () => setCancelOpen(false);
  const [deleteCancelId, setCancelDeleteId] = useState();
   // handle close of the inspection delete inspection(anil)*************************
  const handleClickOpenAlert = (id) => {
    setDeleteId(id);
    setOpen(true);
  };
    // handle cancel close of the inspection cancel inspection(anil)*************************
    const handleCancelClickOpenAlert = (id) => {
      setCancelDeleteId(id);
      setCancelOpen(true);
    };

  // cancel inspection api call (anil)
  const cancelInspection = (orderId) => {
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      order_id: orderId,
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      // agent_id: id,
    };
    apiCall(
      (response) => {
        console.log("CANCEL_INSPECTION", response);
        if (response.data.success) {
          toast.success(response.data.message);
          props.setRefresh((prev) => !prev);
          // setOpen(false);
        } else {
          console.log("else", response.data.message);
        }
      },
      formdata,
      "CANCEL_INSPECTION"
    );
  };

  // delete inspection api call (anil)*******************************************
  const deleteInspection = (orderId) => {
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      order_id: orderId,
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
    };
    apiCall(
      (response) => {
        console.log("DELETE_INSPECTION", response);
        if (response.data.success) {
          toast.success(response.data.message);
          props.setRefresh((prev) => !prev);
          navigate("/inspections");
        } else {
          console.log("else", response.data.message);
        }
      },
      formdata,
      "DELETE_INSPECTION"
    );
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleChange1 = (newValue) => {
    setValue1(newValue);
  };

  const [dateOpen, setDateOpen] = useState(false);
  const [timeOpen, setTimeOpen] = useState(false);
  const [date, setDate] = useState(0);
  const [time, setTime] = useState(0);

  const handleClickAway = () => {
    if (dateOpen) {
      setDateOpen(false);
      setDate(1);
    } else if (timeOpen) {
      setTimeOpen(false);
      setTime(1);
    }
  };

  // let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    const path = location.search;

    console.log(path);
    if (path === "?id=1") {
      setStatus(1);
    } else if (path === "?id=0") {
      setStatus(0);
    } else {
      setStatus(1);
    }
  }, []);

  return (
    <div className="status">
      <div className="status__layout">
        <Typography sx={{ marginBottom: "7px" }} className="heading">
          Status
        </Typography>
        <Chip
          sx={{
            backgroundColor: colors[`${props.status.status.toUpperCase()}_BG`],
            color: colors[`${props.status.status.toUpperCase()}`],
          }}
          label={
            <h3 style={{ fontSize: "14px" }}>
              {/* {status === 0 ? "SCHEDULED" : "DRAFT"} */}
              {props.status.status}
            </h3>
          }
        />
      </div>
      <div className="action">
        <Typography className="heading">Action items</Typography>
        <div
          className="details__text"
          style={{ display: props.CancelledStatus && "none" }}
          // onClick={() => navigate("/schedule-inspection")}
        >
          Reschedule
        </div>
      </div>
      <div className="items">
        <div style={{ display: "flex" }}>
          {props.status.paid === 1 ? (
            <CheckCircleIcon className="check-icon"></CheckCircleIcon>
          ) : (
            <CancelIcon className="cancel-icon"></CancelIcon>
          )}

          <Typography className="description">
            {props.status.paid === 0 ? "Unpaid" : "Paid"}
          </Typography>
        </div>

        <div style={{ display: "flex", paddingLeft: "23px" }}>
          {props.status.agreementsigned === 0 ? (
            <CancelIcon className="cancel-icon"></CancelIcon>
          ) : (
            <CheckCircleIcon className="check-icon"></CheckCircleIcon>
          )}

          <Typography className="description">
            {props.status.agreementsigned === 0
              ? "Agreement not signed"
              : "Agreement signed"}
          </Typography>
        </div>
      </div>
      <Typography className="heading">Service date and time</Typography>
      <div style={{ marginBottom: "-32px" }} className="date">
        <Grid container>
          <Grid
            item
            xs={6}
            md={6}
            lg={6}
            sx={{ mb: 5, wordWrap: "break-word !important" }}
          >
            <div style={{ display: "flex" }}>
              <DateRangeIcon className="calender-icon"></DateRangeIcon>
              <div
                style={{
                  marginLeft: "0px",
                  marginTop: "-5px",
                  whiteSpace: "normal !important",
                  paddingRight: "15px",
                }}
                className="details__title"
              >
                {`${moment(props.status.startTime).format("dddd")}`}
                <br />
                {`${moment(props.status.startTime).format("ll")}`}
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <div style={{ display: "flex" }}>
              <ScheduleIcon className="calender-icon"></ScheduleIcon>
              <div
                style={{ marginLeft: "0px", whiteSpace: "pre" }}
                className="details__title"
              >
                {moment(props.status.startTime).format("LT")}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* {status !== 0 ? (
        <div style={{ marginBottom: "-32px" }} className="date">
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="items">
              {value !== undefined && date === 1 && (
                <div style={{ display: "flex" }}>
                  <DateRangeIcon className="calender-icon"></DateRangeIcon>
                  <div style={{ marginLeft: "0px" }} className="details__title">
                    Wednesday Jan 20, 2022
                  </div>
                </div>
              )}
              <div
                style={{
                  display: value !== undefined && date === 1 && "none",
                  marginBottom: "-25px",
                }}
              >
                <div
                  style={{ fontSize: "14px" }}
                  onClick={() => setDateOpen(!dateOpen)}
                  className="details__text"
                >
                  Add date
                </div>

                <div style={{ width: "10px", visibility: "hidden" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Date desktop"
                      inputFormat="MM/dd/yyyy"
                      value={value}
                      open={dateOpen}
                      onChange={handleChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div
                style={{
                  display: value1 !== undefined && time === 1 && "none",
                  marginBottom: "-25px",
                }}
              >
                <div
                  style={{ marginLeft: "70px", fontSize: "14px" }}
                  className="details__text"
                  onClick={() => setTimeOpen(!timeOpen)}
                >
                  Add time
                </div>

                <div style={{ width: "10px", visibility: "hidden" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      open={timeOpen}
                      label="Time"
                      value={value1}
                      onChange={handleChange1}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              {value1 !== undefined && time === 1 && (
                <div style={{ display: "flex" }}>
                  <ScheduleIcon className="calender-icon"></ScheduleIcon>

                  <div
                    style={{ marginLeft: "0px", whiteSpace: "pre" }}
                    className="details__title"
                  >
                    12:00 pm
                  </div>
                </div>
              )}
            </div>
          </ClickAwayListener>
        </div>
      ) : (
        <div className="date">
          <div className="items">
            <div style={{ display: "flex" }}>
              <DateRangeIcon className="calender-icon"></DateRangeIcon>
              <div style={{ marginLeft: "0px" }} className="details__title">
                Wednesday Jan 20, 2022
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <ScheduleIcon className="calender-icon"></ScheduleIcon>

              <div
                style={{ marginLeft: "0px", whiteSpace: "pre" }}
                className="details__title"
              >
                12:00 pm
              </div>
            </div>
          </div>
        </div>
      )} */}

      {props.status.status === "Scheduled" ? (
        <div style={{ marginTop: "10px" }}>
          <Typography className="heading">Timeline</Typography>

          <div className="timeline">
            <section class="bubbles">
              <div class="bubble small black arrow bl">
                <div class="content">Inspection scheduled</div>
              </div>
            </section>
            <Stepper />
            <div style={{ paddingTop: "21.33px" }}>
              <Typography className="description">
                You have scheduled the inspection and an email confirmation has
                been sent to your client.
              </Typography>
            </div>
          </div>
          {/* cancel && delete inspection button (anil) */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RemoveCircleOutlineIcon
              sx={{ color: "#4282E2", fontSize: "17px", mt: 2 }}
            />
            <Button
              sx={{ fontSize: "12px", mt: 2, display: "flex" }}
              // onClick={() => {
              //   cancelInspection(props.orderId);
              // }}
              onClick={() => handleCancelClickOpenAlert(props.orderId)}
            >
              cancel inspection
            </Button>
            <DeleteIcon
              sx={{ color: "#4282E2", fontSize: "17px", mt: 2 }}
              // onClick={() => handleClickOpenAlert(data.id)}
            />
            <Button
              sx={{ fontSize: "12px", mt: 2, display: "flex" }}
                  onClick={() => handleClickOpenAlert(props.orderId)}
            >
              Delete inspection
            </Button>
          </Box>
          {/* cancel && delete inspection button (anil) ending */}

          {/* delete pop model  */}
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={"inspection-filter-modal-main"}
      >
        <Box
          className={"inspection-filter-modal"}
          sx={{ maxWidth: "480px !important", height: "231px !important" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon
                sx={{ float: "right", cursor: "pointer" }}
                onClick={handleClose}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "22px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                Delete inspection?
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "14px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                This action cannot be undone.
              </Typography>
            </Grid>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "center", mt: 5 }}
            >
              <Button
                variant="outlined"
                className="inspection-filter-modal-buttons-cancel"
                sx={{ width: "119px" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="inspection-filter-modal-buttons-apply"
                sx={{ width: "119px", ml: 3 }}
                onClick={() => {
                  deleteInspection(deleteId);
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

       {/* cancel inspection model (anil) starting */}
       <Modal
        // open={open}
        open={cancelopen}
        onClose={handleCancelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={"inspection-filter-modal-main"}
      >
        <Box
          className={"inspection-filter-modal"}
          sx={{ maxWidth: "480px !important", height: "231px !important" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon
                sx={{ float: "right", cursor: "pointer" }}
                onClick={handleCancelClose}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "22px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                Cancel inspection?
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "14px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                This action cannot be undone.
              </Typography>
            </Grid>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "center", mt: 5 }}
            >
              <Button
                variant="outlined"
                className="inspection-filter-modal-buttons-cancel"
                sx={{ width: "119px" }}
                onClick={handleCancelClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="inspection-filter-modal-buttons-apply"
                sx={{ width: "119px", ml: 3 }}
                onClick={() => {
                  cancelInspection(deleteCancelId);
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* cancel inspection model (anil) ending */}

      
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Status;
