import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ScheduleLayout from "../../components/ScheduleInspection/ScheduleLayout";
import { ScheduleInspectionSteps } from "../../config/app-constants";
import "../../../assets/styles/containers/_scheduleInspection.scss";
import { FetchInspectionDetails, NewInspectionDetails } from "./action";
import useMediaQuery from "@mui/material/useMediaQuery";
import { compareStrings } from "../../utils/services";
import { DataArray } from "@mui/icons-material";

const ScheduleInspection = () => {
  const isTaborMobile = useMediaQuery("(min-width:900px)");
  const isMobile = useMediaQuery("(min-width:600px)");
  const [inspectorList, setInspectorList] = useState([]);
  const [clientDetails, setClientDetails] = useState([]);
  const [inspectionId, setInspectionId] = useState();
  const [agentsList, setAgentsList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [agreementsList, setAgreementsList] = useState([]);
  const [allTemplateList,setAllTemplateList] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [editRefresh, setEditRefresh] = useState(0)
  const [editValues, setEditValues] = useState();
  const [Type, setType] = useState();

  const uuId = sessionStorage.getItem("user_id");
  const roleId = sessionStorage.getItem("role_id");
  const companyId = sessionStorage.getItem("company_id");
  const queryId = window.location.href.split("=")[1];

  let navigate = useNavigate();
  const location = useLocation();

  const GetInspectionDetailsList = () => {
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    NewInspectionDetails((response) => {
      const { data, message, success } = response?.data;
      if (success) {
        setInspectorList(data?.inspectors);
        setClientDetails(data?.clients);
        setAgentsList(data?.agents);
        setInspectionId(data.new_id);
        setServicesList(data?.serviceswithtemplates);
        setAgreementsList(data?.agreements);
        setAllTemplateList(data?.templates?.sort(function (a, b) {
          return compareStrings(a.name, b.name);
        }))
      } else {
        console.log("else ", message);
      }
    }, formdata);
  };

  const FetchEditInspections = () => {
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      inspection_id: queryId,
    };
    FetchInspectionDetails((response) => {
      const { data, message, success } = response.data;
      if (success) {
        setEditValues(data);
        setType("edit");
      setEditRefresh(editRefresh + 1);
      } else {
        console.log("else ", message);
      }
    }, formdata);
  };

  var clientList = [];
  var agentsLists = [];
  var inspList = [];
  var servicesLists = [];

  const ListArray = [clientDetails, agentsList, inspectorList, servicesList];

  const DetailLists = (List, type) => {
    List?.map((detail) => {
      const dataA = {
        id: detail.id,
        name: detail.last_name === null ? detail.first_name : detail.last_name + " " + detail.first_name, //null check condition
      };
      const dataB = {
        id: detail.id,
        title: detail.name,
        rate: detail.amount,
        minutes: detail.minutes,
        agreementid: detail.agreement_id,
        template: detail.servicetemplate,
      };
      if (type === 0) return clientList.push(dataA);
      if (type === 1) return agentsLists.push(dataA);
      if (type === 2) return inspList.push(dataA);
      if (type === 3) return servicesLists.push(dataB);
    });
  };

  ListArray?.map((list, i) => {
    return DetailLists(list, i) /*for clients */;
  });

  clientList.sort(function (a, b) {
    return compareStrings(a.name, b.name);
  });

  agentsLists.sort(function (a, b) {
    return compareStrings(a.name, b.name);
  });

  inspList.sort(function (a, b) {
    return compareStrings(a.name, b.name);
  });

  servicesLists.sort(function (a, b) {
    return compareStrings(a.title, b.title);
  });

  useEffect(() => {
    GetInspectionDetailsList();
  }, [refresh]);

  useEffect(() => {
    if (queryId) {
      FetchEditInspections();
    } else {
      setType("schedule");
    }
  }, []);

  return (
    <div>
      <ScheduleLayout
        uuId={uuId}
        roleId={roleId}
        companyId={companyId}
        inspectionId={inspectionId}
        isMobile={isMobile}
        navigate={navigate}
        isTaborMobile={isTaborMobile}
        inspList={inspList}
        clientList={clientList}
        Type={Type}
        location={location}
        agentsLists={agentsLists}
        servicesLists={servicesLists}
        agreementsList={agreementsList}
        queryId={queryId}
        editValues={editValues}
        editRefresh={editRefresh}
        getDetailsFetch={() => setRefresh(refresh + 1)}
        ScheduleInspectionSteps={ScheduleInspectionSteps}
        allTemplateList={allTemplateList}
      />
    </div>
  );
};

export default ScheduleInspection;
