// File imports*******************************************************************************************************

import axios from 'axios'; //API calls
import validator from 'validator';
import { apiCall } from './action';
import toast from 'react-hot-toast';
import Preloader from '../../helpers/Preloader';
import EditIcon from '@mui/icons-material/Edit'; //Icon
import React, { useState, useEffect } from 'react';
import { API_CONSTANTS } from '../../config/api-constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { onKeyPressed, formatPhoneNumberDashes } from '../../utils/services';
import { Box, Card, Grid, Button, CardMedia, TextField, CardHeader, FormControl, Dialog, Typography, DialogActions, DialogContent, Divider } from '@mui/material'; //MUI Imports
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';

// render function**************************************************************************************************

const EditAgent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const fileInput = React.useRef();
    const [image, setImage] = useState('');
    const [defaultImage, setDefaultImage] = useState('');
    const userData = location.state?.userData;
    const [showpreloader, setShowpreloader] = useState(false);
    const [errors, setErrors] = useState({});
    //dialog box
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [values, setValues] = useState({ firstname: '', lastname: '', email: '', secondemail: '', phone: '', secondphone: '', agency: '', companynumber: '', internalnotes: '', privatenotes: '' });

    // handle change function
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
        setIsChanged(true);
    };

    //handle phone change function
    const handlePhoneChange = (event) => {
        const formattedPhoneNumber = formatPhoneNumberDashes(event.target.value);
        setValues({
            ...values,
            [event.target.name]: formattedPhoneNumber,
        });
        setIsChanged(true);
    };

    // handle submit function
    const handleformSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(values));
        setDataIsCorrect(true);
    };

    // validation function*************************************************************************************************

    const validation = (values) => {
        let errors = {};
        if (!values.firstname.trim()) {
            errors.firstname = 'First name is required.';
        }
        if (!values.email) {
            errors.email = 'Email is required.';
        } else if (!validator.isEmail(values.email)) {
            errors.email = 'Email is invalid';
        }else if ( !/^[A-Z0-9._%+]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
           {
            errors.email = 'Email is invalid';
          } 
        if (!values.secondemail) {
        } else if (!validator.isEmail(values.secondemail)) {
            errors.secondemail = 'Email is invalid';
        }
        if (!values.phone) {
        } else if (values.phone.length < 12) {
            errors.phone = 'Phone number is invalid';
        }
        if (!values.secondphone) {
        } else if (values.secondphone.length < 12) {
            errors.secondphone = 'Phone number is invalid';
        }
        if (!values.companynumber) {
        } else if (values.companynumber.length < 12) {
            errors.companynumber = 'Company number is invalid';
        }
        return errors;
    };

    //Get Agent Details API call*************************************************************************************

    const GetAgentUserDatas = () => {
        setShowpreloader(true);
        const formdata = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
            agent_id: location.state?.id,
        };
        apiCall(
            (response) => {
                console.log('GET AGENT DETAILS', response);
                const { data, message, success } = response.data;
                if (success) {
                    setImage(data.avatar);
                    setDefaultImage(data.avatar);
                    try {
                        setValues({
                            ...values,
                            firstname: data.first_name,
                            lastname: data.last_name,
                            phone: data.phone,
                            email: data.email,
                            agency: data.agentdata.agency === 'null' ? '' : data.agentdata.agency,
                            secondemail: data.agentdata.email_2 === 'null' ? '' : data.agentdata.email_2,
                            secondphone: data.agentdata.phone_2 === 'null' ? '' : data.agentdata.phone_2,
                            companynumber: data.agentdata.agency_number === 'null' ? '' : data.agentdata.agency_number,
                            privatenotes: data.agentdata.private_notes === 'undefined' ? '' : data.agentdata.private_notes,
                            internalnotes: data.agentdata.internal_notes === 'undefined' ? '' : data.agentdata.internal_notes,
                        });
                    } catch (err) {
                        setValues({
                            ...values,
                            firstname: data.first_name,
                            lastname: data.last_name,
                            phone: data.phone,
                            email: data.email,
                            agency: '',
                            secondemail: '',
                            secondphone: '',
                            companynumber: '',
                            privatenotes: '',
                            internalnotes: '',
                        });
                    }
                    setShowpreloader(false);
                } else {
                    console.log('else ', message);
                }
            },
            formdata,
            'GET_AGENT_DETAILS',
        );
    };

    //edit Agent Details API call and validation*************************************************************************************

    const editAgent = () => {
        setShowpreloader(true);
        let formData = new FormData();
        const params = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
            agent_id: location.state?.id,
            first_name: values.firstname,
            last_name: values.lastname === null ? '' : values.lastname,
            phone: values.phone === null ? '' : values.phone,
            email: values.email,
            email_2: values.secondemail,
            phone_2: values.secondphone,
            agency: values.agency === null ? '' : values.agency,
            agency_number: values.companynumber,
            internal_notes: !values.internalnotes ? undefined : values.internalnotes,
            private_notes: !values.privatenotes ? undefined : values.privatenotes,
            avatar: typeof image === 'string' || image === null ? '' : image,
        };
        Object.keys(params).forEach((key) => formData.append(key, params[key]));
        apiCall(
            (response) => {
                console.log('EDIT Agent', response);
                const { message, success } = response.data ? response.data : '';
                if (success) {
                    toast.success(message);
                    console.log(response, 'response');
                    setShowpreloader(false);
                    navigate('/agents');
                } else {
                    console.log('else ', message);
                    toast.error(message);
                    setShowpreloader(false);
                }
            },
            formData,
            'EDIT_AGENT',
        );
    };

    // Add agent function and API call*****************************************************************************

    const addAgent = () => {
        setShowpreloader(true);
        if (Object.keys(errors).length !== 0 && !dataIsCorrect) {
            return;
        }
        let formData = new FormData();
        const params = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
            first_name: values.firstname,
            last_name: values.lastname,
            email: values.email,
            phone: values.phone,
            agency: values.agency,
            agency_number: values.companynumber,
            phone_2: values.secondphone,
            email_2: values.secondemail,
            internal_notes: values.internalnotes,
            private_notes: values.privatenotes,
            avatar: image ? image : '',
        };
        Object.keys(params).forEach((key) => formData.append(key, params[key]));
        axios
            .post(`${API_CONSTANTS.ADD_AGENT}`, formData)
            .then((response) => {
                // Request with success code true
                toast.success(response.data.message);
                setShowpreloader(false);
                navigate('/agents');
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    if (error.response.data.success === false) {
                        toast.error(error.response.data.message);
                        setShowpreloader(false);
                    }
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('no response', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };

    //delete profile picture API call*************************************************************************************
    const deleteProfilePicture = () => {
        setShowpreloader(true);
        const formdata = { company_id: sessionStorage.getItem('company_id'), user_id: sessionStorage.getItem('user_id'), role_id: sessionStorage.getItem('role_id'), member_id: location.state?.id };
        apiCall(
            (response) => {
                console.log('DELETE_PROFILE', response);
                const { message, success } = response.data;
                if (success) {
                    toast.success(message);
                    setOpen(false);
                    setShowpreloader(false);
                    setImage('');
                    setDefaultImage('');
                    fileInput.current.value = "";
                } else {
                    console.log('else ', message);
                }
            },
            formdata,
            'DELETE_PROFILE',
        );
    };

    // validation check***********************************************************************************************

    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect && isChanged) {
            location.state.edit ? editAgent() : addAgent();
        }
    }, [errors]);

    useEffect(() => {
        location.state.edit && GetAgentUserDatas();
    }, []);

    useEffect(()=>{
        if(image === undefined){
            setImage(defaultImage)
        }
    },[image]);

    
    // Card Component **************************************************************************************************

    const profileCard = (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <input
                        ref={fileInput}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            setImage(e.target.files[0]);
                            console.log('uploadsss ---> ', e.target.files[0])
                            setIsChanged(true);
                        }}
                        style={{ display: 'none' }}
                    />
                    {image && (
                        <>
                            <EditIcon sx={{ float: 'right', height: '13.5px', width: '13.5px', mt: '4px', mr: '4px', cursor: 'pointer' }} onClick={() => fileInput.current.click()} />
                            <div className="prof-img-wrapper">
                                <img className="team-prof-img" style={{ height: '205px', maxWidth: '100%' }} src={typeof image === 'string' ? image : URL.createObjectURL(image)} alt="Thumb" />
                            </div>
                        </>
                    )}
                    {!image && (
                        <>
                            <EditIcon sx={{ float: 'right', height: '13.5px', width: '13.5px', mt: '4px', mr: '4px', cursor: 'pointer' }} onClick={() => fileInput.current.click()} />
                            <div style={{ backgroundColor: '#BEBEBE', textAlign: 'center', height: '205px', margin: '0 auto', width: '100%', padding: '60px 0', borderRadius: '4px' }}>
                                <PersonIcon sx={{ width: '72.61px', height: '72.61px', color: '#ffffff' }} />
                            </div>
                        </>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <p style={{ paddingTop: '10px', fontSize: '12px', lineHeight: '20px', letterSpacing: 0, fontWeight: '600', color: ' #E30303', cursor: 'pointer', opacity: image ? '1.00' : '0.25', pointerEvents: !image && 'none', userSelect: 'none' }} onClick={() => handleOpen()}>
                        <DeleteIcon sx={{ height: '10.5px !important', width: '10.5px' }} /> Delete photo
                    </p>
                </Grid>
            </Grid>
        </>
    );

    //Details Card******************************************************************************************************

    const detailsCard = (
        <>
            <Grid container className="team-details-card" alignItems="center" justifyContent="center" sx={{ maxWidth: '970px' }}>
                <Grid xs={12}>
                    <CloseIcon size="small" sx={{ float: 'right', mr: -3, mt: -1.5, cursor: 'pointer' }} onClick={() => navigate('/agents')} />
                </Grid>
                <Grid xs={12} className="team-details-cardTitle">
                    <span style={{ fontSize: '22px' }}>{location.state.edit ? 'Edit' : 'Add'} Agent</span>
                </Grid>
                <Grid container sx={{ mt: '42px', pl: { lg: '40px' }, pr: { lg: '40px' } }}>
                    <Grid item xs={12} sm={4} sx={{ pl: '25px', pr: '25px', mt: 0.9 }}>
                        {profileCard}
                    </Grid>
                    <Grid item xs={12} sm={8} sx={{ mt: { xs: 3, sm: 0, lg: 0 } }}>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="standard" className="forms-control">
                                    <label className="input-form-label" htmlFor="first_name" id="demo-simple-select-label">
                                        First Name*
                                    </label>
                                    <TextField type="text" size="small" name="firstname" variant="outlined" placeholder="First name" className="input-textfield" onChange={handleChange} value={values.firstname} error={errors.firstname} helperText={errors.firstname && errors.firstname} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="standard" className="forms-control">
                                    <label className="input-form-label" id="demo-simple-select-label">
                                        Last Name
                                    </label>
                                    <TextField type="text" size="small" name="lastname" variant="outlined" placeholder="Last name" className="input-textfield" value={values.lastname} onChange={handleChange} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="standard" className="forms-control field-reset">
                                    <label className="input-form-label" id="demo-simple-select-label">
                                        Email*
                                    </label>
                                    <TextField name="email" type="email" size="small" variant="outlined" placeholder="Email address" className="input-textfield" value={values.email} error={errors.email} onChange={handleChange} helperText={errors.email && errors.email} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="standard" className="forms-control field-reset">
                                    <label className="input-form-label" id="demo-simple-select-label">
                                        2nd email(cc)
                                    </label>
                                    <TextField type="email" size="small" name="secondemail" variant="outlined" className="input-textfield " value={values.secondemail} error={errors.secondemail} onChange={handleChange} helperText={errors.secondemail && errors.secondemail} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="standard" className="forms-control">
                                    <label className="input-form-label" id="demo-simple-select-label">
                                        Phone
                                    </label>
                                    <TextField name="phone" size="small" variant="outlined" placeholder="Phone number" className="input-textfield" value={values.phone} error={errors.phone} onKeyDown={onKeyPressed} onChange={handlePhoneChange} helperText={errors.phone && errors.phone} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="standard" className="forms-control">
                                    <label className="input-form-label" id="demo-simple-select-label">
                                        2nd Phone
                                    </label>
                                    <TextField size="small" name="secondphone" variant="outlined" className="input-textfield" onKeyDown={onKeyPressed} value={values.secondphone} error={errors.secondphone} onChange={handlePhoneChange} helperText={errors.secondphone && errors.secondphone} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" id="demo-simple-select-label">
                                COMPANY
                            </label>
                            <TextField type="text" size="small" name="agency" variant="outlined" placeholder="Company name" className="input-textfield" value={values.agency} error={errors.agency} onChange={handleChange} helperText={errors.agency && errors.agency} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" id="demo-simple-select-label">
                                Company number
                            </label>
                            <TextField size="small" variant="outlined" name="companynumber" className="input-textfield" placeholder="Company number" onKeyDown={onKeyPressed} error={errors.companynumber} onChange={handlePhoneChange} value={values.companynumber} helperText={errors.companynumber && errors.companynumber} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" id="demo-simple-select-label">
                                internal notes
                            </label>
                            <div>
                                <TextField rows={5} fullWidth multiline name="internalnotes" id="outlined-multiline-static" placeholder="Notes for the company go here" onChange={handleChange} value={values.internalnotes} />
                                <p
                                    style={{
                                        height: '17px',
                                        marginTop: '7px',
                                        color: '#3B3B3B',
                                        fontSize: '12px',
                                        letterSpacing: 0,
                                        lineHeight: '17px',
                                        fontFamily: 'open sans',
                                    }}>
                                    Notes only visible to the company
                                </p>
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                                private notes
                            </label>
                            <div>
                                <TextField rows={5} fullWidth multiline name="privatenotes" id="outlined-multiline-static" placeholder="Notes for the company admins go here" onChange={handleChange} value={values.privatenotes} />
                                <p
                                    style={{
                                        height: '17px',
                                        marginTop: '7px',
                                        fontSize: '12px',
                                        letterSpacing: 0,
                                        color: '#3B3B3B',
                                        lineHeight: '17px',
                                        fontFamily: 'open sans',
                                    }}>
                                    Notes only visible to the company admins
                                </p>
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5} md={6} sx={{ mt: 2 }}></Grid>
                </Grid>
                <Grid container justifyContent={'right'} justifyItems="right">
                    <Grid item xs={6} className="team-detail-cta">
                        <Button variant="none" onClick={() => navigate('/agents')} sx={{ height: '34px', width: '79px', fontSize: '13px', lineHeight: '24px', letterSpacing: '0.5px', fontWeight: '600', textDecoration: 'underline' }}>
                            Cancel
                        </Button>

                        <Button className="default-btn teams-detail-btn" disabled={!isChanged} sx={{ height: '34px !important', width: '119px !important' }} onClick={handleformSubmit}>
                            {/* {location.state.edit ? 'Update' : 'Finish'} */}
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
    return (
        <>
            {/* <Header /> */}
            <Box sx={{ backgroundColor: 'rgba(0,0,0,0.51)' }} alignItems="center" justifyContent="center">
                <Dialog fullWidth={true} className="dialog-container-details" open={open} onClose={handleClose}>
                    <div className="close-wrapper">
                        <CloseIcon onClick={handleClose} sx={{pointerEvents: showpreloader && 'none'}}/>
                    </div>

                    <DialogContent className="dialog-content">
                        <Grid container className="permission_change_modal">
                            <Typography>Delete confirmation</Typography>
                            <Typography>Are you sure you want to delete the profile picture?</Typography>
                        </Grid>
                    </DialogContent>
                    <DialogActions className="dialog-action">
                        <Button className="back-btn" onClick={handleClose} sx={{pointerEvents: showpreloader && 'none'}}>
                            Cancel
                        </Button>
                        <LoadingButton className="default-btn" onClick={() => deleteProfilePicture()} loading={showpreloader}>
                            Confirm
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
                <Grid container className="teams-container" direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={12} className="details-title">
                        <span className="team-user-name">{userData?.first_name}</span>
                        <br />
                    </Grid>
                    <Box>
                        <Preloader showPreloader={showpreloader} />
                        <Grid container direction="column" alignItems="center" justifyContent="center">
                            <Grid item xs={12} sm={12}>
                                <Card variant="outlined">{detailsCard}</Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Box>
        </>
    );
};

export default EditAgent;
