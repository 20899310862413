import React from "react";
import { useNavigate } from "react-router-dom";
// mui imports
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
// import AddIcon from "@mui/icons-material/Add";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import Divider from "@mui/material/Divider";
import plusIcon from "../../../assets/images/plus_icon.png";

import { NavLink } from "react-router-dom";
// custom style imports
// component imports

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      width: "0",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
const menuItems = [
  "dashboard",
  "calendar",
  "inspections",
  "agents",
  "templates",
  "notifications",
  "metrics",
];
const ptadminMenuItems = ["company", "teams"];

const Navbar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

  // const [anchorElNav, setAnchorElNav] = React.useState(null)
  // const [anchorElUser, setAnchorElUser] = React.useState(null)
  const pathName = window.location.href.split("#/")[1];
  const rootPath = pathName.split("/").filter((x) => x);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null)
  // }

  // Normal user mobile nav items
  const normalMobileMenuNavItems = (
    <Box
      className="display-toggle"
      sx={{
        flexGrow: 1,
        display: { margin: "13px 0px" },
      }}
    >
      <>
        <Button
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="dropdown-menu"
        >
          <Typography className="nav-inspection-light global-font">
            {rootPath[0] === "inspections" ? "Inspection" : rootPath[0]}
          </Typography>
          <KeyboardArrowDownIcon className="nav-down-arrow" />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {menuItems.map((menu, index) =>
            menu === "inspection" ? (
              <MenuItem value="" key={index}>
                <NavLink
                  className="navlink"
                  to={{ pathname: "/inspections" }}
                  style={{ textDecoration: "none" }}
                  onClick={handleClose}
                >
                  <Typography textAlign="center" className="mobile-menus">
                    {menu}
                  </Typography>
                </NavLink>
              </MenuItem>
            ) : props.permission ? (
              <MenuItem value="" key={index}>
                <NavLink
                  className="navlink"
                  to={{ pathname: `/${menu}` }}
                  style={{ textDecoration: "none" }}
                  onClick={handleClose}
                >
                  <Typography textAlign="center" className="mobile-menus">
                    {menu}
                  </Typography>
                </NavLink>
              </MenuItem>
            ) : (
              menu !== "agents" &&
              menu !== "templates" && (
                <MenuItem value="" key={index}>
                  <NavLink
                    className="navlink"
                    to={{ pathname: `/${menu}` }}
                    style={{ textDecoration: "none" }}
                    onClick={handleClose}
                  >
                    <Typography textAlign="center" className="mobile-menus">
                      {menu}
                    </Typography>
                  </NavLink>
                </MenuItem>
              )
            )
          )}
        </Menu>
      </>
    </Box>
  );

  // normal user desktop nav items
  const normalDesktopNavItems = (
    <Box
      className="horizontal-menus"
      sx={{
        flexGrow: 1,
        justifyContent: "space-between",
      }}
    >
      <div className="is-flex">
        <Button id="new_inspection" name="new_inspection"
          className="nav-button"
          sx={{ my: 2, color: "white", display: "flex" }}
          onClick={() => navigate("/schedule-inspection")}
        >
          <img src={plusIcon} alt="icon" className="navmenu-icon" />
          <Typography className="nav-inspection global-font">
            New Inspection
          </Typography>
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem />
      </div>
      {menuItems.map((menu, index) =>
        menu === "inspection" ? (
          <NavLink
            className="navlink"
            key={index}
            to={{ pathname: `/inspections` }}
            style={{ textDecoration: "none" }}
          >
            <Button
              className="nav-button"
              key={menu}
              id={`${menu}_menu`}
              name={`${menu}_menu`}
              // onClick={handleCloseNavMenu}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                margin: "0px",
              }}
            >
              <Typography
                className={
                  rootPath[0] === `${menu}` || rootPath[0] === "inspections"
                    ? "navlist global-font active-link"
                    : "navlist global-font"
                }
              >
                {menu}
              </Typography>
            </Button>
          </NavLink>
        ) : props.permission ? (
          <NavLink
            className="navlink"
            key={index}
            to={{ pathname: `/${menu}` }}
            style={{ textDecoration: "none" }}
          >
            <Button
              className="nav-button"
              key={menu}
              id={`${menu}_menu`}
              name={`${menu}_menu`}
              // onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block", margin: "0px" }}
            >
              <Typography
                className={
                  rootPath[0] === `${menu}`
                    ? "navlist global-font active-link"
                    : "navlist global-font"
                }
              >
                {menu}
              </Typography>
            </Button>
          </NavLink>
        ) : (
          menu !== "agents" &&
          menu !== "templates" && (
            <NavLink
              className="navlink"
              key={index}
              to={{ pathname: `/${menu}` }}
              style={{ textDecoration: "none" }}
            >
              <Button
                className="nav-button"
                key={menu}
                id={`${menu}_menu`}
                name={`${menu}_menu`}
                // onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block", margin: "0px" }}
              >
                <Typography
                  className={
                    rootPath[0] === `${menu}`
                      ? "navlist global-font active-link"
                      : "navlist global-font"
                  }
                >
                  {menu}
                </Typography>
              </Button>
            </NavLink>
          )
        )
      )}
      <Box
        className="is-flex revenue"
        sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
      >
        <Divider orientation="vertical" flexItem variant="middle" />
        <Button
          className="nav-button"
          sx={{ my: 2, color: "white", display: "flex" }}
        >
          <InsertChartIcon className="chart-icon" />
          <Typography className="revenue-content global-font">
            Revenue this&nbsp;month
            <br />
            <span className="revenue-amount">$2500</span>
          </Typography>
        </Button>
      </Box>
    </Box>
  );

  // PT user mobile view nav items
  const PTadminMobileMenuNavItems = (
    <Box
      className="display-toggle"
      sx={{
        flexGrow: 1,
        display: { margin: "13px 0px" },
      }}
    >
      <>
        <Button
          id="responsive_dropdown"
          name="responsive_dropdown"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="dropdown-menu"
        >
          <Typography className="nav-inspection-light global-font">
            {" "}
            {rootPath[0] === "inspections" ? "Inspection" : rootPath[0]}{" "}
          </Typography>{" "}
          <KeyboardArrowDownIcon className="nav-down-arrow" />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {ptadminMenuItems.map((menu, index) =>
            props.ptPermission ? (
              <MenuItem value="" key={index}>
                <NavLink
                  className="navlink"
                  to={{ pathname: `/${menu}` }}
                  style={{ textDecoration: "none" }}
                  onClick={handleClose}
                  id={`${menu}_menu`}
                  name={`${menu}_menu`}
                >
                  <Typography textAlign="center" className="mobile-menus">
                    {/* name changes "teams"->"team" and "company"->"companies" */}
                    {menu === "teams" ? "team" : menu || menu === "company" ? "companies" : menu}
                  </Typography>
                </NavLink>
              </MenuItem>
            ) : (
              menu !== "agents" && (
                <MenuItem value="" key={index}>
                  <NavLink
                    className="navlink"
                    to={{ pathname: `/${menu}` }}
                    style={{ textDecoration: "none" }}
                    onClick={handleClose}
                    id={`${menu}_menu`}
                    name={`${menu}_menu`}
                  >
                    <Typography textAlign="center" className="mobile-menus">
                      {menu}
                    </Typography>
                  </NavLink>
                </MenuItem>
              )
            )
          )}
        </Menu>
      </>
    </Box>
  );

  // PT user desktop nav items
  const PTadminDesktopNavItems = (
    <Box
      className="horizontal-menus"
      sx={{
        flexGrow: 1,
        justifyContent: "space-between",
      }}
    >
      <div className="is-flex">
        <Button
          className="nav-button"
          id="add_new_company"
          name="add_new_company"
          sx={{ my: 2, color: "white", display: "flex" }}
        // onClick={() => navigate("/schedule-inspection")}
        >
          <img src={plusIcon} alt="icon" className="navmenu-icon" />
          <Typography className="nav-inspection global-font">
            Add New Company
          </Typography>
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem style={{ marginRight: '20px' }} />
        {ptadminMenuItems.map((menu, index) =>
          props.ptPermission ? (
            <NavLink
              className="navlink"
              key={index}
              to={{ pathname: `/${menu}` }}
              style={{ textDecoration: "none" }}
            >
              <Button
                className="nav-button"
                key={menu}
                id={`${menu}_menu`}
                name={`${menu}_menu`}
                sx={{ my: 2, color: "white", display: "block", margin: "0px" }}
              >
                <Typography
                  className={
                    rootPath[0] === `${menu}`
                      ? "navlist global-font active-link"
                      : "navlist global-font"
                  }
                >
                   {/* name changes "teams"->"team" and "company"->"companies" */}
                  {menu === "teams" ? "team" : menu || menu === "company" ? "companies" : menu}

                </Typography>
              </Button>
            </NavLink>
          ) : (
            menu !== "agents" && (
              <NavLink
                className="navlink"
                key={index}
                to={{ pathname: `/${menu}` }}
                style={{ textDecoration: "none" }}
              >
                <Button
                  className="nav-button"
                  key={menu}
                  sx={{ my: 2, color: "white", display: "block", margin: "0px" }}
                  id={`${menu}_menu`}
                  name={`${menu}_menu`}
                >
                  <Typography
                    className={
                      rootPath[0] === `${menu}`
                        ? "navlist global-font active-link"
                        : "navlist global-font"
                    }
                  >
                    {menu}
                  </Typography>
                </Button>
              </NavLink>
            )
          )
        )}
      </div>

      <Box
        className="is-flex revenue"
        sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
      >
        <Divider orientation="vertical" flexItem variant="middle" />
        <Button
          className="nav-button"
          sx={{ my: 2, color: "white", display: "flex" }}
          id="revenue_btn"
          name="revenue_btn"
        >
          <InsertChartIcon className="chart-icon" />
          <Typography className="revenue-content global-font">
            Revenue this&nbsp;month
            <br />
            <span className="revenue-amount">$2500</span>
          </Typography>
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar className="navitems" position="static">
          <Toolbar>
            {/* menu dropdown for mobile */}
            {props.PTadminFlag ? PTadminMobileMenuNavItems : normalMobileMenuNavItems}
            {/* desktop full nav */}
            {props.PTadminFlag ? PTadminDesktopNavItems : normalDesktopNavItems}
            {/* search for mobile */}
            <Box className="display-toggle">
              <Search className="header-search-bar-light">
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};
export default Navbar;
