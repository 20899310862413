import React from "react";
import AgreementActionCard from "./AgreementActionCard";
const RejectAgreement = () => {
  return (
    <>
      <AgreementActionCard
        icon={''}
        title={"Thanks"}
        content={"Your response has been forwarded to the inspector."}
        email={""}
      />
    </>
  );
};

export default RejectAgreement;
