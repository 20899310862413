import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function AddTemplateOptionDialog(props) {
  const { handleCloseDialog, openTemplateDialog, HandleOpenEditor, handleOpenOption } = props;

  const handleImportTemplate = () => {
    handleCloseDialog();
    handleOpenOption("options");
  };

  return (
    <Dialog open={openTemplateDialog} maxWidth="sm">
      <DialogTitle>
        <Grid container>
          <Grid item xs={11} pl={2}>
            <Typography className="importDialogHeading">Select an option</Typography>
          </Grid>
          <Grid item xs={1} display={"flex"} alignItems={"flex-start"}>
            <IconButton onClick={handleCloseDialog} className="closeButton">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container my={4} px={3}>
          <Grid item xs={12}>
            <Grid container gap={2}>
              <FiberManualRecordIcon className="dotImage" />
              <Typography className="optionHeading">Start from scratch</Typography>
            </Grid>
            <Typography className="importTitleDesc" mb={2}>
              {`This will create a blank template with no categories or lines.`}
            </Typography>
            <Button
              variant={"contained"}
              className="optionButtons"
              onClick={() => {
                HandleOpenEditor("create", true);
                handleCloseDialog();
              }}
            >
              Create new template
            </Button>
          </Grid>
          <Grid item xs={12} mt={6}>
            <Grid container gap={2}>
              <FiberManualRecordIcon className="dotImage" />
              <Typography className="optionHeading">Duplicate an existing template</Typography>
            </Grid>
            <Typography className="importTitleDesc" mb={2}>
              {`Start with a prebuilt template and customize it to your needs.`}
            </Typography>
            <Button variant={"contained"} className="optionButtons" onClick={() => HandleOpenEditor("duplicate", true)}>
              Duplicate template
            </Button>
          </Grid>
          <Grid item xs={12} mt={6} mb={3}>
            <Grid container gap={2}>
              <FiberManualRecordIcon className="dotImage" />
              <Typography className="optionHeading">Import template from third party</Typography>
            </Grid>
            <Typography className="importTitleDesc" mb={1}>
              {`Upload a template from another program. Our team will import it and add it to your account.`}
            </Typography>
            <Grid container>
              <Grid item xs={12} ml={3}>
                <Button variant="text" endIcon={<ChevronRightIcon />} className="ptTextButton" onClick={() => window.open("https://help.inspectionsupport.com/en/articles/8430088-converting-from-spectora-to-isn-report-writer", "_blank")}>
                  {"How to export template"}
                </Button>
              </Grid>
              <Button variant={"contained"} className="optionButtons" onClick={() => handleImportTemplate()}>
                Import template
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AddTemplateOptionDialog;
