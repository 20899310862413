import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
const MapFullView = () => {
    const map = useRef(null);
    const [zoom] = useState(3);
    const mapContainer = useRef(null);
    const Pointers = localStorage.getItem('marker');
    const [center] = useState(JSON?.parse(Pointers));
    mapboxgl.accessToken = 'pk.eyJ1IjoibWF5b2ppY2giLCJhIjoiY2pla3Q3MzVvMWRoYTJybnVyMndxM2hmdCJ9.nWZlYcpKaMqz6m7xTsnJGA';

    // initialize map only once
    useEffect(() => {
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: center,
            attributionControl: false,
            zoom: zoom,
        });
        //marker
        new mapboxgl.Marker({ color: 'Red', display: 'block' }).setLngLat(center).addTo(map.current);
        //controls for map
        map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
        //move to marker
        map.current.flyTo({
            speed: 3,
            center: center,
            zoom: 15,
            essential: false,
        });
    }, []);

    //render
    return <div ref={mapContainer} className="map-container" style={{ height: '100vh', width: '100%' }} />;
};
export default MapFullView;
