import * as React from 'react';
import { styled } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

const StepperConnect = () => {
    const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));
    return (
        <>
            <div className="stepper">
                <BootstrapTooltip title={<h1 className="font-13">Scheduled</h1>}>
                    <CheckCircleIcon className="stepper__status"></CheckCircleIcon>
                </BootstrapTooltip>
                <hr className="stepper__progress"></hr>
                <BootstrapTooltip title={<h1 className="font-13">Inprogress</h1>}>
                    <CircleOutlinedIcon sx={{ color: '#EAE7E3' }} className="stepper__status"></CircleOutlinedIcon>
                </BootstrapTooltip>
                <hr className="stepper__progress"></hr>
                <BootstrapTooltip title={<h1 className="font-13">Completed</h1>}>
                    <CircleOutlinedIcon sx={{ color: '#EAE7E3' }} className="stepper__status"></CircleOutlinedIcon>
                </BootstrapTooltip>
                <hr className="stepper__progress"></hr>
                <BootstrapTooltip title={<h1 className="font-13">Published</h1>}>
                    <CircleOutlinedIcon sx={{ color: '#EAE7E3' }} className="stepper__status"></CircleOutlinedIcon>
                </BootstrapTooltip>
            </div>
        </>
    );
};

export default StepperConnect;
