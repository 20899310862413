//FILE IMPORTS
import axios from 'axios'; //API calls
import moment from 'moment';
import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';
import Header from '../../common/header/Header';
import Preloader from '../../helpers/Preloader';
import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../common/header/Breadcrumb';
import AlertMessage from '../../common/alert/AlertMessage';
import { API_CONSTANTS } from '../../config/api-constants';
import '../../../assets/styles/containers/_inspDetails.scss';
import Layout from '../../components/InspectionDetails/Layout';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Contacts } from '../../components/InspectionDetails/Contacts';
import { Inspector } from '../../components/InspectionDetails/Inspector';
import { Agreements } from '../../components/InspectionDetails/Agreements';
import { Servicepage } from '../../components/InspectionDetails/Servicepage';

//get inspection details
const InspectionDetails = () => {
    const location = useLocation();
    const [tabvalue, settabvalue] = useState(0);
    const [showpreloader, setShowpreloader] = useState(false);
    //trigger refresh
    const [refresh, setRefresh] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    //property address
    const [values, setValues] = useState({
        inspectionid: location.state?.id,
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
    });
    //property details
    const [propertyvalue, setPropertyvalue] = useState({
        foundation: '',
        squarefeet: '',
        bedrooms: '',
        yearbuild: '',
        bathroom: '',
        images: [],
    });
    //schedule status
    const [statusvalue, setstatusValue] = useState({
        paid: '',
        agreementsigned: '',
        status: '',
        startTime: '2022-05-11T08:07:18.000000Z',
    });
    //inspector component value(s)
    const [orderInspector, setOrderInspector] = useState({
        bucketurl: '',
        orderinspector: [],
    });
    //report details
    const [reportDetails, setReportDetails] = useState([]);
    //agreement component value(s)
    const [agreements, setAgreements] = useState([]);
    const [servicePaymentStatus, setServicePaymentStatus] = useState();
    //guid
    const [guid, setGuid] = useState('');
    //contact component value(s)
    const [contacts, setContacts] = useState({
        allclient: [],
        selectedclient: [],
        buyerAgent: [],
    });
    //selected client array
    let selectedclientArray = [];
    contacts.selectedclient.forEach((client) => selectedclientArray.push(client.client.id));
    //selected agent array
    let selectedagentArray = [];
    contacts.buyerAgent.forEach((agent) => selectedagentArray.push(agent.agent.id));
    //all client value
    const [allclients, setallclients] = useState([]);
    //all agent value
    const [allagents, setallagents] = useState([]);
    //client array
    let clientArray = [];
    allclients?.forEach((value) =>
        clientArray.push({
            id: value.id,
            first_name: value.first_name,
            last_name: value.last_name,
            email: value.email,
            phone: value.phone,
        }),
    );
    let agentArray = [];
    allagents?.forEach((value) =>
        agentArray.push({
            id: value.id,
            first_name: value.first_name,
            last_name: value.first_name,
        }),
    );
    //default client array
    let defaultclientArray = [];
    allclients?.forEach((values, index) => (selectedclientArray.includes(values.id) ? defaultclientArray.push(index) : ``));
    //selected agent value
    let selectedclientValue = [];
    defaultclientArray?.forEach((value) => selectedclientValue.push(clientArray[value]));
    //default agent array
    let defaultagentArray = [];
    allagents?.forEach((values, index) => (selectedagentArray.includes(values.id) ? defaultagentArray.push(index) : ``));
    //selected agent value
    // var selectedclientValue = [];
    // defaultclientArray.map((value)=> selectedclientValue.push(clientArray[value]))
    let selectedagentValue = [];
    defaultagentArray?.forEach((value) => selectedagentValue.push(agentArray[value]));
    //service component value(s)
    const [services, setServices] = useState([]);
    const [allservices, setallServices] = useState([]);
    const [orderCharge, setOrderCharge] = useState([]);
    const [orderDiscount, setOrderDiscount] = useState([]);
    //service array
    let serviceArray = [];
    allservices?.forEach((values) =>
        serviceArray.push({
            id: values.id,
            name: values.name,
            minutes: values.minutes,
            amount: values.amount,
        }),
    );
    let customCharge = [];
    orderCharge?.forEach((value) =>
        customCharge.push({
            id: value.order_id,
            name: value.description,
            amount: value.price,
            type: 'charge',
        }),
    );
    let customDiscount = [];
    orderDiscount?.forEach((value) =>
        customDiscount.push({
            id: value.order_id,
            name: value.description,
            amount: value.discount * -1,
            type: 'discount',
        }),
    );
    //selected services
    let selectedServices = [];
    services?.forEach((values) => selectedServices.push(values.service.id));
    //default services
    let defaultserviceArrary = [];
    allservices?.forEach((values, index) => (selectedServices.includes(values.id) ? defaultserviceArrary.push(index) : ``));
    //selected services values
    let selectedServicesValue = [];
    defaultserviceArrary?.forEach((value) => selectedServicesValue.push(serviceArray[value]));
    //draft alert message
    const message = 'This inspection is saved as a draft and the client has not been notified. ';
    //get inspection details api call
    const getInspDetails = () => {
        setShowpreloader(true);
        const formdata = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
            inspection_id: location.state?.id,
        };
        axios
            .post(`${API_CONSTANTS.GET_INSPECTION_DETAILS}`, formdata)
            .then((response) => {
                console.log('response', response);
                // Request with success code true
                setShowpreloader(false);
                setValues({
                    ...values,
                    address: response.data.data.location_address === null ? '' : response.data.data.location_address,
                    address2: response.data.data.location_address_2 === null ? '' : response.data.data.location_address_2,
                    city: response.data.data.location_city === null ? '' : response.data.data.location_city,
                    state: response.data.data.location_state === null ? '' : response.data.data.location_state,
                    zip: response.data.data.location_zip === null ? '' : response.data.data.location_zip,
                });
                setAgreements(response.data.data.agreements.unsigned_agreements);
                setGuid(response.data.data.inspectiondetails.guid);
                setReportDetails(response.data.data.inspectiondetails);
                setstatusValue({
                    ...statusvalue,
                    paid: response.data.data.paid,
                    agreementsigned: response.data.data.is_agreement_signed,
                    status: response.data.data.status,
                    startTime: moment(moment.utc(response?.data?.data?.start_time).local()),
                });
                setOrderInspector({
                    ...setOrderInspector,
                    bucketurl: response.data.data.bucket_url,
                    orderinspector: response.data.data.orderinspector,
                });
                setContacts({
                    ...contacts,
                    selectedclient: response.data.data?.orderclient,
                    buyerAgent: response.data.data?.orderagent,
                });
                setServices(response.data.data?.orderservice);
                setServicePaymentStatus(response.data.data?.inspection_fees.payment_status);
                setOrderCharge(response.data.data?.ordercharge);
                setOrderDiscount(response.data.data?.orderdiscount);
                try {
                    setPropertyvalue({
                        ...propertyvalue,
                        foundation: response.data.data?.propertydetails?.foundation,
                        squarefeet: response.data.data?.propertydetails?.total_area,
                        bedrooms: response.data.data?.propertydetails?.total_bedrooms,
                        bathroom: response.data.data?.propertydetails?.total_bathrooms,
                        yearbuild: response.data.data?.propertydetails?.year_built,
                        images: response.data.data?.propertydetails?.other_image_urls,
                    });
                } catch (err) {
                    setPropertyvalue({
                        ...propertyvalue,
                        foundation: '',
                        squarefeet: '',
                        bedrooms: '',
                        bathroom: '',
                        yearbuild: '',
                        images: null,
                    });
                }
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    if (error.response.data.success === false) {
                    }
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('no response', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };
    //get all details api call
    const getalldetails = () => {
        setShowpreloader(true);
        const formdata = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
        };
        axios
            .post(`${API_CONSTANTS.NEW_INSPECTION_DETAILS}`, formdata)
            .then((response) => {
                // Request with success code true
                setallclients(response.data.data.clients);
                setallagents(response.data.data.agents);
                setallServices(response.data.data.serviceswithtemplates);
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    if (error.response.data.success === false) {
                    }
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('no response', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    };
    //first initialize
    useEffect(() => {
        getInspDetails();
        getalldetails();
        window.scroll(0, 0);
    }, [refresh]);
    const handleTotalPrice = (price) => {
        setTotalPrice(price);
    };
    const CancelledStatus = statusvalue.status === 'Cancelled';

    //render value
    return (
        <>
            {/* {window.scrollTo(0, 0)} */}
            <Preloader showPreloader={showpreloader} />
            <Box sx={{ flexGrow: 1 }} className="page-background">
                <Header />
                {statusvalue.status === 'Draft' ? <AlertMessage message={message} type={'error'} icon={<ErrorOutlineIcon sx={{ color: '#F44336', mr: '12px', width: '22px', height: '22px' }}></ErrorOutlineIcon>} /> : ''}
                <Breadcrumb value={location.state?.id} />
                <div className="inspection-details">
                    <Layout address={values} property={propertyvalue} status={statusvalue} tabvalue={tabvalue} settabvalue={settabvalue} guid={guid} statusValue={statusvalue} reportData={reportDetails} orderId={location.state?.id} refresh={refresh} setRefresh={setRefresh} CancelledStatus={CancelledStatus} />
                    <br />
                    {tabvalue === 0 ? (
                        <>
                            <Contacts CancelledStatus={CancelledStatus} inspectionId={location.state?.id} contacts={contacts} allclient={clientArray} allagents={allagents} refresh={refresh} setRefresh={setRefresh} selectedclientArray={selectedclientArray} selectedagentArray={selectedagentArray} defaultagentArray={defaultagentArray} clientArray={clientArray} defaultArray={defaultclientArray} selectedclientValue={selectedclientValue} selectedagentValue={selectedagentValue} />
                            <br />
                            <Inspector CancelledStatus={CancelledStatus} inspector={orderInspector} />
                            <br />
                            <Agreements agreements={agreements} CancelledStatus={CancelledStatus} inspectionId={location.state?.id} />
                            <br />
                            <Servicepage
                                inspectionId={location.state?.id}
                                services={services}
                                CancelledStatus={CancelledStatus}
                                allservices={allservices}
                                orderCharge={orderCharge}
                                servicePaymentStatus={servicePaymentStatus}
                                customDiscount={customDiscount}
                                customCharge={customCharge}
                                orderDiscount={orderDiscount}
                                refresh={refresh}
                                status={statusvalue}
                                setRefresh={setRefresh}
                                handleTotalPrice={(price) => handleTotalPrice(price)}
                                selectedServices={selectedServices}
                                serviceArray={serviceArray}
                                defaultserviceArrary={defaultserviceArrary}
                                selectedServicesValue={selectedServicesValue}
                            />
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </Box>
        </>
    );
};

export default InspectionDetails;
