import React from 'react'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { Button, FormControl, Grid, TextField, Typography } from "@mui/material";

const MainTemplateModal = (props) => {
  const {createNewSite, HandleCreateSite, innerTemplate, actions, title, type} = props;

  return (
    <Modal
    open={createNewSite}
    onClose={() => HandleCreateSite}
    className={"edit-category-outer-modal"}
  >
    <Box sx={{maxWidth:'730px'}} className={"edit-category-modal"}>
      <Grid
        className={"inspection-filter-modal-grid-main"}
        container
        spacing={2}
      >
        <Grid item xs={11.5}>
          <h3
            className={"modal_title"}
          >
            {title}
          </h3>
          {type === 'newcat' ? 
          <div className="modal_desc">Choose one or more categories from the list below. If a category exists in this template, another instance will be added. Choose "Blank Category" to add an empty category with your content.</div> :
          type === 'summary' &&
          <div className="modal_desc">This disclaimer will appear in the summary section of the Interactive Report and the  PDF report .</div>

          }
        </Grid>
        <Grid item xs={0.5}>
          <CloseIcon
            onClick={() => HandleCreateSite()}
            className={"inspection-filter-modal-close-icon"}
          />
        </Grid>
        {innerTemplate}
        <Grid
          container
          spacing={2}
          className="inspection-filter-modal-buttons"
        >
          <Button
            className="inspection-filter-modal-buttons-cancel"
            onClick={() => HandleCreateSite()}
          >
            Cancel
          </Button>
          <Button
            className="inspection-filter-modal-buttons-apply"
            onClick={() => actions.AddSiteDetaill()}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Box>
  </Modal>
  )
}

export default MainTemplateModal