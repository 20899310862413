import "../../../assets/styles/containers/_summaryRatings.scss";
const SummaryRating = ({ rating }) => {
  return (
    <div className="summary_rating_block">
      {/* Iterate over each rating and display it. */}
      {rating?.map((rating) => (
        <div className="summary_rating_container">
          <div className="rating_block" style={{ backgroundColor: `${rating?.summary?.color || rating?.color}` }}></div>
          <div className="summary_rating" key={rating?.summary?.id || rating?.id}>
            {rating?.summary?.name || rating?.name || rating?.title}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SummaryRating;
