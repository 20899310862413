import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useDispatch, useSelector } from "react-redux";
import { deleteCommentById } from "../../store/action/CommentLibraryAction";

const DeleteCommentDialog = (props) => {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.commentLibrary);
  const { state, setState } = props;
  const { openDeleteDialog, comment_id } = state;
  const handleClose = () => {
    setState((prevState) => ({ ...prevState, openDeleteDialog: false }));
  };

  const handleDelete = async () => {
    setState((prevState) => ({ ...prevState, openDeleteDialog: false, showLoader: true }));
    const response = await dispatch(deleteCommentById(comment_id));
    setState((prevState) => ({ ...prevState, showLoader: false, comment_id: "", commentListData: response }));
  };

  return (
    <Dialog open={openDeleteDialog} onClose={() => handleClose()} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle sx={{ display: "flex", justifyContent: "center", pb: 0 }}>
        <DialogTitle className="category-dialog-title">{"Delete comment?"}</DialogTitle>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <DialogContentText sx={{ fontSize: "14px !important", fontWeight: "400 !important", color: "#252525 !important" }}>{`Are you sure you want to delete this comment?`}</DialogContentText>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container display={"flex"} mb={1.5} justifyContent={"center"} gap={2}>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Button className="delete-confirm-btn" variant="contained" onClick={() => handleDelete()}>
              DELETE
            </Button>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Button variant="text" className="delete-cancel-btn" disableRipple onClick={() => handleClose()}>
              <Typography className="custom-underline">Cancel</Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCommentDialog;
