
//file imports
import { Box } from "@mui/system";
import Header from "../../common/header/Header";
import Breadcrumb from "../../common/header/Breadcrumb";
import LibraryTabs from "../../pages/MyLibrary/LibraryTabs";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
/**
 * Comment library component.
 *
 * @returns {object} Comment library component.
 */
const CommentLibrary = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if (sessionStorage.getItem("company-key") === null || sessionStorage.getItem("company-key") === "null") {
            sessionStorage.setItem("company-key", searchParams.get("key"));
            searchParams.delete("key");
            setSearchParams(searchParams);
        }

    }, [])
    // Scroll to top on page load
    window.scrollTo(0, 0);

    return (
        // Comment library container
        <Box className="library-container">
            {/* Header */}
            <Header />
            {/* Breadcrumb */}
            <Breadcrumb />
            {/* Library tabs */}
            <LibraryTabs />
        </Box>
    );
};


export default CommentLibrary;