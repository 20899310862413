import React from "react";
import { Button } from "@mui/material";

const TemplateEditorButton = ({btnType,text,action}) => {
  return (
    <>
      <Button className={`temp_editor_cta ${btnType}`} onClick={action} >
        {text}
      </Button>
    </>
  );
};

export default TemplateEditorButton;
