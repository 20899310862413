import React, { useEffect } from "react";
import Header from "../../common/header/Header";
import "../../../assets/styles/containers/_dashboard.scss";
import { Box } from "@mui/system";
import Breadcrumb from "../../common/header/Breadcrumb";
import DashboardLayout from "./Layout";
import { useNavigate } from "react-router-dom";
import toast  from 'react-hot-toast';


const Dashboard = (props) => {
  let navigate = useNavigate();

  useEffect(() => {
    if (
      sessionStorage.getItem("token") === null ||
      sessionStorage.getItem("token") === undefined
    ) {
      navigate("/");
      toast.error("sign in to see the contents");
    }
  });
  return (
    <>
    {/* {console.log(props.userName , "data")} */}
      {window.scrollTo(0, 0)}
      <Box sx={{ flexGrow: 1 }} className="page-background">
        <Header />
        <Breadcrumb />
        <DashboardLayout />
      </Box>
    </>
  );
};

export default Dashboard;
