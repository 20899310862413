import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Button } from "@mui/material";
import { useNavigate,useLocation } from "react-router-dom";
import validator from "validator";

import toast from "react-hot-toast";
import { apiCall } from "../../utils/action";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider} from "@mui/lab";
import moment from "moment";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Preloader from "../../helpers/Preloader";

// pdf converts
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
// pdf converts


const AgreementWebViewNSignature = () => {
  const [showLoader, setShowLoader] = useState();
  const [date1, setDate1] = useState(moment());
  const [date1Error, setDate1Error] = useState(false);
  const [date1ErrorText, setDate1ErrorText] = useState('');
  const [date2, setDate2] = useState(moment());
  const [clientNameOne,setClientNameOne] = useState('');
  const [clientNameOneError,setClientNameOneError] = useState('');
  const [clientNameOneErrorText,setClientNameOneErrorText] = useState('');
  const [clientNameTwo,setClientNameTwo] = useState('');
  const [clientNameTwoError,setClientNameTwoError] = useState('');
  // const [clientNameTwoErrorText,setClientNameTwoErrorText] = useState('');
  const [agreementContent, setAgreementContent] = useState('');
  const [agreementName, setAgreementName] = useState('');
  const [agreeTerms,setAgreeTerms] = useState(false);
  // const [file,setFile] = useState();
  const [pdfFile,setPdfFile] = useState();
  // const [base64Pdf,setBase64Pdf] = useState('');
  let navigate = useNavigate();
  // url params
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let agreementIdParam = Number(searchParams.get("aid"));
  let companyIdParam = searchParams.get("cid");
  let orderIdParam = searchParams.get("oid");
  let userIdParam = searchParams.get("uid");
  let uniqueIdParam = searchParams.get("uniq");
  let emailParamEncoded = encodeURIComponent(searchParams.get("email"));
  let emailParam = decodeURIComponent(emailParamEncoded.replace(/\%20/g, '+'))
  //get agreement details
  const GetAgreementDetails = () => {
    setShowLoader(true);
    // const formdata = { company_id: 11, agreement_id: 39,order_id:317 };
    const formdata = { user_id:userIdParam, company_id: companyIdParam, agreement_id: [agreementIdParam],order_id:orderIdParam,email:emailParam,unique_id:uniqueIdParam };
    apiCall(
      (response) => {
        console.log("GET_AGREEMENT_CONTENT", response);
        const { data, message, success,status_code } = response.data;
        console.log("status",status_code)
        if (success) {      
          setAgreementContent(data[0]?.agreement_body);
          let agreeName = `${data[0]?.name}.pdf`
          setAgreementName(agreeName);          
          // window.history.pushState('', '', '/#/view-agreement/agreement');
          setShowLoader(false);
        } else {
          // navigateing to agreement already signed page if the agreement is already signed
          if(status_code === 400) navigate('/agreement-signed')
          else toast.error(message);
          setShowLoader(false)
        }
      },
      formdata,
      "GET_AGREEMENT_CONTENT"
    );
  };

  const sendSignedAgreement=(convertedPdf)=>{
    setShowLoader(true);
    toast.dismiss();
    // const formdata = new FormData();
    const formdata = {  user_id:userIdParam,company_id: companyIdParam, agreement_id: agreementIdParam,inspection_id:orderIdParam ,agreement_file:convertedPdf,
      user_name:`${clientNameOne?.trim()}${clientNameTwo !==''?`,${clientNameTwo?.trim()}`:''}`,agreement_signed_date:`${date1},${clientNameTwo!=='' && date2 !== '' ?`,${date2}`:''}` ,
      agreement_file_name:agreementName,email:emailParam,unique_id:uniqueIdParam }     
      apiCall(
      (response) => {
        console.log("UPLOAD_SIGNED_AGREEMENT", response);
        const { message, success } = response.data;
        if (success) {   
          setShowLoader(false);
          navigate('/accept-agreement', { state: { emailParam } })
        } else {
          toast.error(message);
          console.log("else ", message);
          setShowLoader(false);
        }
      },
      formdata,
      "UPLOAD_SIGNED_AGREEMENT"
    );
  };

 
  const timestramp1 = moment(date1).format("YYYY-MM-DD");
  const timestramp2 = moment(date2).format("YYYY-MM-DD");

  // signed HTML to PDF conversion
  const HTMLtoPDF =(data)=>{
    var html = htmlToPdfmake(data);
    const documentDefinition = { content: html };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition);
    // const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

    // base 64 conversion
    setPdfFile(pdfMake.createPdf(documentDefinition));
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
      pdfDocGenerator.getBase64((data) => sendSignedAgreement(data))
  }

const addSignatureNameNDate=()=>{
  let signedHTML = `${agreementContent}<p>CLIENT 1</p><p>${clientNameOne} <span>&emsp;&emsp;&emsp;${timestramp1}</span></p>${clientNameTwo!==''?`<p>CLIENT 2</p>`:''}<p>${clientNameTwo} <span>&emsp;&emsp;&emsp;${clientNameTwo !==''?timestramp2:''}</span></p>`
  HTMLtoPDF(signedHTML)
}
const validate=()=>{
  let checkboxSpan = document.getElementById('agreeTermsID');
    checkboxSpan.innerHTML = "";
  if (clientNameOne?.trim() === '' || clientNameOne?.trim() === null) {
    setClientNameOneError(true);
    setClientNameOneErrorText("Client name is required");
  } else if (!validator.isLength(clientNameOne?.trim(), { min: 3, max: undefined })) {
    setClientNameOneError(true);
    setClientNameOneErrorText("Minimum 3 characters required");
  } else setClientNameOneError(false);
  if(date1===null){
    setDate1Error(true);
    setDate1ErrorText("Date is required");
  } else setDate1Error(false);
  
  
  // if(clientNameTwo === '') setDate2(null);
  if(clientNameOne?.trim() !== '' && clientNameOne.trim() !== null && validator.isLength(clientNameOne?.trim(), { min: 3, max: undefined }) && 
  date1 !== null && agreeTerms === false)  checkboxSpan.innerHTML = "Please check the terms and conditions";
  if(clientNameOne !== '' && clientNameOne !== null && validator.isLength(clientNameOne, { min: 3, max: undefined }) && 
  date1 !== null && agreeTerms === true) {
    addSignatureNameNDate();
  }
}

const rejectAgreement =()=>{
  setShowLoader(true);
  const formdata = {company_id:companyIdParam , order_id:orderIdParam, agreement_id:agreementIdParam ,email:emailParam}     
    apiCall(
    (response) => {
      console.log("REJECT_AGREEMENT", response);
      const { data, message, success } = response.data;
      if (success) {   
        setShowLoader(false);
        navigate('/reject-agreement')
      } else {
        console.log("else ", message);
        setShowLoader(false);
      }
    },
    formdata,
    "REJECT_AGREEMENT"
  );
}
// const uploadFile=(e)=>{
//    setFile(e.target.files[0])
// }

  useEffect(() => {
    GetAgreementDetails();   
  }, []);

  return (
    <Box className="web-view-wrapper">
      <Preloader showPreloader={showLoader} />
      <Grid container spacing={2} className="web-view-container">
        <Grid container className="web-view-content">
          <h3>Review and sign the Agreement</h3>
          <div className="agreement-view" dangerouslySetInnerHTML={{ __html: agreementContent }} />
          {/* {agreementContent!=='' && agreementContent} */}
          <Grid item xs={12} className="agreement_consent">
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  onChange={(e)=>{setAgreeTerms(e.target.checked)}}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              }
              label="* I have carefully read this agreement and agree to the terms and conditions above."
            />           
          </Grid>
          <Grid item xs={12} id="agreeTermsID"></Grid>
          <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                Client 1*
              </label>
              <TextField
                id="firstName"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Full Name"
                // value={firstName}
                onChange={(e) => setClientNameOne(e.target.value)}
                error={clientNameOneError}
                helperText={`${clientNameOneError ? clientNameOneErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>        
          <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                Date*
              </label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={date1}
                format="DD-MM-YYYY"
                minDate={new Date()}
                className={"inspection-filter-modal-select"}
                onChange={(newValue) => {
                  setDate1(newValue);
                }}                
                error={date1Error}
                helperText={`${date1Error ? date1ErrorText : ""}`}
                renderInput={(params) => <TextField {...params} size="small" />}
                // error={Boolean(errors.date)}
                // helperText={errors.date?.message}
              />
            </LocalizationProvider>
            </FormControl>
          </Grid>
          {/* ============================================================================================== */}
          <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                client 2
              </label>
              <TextField
                id="firstName"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Full Name"
                // value={firstName}
                onChange={(e) => setClientNameTwo(e.target.value)}
                error={clientNameTwoError}
                helperText={`${clientNameTwoError ? clientNameOneErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>         
          <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                Date
              </label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={date2}
                format="DD-MM-YYYY"
                minDate={new Date()}
                className={"inspection-filter-modal-select"}
                onChange={(newValue) => {
                  setDate2(newValue);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
                // error={Boolean(errors.date)}
                // helperText={errors.date?.message}
              />
            </LocalizationProvider>              
            </FormControl>
          </Grid>
          {/* ================================================================================================= */}
         {/* test input */}
         {/* <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                pdf
              </label>
              <input id='inputPDF'
                  // ref={fileInput}
                  accept=".pdf" type="file" onChange={uploadFile}
      />           
            </FormControl>
          </Grid> */}
         {/* test input */}
          <Grid item xs={12} className="agreement-action">
            <div>
              {/* <Button
              className="cancel-btn">
              <sapn>cancel</sapn>
            </Button>  */}
            </div>
            <div className="right-btn-group">
              <Button onClick={validate}
              className="agree-btn" >
              Agree
            </Button>   
            <Button className="reject-btn"
            onClick={rejectAgreement}
            >
              <span className="reject-text">Reject</span>
            </Button>   
            </div>
                             
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgreementWebViewNSignature;
