import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Button, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import SortIcon from "@mui/icons-material/Sort";
import { GetCurrentResolution } from "../../utils/services";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";
import Tabs from "@mui/material/Tabs";

const EnhancedTableToolbar = (props) => {

  const {isMobile} = props;

  var filt = props.TotalFilter?.status

  const remove = (item) => {
    let filteredArr = filt.filter((el) => el !== item);
    filt = filteredArr
  };

  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      {/* <Preloader showPreloader={true}/> */}
      <Grid
        item
        xs={12}
        sx={{ width: { xs: "371.5px", sm: "100%", md: "100%", lg: "100%" } }}
        className="inspection-toolbar-main"
      >
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: { xs: "241px", sm: "29.64%", md: "29.64%", lg: "29.64%" },
          }}
          className={"inspection-search-field"}
        >
          <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon className="inspection-search-icon" />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1, fontSize: "16px", color: "black" }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            onChange={props.handleSearchFilter}
          />
        </Paper>
        <Button
          variant="outlined"
          sx={{ width: { xs: "63px", sm: "83px", md: "8.7%" } }}
          startIcon={
            <SortIcon
              style={{ width: isMobile && "25px", height: isMobile && "25px" }}
              className={"inspection-filter-button-icon"}
            />
          }
          className={"inspection-filter-button"}
          onClick={props.handleOpen}
        >
          {GetCurrentResolution() === "mobile" ? "" : "FILTER"}
        </Button>
        {isMobile ? (
          <p
            className="inspections-export-content"
            onClick={() => window.print()}
          >
            <ExitToAppIcon
              sx={{ fontSize: "38px" }}
              className="inspections-export-icon"
            />
            {isMobile ? "" : "Export"}
          </p>
        ) : (
          ""
        )}
        <div
          style={{
            width: !isMobile ? "52.35%" : "0%",
            padding: !isMobile ? "6px 18px" : "0px",
          }}
        >
          {props.displayFilters && !isMobile ? (
            <Box sx={{ flexGrow: 1, position: "relative",top:'-10px' }} className="inspections-filter-toolbar">
                 <Tabs
            // className="tabs"
            allowScrollButtonsMobile
            scrollButtons={ true }
            variant="scrollable"
            direction="row" 
            // alignItems="center" 
            // spacing={1}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#239d80",
             
              },
            }}
            // scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >
              {/* <Stack allowScrollButtonsMobile scrollButtons={ true} direction="row" alignItems="center" spacing={1}> */}
            {props?.dates !== undefined && <Chip
                    label={props?.dates}
                    sx={{display : !props?.dates && 'none'}}
                    deleteIcon={
                      <CancelIcon className="inspection-filter-chips-icon" />
                    }
                    onDelete={() => props.removeDates(props?.dates)}
                    className="inspection-filter-chips"
                  />}
              {props?.totalAgents !== null ? props?.totalAgents.map((agents, index) => {
                  return(
                    <Chip
                    label={agents}
                    deleteIcon={
                      <CancelIcon className="inspection-filter-chips-icon" />
                    }
                    onDelete={() => props.removeAgents(agents)}
                    className="inspection-filter-chips"
                  />
                  )
                }) : ''}
                {props?.totalInspectors !== null ? props?.totalInspectors.map((insp, index) => {
                  return(
                    <Chip
                    label={insp}
                    deleteIcon={
                      <CancelIcon className="inspection-filter-chips-icon" />
                    }
                    onDelete={() => props.removeInspector(insp)}
                    className="inspection-filter-chips"
                  />
                  )
                }) : ''}
                { props?.totalPaid !== null ? props?.totalPaid.map((paid, index) => {
                  return(
                    <Chip
                    label={paid}
                    deleteIcon={
                      <CancelIcon className="inspection-filter-chips-icon" />
                    }
                    onDelete={() => props.removePaid(paid)}
                    className="inspection-filter-chips"
                  />
                  )
                }) : ''}
                { props.totalAgreement !== null ? props?.totalAgreement.map((agree, index) => {
                  return(
                    <Chip
                    label={agree}
                    deleteIcon={
                      <CancelIcon className="inspection-filter-chips-icon" />
                    }
            
                    onDelete={() => props.removeAgreement(agree)}
                    className="inspection-filter-chips"
                  />
                  )
                }) : ''}
                {props.totalStatus !== null ? props?.totalStatus?.map((status, index) => {
                  return(
                    <Chip
                    label={status}
                    deleteIcon={
                      <CancelIcon
                      // onClick={() => remove(status)}
                      className="inspection-filter-chips-icon" />
                    }
                    onDelete={() => props.removeStatus(status)}
                    className="inspection-filter-chips"
                  />
                  )
                }) : ""}
     
                <p
                  className="inspection-filter-chip-reset"
                  onClick={props.handleChipReset}
                >
                  Clear all
                </p>
              {/* </Stack> */}
              </Tabs>
            </Box>
          ) : (
            ""
          )}
        </div>

        {!isMobile ? (
          <p sx={{ width: "7.6%" }} className="inspections-export-content" onClick={() => window.print()}>
            <ExitToAppIcon className="inspections-export-icon" />
            {isMobile ? "" : "Export"}
          </p>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={12}>
        {props.displayFilters && isMobile ? (
          <Box sx={{ flexGrow: 1, position: "relative",top:'0px' }} className="inspections-filter-toolbar">
                             <Tabs
            // className="tabs"
            allowScrollButtonsMobile
            scrollButtons={ true }
            variant="scrollable"
            direction="row" 
            // alignItems="center" 
            // spacing={1}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#239d80",
             
              },
            }}
            // scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >
                 {props?.dates !== undefined && <Chip
                    label={props?.dates}
                    sx={{display : !props?.dates && 'none'}}
                    deleteIcon={
                      <CancelIcon className="inspection-filter-chips-icon" />
                    }
                    onDelete={() => props.removeDates(props?.dates)}
                    className="inspection-filter-chips"
                  />}
              {props?.totalAgents !== null ? props?.totalAgents.map((agents, index) => {
                  return(
                    <Chip
                    label={agents}
                    deleteIcon={
                      <CancelIcon className="inspection-filter-chips-icon" />
                    }
                    onDelete={() => props.removeAgents(agents)}
                    className="inspection-filter-chips"
                  />
                  )
                }) : ''}
                {props?.totalInspectors !== null ? props?.totalInspectors.map((insp, index) => {
                  return(
                    <Chip
                    label={insp}
                    deleteIcon={
                      <CancelIcon className="inspection-filter-chips-icon" />
                    }
                    onDelete={() => props.removeInspector(insp)}
                    className="inspection-filter-chips"
                  />
                  )
                }) : ''}
                { props?.totalPaid !== null ? props?.totalPaid.map((paid, index) => {
                  return(
                    <Chip
                    label={paid}
                    deleteIcon={
                      <CancelIcon className="inspection-filter-chips-icon" />
                    }
                    onDelete={() => props.removePaid(paid)}
                    className="inspection-filter-chips"
                  />
                  )
                }) : ''}
                { props.totalAgreement !== null ? props?.totalAgreement.map((agree, index) => {
                  return(
                    <Chip
                    label={agree}
                    deleteIcon={
                      <CancelIcon className="inspection-filter-chips-icon" />
                    }
            
                    onDelete={() => props.removeAgreement(agree)}
                    className="inspection-filter-chips"
                  />
                  )
                }) : ''}
                {props.totalStatus !== null ? props?.totalStatus?.map((status, index) => {
                  return(
                    <Chip
                    label={status}
                    deleteIcon={
                      <CancelIcon
                      // onClick={() => remove(status)}
                      className="inspection-filter-chips-icon" />
                    }
                    onDelete={() => props.removeStatus(status)}
                    className="inspection-filter-chips"
                  />
                  )
                }) : ""}
              <p
                className="inspection-filter-chip-reset"
                onClick={props.handleChipReset}
              >
                Clear all
              </p>
            </Tabs>
          </Box>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};

export default EnhancedTableToolbar;
