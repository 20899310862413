import React from 'react';
import "../../../assets/styles/containers/_inspDetails.scss"
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

const CardHeader = (props) => {
    const {title, icon, iconDesc, CancelledStatus} = props
  return <Box className='details'>
  <div className='details__title'>
    {title}
  </div>

  <div style={{display: CancelledStatus && 'none'}} className='details__link'>
      {icon}
    <div className='details__text'>{iconDesc}</div>
  </div>
</Box>
}

export default CardHeader;
