import Router from "./router/router";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Toaster, ToastBar, toast } from "react-hot-toast";
import "./App.scss";
import store from "./store/index";

function App() {
  return (
    <div>
      <Toaster
        position="bottom-left"
        toastOptions={{
          className: "toastbar",
          icon: null,
          error: { duration: 5000 },
          success: { duration: 5000 },
          style: {
            backgroundColor: "#323232",
            color: "#ffffff",
            minWidth: "434px",
            justifyContent: "space-between",
            display: "flex",
            padding: "14px 10px 14px 10px",
            borderRadius: "4px",
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ message }) => (
              <>
                <p className="toast_message">{message}</p>
                <div className="toast_close_icon_display">{t.type !== "loading" && <CloseIcon className="toast_close_icon" onClick={() => toast.dismiss(t.id)} />}</div>
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <div className="app_container">
        <div className="main__container">
          <Provider store={store}>
            <HashRouter>
              <Router />
            </HashRouter>
          </Provider>
        </div>
      </div>
    </div>
  );
}
export default App;
