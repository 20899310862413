import { API_CONSTANTS, postApiCall } from "../../config/api-constants";
export const UPDATE_TE_DATA = "UPDATE_TE_DATA";

export const updateTemplate = async () => {
  return async (dispatch) => {
    try {
      const response = await postApiCall(API_CONSTANTS.UPDATE_TEMPLATE_DATA);
      return dispatch({ type: UPDATE_TE_DATA, payload: response?.data?.data || [] });
    } catch (error) {
      throw error;
    }
  };
};
