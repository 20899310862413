const config_settings = {
  "localhost:3000": {
    ENV: "DEV",
    SERVER_URL: "https://dev-te-api.yourinspection.app/public/index.php/api",
    PORTAL_SERVER_URL: "https://dev-portal-api.yourinspection.app/public/index.php/api",
    REPORT_SETTING_URL: "https://dev-report-api.myinspection.report/public/index.php/api",
  },
  "localhost:3001": {
    ENV: "DEV",
    SERVER_URL: "https://dev-te-api.yourinspection.app/public/index.php/api",
    PORTAL_SERVER_URL: "https://dev-portal-api.yourinspection.app/public/index.php/api",
    REPORT_SETTING_URL: "https://dev-report-api.myinspection.report/public/index.php/api",
  },
  "localhost:3002": {
    ENV: "DEV",
    SERVER_URL: "https://dev-te-api.yourinspection.app/public/index.php/api",
    PORTAL_SERVER_URL: "https://dev-portal-api.yourinspection.app/public/index.php/api",
    REPORT_SETTING_URL: "https://dev-report-api.myinspection.report/public/index.php/api",
  },
  "develop.yourinspection.app": {
    ENV: "DEV",
    SERVER_URL: "https://dev-te-api.yourinspection.app/public/index.php/api",
    PORTAL_SERVER_URL: "https://dev-portal-api.yourinspection.app/public/index.php/api",
    REPORT_SETTING_URL: "https://dev-report-api.myinspection.report/public/index.php/api",
  },
  "palm-11692.d28ay6eyw0hh60.amplifyapp.com": {
    ENV: "DEV",
    SERVER_URL: "https://dev-te-api.yourinspection.app/public/index.php/api",
    PORTAL_SERVER_URL: "https://dev-portal-api.yourinspection.app/public/index.php/api",
    REPORT_SETTING_URL: "https://dev-report-api.myinspection.report/public/index.php/api",
  },
  "tester.palmtech.dev": {
    ENV: "DEV",
    SERVER_URL: "https://dev-te-api.yourinspection.app/public/index.php/api",
    REPORT_SETTING_URL: "https://dev-report-api.myinspection.report/public/index.php/api",
  },
  "integration.yourinspection.app": {
    ENV: "INT",
    SERVER_URL: "https://int-te-api.yourinspection.app/public/index.php/api",
    PORTAL_SERVER_URL: "https://int-portal-api.yourinspection.app/public/index.php/api",
    REPORT_SETTING_URL: "https://int-report-api.myinspection.report/public/index.php/api",
  },
  "staging.yourinspection.app": {
    ENV: "STAGE",
    SERVER_URL: "https://stg-te-api.yourinspection.app/public/index.php/api",
    PORTAL_SERVER_URL: "https://stg-portal-api.palmtech.com/public/index.php/api",
    REPORT_SETTING_URL: "https://stg-report-api.myinspection.report/public/index.php/api",
  },
  "yourinspection.app": {
    ENV: "PROD",
    SERVER_URL: "https://prod-te-api.yourinspection.app/public/index.php/api",
    PORTAL_SERVER_URL: "https://prod-portal-api.palmtech.com/public/index.php/api",
    REPORT_SETTING_URL: "https://prod-report-api.myinspection.report/public/index.php/api",
  },
};

export default config_settings;
