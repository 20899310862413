import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { colors } from "../../config/app-constants";

const AlertMessage = (props) => {
  const { message, type, icon } = props;

  const TypeCheck = (types) => {
    if (types === "error") {
      var color = colors["ERROR"],
        bg = colors["ERROR_BG"];
      return { color, bg };
    } else if (types === "success") {
      // condition
    }
  };
  return (
    <div
      style={{
        width: "100%",
        position: "absolute",
      }}
    >
      <Box
        sx={{
          display: "flex",
          py: "14px",
          px: { xs: "10px", sm: "80px", md: "80px", lg: "80px" },
          backgroundColor: TypeCheck(type).bg,
          color: TypeCheck(type).color,
          fontSize: "14px",
          alignItems: "center",
        }}
      >
        {icon}
        <Typography>{message}</Typography>
      </Box>
    </div>
  );
};

export default AlertMessage;
