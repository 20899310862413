import React, { useState, useEffect } from "react";
// material ui imports
import { Grid, Button} from "@mui/material";
import Box from "@mui/material/Box";
import toast from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import { useMediaQuery } from "react-responsive";
import TableContainer from "@mui/material/TableContainer";
import { useNavigate } from "react-router-dom";
// dialog imports mui
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";

// styles imports
import "../../../assets/styles/containers/_agreements.scss";
import "../../../assets/styles/containers/_teams.scss";
// component imports
import Preloader from "../../helpers/Preloader";
import {getComparator,BootstrapTooltip,descendingComparator,
  stableSort,GetCurrentResolution,splitDate
} from "../../utils/services";
import { apiCall } from "../../utils/action";
import AntSwitch from "../../common/antSwitch/AntSwitch";

// mock datas
const agreementTableHead = [   
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "NAME",
    },
    // { id: "description", numeric: false, disablePadding: false, label: "DESCRIPTION" },
    { id: "used", numeric: false, disablePadding: false, label: "USED" },
    { id: "date_created", numeric: false, disablePadding: false, label: "DATE CREATED" },
    { id: "actions", numeric: false, disablePadding: false, label: "ACTIONS" },
  ];
  //map funtion of table heading
function EnhancedTableHead(props) {
    // const { order, orderBy, onRequestSort } = props;
    // const createSortHandler = (property) => (event) => {
    //   onRequestSort(event, property);
    // };  
    return (
      <TableHead>
        <TableRow>
          {agreementTableHead.map((headCell) => (
             !props.permission? headCell.label !== "ACTIONS" &&
             <td
              key={headCell.id}
              align={headCell.label === "ACTIONS" ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              className={headCell.label=== "ACTIONS"? 'team-table-head action-column':'team-table-head'}
            >
             
                <span className="head-title"  style={{
                  marginTop: GetCurrentResolution() === "mobile" && "21px",
                  marginBottom: "10.5px",
                  flexDirection: "row-reverse",
                  fontSize: "12px",display:'block'
                }}>
                  {headCell.label}{" "}                 
                </span>
            </td>:
            <td
            key={headCell.id}
            align={headCell.label === "ACTIONS" ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            className={headCell.label=== "ACTIONS"? 'team-table-head action-column':'team-table-head'}
          >
           
              <span className="head-title"  style={{
                marginTop: GetCurrentResolution() === "mobile" && "21px",
                marginBottom: "10.5px",
                flexDirection: "row-reverse",
                fontSize: "12px",display:'block'
              }}>
                {headCell.label}{" "}                 
              </span>
          </td>       
          ))}
        </TableRow>
      </TableHead>
    );
  }
  EnhancedTableHead.propTypes = {
    // numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    // order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    // orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
const AgreementsListView = () => {
  const [table_data, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState();
  const [deleteId,setDeleteId] = useState('');
  const [openAlert,setOpenAlert] = useState(false);
  const [stateChanged,setStateChanged] = useState(false);
  const [agreementStatus,setAgreementStatus]= useState(true);
  const [agreementStatusDescription,setAgreementStatusDescription]= useState('');
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  let navigate = useNavigate();
     // session datas
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  const uuId = sessionStorage.getItem("user_id");
  let permission = roleId === "7" ? false : true;
  

   // get agreements lsit from api
   const getAgreementsList = () => {
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id:uuId, role_id:roleId};
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          setTableData(data)
          setShowLoader(false);
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      "GET_AGREEMENTS_LIST"
    );
  };

  //delete agreement API
  const deleteAgreement=(id)=>{
    setShowLoader(true);    
    toast.dismiss();
    const formdata = { company_id: companyId, user_id:uuId, role_id:roleId, agreement_id:id };
    handleCloseAlert();
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {         
          setStateChanged(!stateChanged);
          setShowLoader(false);
          toast.success(message);
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      "DELETE_AGREEMENT"
    );
  }
  const getAgreementInfo=()=>{
    setShowLoader(true);    
    toast.dismiss();
    const formdata = { company_id: companyId, user_id:uuId, role_id:roleId };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {         
          setAgreementStatus(data.agreement?.agreement_status)
          setAgreementStatusDescription(data.agreement?.agreement_status_description)
          setShowLoader(false);
        } else {
         toast.error(message)
        }
      },
      formdata,
      "GET_COMPANY_INFO"
    );
  }
  const defaultSwitchValue=(status)=>{
    setShowLoader(true);    
    toast.dismiss();
    const formdata = { company_id: companyId, user_id:uuId, role_id:roleId,agreement_status:status };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) { 
          setStateChanged(prev=>!prev)
          setShowLoader(false);
          toast.success(message);
        } else {
         toast.error(message)
         setShowLoader(false);
        }
      },
      formdata,
      "UPDATE_AGREEMENT_INFO"
    );
  }
   //alert modal functions
   const handleClickOpenAlert = (id) => {
    setDeleteId(id);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  useEffect(() => {
   getAgreementsList();
   getAgreementInfo();
  },[stateChanged])

  return (
    <>
     <Preloader showPreloader={showLoader} />
      {/* --------------------delete service dialog box----------------------- */}
      <Dialog
          open={openAlert}
          onClose={handleCloseAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="delete-alert-modal"
        >

          <DialogTitle id="alert-dialog-title">
            {"Delete agreement?"}
            <CloseIcon className="close-icon" onClick={handleCloseAlert}/>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            This agreement has services associated with it. Those services will be associated with default agreement.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="delete-modal-cancel-btn" onClick={handleCloseAlert}>Cancel</Button>
            <Button
              className="default-btn"
              variant="contained"
              color="error"
              onClick={() => {
                deleteAgreement(deleteId);
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
     {showLoader ? (
         ""
        ) : (
        <Box
          sx={{
            width: "100%",
            px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },
          }}
          className="teams-container"
        >
          <Grid item xs={12} className="editor-title-wrapper">
            <div>
              <h3 className="editor-title">Agreements</h3>
              <p className="agreements-subtitle">Your standard agreements that will be sent to your clients. Specify which agreement to be used when creating a new inspection or from the inspection detail page. If agreements are disabled, no agreements will be able to be added to an inspection.</p>
            </div>           
          </Grid>
          <Grid className="agreement__toggle">
          <div className="default_toggler mb-23 guardian__toggle">
            <div className="payment__toggle"> 
             <AntSwitch defaultValue={agreementStatus=== 1?true:false} defaultSwitchValue={defaultSwitchValue}/><span className="payment__toggle__status">Agreements are {agreementStatusDescription ==="Disabled"?'disabled':'enabled'}. </span>
            </div> 
          </div>
          {permission?
            <BootstrapTooltip
              title={
                permission ? '' : <h1 className="font-13">You cannot add Agreements</h1>
              }
            >
              <span className="disable-btn-wrapper">
                <Button disabled={!permission}
                id="add_new_agreement"
                name="add_new_agreement"
                  className={!permission?"disabled-permision-btn":"inspections-new-button"} 
                   sx={{ textTransform: "none" }}
                  onClick={() =>
                    navigate(`/settings/agreements/new`)
                  }
                >
                  Add new &nbsp;&nbsp;+{" "}
                </Button>
              </span>
            </BootstrapTooltip>
            :''}
          </Grid>

          <Paper
            sx={{ width: "100%", sm: 8, lg: 2, mt: "18px" }}
            className="agreements-table-wrapper"
          >
            <TableContainer
              sx={{ overflowX: { sm: "auto", lg: "unset", md: "scroll" } }}
              className={isMobile ? "mobileScroller" : ""}
            >
              <Table
                aria-labelledby="tableTitle"
                size={"small"}
                style={{
                  transform: isMobile && "rotate(180deg)",
                  overflowX: "unset",
                  width: "100",
                }}
                className="agreements-table-main"
              >
                <EnhancedTableHead
                permission={permission}
                //   numSelected={selected.length}
                //   order={order}
                //   orderBy={orderBy}
                  //   onSelectAllClick={handleSelectAllClick}
                //   onRequestSort={handleRequestSort}
                  rowCount={table_data.length}
                />
                <tbody className="agreements-body">
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with: 
                    rows.slice().sort(getComparator(order, orderBy)) */}
                  {table_data.length>0?
                   stableSort(table_data, getComparator())
                    //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                    //   const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      let date = new Date(row.created_at);
                      const daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                      let dateTime = row.created_at.split(" ");
                      var options = { year: 'numeric', month: 'long', day: 'numeric' };
                      return (
                        <tr
                        //   onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                        //   aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={`${row.client}_${index}`}
                          className="agreements-data-table"
                        >
                          <td
                            className="agreement_name"
                            component="th"
                            id={labelId}
                            padding="none"
                          >
                            <span
                            onClick={() =>{
                              navigate(`/settings/agreements/${row?.name?.charAt(0)==="#"? row?.name?.substring(1): row.name}`,
                                {
                                  state: {
                                    id: row.id,
                                    edit: true,
                                  },
                                }
                              )
                            }
                            }
                            >
                              {row.name}
                            </span>
                          </td>
                      

                          <td align="left" className="teams-email-data" style={{width:'130px'}}>
                            <span>{row.used}</span>
                          </td>

                          <td align="left" style={{width:'250px'}}>
                            <p className="phone-data"><span>{date?.toLocaleDateString("en-US",options)}</span>
                            <span className="isFlex date-display"><span className=" date-display">{daysList[date.getDay()]}</span>•<span className=" date-display">{splitDate(dateTime).time}</span></span></p>
                          </td>  
                          {permission && <td className="action-column" align="right">
                            <span className="table-actions">
                              <span>
                                {permission && row.is_default === 0?
                                <>
                                <BootstrapTooltip title={<h1 className="font-13">Edit details</h1>}>
                                  <EditIcon className="edit-icon"
                                    onClick={() =>
                                      navigate(`/settings/agreements/${row.name?.charAt(0)==="#"? row.name?.substring(1): row.name}`,
                                        {
                                          state: {
                                            id: row.id,
                                            edit: true,
                                          },
                                        }
                                      )
                                    }
                                  />
                                </BootstrapTooltip>
                                <BootstrapTooltip 
                                  title={<h1 className="font-13">Delete record</h1>}
                                >
                                  <DeleteIcon className="delete-icon"
                                    sx={{
                                      fontSize: 18
                                    }}
                                    onClick={() => handleClickOpenAlert(row.id)}
                                  />
                                </BootstrapTooltip></>
                                :
                                <>
                                <BootstrapTooltip title={<h1>You cannot edit record</h1>}>
                                  <EditIcon
                                    sx={{ opacity: "0.25" }}
                                  />
                                </BootstrapTooltip>
                                <BootstrapTooltip
                                  title={<h1>You cannot delete record</h1>}
                                >
                                  <DeleteIcon
                                    sx={{
                                      color: "red",
                                      fontSize: 18,
                                      opacity: "0.25",
                                    }}
                                  />
                                </BootstrapTooltip>
                                </>
                                }
                              </span>
                            </span>
                          </td>}
                        </tr>
                      );
                    }):<div>No datas found</div>}
                 
                </tbody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
         )} 
    </>
  )
}

export default AgreementsListView