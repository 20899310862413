// file imports *****************************************************************************
import React from "react";
import { ROUTES } from "../config/app-constants";
import { ProtectedRoutes } from "./ProtectedRoutes"; //authorization
import { Routes as Switch, Route } from "react-router-dom";

//Component imports *************************************************************************

import Signup from "../pages/login/Signup";
import Agents from "../pages/agents/Agents";
// import { Login } from '../pages/login/Login';
import Metrics from "../pages/metrics/Metrics";
import Teams from "../pages/settings/Teams/Teams";
import MyCalendar from "../pages/calendar/Calendar";
import EditAgents from "../pages/agents/EditAgents";
import Dashboard from "../pages/dashboard/Dashboard";
import Templates from "../pages/templates/Templates";
import PageNotFound from "../common/alert/PageNotFound";
import ResetPassword from "../pages/login/ResetPassword";
import Inspections from "../pages/inspection/Inspections";
import ForgotPassword from "../pages/login/ForgotPassword";
import CreatePassword from "../pages/login/CreatePassword";
import TeamDetails from "../pages/settings/Teams/TeamDetails";
import Notifications from "../pages/notifications/Notifications";
import RequestPayment from "../components/requestPayment/RequestPayment";
import InspectionDetails from "../pages/inspectionDetails/InspectionDetails";
import ServicesPage from "../pages/settings/Services/ServicesPage";
import ScheduleInspection from "../pages/scheduleInspection/ScheduleInspection";
import CompanyProfile from "../pages/settings/CompanyProfile/CompanyProfile";
import AgreementsList from "../pages/settings/Agreements/AgreementsList";
import AgreementsDetails from "../pages/settings/Agreements/AgreementsDetails";
import AgreementWebView from "../pages/settings/Agreements/AgreementWebView";
import AppointmentConfirmation from "../pages/notifications/AppointmentConfirmation";
import RescheduleConfirmation from "../pages/notifications/RescheduleConfirmation";
import AgreementToSign from "../pages/notifications/AgreementToSign";
import PaymentMade from "../pages/notifications/PaymentMade";
import InspectionsPublished from "../pages/notifications/InspectionsPublished";
import Settings from "../pages/settings/Settings";
import AcceptAgreement from "../pages/settings/Agreements/AcceptAgreement";
import RejectAgreement from "../pages/settings/Agreements/RejectAgreement";
import MyProfile from "../pages/settings/MyProfile/MyProfile";
import ChangePassword from "../pages/settings/MyProfile/ChangePassword";
import AgreementAlreadySigned from "../pages/settings/Agreements/AgreementAlreadySigned";
import PTCompanyList from "../pages/PTAdmin/Company/PTCompanyList";
import PTCompanyDetails from "../pages/PTAdmin/Company/PTCompanyDetails";
import Privacy from "../pages/privacy/Privacy";
import ReportSettings from "../pages/settings/ReportSettings/ReportSettings";
import PtSupportProfile from "../pages/settings/PTSupportProfile/PtSupportProfile";
import TemplateEditor from "../pages/templates/TemplateEditor";
import Payments from "../pages/settings/Payments/Payments";
import SignatureNeeded from "../pages/notifications/SignatureNeeded";
import AgreementSignedConfirmation from "../pages/notifications/AgreementSignedConfirmation";
import PaymentReminder from "../pages/notifications/PaymentReminder";
import PaymentRequest from "../pages/notifications/PaymentRequest";
import MapFullView from "../components/InspectionDetails/MapFullView";
import CommentLibrary from "../components/CommentLibrary/CommentLibrary";

// ----------------------------------------------------------------------------------------------------------
const Router = () => {
  return (
    <>
      <Switch>
        {/* public routes ****************************************************************************/}
        <Route path={ROUTES.HOME} exact element={<Templates />} />
        <Route path={ROUTES.SIGNUP} exact element={<Signup />} />
        <Route path={ROUTES.EDIT_TEMPLATE} exact element={<TemplateEditor />} />
        <Route path={ROUTES.RESET} exact element={<ResetPassword />} />
        <Route path={ROUTES.REPORT_SETTINGS} exact element={<ReportSettings />} />
        <Route path={ROUTES.MAP_VIEW} exact element={<MapFullView />} />
        <Route path={ROUTES.CREATE} exact element={<CreatePassword />} />
        <Route path={ROUTES.FORGOT} exact element={<ForgotPassword />} />
        <Route path={ROUTES.VIEW_AGREEMENT} exact element={<AgreementWebView />} />
        <Route path={ROUTES.ACCEPT_AGREEMENT} exact element={<AcceptAgreement />} />
        <Route path={ROUTES.COMMENT_LIBRARY} exact element={<CommentLibrary />} />
        <Route path={ROUTES.REQUEST_PAYMENT} exact element={<RequestPayment />} />
        <Route path={ROUTES.REJECT_AGREEMENT} exact element={<RejectAgreement />} />
        <Route path={ROUTES.AGREEMENT_ALREADY_SIGNED} exact element={<AgreementAlreadySigned />} />
        <Route path={ROUTES.PRIVACY_CONTENT} exact element={<Privacy />} />
        <Route path={ROUTES.FALLBACK} exact element={<PageNotFound type="404" message="Page not found" action="Redirect" />} />
        {/* protected routes************************************************************************* */}
        <Route path={ROUTES.AGENT_SCREEN} exact element={<ProtectedRoutes route={<Agents />} />} />
        <Route path={ROUTES.DASHBOARD_SCREEN} exact element={<ProtectedRoutes route={<Dashboard />} />} />
        <Route path={ROUTES.TEAMS} exact element={<ProtectedRoutes route={<Teams />} />} />
        <Route path={ROUTES.MY_PROFILE} exact element={<ProtectedRoutes route={<MyProfile />} />} />
        <Route path={ROUTES.CHANGE} exact element={<ProtectedRoutes route={<ChangePassword />} />} />
        <Route path={ROUTES.TEMPLATES} exact element={<ProtectedRoutes route={<Templates />} />} />
        <Route path={ROUTES.EDIT_AGENT} exact element={<ProtectedRoutes route={<EditAgents />} />} />
        <Route path={ROUTES.EDIT_TEMPLATE} exact element={<ProtectedRoutes route={<TemplateEditor />} />} />
        <Route path={ROUTES.METRICS_SCREEN} exact element={<ProtectedRoutes route={<Metrics />} />} />
        <Route path={ROUTES.CALENDER_SCREEN} exact element={<ProtectedRoutes route={<MyCalendar />} />} />
        <Route path={ROUTES.TEAMS_DETAILS} exact element={<ProtectedRoutes route={<TeamDetails />} />} />
        <Route path={ROUTES.SERVICES} exact element={<ProtectedRoutes route={<ServicesPage />} />} />
        <Route path={ROUTES.INSPECTION_LISTING} exact element={<ProtectedRoutes route={<Inspections />} />} />
        <Route path={ROUTES.NOTIFICATION_MENU} exact element={<ProtectedRoutes route={<Notifications />} />} />
        <Route path={ROUTES.APPOINTMENT_CONFIRMATION} exact element={<ProtectedRoutes route={<AppointmentConfirmation />} />} />
        <Route path={ROUTES.RESCHEDULE_CONFIRMATION} exact element={<ProtectedRoutes route={<RescheduleConfirmation />} />} />
        <Route path={ROUTES.SIGNATURE_NEEDED} exact element={<ProtectedRoutes route={<SignatureNeeded />} />} />
        <Route path={ROUTES.AGREEMENT_TO_SIGN} exact element={<ProtectedRoutes route={<AgreementToSign />} />} />
        <Route path={ROUTES.AGREEMENT_SIGNED_CONFIRMATION} exact element={<ProtectedRoutes route={<AgreementSignedConfirmation />} />} />
        <Route path={ROUTES.PAYMENT_MADE} exact element={<ProtectedRoutes route={<PaymentMade />} />} />
        <Route path={ROUTES.PAYMENT_REQUEST} exact element={<ProtectedRoutes route={<PaymentRequest />} />} />
        <Route path={ROUTES.PAYMENT_REMINDER} exact element={<ProtectedRoutes route={<PaymentReminder />} />} />
        <Route path={ROUTES.INSPECTION_PUBLISHED} exact element={<ProtectedRoutes route={<InspectionsPublished />} />} />
        <Route path={ROUTES.INSPECTION_DETAILS} exact element={<ProtectedRoutes route={<InspectionDetails />} />} />
        <Route path={ROUTES.SCHEDULE_INSPECTION} exact element={<ProtectedRoutes route={<ScheduleInspection />} />} />
        <Route path={ROUTES.COMPANY_PROFILE} exact element={<ProtectedRoutes route={<CompanyProfile />} />} />
        <Route path={ROUTES.AGREEMENTS} exact element={<ProtectedRoutes route={<AgreementsList />} />} />
        <Route path={ROUTES.AGREEMENTS_DETAILS} exact element={<ProtectedRoutes route={<AgreementsDetails />} />} />
        <Route path={ROUTES.SETTINGS} exact element={<ProtectedRoutes route={<Settings />} />} />
        <Route path={ROUTES.PAYMENTS} exact element={<ProtectedRoutes route={<Payments />} />} />
        {/* PT Admin pages ============================================================================== */}
        <Route path={ROUTES.PT_COMPANY} exact element={<ProtectedRoutes route={<PTCompanyList />} />} />
        <Route path={ROUTES.PT_COMPANY_DETAILS} exact element={<ProtectedRoutes route={<PTCompanyDetails />} />} />
        <Route path={ROUTES.PT_TEAMS} exact element={<ProtectedRoutes route={<Teams />} />} />
        <Route path={ROUTES.PT_TEAMS_DETAILS} exact element={<ProtectedRoutes route={<TeamDetails />} />} />
        <Route path={ROUTES.PT_SUPPORT_PROFILE} exact element={<ProtectedRoutes route={<PtSupportProfile />} />} />
      </Switch>
    </>
  );
};

export default Router;
