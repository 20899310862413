import { Box } from "@mui/system";
import { Grid, Paper, Skeleton } from "@mui/material";

const LoadingSkelton = () => {
  return (
    <>
      <Box className="inspections-main" sx={{ width: "100%", px: { xs: "0", sm: "20px", md: "80px", lg: "80px" }}}>
        <div className="inspection-header">
          <Grid sx={{ width: "50%" }}></Grid>
          <Skeleton className="inspections-new-button2" />
        </div>
        <Paper sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 5 }} className={"inspection-table-main"}>
          {Array.from({ length: 8 }, (element, index) => (
            <Skeleton key={index} sx={{ ml: 1.5, mr: 1.5, height: 80 }} animation="wave" />
          ))}
        </Paper>
      </Box>
    </>
  );
};
export default LoadingSkelton;

