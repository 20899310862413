import { API_METHODS } from '../../app/config/api-constants';
import axios from 'axios';

export const fetchCall = (callback, url, method, payload, ...args) => {
    new Promise((resolve, reject) => {
        let options = {};
        const headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${'f0b2f376-ed87-11ec-bdf6-34e6d7007df8'}`,
            // 'api-key' : 'Palmtech',
            'company-key':  sessionStorage.getItem('company-key'),
            // 'company-key': "testcompany"
        };

        if (method === API_METHODS.GET) {
            options = {
                method,
                headers,
                url
            };
        } else {
            options = {
                method,
                data: payload,
                headers,
                url
            };
        }

        axios(options)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                //Network error
                if (error.toJSON().message === 'Network Error') {
                    callback({ data: { success: false, message: 'No Internet Connection' } });
                }
                if (error.response) {
                    // Request made and server responded
                    callback(error.response);
                } else if (error.request) {
                    // The request was made but no response was received
                    callback(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    callback(error.message);
                }
            });
    });
}
export const uploadFiles = (callback, url, method, formData) =>
    new Promise((resolve) => {
        const ajax = new XMLHttpRequest();

        // ajax.upload.addEventListener('progress', progressHandler, false);
        ajax.upload.addEventListener('progress', callback, false);
        // ajax.addEventListener('load', callback, false);
        ajax.addEventListener('error', callback, false);
        ajax.addEventListener('abort', callback, false);

        ajax.onreadystatechange = function () {
            if (ajax.readyState === 4) {
                callback(JSON.parse(ajax.response));
            }
        };

        ajax.open(method, url); // http://www.developphp.com/video/JavaScript/File-Upload-Progress-Bar-Meter-Tutorial-Ajax-PHP
        // use file_upload_parser.php from above url

        // ajax.setRequestHeader(
        //   'auth_token'
        // );

        ajax.send(formData);
    });
    export const CommentLibraryFetchCall = (callback, url, method, payload, ...args) => {
        new Promise((resolve, reject) => {
            let options = {};
            const headers = {
                'Content-Type': 'application/json',
                'company-key' : sessionStorage.getItem('auth-key'),
            };
    
            if (method === API_METHODS.GET) {
                options = {
                    method,
                    headers,
                    url
                };
            } else {
                options = {
                    method,
                    data: payload,
                    headers,
                    url
                };
            }
    
            axios(options)
                .then((response) => {
                    callback(response);
                })
                .catch((error) => {
                    //Network error
                    if (error.toJSON().message === 'Network Error') {
                        callback({ data: { success: false, message: 'No Internet Connection' } });
                    }
                    if (error.response) {
                        // Request made and server responded
                        callback(error.response);
                    } else if (error.request) {
                        // The request was made but no response was received
                        callback(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        callback(error.message);
                    }
                });
        });
    }