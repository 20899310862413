// import
import moment from "moment";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { visuallyHidden } from "@mui/utils";
import { apiCall } from "../../utils/action";
import { useMediaQuery } from "react-responsive";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { GetCurrentResolution } from "../../utils/services";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  Grid,
  Table,
  Box,
  Paper,
  TableRow,
  TablePagination,
  Modal,
  InputBase,
  IconButton,
  TableHead,
  TableSortLabel,
  TableContainer,
  Typography,
  Alert,
} from "@mui/material";
import toast from "react-hot-toast";

//descending comparator
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//table row heading
const tableHead = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "NAME",
    showIcon: true,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "DESCRIPTION",
    showIcon: false,
  },
  {
    id: "publish_date",
    numeric: false,
    disablePadding: false,
    label: "LAST EDITED",
    showIcon: true,
  },
  {
    id: "",
    numeric: false,
    disablePadding: false,
    label: "DOWNLOAD",
    showIcon: false,
  },
];
// acsc / desc function
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
//sort function
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
//map funtion of table heading
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => (
          <td
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            className="activity-table-head "
          >
            <TableSortLabel
              style={{ flexDirection: "row" }}
              IconComponent={
                !headCell.showIcon
                  ? ""
                  : () => (
                      <UnfoldMoreIcon
                        style={{ marginRight: "6px" }}
                        className="sort-switch"
                      />
                    )
              }
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                marginTop: GetCurrentResolution() === "mobile" && "21px",
                marginBottom: "10.5px",
                flexDirection: "row-reverse",
                fontSize: "12px",
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </td>
        ))}
      </TableRow>
    </TableHead>
  );
}
//prop types
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
// search toolbar
const EnhancedTableToolbar = (props) => {
  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" } }}
        className="inspection-toolbar-main"
      >
        <Paper
          elevation={0}
          className={"inspection-search-field"}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: { xs: "241px", sm: "29.64%", md: "29.64%", lg: "29.64%" },
          }}
        >
          <IconButton sx={{ p: "10px" }} aria-label="search">
            <SearchIcon className="inspection-search-icon" />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1, fontSize: "16px" }}
            placeholder="Search template library"
            inputProps={{ "aria-label": "search" }}
            onChange={props.handleSearchFilter}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

const TemplateLibrary = ({
  openModal,
  handleClose,
  refresh,
  setRefresh,
  setShowLoader,
  data,
}) => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [table_data, setTableData] = useState(data);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [importing, setImporting] = useState(false);
  const [orderBy, setOrderBy] = useState("Calories");
  const [deleteControl, setDeleteControl] = useState(false);
  const [default_table_data, setTableDefaultData] = useState(data);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //select
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table_data.map((n) => n.triggered_by);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  // // init
  useEffect(() => {
    setTableData(data);
    setTableDefaultData(data);
  }, [data]);

  //handle click
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  //page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //row function
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //search function
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchFilter = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    if (query === "") {
      setTableData(default_table_data);
    } else {
      const searchData = default_table_data.filter((item) => {
        return item.name.toLowerCase().includes(query);
      });

      setTableData(searchData);
    }
  };

  //select
  const isSelected = (name) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - table_data.length) : 0;
  //is mobile trigger
  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });
  //import template
  const ImportTemplate = (guid, labelId) => {
    // handleClose();
    setShowLoader(true);
    const index = table_data.findIndex((obj) => obj.guid === guid);
    let tableData = table_data;
    if (index !== -1) tableData[index].downloaded = 1;
    setTableData(tableData);
    setImporting(labelId);
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      template_guid: guid,
    };
    apiCall(
      (response) => {
        // console.log("GET AGENT LIST", response);
        if (response.data.success) {
          // success
          toast.success(response.data.message);
          setRefresh(refresh + 1);
          setShowLoader(false);
          setImporting(false);
        } else {
          // console.log("else", response.data.message);
        }
      },
      formdata,
      "IMPORT_TEMP_LIB"
    );
  };

  //render function
  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={"inspection-filter-modal-main"}
    >
      <Box
        className={"inspection-filter-modal"}
        sx={{ maxWidth: "1043px !important", top: "40%" }}
      >
        <Grid container>
          <Grid item xs={12}>
            <CloseIcon
              sx={{ float: "right", cursor: "pointer" }}
              onClick={handleClose}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: "#333333",
                fontFamily: "open sans",
                fontSize: "22px",
                mt: "10px",
                letterSpacing: 0,
                wordWrap: "break-word",
                ml: "33px",
              }}
            >
              Template Library
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ pl: 5, pr: 5 }}>
            <Paper
              sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 0 }}
              className={"inspection-table-main"}
              elevation={0}
            >
              <EnhancedTableToolbar
                table_data={table_data}
                handleSearchFilter={handleSearchFilter}
              />
              {table_data.length !== 0 ? (
                <>
                  <TableContainer
                    className={isMobile && "mobileScroller"}
                    sx={{
                      overflowX: { sm: "auto", lg: "unset", md: "scroll" },
                    }}
                  >
                    <Table
                      aria-labelledby="tableTitle"
                      size={"small"}
                      style={{
                        transform: isMobile && "rotate(180deg)",
                        overflowX: "scroll",
                        width: "100",
                      }}
                    >
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={table_data.length}
                      />
                      <tbody>
                        {stableSort(table_data, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <tr
                                onClick={(event) => handleClick(event, row.id)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                className="inspections-data-table"
                              >
                                <td
                                  align="left"
                                  style={{
                                    paddingLeft: "20px",
                                    paddingRight: "0px",
                                    paddingTop: "20px",
                                    paddingBottom: "20px",
                                  }}
                                  id={labelId}
                                >
                                  <div
                                    style={{
                                      textAlign: "left",
                                      display: "flex",
                                      gap: "5px",
                                    }}
                                  >
                                    <PictureAsPdfOutlinedIcon />
                                    <span
                                      className="date-placeholder"
                                      style={{
                                        width: "200px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {row.name}
                                    </span>
                                  </div>
                                </td>
                                <td
                                  align="left"
                                  style={{
                                    paddingLeft: "15px",
                                    paddingRight: "0px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <span className="agent-row-data-font">
                                    <p
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: "#333333",
                                        letterSpacing: 0,
                                        lineHeight: "20px",
                                        width: "250px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {row.description &&
                                      row.description !== "null"
                                        ? row.description
                                        : ""}
                                    </p>
                                  </span>
                                </td>
                                <div
                                  style={{
                                    textAlign: "left",
                                    paddingTop: "10px",
                                  }}
                                >
                                  <span className="date-placeholder">
                                    {moment(
                                      moment.utc(row?.publish_date).local()
                                    ).format("L")}
                                  </span>
                                  <span
                                    className="time-modified-placeholder"
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {moment(
                                      moment.utc(row?.publish_date).local()
                                    ).fromNow()}
                                  </span>
                                </div>
                                <td
                                  align="left"
                                  style={{
                                    width: "88px !important",
                                    paddingRight: "0px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                  }}
                                  id={labelId}
                                >
                                  {/* <div style={{ width: "88px !important", display: "flex", alignItems: "center", justifyContent: "center" }}> */}
                                  {importing === labelId ? (
                                    <p
                                      style={{
                                        fontSize: "11px",
                                        color: "#999999",
                                        fontWeight: 600,
                                      }}
                                    >
                                      DOWNLOADING...
                                    </p>
                                  ) : row?.downloaded === 1 ? (
                                    <p
                                      style={{
                                        fontSize: "13px",
                                        color: "#999999",
                                        fontWeight: 600,
                                      }}
                                    >
                                      DOWNLOADED
                                    </p>
                                  ) : row.downloaded === 0 ? (
                                    <FileDownloadOutlinedIcon
                                      sx={{
                                        marginLeft: "25px",
                                        height: "25px",
                                        width: "25px",
                                        color: "#4282E2",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        ImportTemplate(row.guid, labelId)
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {/* </div> */}
                                </td>
                              </tr>
                            );
                          })}
                        {emptyRows > 0 && (
                          <tr style={{ height: 33 * emptyRows }}>
                            <td colSpan={6} />
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 20]}
                    component="div"
                    count={table_data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <>
                  <div
                    style={{
                      borderLeft: "1px solid #E7E7E9",
                      borderRight: "1px solid #E7E7E9",
                      marginTop: "22px",
                    }}
                  >
                    {/* <Heading CancelledStatus={false} title="Payments" /> */}
                  </div>
                  <Paper
                    sx={{
                      width: "100%",
                      sm: 8,
                      mb: 4,
                      lg: 2,
                      textAlign: "center",
                    }}
                    elevation={0}
                    className={"inspection-table-main"}
                  >
                    {/* <EnhancedTableToolbar table_data={table_data} handleSearchFilter={handleSearchFilter} /> */}
                    <Alert severity="info">No Data Found</Alert>
                    {/* <p style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px", color: "#888888", height: "10vh" }}>No Data</p> */}
                  </Paper>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TemplateLibrary;
