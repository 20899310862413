import { useState,useEffect } from "react"
import { Box,Card,Grid, SvgIcon} from "@mui/material"
import Preloader from "../../helpers/Preloader"
import "../../../assets/styles/containers/_payments.scss";
import { guardianSvg } from "../../../assets/svgIcons/Svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AntSwitch from "../../common/antSwitch/AntSwitch";
import { apiCall } from "../../utils/action";
import toast from "react-hot-toast";
const PaymentSettings = () => {
    const uuId = sessionStorage.getItem("user_id");
    const companyId = sessionStorage.getItem("company_id");
    const roleId = sessionStorage.getItem("role_id");
    // states
    const [showLoader,setShowLoader] = useState(false);
    const [paymentStatus,setPaymentStatus] = useState(0)
    const [paymentDescription,setPaymentDescription] = useState('')
    const [paymentSetupStatus,setPaymentSetupStatus] = useState(0);
    const[statusChanged,setStatusChanged]= useState(false)
    // api calls
    const getPaymentInfo=()=>{
        setShowLoader(true);
        const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
        apiCall(
            (response) => {              
              const { data, message, success } = response.data;
              if (success) {
                // toast.success(message); 
                setPaymentStatus(data.payment?.payment_status)  
                setPaymentDescription(data.payment?.payment_status_description) 
                setPaymentSetupStatus(data.payment?.payment_setup_status)             
                setShowLoader(false);
              } else {
                 setShowLoader(false);
                 toast.error(message);
              }
            },
            formdata,
            "GET_COMPANY_INFO"
          );
    }
    const updatePayment=(status)=>{
        toast.dismiss();
        setShowLoader(true);
        const formdata = { company_id: companyId, user_id: uuId, role_id: roleId, payment_status:status };
        apiCall(
            (response) => {              
              const {message, success } = response.data;
              if (success) {
                toast.success(message);
                setStatusChanged(prev=>!prev)            
                setShowLoader(false);
              } else {
                 setShowLoader(false);
                 toast.error(message);
              }
            },
            formdata,
            "UPDATE_PAYMENT_INFO"
          );
    }
    const defaultSwitchValue=(toggleStatus)=>{
        updatePayment(toggleStatus)
    }
    useEffect(()=>{
        getPaymentInfo();
    },[statusChanged])
    
  return (
    <Box sx={{ flexGrow: 1 }} className="page-background">
    <Preloader showPreloader={showLoader} />
    {showLoader ? (
         ""
        ) : (
    <Box
          sx={{
            width: "100%",
            px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },
          }}
          className="teams-container"
        >
            <Grid item xs={12} className="guardian-title-wrapper">
            <div>
              <h3 className="guardian-title">Payments</h3>
              <p className="payments-subtitle">
              Set up how you get paid. If you disable payments, you will not be paid directly through our services. {" "}
              </p>
            </div>
          </Grid>
          <Grid container>
              <Grid item xs={12} sm={5}>
                {/* default toggle button */}
          {paymentSetupStatus !== 0 && 
          <div className="default_toggler mb-23 guardian__toggle">
            <div className="payment__toggle"> 
             <AntSwitch defaultValue={paymentStatus !== 0?true:false} defaultSwitchValue={defaultSwitchValue}/><span className="payment__toggle__status">Payments are {paymentDescription ==="Disabled"?'disabled':'enabled'}. </span>
            </div> 
          </div>
}
                <Card className="settings-card guardian" id={`guardian_settings`} name={`guardian_settings`} variant="outlined" 
                // onClick={()=>{permission && navigate(`${navigatePath}`)}} style={{cursor:`${!permission && 'not-allowed'}`}}
                >     
                <Grid container className="details-cards">
                    <Grid className="guardian__svg__header">
                    <div className="guardian__svg"><SvgIcon viewBox="" >{guardianSvg}</SvgIcon></div>
                    {paymentSetupStatus!==0 && paymentStatus !==0 &&
                    <span>{paymentDescription} {paymentStatus === 2 && <CheckCircleIcon/>}</span>
}
                    </Grid>
                    <Grid className='name-wrapper'>
                    <span className='guardian__desc'>{
                        paymentStatus===0?"Set up payment processing with Guardian Inspection Payments.":"Payments will be sent to you through Guardian Inspection Payments."
                    }</span>
                    </Grid>
                    <Grid className='guardian__action'>
                        <span>
                        { paymentStatus===0?
                        <a
                        className="navlink guardian"
                        target="_blank"
                        href={`https://cardconnect.com/signup`}
                        onClick={()=>updatePayment(1)}
                      >
                        <span> Set up now</span>
                      </a>                        
                       :                     
                       <a
                       className="navlink guardian"
                       target="_blank"
                       href={`https://cardpointe.cardconnect.com/`}
                     >
                       <span> Go to Guardian</span>
                     </a>}
                       
                        </span> <span className="arrow"><KeyboardArrowRightIcon/></span></Grid>       
                </Grid>
                </Card>
            </Grid>
          </Grid>          
    </Box>
    )}
    </Box>
  )
}

export default PaymentSettings
