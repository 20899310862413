import React from "react";
import { useDispatch } from "react-redux";
import "../../../assets/styles/containers/_commentButtonGroup.scss";
import { getCommentById } from "../../store/action/CommentLibraryAction";

const ButtonGroup = (props) => {
  const dispatch = useDispatch();
  const { setState, row } = props;

  const handleEdit = async () => {
    setState((prevState) => ({ ...prevState, showLoader: true }));
    const response = await dispatch(getCommentById(row.id));
    const { tags, title, comment_text, id, summaries, summary_comment_text, comment_dropdowns, summary_comment_dropdowns } = response;
    if (response) {
      const transformedCommentData = comment_dropdowns.map((item) => ({ ...item, dropdowns_values: item.dropdowns_values.values }));
      const transformedSummaryData = summary_comment_dropdowns.map((item) => ({ ...item, dropdowns_values: item.dropdowns_values.values }));
      setState((prevState) => ({
        ...prevState,
        openAddDialog: true,
        isEdit: true,
        commentTag: tags?.filter((item) => item !== "")?.map((tag) => tag.tag) || [],
        commentTitle: title || "",
        commentText: { innerHTML: comment_text },
        selectedComment: response,
        commentSummary: { innerHTML: summary_comment_text },
        commentSummaryTag: summaries?.filter((item) => item !== "")?.map((summary) => summary.summary),
        comment_id: id,
        showLoader: false,
        commentDropdownList: transformedCommentData,
        summaryDropdownList: transformedSummaryData,
      }));
    }
  };

  const handleDelete = () => {
    setState((prevState) => ({ ...prevState, openDeleteDialog: true, comment_id: row.id }));
  };

  return (
    <div className="button_group_container">
      <div className="button_group_tab" onClick={() => handleEdit()}>
        <svg width="15.23" height="15.21" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2 17H3.4L12.025 8.375L10.625 6.975L2 15.6V17ZM16.3 6.925L12.05 2.725L13.45 1.325C13.8333 0.941667 14.3043 0.75 14.863 0.75C15.421 0.75 15.8917 0.941667 16.275 1.325L17.675 2.725C18.0583 3.10833 18.2583 3.571 18.275 4.113C18.2917 4.65433 18.1083 5.11667 17.725 5.5L16.3 6.925ZM14.85 8.4L4.25 19H0V14.75L10.6 4.15L14.85 8.4Z"
            fill="#454545"
          />
        </svg>
      </div>
      <div className="button_group_delete" onClick={() => handleDelete()}>
        <svg width="14" height="15" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 18C2.45 18 1.97933 17.8043 1.588 17.4125C1.196 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.0208 14.4125 17.4125C14.0208 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
            fill="#D63D3D"
          />
        </svg>
      </div>
    </div>
  );
};

export default ButtonGroup;

// <div className="button_group_tab">
// <svg width="15.33" height="18.33" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
// {/* Return path for the second tab icon */}
// <path d="M17 18H6C5.45 18 4.97917 17.8042 4.5875 17.4125C4.19583 17.0208 4 16.55 4 16V2C4 1.45 4.19583 0.979167 4.5875 0.5875C4.97917 0.195833 5.45 0 6 0H13L19 6V16C19 16.55 18.8042 17.0208 18.4125 17.4125C18.0208 17.8042 17.55 18 17 18ZM12 7V2H6V16H17V7H12ZM2 22C1.45 22 0.979167 21.8042 0.5875 21.4125C0.195833 21.0208 0 20.55 0 20V6H2V20H13V22H2Z" fill="#454545" />
// </svg>
// </div>
