import React from "react";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const SectionMenu = (props) => {
  const {
    linedata,
    handleOpen,
    from,
    isInspectionLines,
    handleClose,
    OpenEditableMenu,
    RenameExisting,
    DeleteLines,
    duplicateCateOrLine,
    isInspection,
    removeLine,
    AllCategoryList,
  } = props;

  const MenuItems = [
    {
      title: "Edit",
      icon: <EditIcon />,
      clickEvent: (e) => OpenEditableMenu(e),
    },
    {
      title: "Rename",
      icon: <TextFieldsIcon />,
      clickEvent: (e) => RenameExisting(e),
    },
    {
      title: "Duplicate",
      icon: <ContentCopyIcon />,
      clickEvent: (e) => duplicateCateOrLine(e),
    },
    {
      title: "Remove Form Element",
      icon: <RemoveCircleOutlineRoundedIcon />,
      clickEvent: (e) => removeLine(e),
    },
    {
      title: "Delete",
      icon: <DeleteIcon />,
      clickEvent: (e) => DeleteLines(e),
    },
  ];

  if (
    isInspection &&
    linedata.hasOwnProperty("type") &&
    linedata["type"] === "CheckBox"
  ) {
    console.log("The key-value pair exists.");
  } else {
    console.log("The key-value pair does not exist.");
  }

  isInspection === 1 && MenuItems.splice(2, 2);
  isInspection === 1 && MenuItems.splice(0, 1);
  isInspectionLines && MenuItems.splice(0, 1);
  from.split("_")[0] === "additional" && MenuItems.splice(0, 1) && MenuItems.splice(1, 1);
  from.split("_")[0] === "comments" && MenuItems.splice(0, 1) && MenuItems.splice(1, 1);
  return (
    // <ClickAwayListener onClickAway={(e) => handleClose(e)}>
    //   <Grid onClick={(e) => handleOpen(e)} className="sectionMenu">
    //     <CloseIcon
    //       onClick={(e) => handleClose(e)}
    //       className="sectionMenu__close"
    //     />
    //     <div className="sectionMenu__items">
    //       {MenuItems.map((items, index) =>
    //         items.title === "Remove Form Element" &&
    //         from.split("_")[0] !== "additional" ? null : (
    //           <div style={{ paddingBottom: "16px" }}>
    //             {items.icon}
    //             <span
    //               onClick={() => items.clickEvent()}
    //               className="sectionMenu__items__text"
    //             >
    //               {items.title}
    //             </span>
    //           </div>
    //         )
    //       )}
    //     </div>
    //   </Grid>
    // </ClickAwayListener>
    <ClickAwayListener onClickAway={(e) => handleClose(e)}>
      <Grid onClick={(e) => handleOpen(e)} className="sectionMenu">
        <CloseIcon
          onClick={(e) => handleClose(e)}
          className="sectionMenu__close"
        />
        <div className="sectionMenu__items">
         {MenuItems.map((items, index) =>
            (items.title === "Remove Form Element" &&
            from.split("_")[0] !== "additional") ||(items.title === "Remove Form Element" && AllCategoryList?.template?.is_texas)  ? null : (
              <div style={{ paddingBottom: "16px" }} key={index}  onClick={() => items.clickEvent()}>
                {items.icon}
                <span
                  className="sectionMenu__items__text"
                >
                  {items.title}
                </span>
              </div>
            )
          )}
        </div>
      </Grid>
    </ClickAwayListener>
  );
};

export default SectionMenu;
