import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Grid, Button } from "@mui/material";
import Box from "@mui/material/Box";
import { apiCall } from "../../utils/action";
// import { defaultTemplateValue } from "../";
import Placeholder from '../placeholder/Placeholder'
// editor components for draft js module
import { EditorState, Modifier, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import Preloader from "../../helpers/Preloader";

const EmailTemplate = ({title,subTitle,templateFor}) => {
  const [agreementBody, setAgreementBody] = useState('');
  const initialState = () =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(agreementBody))
    );
  const [editorState, setEditorState] = useState(initialState);
  const [convertedContent, setConvertedContent] = useState(null);
  const [showLoader, setShowLoader] = useState();
   // session datas
   const companyId = sessionStorage.getItem("company_id");
   const roleId = sessionStorage.getItem("role_id");
   const uuId = sessionStorage.getItem("user_id");

   const navigate = useNavigate();

   // merge contents functions
   const sendTextToEditor = (text) => {
    setEditorState(insertText(text, editorState));
  };
  const insertText = (text, editorValue) => {
    const currentContent = editorValue.getCurrentContent();
    const currentSelection = editorValue.getSelection();
    const newContent = Modifier.replaceText(currentContent,currentSelection,text);
    const newEditorState = EditorState.push(editorValue,newContent,"insert-characters");
    return EditorState.forceSelection(newEditorState,newContent.getSelectionAfter());
  };
   // merge content end
   const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
   const onChange = async (value) => {
    setEditorState(value);
    convertContentToHTML();
  };

  // API CALLS
  // get template API
  const getAppointmentConfirmationTemplate =()=>{
    setShowLoader(true);
    const formdata = { company_id: companyId,user_id:uuId, role_id:roleId, template_for:templateFor };
    apiCall((response) => {
        console.log("GET_EMAIL_TEMPLATE", response);
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          setEditorState(() =>
            EditorState.createWithContent(ContentState.createFromBlockArray(
                convertFromHTML(data[0]?.email_template_body)
              ))
          );
          setConvertedContent(data[0]?.email_template_body);
          setAgreementBody(data[0]?.email_template_body);
          setShowLoader(false);
        } else {
          toast.error(message);
          setShowLoader(false);
          console.log("else ", message);
        }
      },
      formdata,
      "GET_EMAIL_TEMPLATE"
    );
  }

  // save or edit template api
const saveTemplate=()=>{
  let currentDefaultContentAsHTML = convertToHTML(editorState.getCurrentContent());
  toast.dismiss();
  setShowLoader(true);
  const formdata = { company_id: companyId,user_id:uuId, role_id:roleId,
                     template_for:templateFor,email_body:convertedContent};
  apiCall((response) => {
      console.log("EDIT_EMAIL_TEMPLATE", response);
      const { message, success } = response.data;
      if (success) {
        toast.success(message);
        setShowLoader(false);
        navigate(`/notifications`);
      } else {
        toast.error(message);
        setShowLoader(false);
        console.log("else ", response.data.message);
      }
    },
    formdata,
    "EDIT_EMAIL_TEMPLATE"
  );
}

// send test mail
const sendTestMail =()=>{
  toast.dismiss();
  setShowLoader(true);
  const formdata = { company_id: companyId,user_id:uuId, role_id:roleId,
                     template_for:templateFor,email_body:convertedContent};
  apiCall((response) => {
      console.log("SEND_TEST_EMAIL", response);
      const { message, success } = response.data;
      if (success) {
        toast.success(message);
        setShowLoader(false);
      } else {
        toast.error(message);
        setShowLoader(false);
      }
    },
    formdata,
    "SEND_TEST_EMAIL"
  );
}

// reset template api
const resetTemplate=()=>{
  toast.dismiss();
  setShowLoader(true);
  const formdata = { company_id: companyId,user_id:uuId, role_id:roleId,
                     template_for:templateFor};
  apiCall((response) => {
      console.log("RESET_MAIL_TEMLATE", response);
      const {data, message, success } = response.data;
      if (success) {
        toast.success(message);
        setEditorState(() =>
        EditorState.createWithContent(ContentState.createFromBlockArray(
            convertFromHTML(data?.email_template_body)
          ))
      );
      setConvertedContent(data?.email_template_body);
      setAgreementBody(data?.email_template_body);
        setShowLoader(false);
      } else {
        toast.error(message);
        setShowLoader(false);
      }
    },
    formdata,
    "RESET_MAIL_TEMLATE"
  );
}

 useEffect(()=>{
    getAppointmentConfirmationTemplate()
 },[])
  

  return (
    <>
      <Box sx={{width: "100%",px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },}}
              className="teams-container">
        <Preloader showPreloader={showLoader} />
        <Grid item xs={12} className="editor-title-wrapper">
          <div>
            <h3 className="editor-title color3B">{title}</h3>
            <p className="editor_details_subtitle">{subTitle} </p>
          </div>
        </Grid>       
        <Grid container spacing={4}>
          <Grid item xs={12} sm={9}>
            <Editor
              editorState={editorState}
              defaultEditorState={editorState}
              // onEditorStateChange={handleEditorChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              onEditorStateChange={(value) => {
                onChange(value);
              }}
              toolbar={
                {
                  options: ["inline", "fontSize", "fontFamily","list","textAlign","colorPicker","link","embedded","image", "remove",],
                  inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline"],
                  },
                }
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Placeholder sendTextToEditor={sendTextToEditor} setShowLoader={setShowLoader}/>
          </Grid>
          <Grid item xs={12} className="agreement-action">
            <Button className="agreement-btn editor-btn" onClick={saveTemplate}>
              Save
            </Button>
            <Button className="agreement-btn editor-btn" onClick={sendTestMail} >
              Send test
            </Button>
            <span className="navlink" onClick={resetTemplate} >
              <span>Reset to default</span>
            </span>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default EmailTemplate