import React from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AgreementActionCard from "./AgreementActionCard";

const AgreementAlreadySigned = () => {
  return (
    <>
      <AgreementActionCard
        icon={<CheckCircleOutlineIcon style={{ color: "#239d80" }} />}
        title={"This Agreement is already signed."}
        content={""}
        email={""}
      />
    </>
  );
};

export default AgreementAlreadySigned;
