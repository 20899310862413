import React from 'react';
import Breadcrumb from '../../common/header/Breadcrumb';
import Header from '../../common/header/Header';

function Metrics() {
  return <div>
    <Header/>
    <Breadcrumb/>
    This is metrics
  </div>;
}

export default Metrics;
