import { API_CONSTANTS, API_METHODS } from '../config/api-constants';
import { fetchCall, CommentLibraryFetchCall } from './ajax';

// post methord call
export const apiCall = (callback, payload,end_point_key) => {
    const url = `${API_CONSTANTS[end_point_key]}`;
    const method = API_METHODS.POST;
    return fetchCall(response => callback(response),
      url,
      method,
      payload
    );
  };
// for pagination
  export const apiCallPaginated = (callback, payload,end_point_key,pageCount) => {
    const url = `${API_CONSTANTS[end_point_key]}?page=${pageCount}`;
    const method = API_METHODS.POST;
    return fetchCall(response => callback(response),
      url,
      method,
      payload
    );
  };

  export const commentListApiPaginated = (callback, payload,end_point_key,pageCount) => {
    const url = `${API_CONSTANTS[end_point_key]}?page=${pageCount}`;
    const method = API_METHODS.GET;
    return CommentLibraryFetchCall(response => callback(response),
      url,
      method,
      payload
    );
  };

  export const commentListApiPageCount = (callback, payload,end_point_key,pageCount) => {
    const url = `${API_CONSTANTS[end_point_key]}?page_count=${pageCount}`;
    const method = API_METHODS.GET;
    return CommentLibraryFetchCall(response => callback(response),
      url,
      method,
      payload
    );
  };

  export const CommentListApiCallGet = (callback, payload,end_point_key) => {
    const url = `${API_CONSTANTS[end_point_key]}`;
    const method = API_METHODS.GET;
    return fetchCall(response => callback(response),
      url,
      method,
      payload
    );
  };

// get method call
export const apiCallGet = (callback, payload,end_point_key) => {
    const url = `${API_CONSTANTS[end_point_key]}`;
    const method = API_METHODS.GET;
    return fetchCall(response => callback(response),
      url,
      method,
      payload
    );
  };

export const apiCallPut = (callback, payload,end_point_key) => {
    const url = `${API_CONSTANTS[end_point_key]}`;
    const method = API_METHODS.PUT;
    return fetchCall(response => callback(response),
      url,
      method,
      payload
    );
  };
export const apiCallDelete = (callback, payload,end_point_key) => {
    const url = `${API_CONSTANTS[end_point_key]}`;
    const method = API_METHODS.DELETE;
    return fetchCall(response => callback(response),
      url,
      method,
      payload
    );
  };