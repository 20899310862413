import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SectionMenu from "./SectionMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { BootstrapTooltip, timestamp } from "../../utils/services";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MainTemplateModal from "../../components/TemplateEditors/TemplateModals/MainTemplateModal";
import EditCategorys from "../../components/TemplateEditors/TemplateModals/EditCategorys";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import toast from "react-hot-toast";
import { apiCall } from "../../utils/action";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Skeleton } from "@mui/material";
import Preloader from "../../helpers/Preloader";
import { getCommentLibraryTagList, setShowCommentLibraryModal } from "../../store/action";
import { useDispatch, useSelector } from "react-redux";
import { filterSelectTagIntro } from "./CommentLibraryHelper";

const TemplateAccord = (props) => {
  const {
    disableDnD,
    from,
    HeaderTitle,
    innerTemplate,
    AllCategoryList,
    accordOpen,
    manualPrompt,
    recommended,
    selectedID,
    lineIndex,
    setlineLoader,
    linedata,
    isInspectionLines,
    lineId,
    lineComId,
    actions,
    duplicateIndex,
    skeletonLoad,
    setSkeletonLoad,
    updateTemplate,
    TriggerClick,
    isInspection,
    isChanged,
    renamingId,
    checkActiveFocus,
    renId,
    setduplicateLineId,
    activeRename,
    duplicateCategoryId,
    setDuplicateCategoryId,
    item,
    categoryId,
    categoryName,
    lineItem,
    LoadList,
  } = props;

  const initialTags = linedata?.narrative_categories_tags?.map((item) => ({
    id: item.tag.id,
    title: item.tag.name,
    color: item.tag.color,
  }));

  const [isTitleEdit, setIsTitleEdit] = useState(false);
  const [lineText, setLineText] = useState(HeaderTitle);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [isEditable, setIsEditable] = useState(false);
  const [name, setName] = useState();
  const [text, setText] = useState();
  const [opt, setOpt] = useState();
  const [commentTags, setCommentTags] = useState(initialTags);
  const [showLoader, setShowLoader] = useState(false);
  const [introDropdown, setIntroDropdown] = useState();
  const location = useLocation();
  const selectedTagId = commentTags?.map((data) => data?.id);
  const templateUuid = AllCategoryList?.template;
  const categoryList = AllCategoryList?.template?.categories;
  const categoriesUuid = categoryList?.find((cat) => cat?.category_id === linedata?.category_id);
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.commentLibrary);

  useEffect(() => {
    const initialTags = linedata?.narrative_categories_tags?.map((item) => ({
      id: item.tag.id,
      title: item.tag.name,
      color: item.tag.color,
    }));
    setCommentTags(initialTags);
  }, [linedata, setCommentTags]);
  
  const editableData = (name, introText, optional, dropdowns) => {
    setName(name);
    setText(introText);
    setOpt(optional);
    setIntroDropdown(dropdowns);
  };

  const OpenEditableMenu = () => {
    setIsEditable(!isEditable);
    if (AllCategoryList?.template?.is_narrative_template === 1 && !storeData?.clTagList) dispatch(getCommentLibraryTagList());
  };

  const closeEditableMenu = () => {
    if (from?.split("_")[0] === "CATEGORY") {
      const catList = AllCategoryList?.template?.categories;
      const categoriesData = catList?.findIndex((cat) => cat?.category_id === linedata?.category_id);
      catList[categoriesData].prompt = name;
      catList[categoriesData].introductory_text = filterSelectTagIntro(text) || "";
      catList[categoriesData].optional = opt;
      catList[categoriesData].introductory_text_dropdowns = introDropdown;
      catList[categoriesData].updated_at = timestamp();
      setShowLoader(true);
      updateTemplate("noToast", "", false, () => {
        addTagToCategory();
      });
      setIsEditable(!isEditable);
    }

    if (from?.split("_")[0] === "SUBCATEGORY") {
      const catList = AllCategoryList?.template?.categories;
      catList?.forEach((category) =>
        category?.lines?.forEach((lineValue) => {
          if (lineValue?.line_id === linedata?.line_id) {
            lineValue.prompt = name;
            lineValue.introductory_text = text;
            lineValue.optional = typeof opt === "boolean" ? (opt === true ? 1 : 0) : opt;
            lineValue.updated_at = timestamp();
            updateTemplate("noToast");
            setIsEditable(!isEditable);
          }
        })
      );
    }
  };

  const addTagToCategory = () => {
    setShowLoader(true);
    const formdata = {
      tag_ids: selectedTagId,
      category_id: categoryId,
      category_uuid: categoriesUuid?.uuid,
      template_guid: templateUuid?.guid,
    };
    apiCall(
      (response) => {
        const { data, message, success } = response?.data;
        setTimeout(() => {
          setShowLoader(false);
        }, 500);
      },
      formdata,
      "ADD_TAG_TO_CATEGORY"
    );
  };

  const handleFocus = (event) => {
    event.target.select();
    checkActiveFocus();
  };

  const AccordionEdges = (expanded) => {
    if (expanded?.split("_")[0] === "SUBCATEGORY") {
      return AllCategoryList?.template?.is_narrative_template === 1 ? "32px 64px" : "52px 64px";
    } else if (expanded?.split("_")[0] === "DEFAULTS") {
      return "10px 40px";
    } else if (expanded?.split("_")[0] === "comments") {
      return AllCategoryList?.template?.is_narrative_template === 1 ? "1px 64px 20px 64px" : "52px 64px";
    } else if (from?.split("_")[0] === "additional") {
      return "40px 40px 10px 40px";
    } else if (expanded?.split("_")[0] === "details") {
      return "0px";
    } else {
      return "0px";
    }
  };

  const BorderRadius = (from) => {
    if (from?.split("_")[0] !== "SUBCATEGORY") {
      return "4px";
    }
  };

  const BorderBottomAccord = (from, isOpen) => {
    if (from?.split("_")[0] === "SUBCATEGORY" && isOpen) {
      return "none !important";
    } else if (from?.split("_")[0] === "SUBCATEGORY" && !isOpen) {
      return "1px solid #e7e7e9 ";
    }
  };

  const RenameCategoryList = (e, name, id) => {
    if (name === null) {
      e.stopPropagation();
      renamingId(id);
      setIsTitleEdit(true);
      TriggerClick();
    }
  };

  const ChangeCategoryText = (e, from) => {
    setLineText(e.target?.value);
    if (from?.split("_")[0] === "CATEGORY") {
      const catList = AllCategoryList?.template?.categories;
      const categoriesData = catList?.findIndex((cat) => cat?.category_id === renId);
      catList[categoriesData].prompt = e.target?.value;
      catList[categoriesData].updated_at = timestamp();
    } else if (from?.split("_")[0] === "SUBCATEGORY") {
      const catList = AllCategoryList?.template?.categories;
      catList?.map((cat) =>
        cat?.lines?.map((line) => {
          if (line?.line_id === renId) {
            line.prompt = e.target?.value;
            line.updated_at = timestamp();
          }
        })
      );
    } else if (from?.split("_")[0] === "additional") {
      const catList = AllCategoryList?.template?.categories;
      catList?.map((cat) =>
        cat?.lines?.map((line) => {
          if (line?.line_id === lineId) {
            line?.line_input_controls?.map((ip) => {
              if (ip?.line_input_control_id === renId) {
                ip.prompt = e.target?.value;
                ip.updated_at = timestamp();
              }
            });
          }
        })
      );
    } else if (from?.split("_")[0] === "comments") {
      const catList = AllCategoryList?.template?.categories;
      catList?.map((cat) =>
        cat?.lines?.map((line) => {
          if (line?.line_id === lineComId) {
            line?.comments?.map((com) => {
              if (com?.comment_id === renId) {
                if (AllCategoryList?.template?.is_narrative_template) com.title = e.target?.value;
                else com.prompt = e.target?.value;
                com.updated_at = timestamp();
                com.is_mannual_prompt = 1;
              }
            });
          }
        })
      );
    }
  };

  const RenameExisting = (id) => {
    activeRename();
    renamingId(id);
  };

  const DeleteLines = (id, from) => {
    const catList = AllCategoryList?.template?.categories;
    if (from?.split("_")[0] === "SUBCATEGORY") {
      catList?.map((cat) =>
        cat?.lines?.map((line) => {
          if (line?.line_id === id) {
            line.is_deleted = 1;
            line.updated_at = timestamp();
            updateTemplate("showToast", "Line has been deleted", true);
          }
        })
      );
    } else if (from?.split("_")[0] === "CATEGORY") {
      catList?.map((cat) => {
        if (cat?.category_id === id) {
          cat.is_deleted = 1;
          cat.updated_at = timestamp();
          updateTemplate("showToast", "Category has been deleted", true);
        }
      });
    } else if (from?.split("_")[0] === "comments") {
      catList?.map((cat) =>
        cat?.lines?.map((line) => {
          line?.comments?.map((comm) => {
            if (comm?.comment_id === id) {
              comm.is_deleted = 1;
              comm.updated_at = timestamp();
              updateTemplate("noToast");
            }
          });
        })
      );
    } else if (from?.split("_")[0] === "additional") {
      catList?.forEach((catValue) =>
        catValue?.lines?.forEach((lineValue) => {
          if (lineValue?.line_id === lineId) {
            lineValue?.line_input_controls?.forEach((ip) => {
              if (ip?.line_input_control_id === id) {
                ip.is_deleted = 1;
                ip.updated_at = timestamp();
                updateTemplate("noToast");
              }
            });
          }
        })
      );
    }
  };

  const duplicateCateOrLine = (id, from) => {
    const catList = AllCategoryList?.template?.categories;
    const templateId = location?.state?.id;
    if (from?.split("_")[0] === "SUBCATEGORY") {
      let catId;
      let lineId;
      setduplicateLineId(lineIndex + 1);
      setlineLoader(true);
      catList?.filter((cat) =>
        cat?.lines?.filter((line) => {
          if (cat?.category_id === categoryId && line?.line_id === id) {
            catId = cat?.category_id;
            lineId = line?.uuid;
          }
        })
      );
      const formdata = {
        template_id: templateId,
        category_id: categoryId,
        line_uuid: lineId,
      };
      apiCall(
        (response) => {
          const { message, success } = response?.data;
          if (success) {
            toast.success(message);
            LoadList("noLoader");
          } else {
            toast.error(message);
          }
        },
        formdata,
        "DUPLICATE_LINE"
      );
    } else if (from?.split("_")[0] === "CATEGORY") {
      setSkeletonLoad(true);
      setDuplicateCategoryId(duplicateIndex + 1);
      const formdata = {
        template_id: templateId,
        category_id: id,
      };
      apiCall(
        (response) => {
          const { message, success } = response?.data;
          if (success) {
            toast.success(message);
            LoadList("noLoader");
          } else {
            toast.error(message);
          }
        },
        formdata,
        "DUPLICATE_CATEGORY"
      );
    }
  };

  const removeLine = (id, remove = 1) => {
    if (remove) setIsActive(false);
    else setIsActive(true);
    const catList = AllCategoryList?.template?.categories;
    catList?.map((cat) =>
      cat?.lines?.map((line) => {
        if (line?.line_id === lineId) {
          line?.line_input_controls?.map((ip) => {
            if (ip?.line_input_control_id === id) {
              ip.is_disabled = remove;
              ip.updated_at = timestamp();
              updateTemplate("noToast");
            }
          });
        }
      })
    );
  };

  const IsShadowing = (from) => {
    if (from?.split("_")[0] === "CATEGORY" && from?.split("_")[0] === "details") {
      return "-5px 6px 8px 1px #e1e1e1";
    } else if (from?.split("_")[0] === "CATEGORY" || from?.split("_")[0] === "details") {
      return "-5px 6px 8px 1px #e1e1e1";
    } else {
      return "none";
    }
  };

  const autosave = () => {
    if (from?.split("_")[0] === "CATEGORY") updateTemplate("showToast", "Category has been renamed", true);
    else if (from?.split("_")[0] === "SUBCATEGORY") updateTemplate("showToast", "Line has been renamed", true);
    else if (from?.split("_")[0] === "comments") updateTemplate("noToast");
    else updateTemplate("noToast");
  };

  // useEffect for rename
  useEffect(() => {
    setLineText(HeaderTitle);
  }, [updateTemplate]);

  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    document.addEventListener("mouseout", handleAccordionClick);
    return () => {
      document.removeEventListener("mouseout", handleAccordionClick);
    };
  }, []);
  const handleAccordionClick = (event) => {
    const editorCommentElement = document?.querySelector("#commentText .fr-element");
    const editorSummaryElement = document?.querySelector("#summaryText .fr-element");
    if (editorCommentElement && !editorCommentElement?.contains(event.target)) {
      editorCommentElement.blur();
    }
    if (editorSummaryElement && !editorSummaryElement?.contains(event.target)) {
      editorSummaryElement.blur();
    }
  };
  const NewTitle = () => {
    return (
      <>
        Untitled
        <span
          style={{
            position: "relative",
            top: "2px",
            left: "5px",
            cursor: item?.is_disabled ? "" : "pointer",
          }}
        >
          <EditIcon />
        </span>
      </>
    );
  };

  return (
    <>
      <Preloader showPreloader={showLoader} />
      <div
        style={{
          cursor: "auto",
          marginBottom: from?.split("_")[0] === "SUBCATEGORY" ? "0px" : from?.split("_")[0] === "DEFAULTS" ? "0" : "15px",
        }}
      >
        <div
          onClick={(event) => {
            dispatch(setShowCommentLibraryModal({ open: false, selectedLine: lineItem, selectedCategory: { id: categoryId, name: categoryName } }));
            event.stopPropagation();
            if (!item?.is_disabled) {
              setIsActive(!isActive);
              disableDnD && disableDnD(!isActive, selectedID);
            }
          }}
          style={{
            borderRadius: BorderRadius(from),
            boxShadow: IsShadowing(from),
            position: "relative",
            zIndex: isActive && from?.split("_")[0] === "CATEGORY" && "1",
            borderBottom: BorderBottomAccord(from, accordOpen),
          }}
          className="accordition"
        >
          <DragHandleIcon
            className="accordition__draggable"
            sx={{
              fontSize: "20px",
              color: isActive && linedata?.optional !== 1 ? "#4282E2" : "#333333",
            }}
          />
          <div
            className={isMobile ? "accordition__title__mobile" : "accordition__title"}
            style={{
              color: isActive && linedata?.optional !== 1 ? "#4282E2" : "#333333",
            }}
          >
            {(isTitleEdit === true && renId === selectedID && HeaderTitle === null) || (isChanged && selectedID === renId) ? (
              <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={(e) => autosave()}>
                <input
                  type="text"
                  name="title"
                  value={lineText}
                  onKeyDown={(e) => e.keyCode === 13 && autosave()}
                  onChange={(e) => {
                    ChangeCategoryText(e, from);
                  }}
                  onFocus={handleFocus}
                  autoFocus
                  className="accordion_empty"
                />
              </ClickAwayListener>
            ) : (
              <div
                onClick={(e) => (!item?.is_disabled ? RenameCategoryList(e, HeaderTitle, selectedID) : null)}
                style={{
                  width: isMobile ? "94%" : item?.type === "Additional Input" && !item?.is_disabled ? "98%" : "100%",
                  paddingLeft: isMobile && "15px",
                  fontStyle: linedata?.optional === 1 && "italic",
                  opacity: linedata?.optional === 1 && "0.5",
                  overflow: "hidden",
                  whiteSpace: item?.type === "Additional Input" && item?.is_disabled ? "unset" : "nowrap",
                  textOverflow: "ellipsis",
                  fontSize: from?.split("_")[0] === "SUBCATEGORY" || from?.split("_")[0] === "DEFAULTS" ? "14px" : "18px",
                  display: item?.type === "Additional Input" && !item?.is_disabled ? "block" : "flex",
                  alignItems: "center",
                  marginLeft: item?.type == "Additional Input" && !item?.is_disabled && "4%",
                }}
                className={item?.type !== "Additional Input" && item?.is_disabled ? "accordionTitleDisable" : item?.type === "Additional Input" && item?.is_disabled ? "additional_title_disabled" : ""}
              >
                {item?.type === "Additional Input" && item?.is_disabled ? (
                  HeaderTitle === null && item?.type === "Additional Input" && item?.is_disabled ? (
                    ""
                  ) : (
                    <Typography
                      className={item?.is_disabled ? "accordionTitleDisable" : "accordionTitle"}
                      style={{
                        width: HeaderTitle === null && from?.split("_")[0] !== "comments" ? "0px" : "180px",
                      }}
                    >
                      {item?.prompt}
                    </Typography>
                  )
                ) : from?.split("_")[0] === "comments" && manualPrompt !== undefined ? (
                  manualPrompt === 0 ? (
                    recommended === null ||
                    recommended
                      ?.replace(/<\/?[^>]+(>|$)/g, "")
                      ?.replace(/&nbsp;/g, " ")
                      ?.trim()?.length === 0 ? (
                      NewTitle()
                    ) : (
                      recommended?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/&nbsp;/g, " ")
                    )
                  ) : HeaderTitle === null || HeaderTitle === "" ? (
                    recommended === null ||
                    recommended
                      ?.replace(/<\/?[^>]+(>|$)/g, "")
                      ?.replace(/&nbsp;/g, " ")
                      ?.trim()?.length === 0 ? (
                      NewTitle()
                    ) : (
                      recommended?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/&nbsp;/g, " ")
                    )
                  ) : HeaderTitle?.length > 150 ? (
                    `${HeaderTitle?.substring(150, 0)}`
                  ) : (
                    HeaderTitle
                  )
                ) : HeaderTitle === null || HeaderTitle === "" ? (
                  recommended === null ||
                  recommended
                    ?.replace(/<\/?[^>]+(>|$)/g, "")
                    ?.replace(/&nbsp;/g, " ")
                    ?.trim()?.length === 0 ? (
                    NewTitle()
                  ) : (
                    recommended?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/&nbsp;/g, " ")
                  )
                ) : HeaderTitle?.length > 150 ? (
                  ` ${HeaderTitle?.substring(150, 0)}`
                ) : (
                  HeaderTitle
                )}
                {(HeaderTitle === null || HeaderTitle === "" ) && from?.split("_")[0] !== "comments" && "Untitled"}
                {linedata?.optional === 1 ? (
                  <span
                    style={{
                      position: "relative",
                      top: "3px",
                      left: "10px",
                      marginRight: "12px",
                    }}
                  >
                    <BootstrapTooltip title={<h1 className="font-13">Optional items do not appear in the report by default, but can easily be added</h1>}>
                      <VisibilityOffIcon
                        sx={{
                          fontSize: "15px",
                        }}
                      />
                    </BootstrapTooltip>
                  </span>
                ) : null}
                {(HeaderTitle === null || HeaderTitle === "" ) && from?.split("_")[0] !== "comments" && (
                  <span
                    style={{
                      position: "relative",
                      top: "2px",
                      left: "5px",
                      cursor: item?.is_disabled ? "" : "pointer",
                    }}
                  >
                    <EditIcon />
                  </span>
                )}
                {item?.is_disabled ? (
                  <Typography className="titleDisable" ml={2} mt={0.8}>
                    {`*This form element has been removed and will not be published.`}
                  </Typography>
                ) : null}
              </div>
            )}
          </div>
          {item?.is_disabled ? (
            <BootstrapTooltip title={<h1>Add form element</h1>}>
              <AddCircleOutlineRoundedIcon sx={{ color: "#777777" }} onClick={() => removeLine(item?.line_input_control_id, 0)} />
            </BootstrapTooltip>
          ) : (
            <>
              {isActive ? (
                <ExpandLessIcon sx={{ color: linedata?.optional !== 1 && "#4282E2" }} className="accordition__open" />
              ) : (
                <ExpandMoreIcon sx={{ color: isActive && linedata?.optional !== 1 ? "#4282E2 !important" : "#3B3B3B !important" }} className="accordition__open" />
              )}
              <div style={{ marginLeft: isMobile && "10px" }} className="accordition__menus">
                <MoreHorizIcon
                  onClick={(e) => {
                    actions?.OpenMenu(e, from);
                  }}
                  sx={{
                    fontSize: "20px",
                    color: isActive && linedata?.optional !== 1 ? "#4282E2" : "#3B3B3B",
                    width: "100%",
                  }}
                />
                {from !== "details" &&
                  (actions?.menu === true && actions?.ActiveId === from ? (
                    <SectionMenu
                      AllCategoryList={AllCategoryList}
                      from={from}
                      linedata={linedata}
                      isInspectionLines={isInspectionLines}
                      handleClose={actions?.CloseMenu}
                      handleOpen={actions?.OpenMenu}
                      isInspection={isInspection}
                      RenameExisting={() => RenameExisting(selectedID)}
                      DeleteLines={() => DeleteLines(selectedID, from)}
                      removeLine={() => removeLine(selectedID)}
                      OpenEditableMenu={OpenEditableMenu}
                      duplicateCateOrLine={() => duplicateCateOrLine(selectedID, from)}
                    />
                  ) : null)}
              </div>
            </>
          )}
        </div>
        {isActive && (
          <Box
            sx={{
              padding: {
                md: AccordionEdges(from),
                lg: AccordionEdges(from),
                xs: "10px ",
                sm: "10px ",
              },
            }}
            className="accordion-content"
          >
            <div>{innerTemplate}</div>
          </Box>
        )}
      </div>
      {skeletonLoad && duplicateIndex === duplicateCategoryId - 1 ? (
        <motion.div initial={{ opacity: 0, scale: 1, y: -50 }} animate={{ opacity: 1, scale: 1, y: 0 }} transition={{ duration: 0.5 }}>
          <Skeleton sx={{ height: "79px", marginTop: "-5px" }} />
        </motion.div>
      ) : (
        ""
      )}
      <MainTemplateModal
        createNewSite={isEditable}
        title={`Edit "${HeaderTitle ?? "Untitled"}"`}
        type={"EditCategory"}
        HandleCreateSite={OpenEditableMenu}
        actions={{
          AddSiteDetaill: () => closeEditableMenu(),
        }}
        innerTemplate={
          <>
            <EditCategorys
              linedata={linedata}
              AllCategoryList={AllCategoryList}
              from={from}
              setCommentTags={setCommentTags}
              commentTags={commentTags}
              editableData={(name, text, optional, dropdowns) => editableData(name, text, optional, dropdowns)}
            />
          </>
        }
      />
    </>
  );
};

export default TemplateAccord;
