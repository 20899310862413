import React from "react";
import "../../../assets/styles/containers/_scheduleInspection.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const ScheduleButtons = (props) => {
  const {
    isMobile,
    activeStep,
    navigate,
    handleNext,
    disableSchedule,
    disableSchedule2,
    disableSchedule3,
    ScheduleInspectionSteps,
    handleBack,
    handleDraft,
    location,
    ChargeValid
  } = props;

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  return (
    <>
      {!isMobile ? (
        <Grid container className="button-section-light">
          <Grid item xs={12} sx={{ marginBottom: "25px" }}>
            {activeStep === ScheduleInspectionSteps?.length - 1 ? (
              <Button
                variant="contained"
                className="default-btn-light"
                onClick={handleNext}
              >
                Schedule
              </Button>
            ) : (
              ""
            )}
            {activeStep === 0 ? (
              <Button
                variant="contained"
                className="default-btn-light"
                onClick={handleNext}
              >
                Next
              </Button>
            ) : (
              ""
            )}
          </Grid>

          {activeStep !== ScheduleInspectionSteps?.length - 1 ? (
            activeStep === 0 ? (
              ""
            ) : (
              <>
                <Grid item xs={6}>
                  <Button
                    className={
                      activeStep !== 0
                        ? "back-btn xs-btn"
                        : "back-btn-disabled xs-btn"
                    }
                    variant="outlined"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    <Typography className="back-btn-content">
                      <ArrowBackIosIcon fontSize="small" /> Back
                    </Typography>{" "}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    variant="contained"
                    className="default-btn xs-btn"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Grid>
              </>
            )
          ) : (
            <>
              <Grid item xs={6}>
                <Button
                  className={
                    activeStep !== 0
                      ? "back-btn xs-btn"
                      : "back-btn-disabled xs-btn"
                  }
                  variant="outlined"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <Typography className="back-btn-content">
                    <ArrowBackIosIcon fontSize="small" /> Back
                  </Typography>{" "}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className="default-btn draft-btn xs-btn"
                  variant="contained"
                  onClick={handleDraft}
                >
                  Save as draft
                </Button>
              </Grid>
            </>
          )}

          <Grid item xs={12} className="cancel-btn-wrapper">
            <Button
              variant="text"
              className="cancel-btn xs-btn"
              onClick={() => location.state?.calendar ? navigate("/calendar") : navigate("/inspections")}
            >
              <Typography>Cancel</Typography>{" "}
            </Button>
          </Grid>
        </Grid>
      ) : (
        // desktop screen button
        <Grid className="button-section">
          <Button
            className={activeStep !== 0 ? "back-btn" : "back-btn-disabled"}
            variant="outlined"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            <Typography className="back-btn-content">
              <ArrowBackIosIcon fontSize="small" /> Back
            </Typography>{" "}
          </Button>
          <div>
            <Button
              variant="text"
              className="cancel-btn"
              onClick={() => location.state?.calendar ? navigate("/calendar") : navigate("/inspections")}
            >
              <Typography>Cancel</Typography>{" "}
            </Button>
            {activeStep === ScheduleInspectionSteps?.length - 1 ? (
              <Button
                // disabled={!(disableSchedule && disableSchedule2 && disableSchedule3)}
                className={"default-btn draft-btn"}
                variant="contained"
                onClick={handleDraft}
              >
                Save as draft
              </Button>
            ) : (
              ""
            )}

            {activeStep === ScheduleInspectionSteps?.length - 1 ? (
              <BootstrapTooltip
                title={
                  !(disableSchedule && disableSchedule2 && disableSchedule3) || (ChargeValid) ? (
                    <h1>{`You cannot schedule an inspection without selecting required fields`}</h1>
                  ) : (
                    ""
                  )
                }
              >
                <span className="disable-btn-wrapper">
                  <Button
                    disabled={!(disableSchedule && disableSchedule2 && disableSchedule3) || (ChargeValid === true && true)}
                    variant="contained"
                    className={
                      (disableSchedule && disableSchedule2 && disableSchedule3) ?
                        "default-btn" : ChargeValid ? "disabled-btn"
                          : "disabled-btn"
                    }
                    onClick={handleNext}
                    type="submit"
                  >
                    Schedule
                  </Button>
                </span>
              </BootstrapTooltip>
            ) : (
              <Button
                variant="contained"
                className="default-btn"
                onClick={handleNext}
              >
                Next
              </Button>
            )}
          </div>
        </Grid>
      )}
    </>
  );
};

export default ScheduleButtons;
