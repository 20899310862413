//file import********************************************************
import moment from "moment";
import { Box } from "@mui/system";
import Avatar from "react-avatar";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import CustomToolbar from "./CustomToolbar";
import { useNavigate } from "react-router-dom";
import Header from "../../common/header/Header";
import Preloader from "../../helpers/Preloader";
import CustomDateHeader from "./CustomDateHeader";
import CloseIcon from "@mui/icons-material/Close";
import "../../../assets/styles/calendar/style.css"; //calendar container style from react-big-calendar
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Breadcrumb from "../../common/header/Breadcrumb";
import "../../../assets/styles/containers/_calendar.scss"; //calendar scss
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { apiCall } from "../../pages/settings/Services/action";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Button,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  Grid,
  FormControlLabel,
  Modal,
  Switch,
  Stack,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material"; //mui import

//render function******************************************************
const MyCalendar = () => {
  let events = [];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 6;
  let navigate = useNavigate();
  const localizer = momentLocalizer(moment);
  const matches = useMediaQuery("(min-width:600px)");
  //dialog box
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);
  //modal function
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [eventId, setEventId] = useState("");
  //all day event toggle
  const [allDay, setAllDay] = useState(false);
  //change flag
  const [changed, setChanged] = useState(false);
  //loader toggle
  const [showLoader, setShowLoader] = useState(false);
  //validation check
  const [errors, setErrors] = useState({});
  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  //view
  const [view, setView] = useState("month");
  //all inspector details for the dropdown
  const [currentDate] = useState(new Date());
  const [allInspector, setAllInspector] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [filterToggle, setFilterToggle] = useState(false);
  const [screenName, setScreenName] = useState("Schedule");
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [refreshControl, setRefreshControl] = useState(false);
  const [selectedInspector, setSelectedInspector] = useState("");
  const [startDateTime, setStartDateTime] = useState(new Date());
  //details for event
  const [eventDetails, setEventDetails] = useState({
    name: "",
    description: "",
  });

  // HANDLE EVENTS********************************************

  // handle submit function
  const handleformSubmit = (event) => {
    event.preventDefault();
    setErrors(validation());
    setDataIsCorrect(true);
  };
  //handle selected inspector
  const handleInspector = (event) => {
    setChanged(true);
    setSelectedInspector(event.target.value);
    setErrors({ ...errors, selectedInspector: "" });
  };
  //handle close
  const handleClose = () => {
    setErrors({});
    setOpen(false);
    setAllDay(false);
    setChanged(false);
    setSelectedInspector("");
    setScreenName("Schedule");
    setEndDateTime(new Date());
    setStartDateTime(new Date());
    setEventDetails({ name: "", description: "" });
  };
  //start date change
  const handleStartDateTime = (newValue) => {
    setChanged(true);
    setStartDateTime(newValue);
  };
  //end date change
  const handleEndDateTime = (newValue) => {
    setChanged(true);
    setEndDateTime(newValue);
  };
  // handle change function
  const handleChange = (event) => {
    setEventDetails({
      ...eventDetails,
      [event.target.name]: event.target.value,
    });
    setChanged(true);
  };
  //handle all day toggle
  const handleAllDayEvent = (event) => {
    setChanged(true);
    setAllDay(event.target.checked);
  };
  //handle filter
  const handleFilter = (event) => {
    setFilterToggle(event.target.checked);
    if (event.target.checked) {
      getCalendarDetails("1");
    } else {
      getCalendarDetails("0");
    }
  };
  //handle selection of event
  const selectEvent = (event) => {
    if (event.type === "Event") {
      setOpen(true);
      setChanged(false);
      setEventId(event.id);
      setScreenName("Edit");
      setAllDay(event.isAllDay);
      setEndDateTime(event.end);
      setStartDateTime(event.start);
      setSelectedInspector(event.selectedInspector);
      setEventDetails({ name: event.title, description: event.description });
    } else if (event.type === "Inspection") {
      navigate(`/schedule-inspection/?id=${event.inspectionId}`, {
        state: { calendar: true },
      });
    } else return;
  };

  // validation function************************************************

  const validation = () => {
    let errors = {};
    if (!eventDetails.name.trim()) {
      errors.name = "Event name is required.";
    }
    if (selectedInspector === "") {
      errors.selectedInspector = "Inspector is required";
    }
    if (startDateTime === "" || startDateTime === null) {
      errors.startDateTime = "Start date is required";
    }
    if (startDateTime.getTime() > endDateTime.getTime()) {
      errors.endDateTime = "Please choose a correct date range";
      errors.startDateTime = "Please choose a correct date range";
    }
    return errors;
  };

  // validation check***************************************************

  useEffect(() => {
    if (Object.keys(errors).length === 0 && dataIsCorrect && changed) {
      screenName === "Schedule" ? addEvent() : editEvent();
    }
    // eslint-disable-next-line
  }, [errors]);

  //event data (inspection)
  calendarData?.inspections?.forEach((value) =>
    events.push({
      opacity: 1,
      fgColor: "white",
      title: value.name,
      type: "Inspection",
      fontColor: "#3B3B3B",
      status: value.status,
      primaryColor: view === "week" || view === "day" ? "#F1F3F4" : "#fff",
      inspectionId: value.order_id,
      name: value.userdetails?.first_name,
      inspectorDetails: value.inspectordetails,
      profilePic: value.userdetails?.profile_pic,
      secondaryColor: value.userdetails?.user_primary_color,
      end: new Date(moment.utc(value?.working_end_time).local()),
      start: new Date(moment.utc(value?.working_start_time).local()),
    })
  );
  //event data (user events)
  calendarData?.events?.forEach((value) =>
    events.push({
      status: "",
      id: value.id,
      opacity: 0.8,
      type: "Event",
      title: value.name,
      fgColor: "#3B3B3B",
      fontColor: "#white",
      isAllDay: value.is_all_day,
      description: value.description,
      name: value.userdetails?.first_name,
      selectedInspector: value.inspector_id,
      profilePic: value.userdetails?.avatar,
      inspectorDetails: value.inspectordetails,
      end: new Date(moment.utc(value?.end_date).local()),
      primaryColor: value.inspectordetails?.user_primary_color,
      start: new Date(moment.utc(value?.start_date).local()),
      secondaryColor: value.inspectordetails?.user_secondary_color,
    })
  );

  //style for select menu item inspector
  const MenuProps = {
    PaperProps: {
      style: { width: 250, maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP },
    },
    // Show dropdow at bottom of select
    getContentAnchorEl: null,
    anchorOrigin: { vertical: "top", horizontal: "center" },
    MenuListProps: { tabindex: "1", tabIndex: "1" },
  };

  //api call for calendar events details
  const getCalendarDetails = (filterValue) => {
    setShowLoader(true);
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      view_only_my_event: filterValue,
    };
    apiCall(
      (response) => {
        console.log("GET_CALENDAR_DETAILS", response);
        if (response.data.success) {
          setCalendarData(response.data.data);
          setShowLoader(false);
        } else {
          console.log("err from calendar api", response.data.message);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_CALENDAR_DETAILS"
    );
  };

  //api call for all inspector details
  const getInspectorDetails = () => {
    setShowLoader(true);
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
    };
    apiCall(
      (response) => {
        console.log("GET_INSPECTOR_LIST", response);
        if (response.data.success) {
          setAllInspector(response.data.data);
          setShowLoader(false);
        } else {
          console.log("err from inspector api", response.data.message);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_INSPECTOR_LIST"
    );
  };

  //api call for add event
  const addEvent = () => {
    setShowLoader(true);
    const formdata = {
      name: eventDetails.name,
      is_all_day_event: allDay,
      inspector_id: selectedInspector,
      description: eventDetails.description,
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      company_id: sessionStorage.getItem("company_id"),
      event_end_datetime: allDay
        ? moment
            .utc(new Date(endDateTime.setHours(24, 0, 0, 0)).toUTCString())
            .format("YYYY-MM-DD HH:mm:ss")
        : moment
            .utc(new Date(endDateTime).toUTCString())
            .format("YYYY-MM-DD HH:mm:ss"),
      event_start_datetime: allDay
        ? moment
            .utc(new Date(startDateTime.setHours(0, 0, 0, 0)).toUTCString())
            .format("YYYY-MM-DD HH:mm:ss")
        : moment
            .utc(new Date(startDateTime).toUTCString())
            .format("YYYY-MM-DD HH:mm:ss"),
    };
    apiCall(
      (response) => {
        console.log("CALENDAR_ADD_EVENT", response);
        if (response.data.success) {
          setOpen(false);
          setAllDay(false);
          setShowLoader(false);
          setSelectedInspector("");
          setEndDateTime(new Date());
          setStartDateTime(new Date());
          setRefreshControl(!refreshControl);
          toast.success(response.data.message);
          setEventDetails({ name: "", description: "" });
        } else {
          toast.error(response.data.message);
          console.log("err from add event api", response.data.message);
          setShowLoader(false);
        }
      },
      formdata,
      "CALENDAR_ADD_EVENT"
    );
  };

  //api call for edit event
  const editEvent = () => {
    setShowLoader(true);
    const formdata = {
      event_id: eventId,
      name: eventDetails.name,
      is_all_day_event: allDay,
      inspector_id: selectedInspector,
      description: eventDetails.description,
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      company_id: sessionStorage.getItem("company_id"),
      event_end_datetime: allDay
        ? moment
            .utc(new Date(endDateTime.setHours(24, 0, 0, 0)).toUTCString())
            .format("YYYY-MM-DD HH:mm:ss")
        : moment
            .utc(new Date(endDateTime).toUTCString())
            .format("YYYY-MM-DD HH:mm:ss"),
      event_start_datetime: allDay
        ? moment
            .utc(new Date(startDateTime.setHours(0, 0, 0, 0)).toUTCString())
            .format("YYYY-MM-DD HH:mm:ss")
        : moment
            .utc(new Date(startDateTime).toUTCString())
            .format("YYYY-MM-DD HH:mm:ss"),
    };
    apiCall(
      (response) => {
        console.log("CALENDAR_EDIT_EVENT", response);
        if (response.data.success) {
          setOpen(false);
          setAllDay(false);
          setSelectedInspector("");
          setScreenName("Schedule");
          setEndDateTime(new Date());
          setStartDateTime(new Date());
          setRefreshControl(!refreshControl);
          toast.success(response.data.message);
          setEventDetails({ name: "", description: "" });
        } else {
          console.log("err from edit event api", response.data.message);
          toast.error(response.data.message);
          setShowLoader(false);
        }
      },
      formdata,
      "CALENDAR_EDIT_EVENT"
    );
  };

  //api call for delete event
  const deleteEvent = () => {
    setShowLoader(true);
    const formdata = {
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      company_id: sessionStorage.getItem("company_id"),
      event_id: eventId,
    };
    apiCall(
      (response) => {
        console.log("CALENDAR_DELETE_EVENT", response);
        if (response.data.success) {
          setOpen(false);
          setAllDay(false);
          setShowLoader(false);
          setDialogOpen(false);
          setSelectedInspector("");
          setScreenName("Schedule");
          setEndDateTime(new Date());
          setStartDateTime(new Date());
          setRefreshControl(!refreshControl);
          toast.success(response.data.message);
          setEventDetails({ name: "", description: "" });
        } else {
          console.log("err from delete event api", response.data.message);
          setShowLoader(false);
        }
      },
      formdata,
      "CALENDAR_DELETE_EVENT"
    );
  };

  //intialization
  useEffect(() => {
    getCalendarDetails(0);
    getInspectorDetails();
  }, [refreshControl]);

  // custom event component
  const CustomEvent = (event) => {
    return (
      <div className="calendar-month-event">
        <Stack direction="row" spacing={1} alignItems="center">
          {event.event.type === "Inspection" ? (
            event.event?.inspectorDetails?.map((value) => {
              return (
                <Avatar
                  name={value.first_name}
                  size="18"
                  round
                  maxInitials={1}
                  color={value.user_primary_color}
                  fgColor={event.event.fgColor}
                  textSizeRatio={2}
                  style={{ opacity: "0.8" }}
                />
              );
            })
          ) : (
            <Avatar
              name={event.event.inspectorDetails.first_name}
              size="18"
              round
              maxInitials={1}
              color={event.event.inspectorDetails.user_secondary_color}
              fgColor={event.event.fgColor}
              textSizeRatio={2}
              style={{ opacity: "0.8" }}
            />
          )}
          {event.event.status === "Draft" ? (
            <Typography
              className="calendar-month-event-draft"
              sx={{ color: event.event.fontColor }}
            >
              *DRAFT*
            </Typography>
          ) : (
            ""
          )}
          <Typography
            className="calendar-month-event-time"
            sx={{ color: event.event.fontColor }}
          >
            {moment.utc(event.event.start).local().format("LT")}
          </Typography>
          {view === "week" ? (
            ""
          ) : (
            <Typography
              className="calendar-month-event-title"
              sx={{ color: event.event.fontColor }}
            >
              {event.title}
            </Typography>
          )}
        </Stack>
      </div>
    );
  };

  // event style function
  const eventStyleGetter = (event, start, end, isSelected) => {
    var opacity = event.opacity;
    var backgroundColor = event.primaryColor;
    var style = {
      border: "0px",
      opacity: opacity,
      borderRadius: "2px",
      margin: "2px 0px 2px 0px",
      backgroundColor: backgroundColor,
    };
    return { style: style };
  };
  // format change in week view
  const dayRangeHeaderFormat = ({ start, end }, culture, local) =>
    local.format(start, "MMM DD", culture) +
    " – " +
    // updated to use this localizer 'eq()' method
    local.format(
      end,
      local.eq(start, end, "month") ? "DD, YYYY" : "MMM DD, YYYY",
      culture
    );
  // slot style
  const customSlotPropGetter = () => {
    return {
      className: "slot",
      style: {
        minHeight: "5vh",
        minWidth: "66.4375px",
        color: "#8898AA",
        fontSize: "13px",
        textAlign: "right",
        letterSpacing: 0,
        textTransform: "uppercase",
      },
    };
  };
  //return function**************************************************************
  return (
    <>
      <Box className="calendar_page_background">
        <Header />
        <Breadcrumb />
        <Preloader showPreloader={showLoader} />
        <Grid container className="calendar_page_header" spacing={1}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography className="calendar_title">Calendar</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            className="calendar_grid"
            sx={{
              alignItems: {
                xs: "left",
                sm: "end",
                md: "end",
                lg: "end",
                xl: "end",
              },
              justifyContent: {
                xs: "left",
                sm: "end",
                md: "end",
                lg: "end",
                xl: "end",
              },
              pl: { xs: "0px !important" },
            }}
          >
            <Button
              className="calendar_add_inspection"
              startIcon={<ContentPasteIcon className="calendar_header_icon" />}
              onClick={() =>
                navigate("/schedule-inspection", { state: { calendar: true } })
              }
            >
              Add inspection
            </Button>
            <Button
              className="calendar_add_event"
              startIcon={<AccessTimeIcon className="calendar_header_icon" />}
              onClick={handleOpen}
            >
              Add event
            </Button>
          </Grid>
        </Grid>
        {/* ------------------------delete confirmation dialog------------------ */}
        <Dialog
          fullWidth={true}
          className="dialog-container-details"
          open={dialogOpen}
          onClose={handleDialogClose}
        >
          <div className="close-wrapper">
            <CloseIcon
              onClick={handleClose}
              sx={{ pointerEvents: showLoader && "none" }}
            />
          </div>
          <DialogContent className="dialog-content">
            <Grid container className="permission_change_modal">
              <Typography>Delete confirmation</Typography>
              <Typography>
                Are you sure you want to delete the event?
              </Typography>
            </Grid>
          </DialogContent>
          <DialogActions className="dialog-action">
            <Button
              className="back-btn"
              onClick={handleDialogClose}
              sx={{ pointerEvents: showLoader && "none" }}
            >
              Cancel
            </Button>
            <LoadingButton
              className="default-btn"
              loading={showLoader}
              onClick={() => deleteEvent()}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {/* ************************add/edit event screen ***************************** */}
        <Modal
          open={open}
          onClose={handleClose}
          className={"calendar_edit_screen_modal"}
        >
          <Box className={"calendar_edit_modal"}>
            <Grid
              sx={{ p: { xs: "20px", md: "30px", lg: "30px" } }}
              container
              spacing={2}
            >
              <Grid item xs={8}>
                <Typography className="calendar-modal-title">
                  {screenName} an event
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <CloseIcon
                  onClick={handleClose}
                  className={"calendar-modal-close-icon"}
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                mt: "20px",
                pl: { sm: "10px", md: "40px", lg: "40px" },
                pr: { sm: "10px", md: "40px", lg: "40px" },
              }}
            >
              <Grid item xs={12} sx={{ mt: { xs: 3, sm: 0, lg: 0 } }}>
                <Grid item xs={12} sm={12} lg={12}>
                  <FormControl variant="standard" className="forms-control">
                    <label
                      className="input-form-label"
                      id="demo-simple-select-label"
                    >
                      NAME*
                    </label>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="name"
                      className="input-textfield"
                      id="namefield"
                      placeholder="Enter Name"
                      onChange={handleChange}
                      error={errors.name}
                      value={eventDetails.name}
                      helperText={errors.name && errors.name}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <FormControl variant="standard" className="forms-control">
                    <label
                      className="input-form-label"
                      id="demo-simple-select-label"
                    >
                      DESCRIPTION
                    </label>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="description"
                      id="desc"
                      className="input-textfield"
                      placeholder="Enter description"
                      onChange={handleChange}
                      value={eventDetails.description}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <FormControl className="calendar_forms_control">
                    <label
                      className="input-form-label"
                      id="demo-simple-select-label"
                    >
                      INSPECTOR*
                    </label>
                    <Select
                      size="small"
                      MenuProps={{ ...MenuProps, autoFocus: true }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedInspector}
                      placeholder="Select inspector"
                      onChange={handleInspector}
                      error={errors.selectedInspector}
                      helperText={
                        errors.selectedInspector && errors.selectedInspector
                      }
                    >
                      <MenuItem disabled>--Select inspector--</MenuItem>
                      {allInspector.map((option) => (
                        <MenuItem value={option.id}>
                          <Box
                            component="li"
                            sx={{
                              "& > img": { mr: 2, flexShrink: 0 },
                              m: "2px 0px 2px 0px",
                            }}
                          >
                            <span>
                              <Avatar
                                name={option.first_name}
                                size="21"
                                round
                                maxInitials={1}
                                color={option.user_primary_color}
                                style={{ opacity: "80%" }}
                              />{" "}
                              {`${option.first_name} ${option.last_name}`}
                            </span>
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>
                      {errors.selectedInspector && errors.selectedInspector}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid container>
                  <Grid item xs={5.7} sm={5.7} md={5.7} lg={5.7}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        value={startDateTime}
                        inputFormat="MM/dd/yyyy"
                        onChange={handleStartDateTime}
                        disablePast
                        components={{ OpenPickerIcon: ArrowDropDownIcon }}
                        renderInput={(params) => (
                          <FormControl
                            variant="standard"
                            className="forms-control"
                            sx={{ pr: { lg: "0px !important" } }}
                          >
                            <label
                              className="input-form-label"
                              id="demo-simple-select-label"
                            >
                              <CalendarMonthIcon className="calendar_start_icon" />{" "}
                              START DATE*
                            </label>
                            <TextField
                              {...params}
                              size="small"
                              error={errors.startDateTime}
                              helperText={
                                errors.startDateTime && errors.startDateTime
                              }
                            />
                          </FormControl>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={0.6}
                    sm={0.6}
                    md={0.6}
                    lg={0.6}
                    className="calender_icon_center"
                  >
                    <ArrowRightAltIcon className="calendar_allright_icon" />
                  </Grid>
                  <Grid item xs={5.7} sm={5.7} md={5.7} lg={5.7}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        value={endDateTime}
                        inputFormat="MM/dd/yyyy"
                        onChange={handleEndDateTime}
                        disablePast
                        components={{ OpenPickerIcon: ArrowDropDownIcon }}
                        renderInput={(params) => (
                          <FormControl
                            variant="standard"
                            className="forms-control"
                            sx={{ pl: { lg: "0px !important" } }}
                          >
                            <label
                              className="input-form-label"
                              id="demo-simple-select-label"
                            >
                              <CalendarMonthIcon className="calendar_start_icon" />{" "}
                              END DATE*
                            </label>
                            <TextField
                              {...params}
                              size="small"
                              error={errors.endDateTime}
                              helperText={
                                errors.endDateTime && errors.endDateTime
                              }
                            />
                          </FormControl>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid className="calendar-all-day" xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={handleAllDayEvent}
                          checked={allDay}
                          size="small"
                        />
                      }
                      label={
                        <div className="calendar_allday">
                          Mark as all day event
                        </div>
                      }
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5.7}
                    sm={5.7}
                    md={5.7}
                    lg={5.7}
                    sx={{ display: allDay ? "none" : "flex" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        disabled={allDay}
                        value={startDateTime}
                        onChange={handleStartDateTime}
                        components={{ OpenPickerIcon: ArrowDropDownIcon }}
                        renderInput={(params) => (
                          <FormControl
                            variant="standard"
                            className="forms-control"
                            sx={{ pr: { lg: "0px !important" } }}
                          >
                            <label
                              className="input-form-label"
                              id="demo-simple-select-label"
                            >
                              <AccessTimeIcon className="calendar_start_icon" />{" "}
                              START TIME*
                            </label>
                            <TextField {...params} size="small" />
                          </FormControl>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={0.6}
                    sm={0.6}
                    md={0.6}
                    lg={0.6}
                    className="calender_icon_center"
                    sx={{ display: allDay ? "none" : "flex" }}
                  >
                    <ArrowRightAltIcon className="calendar_allright_icon" />
                  </Grid>
                  <Grid
                    item
                    xs={5.7}
                    sm={5.7}
                    md={5.7}
                    lg={5.7}
                    sx={{ display: allDay ? "none" : "flex" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        disabled={allDay}
                        value={endDateTime}
                        onChange={handleEndDateTime}
                        components={{ OpenPickerIcon: ArrowDropDownIcon }}
                        renderInput={(params) => (
                          <FormControl
                            variant="standard"
                            className="forms-control"
                            sx={{ pl: { lg: "0px !important" } }}
                          >
                            <label
                              className="input-form-label"
                              id="demo-simple-select-label"
                            >
                              <AccessTimeIcon className="calendar_start_icon" />{" "}
                              END TIME*
                            </label>
                            <TextField {...params} size="small" />
                          </FormControl>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              {screenName === "Edit" ? (
                <Grid item xs={12}>
                  <p
                    style={{
                      paddingTop: "3px",
                      paddingLeft: "17.5px",
                      fontSize: "12px",
                      lineHeight: "20px",
                      letterSpacing: 0,
                      fontWeight: "600",
                      color: " #E30303",
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                    onClick={handleDialogOpen}
                  >
                    <DeleteIcon
                      sx={{ height: "11.5px !important", width: "11.5px" }}
                    />{" "}
                    Delete event
                  </p>
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12} sm={5} md={6} sx={{ mt: 2 }}></Grid>
            </Grid>
            {/* buttons for the add/edit screen**************************************** */}
            <Grid
              container
              spacing={2}
              className="calendar_edit_modal_buttons"
              sx={{ mb: "15px" }}
            >
              <Button
                variant="none"
                id="cancel"
                onClick={() => handleClose()}
                className="calendar_edit_cancel_button"
              >
                Cancel
              </Button>
              <LoadingButton
                disabled={!changed}
                id="save"
                className="calendar_edit_save_button"
                onClick={handleformSubmit}
                loading={showLoader}
              >
                Save
              </LoadingButton>
            </Grid>
          </Box>
        </Modal>
        {/* calendar ********************************* */}
        <Paper
          sx={{
            maxWidth: "100%",
            height: "calc(100vh + 200px)",
            margin: { lg: "10px 30px 10px 30px", md: "10px 30px 10px 30px" },
          }}
        >
          <Calendar
            popup
            events={events}
            endAccessor="end"
            startAccessor="start"
            defaultView={"month"}
            localizer={localizer}
            onSelectEvent={selectEvent}
            views={["month", "week", "day"]}
            dayLayoutAlgorithm="no-overlap"
            eventPropGetter={eventStyleGetter}
            popupOffset={matches ? undefined : { x: 200, y: 0 }}
            // onShowMore={(events, date) => console.log(events)}
            scrollToTime={new Date()}
            slotPropGetter={customSlotPropGetter}
            style={{
              height: "calc(100vh + 200px)",
              padding: "21px 26.5px 21.5px 26.5px",
            }}
            formats={{
              dateFormat: "D",
              dayRangeHeaderFormat: dayRangeHeaderFormat,
              dayHeaderFormat: "MMM, YYYY",
              monthHeaderFormat: "MMMM, YYYY",
              timeGutterFormat: "h a",
            }}
            components={{
              month: {
                header: ({ label }) => (
                  <div className="calendar-month-day-header">{label}</div>
                ),
                dateHeader: (props) => (
                  <CustomDateHeader {...props} currentDate={currentDate} />
                ),
              },
              toolbar: (props) => (
                <CustomToolbar
                  {...props}
                  handleFilter={handleFilter}
                  filterToggle={filterToggle}
                  setView={setView}
                />
              ),
              event: CustomEvent,
              week: {
                header: ({ date }) => (
                  <div className="calendar-week-day-header">
                    {moment(date).format("ddd M/D")}
                  </div>
                ),
              },
              day: {
                header: ({ date }) => (
                  <div className="calendar-month-day-header">
                    {moment(date).format("dddd")}
                  </div>
                ),
              },
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default MyCalendar;
