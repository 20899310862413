import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import CardHeader from "../../common/cardHeader";
import "../../../assets/styles/containers/_inspDetails.scss";

const PropertyDetails = (props) => {
  const images = [
    {
      label: "San Francisco – Oakland Bay Bridge, United States",
      imgPath:
        "https://media.istockphoto.com/photos/wide-angle-real-estate-interior-shot-of-a-beautiful-trendy-aframe-picture-id1322234491?b=1&k=20&m=1322234491&s=170667a&w=0&h=aTJKqFwwM6aHtfi4rtwLRKewP_lwTCvR7u8XqNFVoPo=",
    },
    {
      label: "Bird",
      imgPath:
        "https://media.istockphoto.com/photos/beautiful-house-swimming-pool-view-from-the-veranda-summer-day-picture-id1297372607?b=1&k=20&m=1297372607&s=170667a&w=0&h=BfOqIlwQI9V6UwELdl6sJa7PSwGEnzMUCJ_juua9870=",
    },
    {
      label: "Bali, Indonesia",
      imgPath: "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80",
    },
    {
      label: "Goč, Serbia",
      imgPath: "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
    },
  ];

  function SwipeableTextMobileStepper() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
      setActiveStep(step);
    };

    return (
      <Box>
        <div style={{ backgroundColor: "#BEBEBE", textAlign: "center", height: "199px", margin: "0 auto", width: "100%", padding: "60px 0" }}>
          <HomeIcon sx={{ width: "65.83px", height: "55.96px", color: "#ffffff" }} />
        </div>
      </Box>
    );
  }
  return (
    <>
      <CardHeader CancelledStatus={props.CancelledStatus} title={"Property Details"} icon={<EditIcon className="details__icon"> </EditIcon>} iconDesc={"Edit"} />
      <div className="details__layout">
        <div className="details__img">{SwipeableTextMobileStepper()}</div>
        <div className="details__address">
          <div style={{ paddingTop: "10px", paddingBottom: "24px" }}>
            <Typography className="heading">Address</Typography>
            <Typography className="description">{`${props.address.address} ${props.address.address2}  ${props.address.city} ${props.address.state} ${props.address.zip}`}</Typography>
            <div className="gridBox">
              <div className="grid__container">
                <Typography className="heading">Foundation</Typography>
                <Typography className="description">{props.property.foundation ? props.property.foundation : "Unspecified"}</Typography>
              </div>
              <div className="grid__container">
                <Typography className="heading">Square Feet</Typography>
                <Typography className="description">{props.property.squarefeet ? props.property.squarefeet : "Unspecified"}</Typography>
              </div>
              <div className="grid__container">
                <Typography className="heading">Bedrooms</Typography>
                <Typography className="description">{props.property.bedrooms ? props.property.bedrooms : "Unspecified"}</Typography>
              </div>
              <div className="grid__container">
                <Typography className="heading">Year Built</Typography>
                <Typography className="description">{props.property.yearbuild ? props.property.yearbuild : "Unspecified"}</Typography>
              </div>
              <div className="grid__container">
                <Typography className="heading">Year Built</Typography>
                <Typography className="description">{props.property.yearbuild ? props.property.yearbuild : "Unspecified"}</Typography>
              </div>
              <div className="grid__container">
                <Typography className="heading">Bathrooms</Typography>
                <Typography className="description">{props.property.bathroom ? props.property.bathroom : "Unspecified"}</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyDetails;
