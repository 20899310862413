import React from 'react';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation, useNavigate } from 'react-router-dom';


const Breadcrumb = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const path = location.pathname.split("/").filter(x => x);
  const breadcrumbs = [
    <Link
      underline="hover"
      onClick={() => { navigate('/') }}
      className='home-breadcrumb'
      fontFamily="Roboto, sans-serif !important"
      key={1}
    >
      Dashboard
    </Link>
    ,
    path?.map((name, index) => {
      const routeTo = `/${path.slice(0, index + 1).join('/')}`;
      const isLast = index === path?.length - 1;
      name = name.includes('-') ? name.replace(/-/g, " ") : name;
      return isLast ?
        <Typography fontFamily="Roboto, sans-serif !important" className='breadcrumb-last' key={name}>{name === 'inspection' ? `inspection #${props.value}` : name.includes('%20') ? name.replace(/%20/g, " ") : name === 'comment library' ? 'my library' : name}</Typography> :
        <Link fontFamily="Roboto, sans-serif !important" className='breadcrumb-normal' onClick={() => navigate(routeTo)} key={name}>{name}</Link>
    })
  ];
  return <div style={{ backgroundColor: path[0] === 'templates' && '#f7f9fa' }} className={props.alertPresent ? 'breadcrumb-container-with-alert' : 'breadcrumb-container'}>
    <Stack fontFamily="Roboto, sans-serif !important" className='stack-container' spacing={1}>
      <Breadcrumbs separator="/" aria-label="breadcrumb">
        {path[0] === 'templates' ? "" : breadcrumbs}
      </Breadcrumbs>
    </Stack>
  </div>;
};
export default Breadcrumb;
