//file imports-------------------------------------------------------------------------------------------------------------------/
import axios from "axios"; //API calls
import { useState } from "react";
import validator from "validator";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { useNavigate,useLocation,Link } from "react-router-dom";
import { API_CONSTANTS } from "../../config/api-constants";
import logo from "../../../assets/images/logo.png"; //Palm-tech logo
import "../../../assets/styles/containers/_login.scss"; //SCSS import
import Visibility from "@mui/icons-material/Visibility"; //Icon
import VisibilityOff from "@mui/icons-material/VisibilityOff"; //Icon
import {
  Grid,
  Paper,
  TextField,
  Typography,
  InputLabel,
  IconButton,
  FormControl,
  useMediaQuery,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {apiCall} from '../../utils/action';
//render function-----------------------------------------------------------------------------------------------------------------/
const CreatePassword = () => {
  // get token and email from signup mail url
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let tokenParam = searchParams.get("token");
  let emailParamEncoded = encodeURIComponent(searchParams.get("email"));
  let emailParam = decodeURIComponent(emailParamEncoded.replace(/\%20/g, '+'))

  const [email, setEmail] = useState(emailParam);
  const [agree, setAgree] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery("(min-width:900px)");
  const [emailerror, setemailError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [passworderror, setpasswordError] = useState(false);
  const [passworderrorText, setpasswordErrorText] = useState('');
  const [confirmPassError, setConfirmPassError] = useState(false);
  const [confirmPassErrorText, setConfirmPassErrorText] = useState('');
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  
  // navigator function**********************************************************/
  let navigate = useNavigate();
  const routeChange = (roleId) => {
    (roleId === 2 ||roleId === 3 ||roleId === 4 )?navigate(`/company`) :navigate(`/dashboard`)
  };

  const getCompanyInfo=(company_id,user_id,role_id,route,role)=>{
    const formdata = { company_id: company_id, user_id: user_id , role_id : role_id};
    apiCall((response)=>{
      if(response.data.success){
        sessionStorage.setItem("company_name", response.data.data.company_details.name);
        routeChange(route);
        toast.success(`Logged in as ${role}`);
      }else {
        console.log("else", response.data.message);
      }
    },formdata,"GET_COMPANY_INFO")
  }

  const createPassword =()=>{
      setLoading(true);
      toast.dismiss();
      const formdata = {token:tokenParam , email, password, password_confirmation:confirmPass, is_agree:agree  };
      axios
        .post(`${API_CONSTANTS.CREATE_PASSWORD}`, formdata)
        .then((response) => {
          // Request with success code true
          toast.success(response.data.message);
          setLoading(false);
          loginuser();
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            if (error.response.data.success === false) {
              toast.error(error.response.data.message);           
              setLoading(false);
            }
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log("no response", error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          setLoading(false);
        });


          // const formdata = {token:tokenParam , email, password, password_confirmation:confirmPass, is_agree:agree  };
          // apiCall((response) => {
          //   console.log("CREATE_PASSWORD", response);
          //   const { status_code, message, success } = response?.data;
          //   if (success) {
          //     toast.success(message);
          //     setLoading(false);
          //   } else {
          //     console.log("else ", status_code);
          //      toast.error(message);
          //   }
          // }, formdata,"CREATE_PASSWORD");
  }
  //login user function*******************************************************************
  const loginuser = () => {
    setLoading(true);
    const formdata = { userEmail: email, password,is_agree: agree };
    axios
      .post(`${API_CONSTANTS.LOGIN}`, formdata)
      .then((response) => {
        // Request with success code true
        sessionStorage.setItem("token", response.data.data.token);
        sessionStorage.setItem("user_id", response.data.data.user_id);
        sessionStorage.setItem("company_id" , response.data.data.company_id);
        sessionStorage.setItem("user_name", response.data.data.user_name);
        sessionStorage.setItem("role_id", response.data.data.role_data.role_id);
        sessionStorage.setItem('is_trial_company', response.data.data.is_trial_company);
        sessionStorage.setItem('total_insp', response.data.data.total_inspections);
        // toast.success(
        //   `Logged in as ${response.data.data.role_data.role_display_name}`
        // );
        getCompanyInfo(response.data.data.company_id,response.data.data.user_id,response.data.data.role_data.role_id ,response?.data?.data.role_data.role_id,response.data.data.role_data.role_display_name)
        // routeChange(response?.data?.data.role_data.role_id);
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          if (error.response.data.success === false) {
            toast.error(error.response.data.message);
            setLoading(false);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("no response", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };
  
  // validation for email and password********************************************/
  const validation = () => {
    let checkboxSpan = document.getElementById('agreement-box-error');
    checkboxSpan.innerHTML = "";
    if(email === '' || email === null){
      setemailError(true);
    }
    else if (validator.isEmail(email)) {
      setemailError(false);
    } else {
      setemailError(true);
    }
    if(password === ''){
    setpasswordError(true);
    setpasswordErrorText('Password is required');
    }
    else if (!validator.isStrongPassword(password)) {
      setpasswordError(true);
      setpasswordErrorText('Error: please make your password stronger. It must be at least 8 characters long, contains at least one special character and you have uppercase and lowercase letters. ')
    } else {
      setpasswordError(false);
    }
    if (confirmPass === ''){
      setConfirmPassError(true);
      setConfirmPassErrorText('Password confirmation is required')
    }
    if(password !== '' && validator.isStrongPassword(password) && confirmPass !== ''){
      if(password !== confirmPass){        
        setpasswordErrorText('Passwords must match')
        setConfirmPassErrorText('Passwords must match')
        setpasswordError(true);
        setConfirmPassError(true);
      }
      else{
        setpasswordError(false)
        setConfirmPassError(false);
      }
    }

    if (
      validator.isEmail(email) &&
      validator.isStrongPassword(password) && password === confirmPass &&
      agree === 0
    ) checkboxSpan.innerHTML = "Please check the terms and conditions"
    // if (
    //   validator.isEmail(email) &&
    //   validator.isStrongPassword(password) &&
    //   agree === true
    // ) {
    //   loginuser(email, password);
    // }
    if(email !== '' && email !== null && validator.isEmail(email) && password !== '' && validator.isStrongPassword(password)
    && confirmPass !== '' && password === confirmPass && agree === 1) createPassword();
  };
  // return function starts from here *******************************************/
  return (
    <>
      {sessionStorage.getItem("token") !== null ? (
        navigate("/dashboard")
      ) : (
        <>         
          {/* background div for mobile and desktop******************************** */}
          <div className={`${matches ? "background" : "background-mobile"}`}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              {/* palm tech logo*************************************************** */}
              <img
                className={`${matches ? "logo" : "logo-mobile"}`}
                src={logo}
                alt="logo"
              ></img>
              {/* login component starts from here********************************* */}
              <Grid item xs={3}>
                <Paper
                  className="paper-normal"
                  sx={{
                    minWidth: { xs: 370, md: 390, lg: 390 },
                    minHeight: { xs: 505, md: 505, lg: 505 },
                    boxShadow: "0 30px 30px 0 rgba(40, 40, 40, 0.4)",
                  }}
                >
                  <Grid className="center">
                    <Typography
                      className="header"
                      sx={{
                        mt: "27px",
                        fontSize: { xs: "24px", md: "22px", lg: "22px" },
                      }}
                    >
                      Finish creating your account
                    </Typography>
                  </Grid>
                  {/* Input field for email******************************************************************************************************** */}
                  <Grid className="center">
                    <FormControl variant="standard">
                      <InputLabel
                        htmlFor="component-simple"
                        className="input_lable"
                        sx={{
                          top: { xs: "18px", md: "17px", lg: "17px" },
                          fontSize: { xs: "14px", md: "12px", lg: "12px" },
                        }}
                      >
                        EMAIL ADDRESS
                      </InputLabel>
                      <TextField
                        disabled
                        type="email"
                        className="input_field"
                        variant="outlined"
                        error={emailerror}
                        placeholder="Enter email"
                        size={`${matches ? "small" : "medium"}`}
                        onChange={(event) => setEmail(event.target.value)}
                        value={email}
                        onKeyPress={(e) => {
                          if (e.key === "Enter")
                            validation();
                        }}
                        helperText={`${
                          emailerror === true
                            ? "Email is required"
                            : ""
                        }`}
                        sx={{
                          marginTop: "63px",
                          width: { xs: "340px", md: "317px", lg: "317px" },
                        }}
                        InputProps={{
                          className: "input_props_field",
                          sx: {
                            height: { xs: "55px", md: "40px", lg: "40px" },
                            fontSize: { xs: "18px", md: "14px", lg: "14px" },
                            background: "rgba(239, 239, 239, 0.8)",
                            color: "text.primary !important",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  {/* input field for password *************************************************************************************************** */}
                  <Grid className="center">
                    <FormControl variant="standard">
                      <InputLabel
                        htmlFor="component-simple"
                        className="input_lable"
                        sx={{
                          top: "4px",
                          fontSize: { xs: "14px", md: "12px", lg: "12px" },
                        }}
                      >
                        CREATE A PASSWORD
                      </InputLabel>
                      <TextField
                        className="input_field"
                        variant="outlined"
                        id="password"
                        error={passworderror}
                        placeholder="Password"
                        size={`${matches ? "small" : "medium"}`}
                        type={showPassword ? "text" : "password"}
                        onChange={(event) => setPassword(event.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter")
                            validation();
                        }}
                        helperText={passworderror?passworderrorText:"Min 8 characters with uppercase, lowercase and special character"}
                        sx={{
                          marginTop: "47px",
                          width: { xs: "340px", md: "317px", lg: "317px" },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                sx={{ mr: "-9px" }}
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          className: "input_props_field",
                          sx: {
                            height: { xs: "55px", md: "40px", lg: "40px" },
                            fontSize: { xs: "18px", md: "14px", lg: "14px" },
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid className="center">
                    <FormControl variant="standard">
                      <InputLabel
                        htmlFor="component-simple"
                        className="input_lable"
                        sx={{
                          top: "4px",
                          fontSize: { xs: "14px", md: "12px", lg: "12px" },
                        }}
                      >
                        RE-TYPE A PASSWORD
                      </InputLabel>
                      <TextField
                        className="input_field"
                        variant="outlined"
                        id="confirm_password"
                        error={confirmPassError}
                        placeholder="Password"
                        size={`${matches ? "small" : "medium"}`}
                        type={showPassword1 ? "text" : "password"}
                        onChange={(event) => setConfirmPass(event.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter")
                            validation();
                        }}
                        helperText={confirmPassError && confirmPassErrorText}
                        sx={{
                          marginTop: "47px",
                          width: { xs: "340px", md: "317px", lg: "317px" },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                sx={{ mr: "-9px" }}
                                onClick={() => setShowPassword(!showPassword1)}
                                onMouseDown={() =>
                                  setShowPassword1(!showPassword1)
                                }
                              >
                                {showPassword1 ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          className: "input_props_field",
                          sx: {
                            height: { xs: "55px", md: "40px", lg: "40px" },
                            fontSize: { xs: "18px", md: "14px", lg: "14px" },
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  {/* terms and conditions********************************************* */}
                  <Grid className="center_terms_and_condition">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => setAgree(e.target.checked? 1:0)}
                          id="agree_terms"
                        />
                      }
                      label={
                        <div className="terms_and_condition">
                          I agree to the{" "}
                          <a
                            className="terms_and_condition_hyperlink"
                            href="https://www.palm-tech.com/contentpages.aspx?p=4afe6b27-86f9-465b-9004-c1495f6a348f"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Terms of Services
                          </a>{" "}
                          and{" "}
                          <a
                            className="terms_and_condition_hyperlink"
                            href="https://www.palm-tech.com/privacy"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Privacy Policy.
                          </a>
                        </div>
                      }
                      labelPlacement="end"
                    />
                    <div id="agreement-box-error"></div>
                  </Grid>
                  {/* login in button ************************************************* */}
                  <Grid className="center">
                    <LoadingButton
                      className="login_button"
                      sx={{
                        height: { xs: 42, md: 34, lg: 34 },
                        width: { xs: 180, md: 119, lg: 119 },
                        fontSize: { xs: 16, md: 13, lg: 13 },
                      }}
                      size="small"
                      variant="contained"
                      loading={loading}
                      onClick={() => validation()}
                    >
                      Log in
                    </LoadingButton>
                  </Grid>
                  {/* Sign up hyperlink *********************************************** */}
                  <Grid className="center" sx={{ mt: 2 }}>
                    <Typography
                      className="link_nologin"
                      fontSize={{
                        xs: "16px",
                        md: "12px",
                        lg: "12px",
                        xl: "12px",
                      }}
                    >
                      Already have an account?&nbsp;
                    </Typography>
                    <Link to="/">
                      <Typography
                        className="link_signup"
                        fontSize={{
                          xs: "16px",
                          md: "12px",
                          lg: "12px",
                          xl: "12px",
                        }}
                      >
                        Log in here
                      </Typography>
                    </Link>
                  </Grid>
                </Paper>
                {/* Footer starts from here****************************************************************************************************************** */}
                <Grid
                  className="center"
                  sx={{
                    marginTop: { xs: "123px", md: "41px", lg: "41px" },
                    marginBottom: { xs: "0px", md: "25px", lg: "25px" },
                  }}
                >
                  <div className="footer">
                    <p>© 2022 Palmtech&nbsp;</p>
                    <a
                      href="https://www.palm-tech.com/"
                      rel="noreferrer"
                      target="_blank"
                      style={{ marginBottom: "-10px" }}
                      className="footer"
                    >
                      www.palm-tech.com
                    </a>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};
export default CreatePassword;
