import { API_CONSTANTS, API_METHODS } from '../../config/api-constants';
import { fetchCall } from '../../utils/ajax';

export const NewInspectionDetails = (callback, payload) => {
    const url = `${API_CONSTANTS.NEW_INSPECTION_DETAILS}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const NewFilterInspectionDetails = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_FILTER_LIST_DETAILLS}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const ScheduleNewInspection = (callback, payload) => {
    const url = `${API_CONSTANTS.SCHEDULE_INSPECTION}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const AddNewAgentRequest = (callback, payload) => {
    const url = `${API_CONSTANTS.ADD_NEW_AGENT}`;
    const method = API_METHODS.POST;
    console.log("agent call",url,method,payload)
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const AddNewClientRequest = (callback, payload) => {
    const url = `${API_CONSTANTS.ADD_NEW_CLIENT}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        if(response.data.success){
          callback(response);

        } else {
          callback('failed to add new client');

        }
      },
      url,
      method,
      payload
    );
  };


  export const EditInspectionAPI = (callback, payload) => {
    const url = `${API_CONSTANTS.UPDATE_INSPECTION_DETAILS}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        if(response.data.success){
          callback(response);

        } else {
          callback('failed to Edit');

        }
      },
      url,
      method,
      payload
    );
  };

  export const FetchInspectionDetails = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_INSPECTION_DETAILS}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        if(response.data.success){
          callback(response);

        } else {
          callback('failed to fetch client');
        }
      },
      url,
      method,
      payload
    );
  };
  
//   export const updateUserStatus = (callback,payload, queryParams) => {
//     const url = `${API_CONSTANTS.UPDATE_USER_STATUS}${queryParams}`;
//     const method = API_METHODS.PUT;
//     return fetchCall(
//       (response) => {
//         if (response.error === 0) {
//           callback(response);
//         } else {
//           callback('failure');
//         }
//       },
//       url,
//       method,
//       payload
//     );
//   };

//   export const getUserList = (callback, queryParams) => {
//     const url = `${API_CONSTANTS.ADD_USER}${queryParams}`;
//     const method = API_METHODS.GET;
//     return fetchCall(
//       (response) => {
//         callback(response.data);
//       },
//       url,
//       method
//     );
//   };