import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import SvgIcon from "@mui/material/SvgIcon";
import {
  homeGaugeIcon,
  spectoraIcon,
  palmtechIcon,
} from "../../../assets/svgIcons/Svg";

function OptionDialog(props) {
  const { handleOpenOption } = props;
  const importOptionList = [
    { name: "SPECTORA", id: "spectora", icon: spectoraIcon },
    // { name: "HOMEGAUGE", id: "homeGauge", icon: homeGaugeIcon },
    // { name: "PALMTECH", id: "palmtech", icon: palmtechIcon },
    {},
  ];

  return (
    <Grid container justifyContent={"center"} display={"flex"} px={5}>
      <Grid item xs={12} justifyContent={"center"} display={"flex"}>
        <Typography className="optionDialogTitle">{`Choose where to import from`}</Typography>
      </Grid>
      <Grid item xs={12} justifyContent={"center"} display={"flex"} my={7}>
        <Grid container display={"flex"}  justifyContent={"center"}>
          {importOptionList.map((obj) => (
            <Grid item xs={12} key={obj?.id} display={"flex"} className="center">
              {obj?.id !== undefined ? (
                <Paper
                  elevation={0}
                  className="templatePaperDesign"
                  onClick={() => handleOpenOption(obj?.id)}
                >
                  <SvgIcon
                    viewBox=""
                    sx={{ mt: `${obj?.id === "homeGauge" ? "10px" : null}` }}
                    style={{ height: "35px", width: "200px" }}
                  >
                    {obj?.icon}
                  </SvgIcon>
                </Paper>
              ) : null}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        justifyContent={"center"}
        display={"flex"}
        mt={10}
        mb={3}
      >
        <Typography className="optionDialogDesc">
          {`If your software is not listed, please `}
          <a href="mailto:help@inspectionsupport.com" className="templateContactUs">
            contact us
          </a>
          {` and we'll be happy to help.`}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default OptionDialog;
