import React from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import SettingsCard from './SettingsCard';
import SvgIcon from "@mui/material/SvgIcon"
import {agreementSvg,billingSvg,teamSvg,companySvg,paymentSvg,serviceSvg,reportSvg} from '../../../assets/svgIcons/Svg'

const SettingsTabs = () => {    
  const roleId = sessionStorage.getItem("role_id");
  let permission = roleId === "7" ? false : true;
  return (
    <Box
      sx={{ width: "100%",px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },}}
      className="teams-container">
      <Grid item xs={12} className="details-title">
        <span className="team-user-name">Account Settings</span>
        <p style={{ color: "#3B3B3B" }}>Everything related to your account</p>
      </Grid>
      <Grid container spacing={3}>
        
        {/* company profile card */}
        <SettingsCard navigatePath={'/settings/company-profile'} 
         title={'Company Profile'} 
         description={'Edit your company name, contact information and logo.'}
        icon={<SvgIcon viewBox="" style={{height:'37px',width:'37px'}}>{companySvg}</SvgIcon>}
        permission={true}
        id="company_profile"
        />

        {/* payments card */}
        <SettingsCard navigatePath={'/settings/payments'} 
         title={'Payments'} 
         description={'Manage how you get paid. '}
         icon={<SvgIcon viewBox="" style={{height:'34px',width:'42px'}}>{paymentSvg}</SvgIcon>}
         permission={permission}
         id="payments"
        />

        {/* Services and Fees card */}
        <SettingsCard navigatePath={'/settings/services'} 
         title={'Services and Fees'} 
         description={'Add and manage all the services that your company offers, and their price. '}
         icon={<SvgIcon viewBox="" style={{height:'31px',width:'39px'}}>{serviceSvg}</SvgIcon>}
         permission={true}
         id="services_fees"
        />

        {/* Team card */}
        <SettingsCard navigatePath={'/settings/team'} 
         title={'Team'} 
         description={'Manage your inspectors and their permissions. '}
         icon={<SvgIcon viewBox=""  style={{height:'36px',width:'42px'}}>{teamSvg}</SvgIcon>}
         permission={permission}
         id="team"
        />

        {/* Agreements card */}
        <SettingsCard navigatePath={'/settings/agreements'} 
         title={'Agreements'} 
         description={'Create and manage your inspection agreement contracts. '}
         icon={<SvgIcon viewBox="" style={{height:'39px',width:'33px'}}>{agreementSvg}</SvgIcon>}
         permission={true}
         id="agreement"
        />

        {/* Billing card */}
        <SettingsCard navigatePath={'/settings/billing'} 
         title={'Billing Plan'} 
         description={'Renew and manage your Palm-Tech subscription and add-ons. '}
         icon={<SvgIcon viewBox="" style={{height:'29px',width:'29px'}}>{billingSvg}</SvgIcon>}
         permission={false}
         id="billing"
        />

        {/* Report card */}
        <SettingsCard navigatePath={'/settings/report'} 
         title={'Report'} 
         description={'Customize your HTML and PDF report. '}
         icon={<SvgIcon viewBox="" style={{height:'29px',width:'29px'}}>{reportSvg}</SvgIcon>}
         permission={false}
         id="report"
        />
      </Grid>
    </Box>
  );
};

export default SettingsTabs;
