import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import FileUpload from "../../common/fileUpload/FileUpload";
import { SpectoraUpload } from "../../pages/PTAdmin/Import/PtUploadFile";

function SpectoraImportDialog(props) {
  const { handleOpenOption } = props;
  const [disableButton, setDisableButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    file: null,
    progressPercent: 0,
  });
  const handleUploadFile = async (file, value) => {
    setState((prevState) => ({
      ...prevState,
      file: file,
    }));
    let apiRes = false;
    let formData = new FormData();
    let progress = 0;
    const increment = 5; // Change this to adjust the increment value
    const intervalDuration = 50; // Change this to adjust the interval duration in milliseconds
    const params = {
      // user_id: sessionStorage.getItem("user_id"),
      // role_id: sessionStorage.getItem("role_id"),
      company_key: sessionStorage.getItem("company-key"),
      template_file: file || null,
      is_isn_company: 1,
      // is_texas: isTexasTemplate ? 1 : 0,
      // inspection_type_id: selectedType?.id || "",
    };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    try {
      if (!value) {
        setDisableButton(true);
        const percentCompleted = setInterval(() => {
          if (progress >= 100) {
            setDisableButton(false);
            clearInterval();
            return;
          }
          progress += increment;
          setState((prevState) => ({
            ...prevState,
            progressPercent: progress,
          }));
        }, intervalDuration);
      }

      if (value) {
        setLoading(true);
        const response = await SpectoraUpload(formData, setState);
        if (response.data.success) {
          setLoading(false);
          apiRes = true;
          setState((prevState) => ({
            ...prevState,
            file: file,
            fileImportError: undefined,
          }));
          // toast.success(response.data.message);
          // handleOpenOption("");
          handleOpenOption("donePage");
        }
      }
    } catch (error) {
      if (error?.response?.data?.data?.downloadable_error_file_link) {
        setState((prevState) => ({
          ...prevState,
          file: file,
          progressPercent: 0,
          fileImportError: true,
        }));
        apiRes = false;
      } else {
        toast.error(error?.response?.data?.message);
        setState((prevState) => ({
          ...prevState,
          file: null,
          progressPercent: 0,
        }));
        apiRes = false;
      }
    }
    return apiRes;
  };

  const { file, progressPercent } = state;
  const cancelDisabled = () => {
    if (!file) return false;
    // else if (apiCallInProgress) return true;
    // else if (!selectedCompany?.template_guid && !fileImportError) return true;
    else return false;
  };

  const handleCloseDialog = (closeDialog = false, closeFrom = "button") => {
    setState((prevState) => ({
      ...prevState,
      file: null,
      addDialog: closeDialog ? true : false,
      apiCallInProgress: false,
      fileImportError: false,
    }));
  };

  return (
    <Grid container display={"flex"} justifyContent={"center"}>
      <Grid item xs={12} justifyContent={"center"} display={"flex"}>
        <Typography className="importDialogTitle">{`Send your file to our team`}</Typography>
      </Grid>
      <Grid item xs={12} justifyContent={"center"} display={"flex"} mt={3} mb={1}>
        <Typography className="optionDialogTypo">
          {`Make sure your file is saved as an exl file. For help with file formatting, `}
          {/* <a href="" className="templateContactUs">
            {`read our FAQ.`}
          </a> */}
        </Typography>
      </Grid>
      <Grid item xs={12} px={5} mb={3}>
        <FileUpload progressPercent={progressPercent} handleCloseDialog={handleCloseDialog} setState={setState} handleUploadFile={handleUploadFile} file={file} cancelDisabled={cancelDisabled} />
      </Grid>
      <Grid item xs={12} gap={2} justifyContent={"flex-end"} display={"flex"}>
        <Button className="ptCancelButton" onClick={() => handleOpenOption("")}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          disabled={!file ? true : disableButton}
          className="ptDialogButton"
          onClick={() => handleUploadFile(file, true)}
          // disabled={!file ? true : false}
        >
          Send file
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

export default SpectoraImportDialog;
