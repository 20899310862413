import React, {useEffect} from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Input from "@mui/material/Input";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { timestamp } from "../../../utils/services";

const Temprature = ({AllCategoryList,updateTemplate, selectedID, data}) => {
  const defaultTemplate = Array.isArray(data) ? "" : data.type
  const defaultNumber = Array.isArray(data) ? "" : Number(data.value)

  const DefType = defaultTemplate === "" || defaultTemplate === null ? "F" : defaultTemplate

  const [value, setValue] = React.useState(DefType);
  const [temp, setTemp] = React.useState(defaultNumber)
  const [isEdit , setEdit]  = React.useState(false)
 
  const CateList = AllCategoryList.template.categories
  let insp = CateList.find((insp) => insp.is_inspection_details === 1)
  const linesdata = insp.lines
  const valData = linesdata.find((line) => line.line_input_controls[0].line_input_control_id === selectedID)

  console.log(value)
  const TempratureCheck = (e) => {
    setEdit(true)

    setTemp(e.target.value)
  }

    const handleChange = (event) => {
    setEdit(true)
    setValue(event.target.value);
    const Temprature = {
      type:value === "F" ? 'C' : 'F',
      value:temp ?? "",
    }
    valData.line_input_controls[0].values = ([Temprature])
    valData.line_input_controls[0].data = Temprature
    valData.line_input_controls[0].updated_at = timestamp();
    auto()
  };

  const TempratureObj = {
    type:value ,
    value:temp ?? "",
  }

  valData.line_input_controls[0].values = ([TempratureObj])
  valData.line_input_controls[0].data = TempratureObj


  const auto = () => {
    valData.line_input_controls[0].updated_at = timestamp();
    updateTemplate("noToast")
    setEdit(false)
  }
  useEffect(() => {
    if(data?.type === "°C" || data?.type === "C") setValue('C')
    else setValue('F')
   }, [])


  return (
      <Grid
      className='temprature'
        sx={{ width: "100% !important",
        marginBottom: "30px"
    }}
      >
            <ClickAwayListener  mouseEvent="onMouseDown"
    touchEvent="onTouchStart" onClickAway={() => isEdit && auto() }>
        <Input
          sx={{ p: "10px 18px !important", width :'42% !important', border: "1px solid #E3E3E3", borderRadius: "4px" }}
          className="input-textfield"
          size="small"
          disableUnderline
          value={temp}
          type="number"
          onKeyDown={(e) => e.keyCode === 13 && auto()}
          onChange={(e) => TempratureCheck(e)}
          variant="outlined"
          startAdornment={
            <InputAdornment sx={{marginTop: "-2px"}} position="start">
              {value === "" ? '°C' : value === 'C' ?  "°C" : "°F"}
              {/* <SearchOutlinedIcon sx={{ color: "#BEBEBE" }} /> */}
            </InputAdornment>
          }
        />
          </ClickAwayListener>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
          sx={{display:'contents'}}
        >
          <FormControlLabel
            value="F"
            control={<Radio className="section__checks__table" />}
            label={
              <Typography className="section__checks__table">
                Fahrenheit
              </Typography>
            }
          />
          <FormControlLabel
            value="C"
            control={<Radio className="section__checks__table" />}
            label={
              <Typography className="section__checks__table">
                Celsius
              </Typography>
            }
          />
        </RadioGroup>
      </Grid>
  );
};

export default Temprature;
