import { Box } from "@mui/system";
import Header from "../../../common/header/Header";
import ReportSettingsLayout from "./ReportSettingsLayout";
import Breadcrumb from "../../../common/header/Breadcrumb";

const ReportSettings = () => {
  document.title = "ISN - Report Settings";
  return (
    <Box sx={{ flexGrow: 1 }} className="page-background">
      {window.scroll(0, 0)}
      <Header />
      <div style={{ marginTop: "-67px" }}>
        <Breadcrumb />
        <ReportSettingsLayout />
      </div>
    </Box>
  );
};

export default ReportSettings;
