import * as Actions from "../action/CommentLibraryAction";

const initialState = {
  showClModal: false,
};

const CommentLibraryReducer = (state = initialState, action) => {
  switch (action?.type) {
    case Actions.SHOW_CL_MODAL: {
      return { ...state, showClModal: action.payload };
    }
    case Actions.GET_CL_LIST: {
      return { ...state, commentLibraryList: action.payload?.data, totalListCount: action.payload?.total };
    }
    case Actions.GET_CL_TAG_LIST: {
      return { ...state, clTagList: action.payload };
    }
    case Actions.GET_CL_SUMMARY_TAG_LIST: {
      return { ...state, clSummaryTagList: action.payload };
    }
    case Actions.GET_FULL_CL_LIST: {
      return { ...state, commentLibraryWholeList: action.payload?.data };
    }
    case Actions.ADD_NEW_NARRATIVE_COMMENT: {
      state.commentLibraryList.pop();
      return { ...state, totalListCount: state.totalListCount + 1, commentLibraryList: [action.payload, ...state.commentLibraryList] };
    }
    case Actions.EDIT_NARRATIVE_COMMENT: {
      const index = state.commentLibraryList.findIndex((item) => item.id === action.payload.comment_id);
      state.commentLibraryList[index] = { ...action.payload, ...state.commentLibraryList[index], tags: action.payload?.tag_ids, summaries: action.payload?.summary_ids };
      return { ...state };
    }
    case Actions.DELETE_NARRATIVE_COMMENT: {
      let deletedCommentList = state.commentLibraryList.filter((item) => item.id !== action.payload);
      return { ...state, commentLibraryList: deletedCommentList, totalListCount: state.totalListCount - 1 };
    }
    case Actions.UPDATE_DROPDOWN_VALUES: {
      return { ...state, [action.payload.key]: action.payload.value };
    }
    default: {
      return state;
    }
  }
};

export default CommentLibraryReducer;
