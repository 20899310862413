import moment from 'moment';
import { useState } from 'react';
import { Box } from '@mui/system';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import Heading from '../../common/cardHeading/Heading';
import FormControlLabel from '@mui/material/FormControlLabel';
import { apiCall } from '../../pages/settings/Services/action';
import { circularProgressClasses } from '@mui/material/CircularProgress';
import { Button, CircularProgress, Grid, Paper, Typography, Modal } from '@mui/material';

const Report = (props) => {
    const handleOpen = () => setOpen(true);
    const [mapaid, setMapaid] = useState();
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [masigned, setMasigned] = useState();
    const [loading, setLoading] = useState(false);
    const handleChange = (event) => setStatus(event.target.value);
    const handleMAPaid = (event) => setMapaid(event.target.checked);
    const handleMASigned = (event) => setMasigned(event.target.checked);
    const { guid, reportData, statusValue, refresh, setRefresh } = props;
    const [status, setStatus] = useState(statusValue.status === 'Draft' || statusValue.status === 'Scheduled' || statusValue.status === 'In-Progress' || statusValue.status === 'Draft' || statusValue.status === 'Cancelled' ? 'Incomplete' : 'Complete');
    console.log(props.reportData, 'reportData');
    //change status api call*******************************************************************
    const changeStatus = () => {
        setLoading(true);
        const formdata = { company_id: sessionStorage.getItem('company_id'), user_id: sessionStorage.getItem('user_id'), role_id: sessionStorage.getItem('role_id'), guid: guid, status: mapaid || masigned ? 'Published' : 'Complete', status_date: moment.utc(new Date().toUTCString()).format('YYYY-MM-DD'), mark_as_paid: mapaid ? '1' : '0', mark_agreement_signed: masigned ? '1' : '0' };
        apiCall(
            (response) => {
                console.log('CHANGE_STATUS', response);
                if (response.data.success) {
                    toast.success(response.data.message);
                    setOpen(false);
                    setMapaid();
                    setMasigned();
                    setLoading(false);
                    setRefresh(!refresh);
                } else {
                    console.log('else in CHANGE_STATUS', response.data.message);
                    toast.error(response.data.message);
                    setLoading(false);
                }
            },
            formdata,
            'CHANGE_STATUS',
        );
    };

    const percentage = eval((reportData?.completed_line_input_controls / (reportData?.incomplete_line_input_controls + reportData.completed_line_input_controls)) * 100)?.toFixed(2);

    //render
    return (
        <>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={'inspection-filter-modal-main'}>
                <Box className={'inspection-filter-modal'} sx={{ maxWidth: '630px !important', minHeight: '231px !important', top: '40% !important' }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <CloseIcon sx={{ float: 'right', cursor: 'pointer' }} onClick={handleClose} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography sx={{ color: '#333333', fontFamily: 'open sans', fontSize: '22px', mt: '15px', letterSpacing: 0 }}>Mark this inspection as complete?</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                            {statusValue.paid !== 0 && statusValue.agreementsigned !== 0 ? (
                                <Typography sx={{ color: '#333333', maxWidth: '512px', fontFamily: 'open sans', fontSize: '14px', mt: '15px', letterSpacing: 0 }}>This action cannot be undone. This action will send a link containing the report to the client’s email based on your notification settings.</Typography>
                            ) : (
                                <Typography sx={{ color: '#333333', maxWidth: '512px', fontFamily: 'open sans', fontSize: '14px', mt: '15px', letterSpacing: 0 }}>This inspection is unpaid, and has an unsigned agreement. Marking the inspection complete will trigger automated communications to your client based on your notification settings.</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: '30px' }}>
                            <FormGroup sx={{ maxWidth: '425px', paddingLeft: { xs: '0px', md: '30px', lg: '30px' } }}>
                                {statusValue.paid === 0 ? <FormControlLabel control={<Checkbox color="default" value={mapaid} onChange={handleMAPaid} />} label={<Typography sx={{ userSelect: 'none', fontSize: '14px', fontWeight: '600', lineHeight: '20px', letterSpacing: '0px', color: '#3b3b3b' }}>Also waive the inspection payment, forgoing any email requests for payment sent to your client.</Typography>} /> : ''}
                                {statusValue.agreementsigned === 0 ? <FormControlLabel control={<Checkbox color="default" value={masigned} onChange={handleMASigned} />} label={<Typography sx={{ userSelect: 'none', fontSize: '14px', fontWeight: '600', lineHeight: '20px', letterSpacing: '0px', color: '#3b3b3b', mt: '21px' }}>Also waive the agreement signature, forgoing any email reminders for signature send to your client.</Typography>} /> : ''}
                            </FormGroup>
                        </Grid>
                        <Grid container sx={{ display: 'flex', justifyContent: 'center', mt: 5, paddingRight: '20px' }}>
                            <LoadingButton loading={loading} variant="contained" className="inspection-filter-modal-buttons-apply" sx={{ width: '178px', ml: 3, borderRadius: '2px !important', textTransform: 'none', fontWeight: '600' }} onClick={changeStatus}>
                                Mark as complete
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Box sx={{ flexGrow: 1, mt: 3 }}>
                <Grid container>
                    <Paper elevation={0} sx={{ minHeight: '169px', border: '1px solid #E7E7E9', width: '100%' }}>
                        <Heading title={'Report'} icon={''} iconDesc={''} />
                        {/* mapping function for the contact screen (CLIENT)******************** */}
                        <Grid container spacing={0} sx={{ p: '25px' }}>
                            {/* mapping function for the contact screen (BUYER)******************** */}
                            <Grid item xs={4}>
                                <CircularProgress
                                    variant="determinate"
                                    value={percentage}
                                    size="90px"
                                    thickness={3}
                                    sx={{
                                        color: (theme) => (theme.palette.mode === 'light' ? '#757575' : '#308fe8'),
                                        animationDuration: '550ms',
                                        left: 0,
                                        [`& .${circularProgressClasses.circle}`]: {
                                            strokeLinecap: 'round',
                                        },
                                    }}
                                />
                                <br />
                                <Typography sx={{ fontSize: '14px', mt: 1 }}>{percentage}% filled out</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ minWidth: 130, borderRadius: 0 }} size="small">
                                    <p style={{ fontSize: '12px', color: '#888888' }}>Completion status</p>
                                    <Select disabled={statusValue.status === 'Cancelled' || statusValue.status === 'Complete' || statusValue.status === 'Published' ? true : false} labelId="demo-select-small" id="demo-select-small" sx={{ mt: 1, borderRadius: 0, fontWeight: 'bold' }} value={status} onChange={handleChange}>
                                        <MenuItem value={'Incomplete'} style={{ fontWeight: 'bold' }}>
                                            INCOMPLETE
                                        </MenuItem>
                                        <MenuItem value={'Complete'} style={{ fontWeight: 'bold' }} onClick={handleOpen}>
                                            COMPLETED
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button className="login_button" sx={{ height: { xs: 42, md: 34, lg: 34 }, display: props.CancelledStatus && 'none', width: { xs: 180, md: 153, lg: 153 }, fontSize: { xs: 16, md: 13, lg: 13 }, mt: '20px !important', color: '#fff' }}>
                                    Send report
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button className="login_button" onClick={() => window.open(`https://develop.d1i3zrhc7hnrzw.amplifyapp.com?guid=${guid}`, '_blank')} sx={{ height: { xs: 42, md: 34, lg: 34 }, display: props.CancelledStatus && 'none', width: { xs: 180, md: 153, lg: 153 }, fontSize: { xs: 16, md: 13, lg: 13 }, mt: '20px !important', color: '#fff' }}>
                                    View report
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Box>
        </>
    );
};

export default Report;
