import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import validator from "validator";
// material ui imports
import FormHelperText from "@mui/material/FormHelperText";
import { Grid, Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import DeleteIcon from "@mui/icons-material/Delete";
// dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
// component imports
import Breadcrumb from "../../../common/header/Breadcrumb";
import Header from "../../../common/header/Header";
import Preloader from "../../../helpers/Preloader";
import {
  onKeyPressed,
  compareStrings,
  formatPhoneNumberDashes,
  formatZip,
  formatState,
} from "../../../utils/services";
import { apiCall } from "./action";

const TeamDetails = () => {
  const location = useLocation();
  const roleList = location.state?.roleList;
  const fileInput = React.useRef();
  const editTeam = location.state ? location.state.edit : false;

  const [open, setOpen] = useState(false);
  const [defaultRole, setDefaultRole] = useState("");
  const [updatedRole, setUpdatedRole] = useState("");
  const [role, setRole] = useState("");
  const [userData, setUserData] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState({ email: "", edit: false });
  const [tempMail, setTempMail] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState({ phone: "", edit: false });
  const [tempPhone, setTempPhone] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [showLoader, setShowLoader] = useState();
  const [image, setImage] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorText, setLastNameErrorText] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  // const [cityError, setCityError] = useState(false);
  // const [cityErrorText, setCityErrorText] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorText, setPhoneErrorText] = useState("");
  const [stateError, setStateError] = useState(false);
  const [stateErrorText, setStateErrorText] = useState("");
  const [zipError, setZipError] = useState(false);
  const [zipErrorText, setZipErrorText] = useState("");
  const [homeAddressError, setHomeAddressError] = useState(false);
  const [homeAddressErrorText, setHomeAddressErrorText] = useState("");
  const [roleError, setRoleError] = useState(false);
  const [ownerChanged, setOwnerChanged] = useState(false);
  const [showOwnerChangeDialog, setShowOwnerChangeDialog] = useState(false);
  const [showProfRemoveDialog, setShowProfRemoveDialog] = useState(false);
  const [openActivateUserAlert, setOpenActivateUserAlert] = useState(false);
  const navigate = useNavigate();

  const handleCloseAlert = () => {
    setOpenActivateUserAlert(false);
  };

  // const handleActivatedOpenAlert = () => {
  //   setOpenActivateUserAlert(true);
  // };
  const uuId = sessionStorage.getItem("user_id");
  const companyId = sessionStorage.getItem("company_id");
  const role_nameId = sessionStorage.getItem("role_id");
  const member_id = location.state?.id;
  let PTadminFlag =
    role_nameId === "2" || role_nameId === "3" || role_nameId === "4"
      ? true
      : false;
  const handleCloseOwnerChangeDialog = () => {
    setOwnerChanged(false);
    setShowOwnerChangeDialog(false);
  };

  // logout function on owner change
  const logout = () => {
    setShowLoader(true);
    const formdata = { token: sessionStorage.getItem("token") };
    apiCall(
      (response) => {
        console.log("LOGOUT", response);
        const { message, success } = response.data ? response.data : "";
        if (success) {
          toast.success(message);
          sessionStorage.clear();
          navigate("/");
          setShowLoader(false);
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "LOGOUT"
    );
  };

  // Load member data
  const GetTeamUserDatas = (userType) => {
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: PTadminFlag ? member_id : uuId,
      role_id: role_nameId,
      member_id,
    };
    apiCall(
      (response) => {
        console.log("GET USER DETAILS", response);
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          setUserData(data);
          setFirstName(data.first_name);
          setLastName(data.last_name);
          setState(data.state);
          setZip(data.zip);
          setEmail({ email: data.email, edit: false });
          setTempMail(data.email);
          setCity(data.city);
          setPhone({ phone: data.phone, edit: false });
          setTempPhone(data.phone);
          setHomeAddress(data.home_address);
          setRole(data.role_id);
          setImage(data.profile_pic);
          setShowLoader(false);
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      userType
    );
  };

  // Edit team members function and API call
  const editTeamMember = (editType) => {
    setShowLoader(true);
    toast.dismiss();
    let formData = new FormData();
    let params;
    // conditions to pass mail and phone params only if they are edited
    if (email.edit || phone.edit) {
      if (
        email.edit &&
        email.email !== tempMail &&
        phone.edit &&
        phone.phone !== tempPhone
      ) {
        params = {
          company_id: companyId,
          user_id: uuId,
          role_id: role_nameId,
          member_id,
          first_name: firstName,
          last_name: lastName === null ? "" : lastName,
          email: email.email,
          phone: phone.phone === null ? "" : phone.phone,
          member_role_id: role,
          home_address: homeAddress === null ? "" : homeAddress,
          city: city === null ? "" : city,
          state: state === null ? "" : state,
          zip: zip === null ? "" : zip,
          profile_image:
            typeof image === "string" || image === null ? "" : image,
        };
      } else if (email.edit && email.email !== tempMail) {
        params = {
          company_id: companyId,
          user_id: uuId,
          role_id: role_nameId,
          member_id,
          first_name: firstName,
          last_name: lastName,
          email: email.email,
          member_role_id: role,
          home_address: homeAddress === null ? "" : homeAddress,
          city: city === null ? "" : city,
          state: state === null ? "" : state,
          zip: zip === null ? "" : zip,
          profile_image:
            typeof image === "string" || image === null ? "" : image,
        };
      } else if (phone.edit && phone.phone !== tempPhone) {
        params = {
          company_id: companyId,
          user_id: uuId,
          role_id: role_nameId,
          member_id,
          first_name: firstName,
          last_name: lastName === null ? "" : lastName,
          phone: phone.phone === null ? "" : phone.phone,
          member_role_id: role,
          home_address: homeAddress === null ? "" : homeAddress,
          city: city === null ? "" : city,
          state: state === null ? "" : state,
          zip: zip === null ? "" : zip,
          profile_image:
            typeof image === "string" || image === null ? "" : image,
        };
      } else {
        params = {
          company_id: companyId,
          user_id: uuId,
          role_id: role_nameId,
          member_id,
          first_name: firstName,
          last_name: lastName === null ? "" : lastName,
          phone: "",
          member_role_id: role,
          home_address: homeAddress === null ? "" : homeAddress,
          city: city === null ? "" : city,
          state: state === null ? "" : state,
          zip: zip === null ? "" : zip,
          profile_image:
            typeof image === "string" || image === null ? "" : image,
        };
      }
    } else {
      params = {
        company_id: companyId,
        user_id: uuId,
        role_id: role_nameId,
        member_id,
        first_name: firstName,
        last_name: lastName === null ? "" : lastName,
        member_role_id: role,
        home_address: homeAddress === null ? "" : homeAddress,
        city: city === null ? "" : city,
        state: state === null ? "" : state,
        zip: zip === null ? "" : zip,
        profile_image: typeof image === "string" || image === null ? "" : image,
      };
    }

    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    apiCall(
      (response) => {
        console.log("EDIT MEMBER", response);
        const { message, success } = response.data ? response.data : "";
        if (success) {
          toast.success(message);
          setShowLoader(false);
          ownerChanged ? logout() : navigate("/settings/team");
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formData,
      editType
    );
  };

  // activation of team member(anil)
  const activationTeamMember = (email) => {
    setShowLoader(true);
    setOpenActivateUserAlert(false);
    toast.dismiss();

    const formData = {
      company_id: companyId,
      user_id: uuId,
      role_id: role_nameId,
      user_email: email.email,
    };
    apiCall(
      (response) => {
        const { message, success } = response.data ? response.data : "";
        if (success) {
          toast.success(message);
          setShowLoader(false);
          navigate("/settings/team");
        }
        else{
          setShowLoader(false);
          toast.error(message);
        }
      },
      formData,
      "ACTIVATE_MEMBER"
    );
  };
  // end of the activation of team member(anil)

  // Add team members function and API call
  const addTeamMember = (userType) => {
    setShowLoader(true);
    toast.dismiss();
    let formData = new FormData();
    const params = {
      company_id: companyId,
      user_id: uuId,
      role_id: role_nameId,
      first_name: firstName,
      last_name: lastName,
      email: email.email,
      phone: phone.phone,
      member_role_id: role,
      home_address: homeAddress,
      city,
      state,
      zip,
      profile_image: image ? image : "",
    };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    apiCall(
      (response) => {
        console.log("ADD MEMBER", response);
        const { message, success } = response.data ? response.data : "";
        if (success) {
          toast.success(message);
          setShowLoader(false);
          PTadminFlag ? navigate("/teams") : navigate("/settings/team");
        } else {
          //usestate
          // toast.error(message);
          setShowLoader(false);
          // condition for deactived user and reactivated again(anil)
          if (message === "User is deactivated.") {
            setOpenActivateUserAlert(true);
          } else {
            setOpenActivateUserAlert(false);
            toast.error(message);
          }
        }
      },
      formData,
      userType
    );
  };

  const editTeamConfirmation = () => {
    if (member_id !== Number(uuId) && role === 5) {
      setShowOwnerChangeDialog(true);
      setOwnerChanged(true);
    } else if (PTadminFlag) editTeamMember("EDIT_PT_TEAM_MEMBER");
    else editTeamMember("EDIT_MEMBER");
  };

  // delete profile image

  const deleteProfileImage = () => {
    setShowLoader(true);
    toast.dismiss();
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: role_nameId,
      member_id,
    };

    apiCall(
      (response) => {
        console.log("DELETE_PROFILE", response);
        const { message, success } = response.data ? response.data : "";
        if (success) {
          toast.success(message);
          setShowLoader(false);
          setShowProfRemoveDialog(false);
        } else {
          toast.error(message);
          setShowLoader(false);
          setShowProfRemoveDialog(false);
        }
      },
      formdata,
      "DELETE_PROFILE"
    );
  };

  const validation = (edit) => {
    if (firstName === "" || firstName === null) {
      setFirstNameError(true);
      setFirstNameErrorText("First name is required");
    } else if (!validator.isLength(firstName, { min: 3, max: undefined })) {
      setFirstNameError(true);
      setFirstNameErrorText("Minimum 3 characters required");
    } else setFirstNameError(false);
    // if (lastName === '' || lastName === null) {
    //   setLastNameError(true);
    //   setLastNameErrorText("Last name is required");
    // } else setLastNameError(false);
    // if (phone.phone === '' || phone.phone === null) {
    //   setPhoneError(true);
    //   setPhoneErrorText("Phone is required");
    // }else if(!/^[\d\(\)\-+ ]+$/.test(phone.phone)){
    //   setPhoneError(true);
    //   setPhoneErrorText("Enter correct format");
    // }
    //  else setPhoneError(false);
    // if (homeAddress === '' || homeAddress === null) {
    //   setHomeAddressError(true);
    //   setHomeAddressErrorText("Home address is required");
    // } else setHomeAddressError(false);
    
    // email validation (anil)
    if (email.email === "" || email.email === null) {
      setEmailError(true);
      setEmailErrorText("Email is required");
    } else if ( !validator.isEmail(email.email)) {
      setEmailError(true);
      setEmailErrorText("Enter correct email format");
    } 
    else if (
      !/^[A-Z0-9._%+]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.email))
     {
      setEmailError(true);
      setEmailErrorText("Enter correct email format");
    } 
    else setEmailError(false);
    // if (city === '' || city === null) {
    //   setCityError(true);
    //   setCityErrorText("City is required");
    // } else setCityError(false);
    // if (state === '' || state === null) {
    //   setStateError(true);
    //   setStateErrorText("State is required");
    // } else setStateError(false);
    // if (zip ==='' || zip ===null) {
    //   setZipError(true);
    //   setZipErrorText("Zip is required");
    // } else if (zip.length < 5 || zip.length >6) {
    //   setZipError(true);
    //   setZipErrorText("Minimum 5 digits required");
    // }
    //    else setZipError(false);
    if (role === "") {
      setRoleError(true);
    } else setRoleError(false);
    //  if (firstName !== '' && firstName !== null &&
    // validator.isLength(firstName, { min: 3, max: undefined }) &&
    // lastName !== '' && lastName !== null &&
    // phone.phone !== '' && phone.phone !== null &&
    // /^[\d\(\)\-+ ]+$/.test(phone.phone) &&
    // homeAddress !== '' && homeAddress !== null &&
    // email.email !== '' && email.email !== null &&
    // validator.isEmail(email.email) &&
    // city !== '' && city !== null &&
    // state !=='' && state !== null &&
    // zip !== '' && zip !== null &&
    // (zip.length > 4 && zip.length < 7) &&
    // role !== '' && role !== null
    // ) edit?editTeamMember():addTeamMember();

    if (
      firstName !== "" &&
      firstName !== null &&
      // lastName !== '' && lastName !== null &&
      validator.isLength(firstName, { min: 3, max: undefined }) &&
      email.email !== "" &&
      email.email !== null &&
      validator.isEmail(email.email) &&/^[A-Z0-9._%+]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.email)&&
      role !== "" &&
      role !== null
    )
      if (edit) {
        editTeamConfirmation();
      } else {
        if (!PTadminFlag) addTeamMember("ADD_MEMBER");
        else addTeamMember("ADD_PT_TEAM_MEMBER");
      }
  };

  useEffect(() => {
    if (location.state.edit) {
      PTadminFlag
        ? GetTeamUserDatas("GET_PT_TEAM_DETAILS")
        : GetTeamUserDatas("GET_USER_DETAILS");
    }
  }, []);

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumberDashes(e.target.value);
    setPhone({ phone: formattedPhoneNumber, edit: true });
  };
  const handleZipChange = (e) => {
    const formattedZip = formatZip(e.target.value);
    setZip(formattedZip);
  };

  //modal functions
  const handleOpen = (defaultVal, currentVal) => {
    setOpen(true);
    setDefaultRole(defaultVal);
    setUpdatedRole(currentVal);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // card components
  const card = (
    <>
      <CardHeader
        action={<EditIcon onClick={() => fileInput.current.click()} />}
        title="Profile"
        className="team-profile-header"
      />
      <input
        ref={fileInput}
        type="file"
        accept="image/*"
        onChange={(e) => setImage(e.target.files[0])}
        style={{ display: "none" }}
      />
      {image && (
        <div className="prof-img-wrapper">
          <img
            className="team-prof-img"
            style={{ maxHeight: "320px", maxWidth: "100%" }}
            src={typeof image === "string" ? image : URL.createObjectURL(image)}
            alt="Thumb"
          />
          <p
            onClick={() => {
              setShowProfRemoveDialog(true);
            }}
          >
            <DeleteIcon style={{ color: "#E30303" }} />
            <span>Delete photo</span>
          </p>
        </div>
      )}
      {!image && (
        <div className="prof-img-wrapper">
          <CardMedia
            component="img"
            id="prof_image"
            className="team-prof-img"
            alt="/broken-image.jpg"
            src={
              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
            }
          />
          <p style={{ opacity: "0.4", cursor: "context-menu" }}>
            <DeleteIcon style={{ color: "#E30303" }} />
            <span>Delete photo</span>
          </p>
        </div>
      )}
    </>
  );
  const permissionCard = (
    <>
      <Grid container className="team-permission-card">
        <Grid item xs={12} sm={2}>
          <VerifiedUserIcon />
        </Grid>
        <Grid item xs={12} sm={10}>
          Permission
        </Grid>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={10} sx={{ marginTop: "7px" }}>
          <span className="team-permision-text">
            User can change settings and reschedule etc. Lorem ipsum dolor sit
            amet.
          </span>{" "}
        </Grid>
      </Grid>
    </>
  );
  const detailsCard = (
    <>
      <Grid container className="team-details-card">
        <Grid item xs={12} className="team-details-cardTitle">
          <span>Contact Info</span>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="first_name"
              >
                First Name*
              </label>
              <TextField
                id="first_name"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={firstNameError}
                helperText={`${firstNameError ? firstNameErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="last_name"
              >
                Last Name*
              </label>
              <TextField
                id="last_name"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={lastNameError}
                helperText={`${lastNameError ? lastNameErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="standard"
              className="forms-control field-reset"
            >
              <label
                className="input-form-label"
                htmlFor="email"
              >
                Email*
              </label>
              <TextField
                id="email"
                className="input-textfield "
                size="small"
                variant="outlined"
                placeholder="Email"
                value={email.email}
                onChange={(e) =>
                  setEmail({ email: e.target.value, edit: true })
                }
                error={emailError}
                helperText={`${emailError ? emailErrorText : ""}`}
                type="email"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="phone"
              >
                Phone
              </label>
              <TextField
                id="phone"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Phone"
                value={phone.phone}
                onChange={(e) => handlePhoneChange(e)}
                // onChange={(e) => setPhone({phone:e.target.value,edit:true})}
                error={phoneError}
                helperText={`${phoneError ? phoneErrorText : ""}`}
                type="tel"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="home_address"
              >
                Home Address
              </label>
              <TextField
                id="home_address"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Home address"
                value={homeAddress}
                onChange={(e) => setHomeAddress(e.target.value)}
                error={homeAddressError}
                helperText={`${homeAddressError ? homeAddressErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="city"
              >
                City
              </label>
              <TextField
                id="city"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                inputProps={{ maxLength: 25 }} 
                // error={cityError}
                // inputProps={{
                //   ...city.inputProps,
                //   onKeyPress: (e) => {
                //     if (city.length > 5) {
                //       e.preventDefault();
                //     } else {
                //       return true;
                //     }
                //   },
                // }}
                // helperText={`${cityError ? cityErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="state"
              >
                State
              </label>
              <TextField
                id="state"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="State"
                pattern="[A-Za-z]"
                inputProps={{ maxLength: 2 }}
                value={state}
                onChange={(e) =>
                  setState(formatState(e.target.value.toUpperCase()))
                }
                error={stateError}
                helperText={`${stateError ? stateErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} className="zip-field">
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="zip"
              >
                Zip
              </label>
              <TextField
                id="zip"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Zip"
                value={zip}
                onKeyDown={onKeyPressed}
                // onChange={(e) => setZip(e.target.value)}
                onChange={(e) => handleZipChange(e)}
                error={zipError}
                helperText={`${zipError ? zipErrorText : ""}`}
                type="number"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={7} md={6} style={{ marginTop: "6px" }}>
            <label
              className="input-form-label pl-15 mb-5"
              htmlFor="Role"
            >
              Role*
            </label>
            {role_nameId == "2" && location.state.edit && role == 2 ? (
              <FormControl fullWidth className="forms-control field-reset">
                <TextField
                  disabled
                  id="Role"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder=""
                  value={"Palmtech Owner"}
                  type="text"
                />
              </FormControl>
            ) : (
              <FormControl fullWidth className="forms-control field-reset">
                <Select
                  disabled={
                    (role_nameId !== "5" && location.state.edit && role == 5) ||
                    (role_nameId == "2" && location.state.edit && role == 2)
                  }
                  labelId="demo-simple-select-label"
                  id="Role"
                  size="small"
                  displayEmpty
                  value={role}
                  onChange={handleChangeRole}
                  error={roleError}
                >
                  <MenuItem value="">Select Role</MenuItem>
                  {roleList
                    ?.sort(function (a, b) {
                      return compareStrings(a.display_name, b.display_name);
                    })
                    .map((role, key) => {
                      return (
                        <MenuItem key={key} value={role.role_id}>
                          {role.display_name}
                        </MenuItem>
                      );
                    })}
                </Select>
                {roleError && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    Role is required
                  </FormHelperText>
                )}
              </FormControl>
            )}

            {role === "Admin" && (
              <span className="role-info">
                As an admin, the team member will be able to create and manage
                new inspections, manage other team member details, see billing
                information, and manage payments.
              </span>
            )}
            {role === "Inspector" && (
              <span className="role-info">
                As an inpector, the team member will be able to create and
                manage new inspections, but not see other team member details or
                billing information.
              </span>
            )}
            {role === "Owner" && (
              <span className="role-info">
                As an owner, (owner role description here..........)
              </span>
            )}
          </Grid>
          <Grid item xs={12} sm={5} md={6}></Grid>

          <Grid item xs={12} className="team-detail-cta">
            <Button
              className="back-btn"
              id="cancel_changes"
              onClick={() => {
                PTadminFlag ? navigate("/teams") : navigate("/settings/team");
              }}
            >
              Cancel
            </Button>
            <Button id="submit_team_data"
              onClick={() => (editTeam ? validation(true) : validation(false))}
              className="default-btn teams-detail-btn"
            >
              {editTeam ? "Update" : "Finish"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
  return (
    <>
      <Header />
      <Box className="page-background">
        <Breadcrumb />
        <Preloader showPreloader={showLoader} />

        {/* ------------------------activation alert dialog box start(anil)-------------- */}
        <Dialog
          open={openActivateUserAlert}
          onClose={handleCloseAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="delete-alert-modal"
        >
          <DialogTitle id="alert-dialog-title">
            {"Activate team member?"}
            <CloseIcon className="close-icon" onClick={handleCloseAlert} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            A user with the same email address already exists in deactivate mode. Do you want to reactivate the user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="delete-modal-cancel-btn"
              onClick={handleCloseAlert}
            >
              No
            </Button>
            <Button
              className="default-btn"
              variant="contained"
              color="error"
              onClick={() => {
                activationTeamMember(email);
              }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* ------------------------activation alert dialog box end(anil)-------------- */}


        {/* ------------------delete alert dialog start --------------*/}
        <Dialog
          open={showOwnerChangeDialog}
          onClose={handleCloseOwnerChangeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="delete-alert-modal"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure to change owner"}
            <CloseIcon
              className="close-icon"
              onClick={handleCloseOwnerChangeDialog}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your role will be changed to Admin and will be logged out from
              current session.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="delete-modal-cancel-btn"
              onClick={handleCloseOwnerChangeDialog}
            >
              Cancel
            </Button>
            <Button
              className="default-btn"
              variant="contained"
              color="error"
              onClick={() => {
                editTeamMember("EDIT_MEMBER");
                setShowOwnerChangeDialog(false);
              }}
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {/* ------------------delete alert dialog end --------------*/}
        {/* ------------------delete profile image alert dialog------------------------ */}
        <Dialog
          open={showProfRemoveDialog}
          onClose={() => setShowProfRemoveDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="delete-alert-modal"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete confirmation"}
            <CloseIcon
              className="close-icon"
              onClick={() => setShowProfRemoveDialog(false)}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to delete the profile image?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="delete-modal-cancel-btn"
              onClick={() => setShowProfRemoveDialog(false)}
            >
              Cancel
            </Button>
            <Button
              className="default-btn"
              variant="contained"
              color="error"
              onClick={() => {
                deleteProfileImage();
                setImage("");
              }}
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {/* ----------------------------profile section-------------------------------- */}
        <Grid container className="teams-container">
          <Grid item xs={12} className="details-title">
            <span className="team-user-name">{userData?.first_name}</span>
            <br />
            {/* <div className="role-wrapper">
              {userData?.role !== "Inspector" ? (
                <VerifiedUserIcon className="owner-icon" />
              ) : (
                <BusinessCenterIcon sx={{ fontSize: "16px" }} />
              )}
              <div>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    // value={age}
                    onChange={(e)=>handleOpen(userData?.role,e.target.value)}
                    // displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <KeyboardArrowDownIcon className="arrowDown-icon" />
                    )}
                    value={userData?.role}
                  >
                    <MenuItem value={"Admin"}>Admin</MenuItem>
                    <MenuItem value={"Inspector"}>Inspector</MenuItem>
                    <MenuItem value={"Owner"}>Owner</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div> */}
          </Grid>
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={3}>
                <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
                  <Card variant="outlined">{card}</Card>
                </Box>
                {/* <Box sx={{ minWidth: 145 }}>
                  <Card variant="outlined">{permissionCard}</Card>
                </Box> */}
              </Grid>
              <Grid item xs={12} sm={9}>
                <Card variant="outlined">{detailsCard}</Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default TeamDetails;
