import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import toast from "react-hot-toast";
import TextField from "@mui/material/TextField";
import { Grid, Modal } from "@mui/material";
import { onKeyPressed } from "../../utils/services";
import { Box } from "@mui/system";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

const AgentDetails = (props) => {
  const {
    setBuyerAgent2,
    agentsLists,
    buyerAgent2,
    handleChangeAgent,
    handleNewAgent,
    isNewAgent,
    user3,
    handleUser3,
    handleBuyerAgent,
    buyerAgent,
    setBuyerAgent,
    sellerAgent,
    sellerAgent2,
    handleSellerAgent,
    handleSellerAgent2,
    AddNewAgentRequest,
    getDetailsFetch,
    setSellerAgent,
    setSellerAgent2,
    formdata3,
  } = props;

  //popUP for adding new agent
  const [popUptype, setPopUptype] = useState("");
  const [agentFname, setAgentFname] = useState(null);
  const [agentLname, setAgentLname] = useState(null);
  const [agentemail, setAgentemail] = useState(null);
  const [open, setOpen] = useState(true);

  const useStyles = styled((theme) => ({
    inputRoot: {
      "& .MuiOutlinedInput-notchedOutline": {
        height: buyerAgent2.length === 0 && "45px",
        paddingTop: buyerAgent2.length === 0 && "10px",
      },
    },
  }));
  const PaperComponentCustom = (options, action) => {
    const classes = useStyles();
    if (action === "buyerAgent1") setPopUptype("buyerAgent1");
    else if (action === "buyerAgent2") setPopUptype("buyerAgent2");
    else if (action === "sellerAgent1") setPopUptype("sellerAgent1");
    else if (action === "sellerAgent2") setPopUptype("sellerAgent2");
    const { containerProps, children } = options;
    return (
      <Paper className={classes.paper} {...containerProps}>
        {children}
        <Divider />
        <Button fullWidth className="add-select" onMouseDown={handleNewAgent}>
          + Add New
        </Button>
      </Paper>
    );
  };
  //ending

  //addnew agent api call starting (anil)
  const agentValidation = (popUptypeNew) => {
    if (formdata3.first_name == "") {
      setAgentFname("Name cannot be blank");
    } else {
      setAgentFname(undefined);
    }
    if (formdata3.last_name == "") {
      setAgentLname("LastName cannot be blank");
    } else {
      setAgentLname(undefined);
    }
    if (formdata3.email == "") {
      setAgentemail("Email cannot be blank");
    } else {
      setAgentemail(undefined);
    }
    if ((formdata3.first_name != "", formdata3.last_name != "", formdata3.email != "")) {
      AddNewAgentReq();
      handleNewAgent();
    }
  };
  const AddNewAgentReq = () => {
    AddNewAgentRequest((response) => {
      const { data, message, success } = response.data;
      if (response.data.success) {
        if (popUptype === "buyerAgent1") setBuyerAgent([...buyerAgent, { id: data[0].agent_id, name: data[0].agent_name }]);
        else if (popUptype === "buyerAgent2") setBuyerAgent2([...buyerAgent2, { id: data[0].agent_id, name: data[0].agent_name }]);
        else if (popUptype === "sellerAgent1") setSellerAgent([...sellerAgent, { id: data[0].agent_id, name: data[0].agent_name }]);
        else if (popUptype === "sellerAgent2") setSellerAgent2([...sellerAgent2, { id: data[0].agent_id, name: data[0].agent_name }]);
        toast.success(message);
        getDetailsFetch();
      } else {
        toast.error(message);
      }
    }, formdata3);
  };
  //api call ending

  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [unshow, setUnShow] = useState(true);
  const [sellerShow, setSellerShow] = useState(false);
  const [sellerUnshow, setSellerUnShow] = useState(true);

  const handlehide = () => {
    setShow((prev) => !prev);
    setUnShow(false);
  };

  const deleteHideAgent = () => {
    setBuyerAgent2([]);
    setShow((prev) => !prev);
    setUnShow((prev) => !prev);
  };

  const handleSellerhide = () => {
    setSellerShow((prev) => !prev);
    setSellerUnShow(false);
  };

  const deleteHideSeller = () => {
    setSellerAgent2([]);
    setSellerShow((prev) => !prev);
    setSellerUnShow((prev) => !prev);
  };

  return (
    <>
      {/* buyer agent 1 starting  */}
      <Grid container className="modal-wrapper input-controll">
        <Grid item xs={12} sm={12}>
          <Typography>
            <Box sx={{ fontWeight: 900, m: 1 }}>BUYER'S AGENT</Box>
          </Typography>

          <FormControl fullWidth className="forms-control multi-select">
            <label className="input-form-label-select" htmlFor="buyuers-agent" id="demo-simple-select-label">
              BUYERS AGENT 1
            </label>
            {/* for multiple agent selection in step 4 */}
            <Autocomplete
              className="forms-control1 custom-color"
              id="tags-outlined"
              classes={classes}
              options={buyerAgent.length <= 0 ? agentsLists : []}
              filterSelectedOptions
              multiple
              maximumSelectionLength
              size="small"
              value={buyerAgent}
              getOptionLabel={(option) => option.name}
              PaperComponent={buyerAgent.length <= 0 ? (option) => PaperComponentCustom(option, "buyerAgent1") : console.log("can not select more agent")}
              onChange={(e, value) => handleBuyerAgent(value)}
              renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" deleteIcon={<CloseIcon />} label={option.name} {...getTagProps({ index })} />)}
              renderInput={(params) => <TextField {...params} placeholder={buyerAgent.length <= 0 ? "Select Agent" : []} />}
            />
            <Typography>
              {unshow && buyerAgent2.length === 0 && (
                <Button id="hideButton" onClick={handlehide}>
                  <Box sx={{ fontWeight: 900, m: 1, color: "#4282E2" }}>
                    Add another buyer's agent
                    <AddCircleIcon
                      sx={{
                        fontSize: 18,
                        verticalAlign: "middle",
                        minWidth: 30,
                        display: "inline-flex",
                      }}
                    />
                  </Box>
                </Button>
              )}
            </Typography>
          </FormControl>
          {/* buyer agent 1 ending */}
          {/* buyer agent 2 starting */}
          {(show || buyerAgent2.length !== 0) && (
            <FormControl fullWidth className="forms-control multi-select">
              <label className="input-form-label-select" htmlFor="buyuers-agent" id="demo-simple-select-label">
                BUYERS AGENT 2
              </label>
              {/* for multiple agent selection in step 4 */}
              <div className="buyer2">
                <Autocomplete
                  sx={{ display: "flex", direction: "left", width: 790 }}
                  className=" custom-color"
                  id="tags-outlined"
                  classes={classes}
                  options={buyerAgent2.length <= 0 ? agentsLists : []}
                  filterSelectedOptions
                  multiple
                  size="small"
                  value={buyerAgent2}
                  getOptionLabel={(option) => option.name}
                  PaperComponent={buyerAgent2.length <= 0 ? (option) => PaperComponentCustom(option, "buyerAgent2") : console.log("can not select more agent")}
                  onChange={(e, value) => handleChangeAgent(value)}
                  renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" deleteIcon={<CloseIcon />} label={option.name} {...getTagProps({ index })} />)}
                  renderInput={(params) => <TextField {...params} placeholder={buyerAgent2.length <= 0 ? "Select Agent" : []} />}
                />
                <Button onClick={deleteHideAgent}>
                  <DeleteIcon sx={{ color: "red" }} />
                </Button>
              </div>
              <Typography>
                {buyerAgent2.length <= 0 ? (
                  <Button
                    onMouseDown={() => {
                      handleNewAgent();
                      setPopUptype("buyerAgent2");
                    }}
                  >
                    <Box sx={{ fontWeight: 900, m: 1, color: "#4282E2" }}>
                      Add another buyer's agent
                      <AddCircleIcon
                        sx={{
                          fontSize: 18,
                          verticalAlign: "middle",
                          minWidth: 30,
                          display: "inline-flex",
                        }}
                      />
                    </Box>
                  </Button>
                ) : (
                  ""
                )}
              </Typography>
            </FormControl>
          )}
        </Grid>
        {/* buyer agent 2 ending */}
        {/* seller agent 1 starting */}
        <Grid item xs={12} sm={12}>
          <Typography>
            <Box sx={{ fontWeight: 900, m: 1 }}>SELLER'S AGENT</Box>
          </Typography>

          <FormControl fullWidth className="forms-control multi-select">
            <label className="input-form-label-select" htmlFor="buyuers-agent" id="demo-simple-select-label">
              SELLER AGENT 1
            </label>
            {/* for multiple agent selection in step 4 */}
            <Autocomplete
              className="forms-control1 custom-color"
              id="tags-outlined"
              classes={classes}
              options={sellerAgent.length <= 0 ? agentsLists : []}
              filterSelectedOptions
              multiple
              maximumSelectionLength
              size="small"
              value={sellerAgent}
              getOptionLabel={(option) => option.name}
              PaperComponent={sellerAgent.length <= 0 ? (option) => PaperComponentCustom(option, "sellerAgent1") : console.log("can not select more agent")}
              onChange={(e, value) => handleSellerAgent(value)}
              renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" deleteIcon={<CloseIcon />} label={option.name} {...getTagProps({ index })} />)}
              renderInput={(params) => <TextField {...params} placeholder={sellerAgent.length <= 0 ? "Select Agent" : []} />}
            />

            <Typography>
              {sellerUnshow && sellerAgent2.length === 0 && (
                <Button id="hideButton" onClick={handleSellerhide}>
                  <Box sx={{ fontWeight: 900, m: 1, color: "#4282E2" }}>
                    Add another seller's agent
                    <AddCircleIcon
                      sx={{
                        fontSize: 18,
                        verticalAlign: "middle",
                        minWidth: 30,
                        display: "inline-flex",
                      }}
                    />
                  </Box>
                </Button>
              )}
            </Typography>
          </FormControl>
          {/* seller agent 1 ending */}
          {/* seller agent 2 starting */}
          {(sellerShow || sellerAgent2.length !== 0) && (
            <FormControl fullWidth className="forms-control multi-select">
              <label className="input-form-label-select" htmlFor="buyuers-agent" id="demo-simple-select-label">
                SELLER AGENT 2
              </label>
              {/* for multiple agent selection in step 4 */}
              <div className="buyer2">
                <Autocomplete
                  sx={{ display: "flex", direction: "left", width: 790 }}
                  className=" custom-color"
                  id="tags-outlined"
                  classes={classes}
                  options={sellerAgent2.length <= 0 ? agentsLists : []}
                  filterSelectedOptions
                  multiple
                  size="small"
                  value={sellerAgent2}
                  getOptionLabel={(option) => option.name}
                  PaperComponent={sellerAgent2.length <= 0 ? (option) => PaperComponentCustom(option, "sellerAgent2") : console.log("can not select more agent")}
                  onChange={(e, value) => handleSellerAgent2(value)}
                  renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" deleteIcon={<CloseIcon />} label={option.name} {...getTagProps({ index })} />)}
                  renderInput={(params) => <TextField {...params} placeholder={sellerAgent2.length <= 0 ? "Select Agent" : []} />}
                />
                <Button onClick={deleteHideSeller}>
                  <DeleteIcon sx={{ color: "red" }} />
                </Button>
              </div>
              <Typography>
                {sellerAgent2.length <= 0 ? (
                  <Button
                    onMouseDown={() => {
                      handleNewAgent();
                      setPopUptype("sellerAgent2");
                    }}
                  >
                    <Box sx={{ fontWeight: 900, m: 1, color: "#4282E2" }}>
                      Add another seller's agent
                      <AddCircleIcon
                        sx={{
                          fontSize: 18,
                          verticalAlign: "middle",
                          minWidth: 30,
                          display: "inline-flex",
                        }}
                      />
                    </Box>
                  </Button>
                ) : (
                  ""
                )}
              </Typography>
            </FormControl>
          )}
        </Grid>
        {/* seller agent 2 ending */}
        {/* add new agent form */}
        {isNewAgent ? (
          <Modal open={open} className={"inspection-filter-modal-main"}>
            <Box sx={{ maxWidth: "730px" }} className={"inspection-filter-modal"}>
              <Grid className={"inspection-filter-modal-grid-main"} container spacing={2}>
                <Grid item xs={8}>
                  <h3 className={"modal_title"}>Create new agent contact</h3>
                </Grid>
                <Grid item xs={4}>
                  <CloseIcon onMouseDown={handleNewAgent} className={"inspection-filter-modal-close-icon"} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" className="add-agent-form">
                    <label className="input-form-label-select" htmlFor="first-name" id="demo-simple-select-label">
                      First Name
                    </label>
                    <TextField
                      className="input-textfield"
                      id="clientFirstId3"
                      size="small"
                      value={user3.first_name}
                      name="first_name"
                      variant="outlined"
                      onChange={handleUser3}
                      placeholder="First Name"
                      type="text"
                      error={agentFname === null ? false : agentFname === undefined ? false : true}
                      helperText={agentFname}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" className="add-agent-form">
                    <label className="input-form-label-select" htmlFor="last-name" id="demo-simple-select-label">
                      Last Name
                    </label>
                    <TextField
                      className="input-textfield"
                      size="small"
                      id="clientLastId3"
                      name="last_name"
                      value={user3.last_name}
                      onChange={handleUser3}
                      variant="outlined"
                      placeholder="Last Name"
                      type="text"
                      error={agentLname === null ? false : agentLname === undefined ? false : true}
                      helperText={agentLname}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" className="add-agent-form">
                    <label className="input-form-label-select" htmlFor="email" id="demo-simple-select-label">
                      Email
                    </label>
                    <TextField
                      className="input-textfield"
                      id="clientEmailId3"
                      size="small"
                      name="email"
                      value={user3.email}
                      onChange={handleUser3}
                      variant="outlined"
                      placeholder="Email"
                      type="email"
                      error={agentemail === null ? false : agentemail === undefined ? false : true}
                      helperText={agentemail}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" className="add-agent-form">
                    <label className="input-form-label-select" htmlFor="phone" id="demo-simple-select-label">
                      Phone
                    </label>
                    <TextField className="input-textfield" id="clientPhoneId3" size="small" value={user3.phone} onChange={handleUser3} placeholder="Phone" name="phone" onKeyDown={onKeyPressed} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" className="add-agent-form">
                    <label className="input-form-label-select" htmlFor="company" id="demo-simple-select-label">
                      Company
                    </label>

                    <TextField className="input-textfield" size="small" name="company" value={user3.company} onChange={handleUser3} variant="outlined" placeholder="Company" type="text" />
                  </FormControl>
                </Grid>
                <Grid container spacing={2} className="inspection-filter-modal-buttons">
                  <Button variant="none" className="cancel-btn" onMouseDown={handleNewAgent}>
                    <p> Cancel</p>
                  </Button>
                  <Button className="inspection-filter-modal-buttons-apply" onMouseDown={() => agentValidation(popUptype)}>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        ) : (
          ""
        )}
        {/* add new agent form ending */}
      </Grid>
    </>
  );
};

export default AgentDetails;
