import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../common/header/Breadcrumb";
import Header from "../../common/header/Header";
import Table from "../table/Table";
import { GetFilteredInspections, GetInspectionList } from "./action";
import LoadingSkelton from "../../helpers/LoadingSkeleton";
import Preloader from "../../helpers/Preloader";
import { apiCall } from "../../utils/action";
import moment from "moment";
import { ConvertDate } from "../../utils/services";

function Inspections() {
  const [showLoader, setShowLoader] = useState();
  const [InspectionList, setInspectionList] = useState();
  const [inspCountData, setInspCountData] = useState([]);
  const [loggedUserData, setLoggedUserData] = useState([]);
  const [table_data, setTableData] = React.useState();
  const uuId = sessionStorage.getItem("user_id");
  const roleId = sessionStorage.getItem("role_id");
  const companyId = sessionStorage.getItem("company_id");
  const [loade, reLoader] = useState(0);
  const [rowChange, setRowChange] = useState(false);
  const [FilterType, setFilterType] = React.useState(0);
  const [paymentStatus,setPaymentStatus] = useState();
  const [agreementStatus,setAgreementStatus] = useState();
  const [chargifyURL,setChargifyURL] = useState('');

  const [specificDate, setSpecficDate] = React.useState(moment());
  const [fromDate, setFromDate] = React.useState(moment());
  const [toDate, setToDate] = React.useState(moment());

  const [allInspectors, setAllInspectors] = React.useState([]);
  const [allAgents, setAllAgents] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalList, setTotalList] = useState(0);
  const [restrict, setRestrict] = useState(0);
  const [PageType, setPageType] = React.useState("inspection");

  const [payment, setPayment] = React.useState({
    Paid: false,
    Unpaid: false,
  });

  const [agreement, setAgreement] = React.useState({
    Signed: false,
    Unsigned: false,
  });

  const [status, setStatus] = React.useState({
    Scheduled: false,
    Draft: false,
    Inprogress: false,
    Complete: false,
    Published: false,
    Cancelled: false,
  });

  const handleStatus = (event) => {
    setStatus({
      ...status,
      [event.target.name]: event.target.checked,
    });
  };

  const handlePaid = (event) => {
    setPayment({
      ...payment,
      [event.target.name]: event.target.checked,
    });
  };

  const handleAgreement = (event) => {
    setAgreement({
      ...agreement,
      [event.target.name]: event.target.checked,
    });
  };

  const RemoveStatusChange = (data) => {
    setStatus({
      ...status,
      [data]: false,
    });
  };

  const RemovePaidChange = (data) => {
    setPayment({
      ...payment,
      [data]: false,
    });
  };

  const RemoveAgreeChange = (data) => {
    setAgreement({
      ...agreement,
      [data]: false,
    });
  };

  const handleSpecificDate = (newValue) => {
    setSpecficDate(newValue);
  };

  const handleFromDate = (newValue) => {
    setFromDate(newValue);
  };

  const handleToDate = (newValue) => {
    setToDate(newValue);
  };

  const handleChangeInspectors = (val) => {
    setAllInspectors(val);
  };

  const handleChangeAgents = (val) => {
    setAllAgents(val);
  };

  const RemoveAgentsChange = (data) => {
    var removedAgents = [];
    data.length > 0 &&
      data.map((sel) =>
        allAgents.map((data) => {
          if (sel === data.name) {
            removedAgents.push(data);
          }
        })
      );
    setAllAgents(removedAgents);
  };

  const RemoveInspectorChange = (data) => {
    var removedIns = [];
    data.length > 0 &&
      data.map((sel) =>
        allInspectors.map((data) => {
          if (sel === data.name) {
            removedIns.push(data);
          }
        })
      );
    setAllInspectors(removedIns);
  };
  // const [val, setVal] = useState(Object.keys(status).filter(k => status[k] === true))

  const SelectedStatus = Object.keys(status).filter((k) => status[k] === true);
  const SelectedPayment = Object.keys(payment).filter(
    (k) => payment[k] === true
  );
  const paymentIds = SelectedPayment.map((name) =>
    name === "Paid" ? 1 : name === "Unpaid" && 0
  );

  const SelectedAgreement = Object.keys(agreement).filter(
    (k) => agreement[k] === true
  );
  const agreementIds = SelectedAgreement.map((name) =>
    name === "Signed" ? 1 : name === "Unsigned" && 0
  );

  // const vivid = SelectedStatus.length > 0 && SelectedStatus

  var Inspectors = [];
  const getIds =
    allInspectors.length > 0 &&
    allInspectors.map((ins) => Inspectors.push(ins.id));

  var inspNames = [];

  allInspectors.length > 0 &&
    allInspectors.map((ins) => inspNames.push(ins.name));

  var agentNames = [];

  allAgents.length > 0 && allAgents.map((agent) => agentNames.push(agent.name));

  const getNames = allInspectors.length > 0 && allInspectors.map((ins) => Inspectors.push(ins.id));

  var agentIds = [];
  const age =allAgents.length > 0 && allAgents.map((agent) => agentIds.push(agent.id));

  const isTrialCompany = sessionStorage.getItem("is_trial_company");

  const GetTotalList = (data) => {
    setTotalList(data);
  };

  const GetInspectionLists = () => {
    if (!rowChange) {
      setShowLoader(true);
    }
    const queryParams = `?page=${page + 1}`;
    const formdata = {
      local: {
        company_id: companyId,
        user_id: uuId,
        role_id: roleId,
        page_count: rowsPerPage,
      },
      query: queryParams,
    };
    GetInspectionList((response) => {
      const { data, message, success } = response?.data;
      if (success) {
        setShowLoader(false);
        setPageType("inspection");
        // toast.success(message);
        setInspectionList(data.data);
        setTableData(data.data);
        GetTotalList(data.total);
      } else {
        console.log("else ", message);
        setShowLoader(false);
      }
    }, formdata);
  };

  const FilterTypeHandler = (event, newValue) => {
    setFilterType(newValue);
  };

  const removeDate = () => {
    setFilterType(0);
  };

  const AllFilters = {
    inspectors: inspNames.length === 0 ? null : inspNames,
    agents: agentNames.length === 0 ? null : agentNames,
    paid: SelectedPayment.length === 0 ? null : SelectedPayment,
    agreement: SelectedAgreement.length === 0 ? null : SelectedAgreement,
    status: SelectedStatus.length === 0 ? null : SelectedStatus,
    dates:
      FilterType === 1
        ? ConvertDate(specificDate)
        : FilterType === 2
        ? ConvertDate(fromDate) + `-` + ConvertDate(toDate)
        : null,
  };

  const dateRange = {
    from_date: ConvertDate(fromDate),
    to_date: ConvertDate(toDate),
  };

  const fixDate = {
    specific_date: ConvertDate(specificDate),
  };

  const TotalFilter = {
    company_id: companyId,
    user_id: uuId,
    role_id: roleId,
    agent_id: agentIds,
    inspector_id: Inspectors,
    status: SelectedStatus,
    paid: paymentIds,
    agreement: agreementIds,
    page_count: rowsPerPage,
  };
  const getCompanyInfo =()=>{
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setPaymentStatus(data.payment?.payment_status);
          setAgreementStatus(data.agreement?.agreement_status); 
          setChargifyURL(data.chargify_public_signup_page_url);        
          setShowLoader(false);
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      "GET_COMPANY_INFO"
    );
  }
  const FilterInspections = () => {
    // setShowLoader(true);
    const queryParams = `?page=${page}`;
    const formdata = {
      local:
        FilterType === 0
          ? TotalFilter
          : FilterType === 1
          ? { ...TotalFilter, ...fixDate }
          : { ...TotalFilter, ...dateRange },
      query: queryParams,
    };

    GetFilteredInspections((response) => {
      const { data, message, success } = response.data;
      if (success) {
        setShowLoader(false);
        setPageType("filter");
        // toast.success(message);
        setInspectionList(data.data);
        setTableData(data.data);
        GetTotalList(data.total);
        // reLoader(loade + 1)
      } else {
        setTableData([]);
        setShowLoader(false);
      }
    }, formdata);
  };

  const BackFilterdata = (data) => {
    setTableData(data);
  };

  const handleSearchFilter = (event) => {
    let searchQuery = event.target.value.toLowerCase();
    let searchData = table_data.filter((ele) => {
      return ele.orderclient[0]?.client?.first_name?.toLowerCase().includes(searchQuery)|| ele.orderagent[0]?.agent?.first_name?.toLowerCase().includes(searchQuery)||ele.orderinspector[0]?.inspector?.first_name?.toLowerCase().includes(searchQuery)||ele.location_city?.toLowerCase().includes(searchQuery)||ele.location_zip?.toLowerCase().includes(searchQuery);;});
    if (searchQuery !== "") {
      if (searchData.length !== 0) {
        setTableData(searchData);
      }
    } else {
      setTableData(table_data);
      reLoader(loade + 1);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setRowChange(true);
    if (PageType === "inspection") {
      reLoader(loade + 1);
    } else if (PageType === "filter") {
      setRestrict(restrict + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setRowChange(true);
    if (PageType === "inspection") {
      reLoader(loade + 1);
    } else if (PageType === "filter") {
      setRestrict(restrict + 1);
    }
  };

  const getInspectionsCount = () => {
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          setInspCountData(data);
          setShowLoader(false);
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      "GET_INSPECTIONS_COUNT"
    );
  };
  // get owner details
  const getOwnerData = () => {
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_name_id: roleId,
      member_id: uuId,
    };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          setLoggedUserData(data);
          setShowLoader(false);
        } else {
          console.log("else ", message);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_USER_DETAILS"
    );
  };

  useEffect(() => {
    // GetInspectionLists();
    getInspectionsCount();
    getOwnerData();
  }, []);

  useEffect(() => {
    GetInspectionLists();
    getCompanyInfo()
  }, [loade]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className="page-background">
        <Header />
        <Breadcrumb />
        {showLoader ? (
          <Preloader showPreloader={true} />
        ) : (
          <Table
            InspectionList={InspectionList}
            GetInspectionLists={GetInspectionLists}
            filterHandler={FilterInspections}
            clearInspections={() => GetInspectionLists()}
            handleSearchFilter={handleSearchFilter}
            table_data={table_data}
            FilterType={FilterType}
            specificDate={specificDate}
            fromDate={fromDate}
            toDate={toDate}
            reLoader={reLoader}
            restricter={() => setRestrict(restrict + 1)}
            pgType={() => setPageType("filter")}
            restrict={restrict}
            handleSpecificDate={(newValue) => handleSpecificDate(newValue)}
            handleFromDate={(newValue) => handleFromDate(newValue)}
            handleToDate={(newValue) => handleToDate(newValue)}
            BackFilterdata={(data) => BackFilterdata(data)}
            GetTotalList={(data) => GetTotalList(data)}
            allInspectors={allInspectors}
            allAgents={allAgents}
            handleStatus={handleStatus}
            TotalFilter={AllFilters}
            status={status}
            removeDate={removeDate}
            payment={payment}
            agreement={agreement}
            page={page}
            totalList={totalList}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            handlePaid={handlePaid}
            handleAgreement={handleAgreement}
            handleChangeInspectors={handleChangeInspectors}
            handleChangeAgents={handleChangeAgents}
            RemoveAgentsChange={(data) => RemoveAgentsChange(data)}
            RemoveInspectorChange={(data) => RemoveInspectorChange(data)}
            RemoveStatusChange={(data) => RemoveStatusChange(data)}
            RemovePaidChange={(data) => RemovePaidChange(data)}
            RemoveAgreeChange={(data) => RemoveAgreeChange(data)}
            FilterTypeHandler={FilterTypeHandler}
            inspCountData={inspCountData}
            loggedUserData={loggedUserData}
            paymentStatus={paymentStatus}
            agreementStatus={agreementStatus}
            chargifyURL={chargifyURL}
          />
        )}
      </Box>
    </>
  );
}

export default Inspections;
