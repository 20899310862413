import React, { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import {  styled } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";  
import { timestamp } from "../../../utils/services";

const Checks = (props) => {

  const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  })); 

  const {labels,numbers, index, lineId, categoryId,item, selectedID, AllCategoryList, updateTemplate, isPlaceHolder, from} = props;

  // console.log(labels)
  const [isChecked, setIsChecked] = useState(labels.is_selected);
  const [checkedData, setIsCheckedData] = useState()
  const isMobile = useMediaQuery('(max-width: 600px)');


  const handleCheckBox = ( value,uuid) => {
    return (e) => { 
      let lineIndex = null;
      let locationIndex = null;
      const catList = AllCategoryList?.template?.categories;
      const categoriesDataIndex = catList.findIndex(
        (cat) => cat.category_id === categoryId
      );
      lineIndex = catList[categoriesDataIndex].lines.findIndex(
        (line) => line.line_id === lineId
      );
      // console.log(value)
      if(!isPlaceHolder){
        setIsChecked(e.target.checked)

        locationIndex = catList[categoriesDataIndex].lines[
          lineIndex
        ].line_input_controls.findIndex(
          (info) => info.line_input_control_id === selectedID
        );
        let selectd = catList[categoriesDataIndex].lines[lineIndex].line_input_controls[
          locationIndex
        ].values
        // let selArr = 
        selectd.map((value) => {
          if(value.uuid === uuid) {
           value.is_selected = e.target.checked;
           value.updated_at = timestamp();
          }
         })
    
        let sels = selectd.filter((sel) => {
          return sel.is_selected === true
        })
        let dataArr = []
        sels.map((gas) => {
          dataArr.push(gas.value)
        })
        catList[categoriesDataIndex].lines[lineIndex].line_input_controls[
          locationIndex
        ].data = dataArr
        catList[categoriesDataIndex].lines[lineIndex].line_input_controls[
          locationIndex
        ].updated_at = timestamp();
        // console.log(dataArr)
        // selArr=
    
        updateTemplate("noToast")
      } else {
        setIsChecked(e.target.checked)
        item.values.map((val) => {
          if(val.value === value){
            val.is_selected = e.target.checked;
            val.updated_at = timestamp();
          }
        })

        let sels = item.values.filter((sel) => {
          return sel.is_selected === true
        })

        let dataArr = []
        sels.map((gas) => {
          dataArr.push(gas.value)
        })
        item.data =dataArr

        locationIndex = catList[categoriesDataIndex].lines[
          lineIndex
        ].line_input_controls.push(item)
        updateTemplate("noToast");

      }

  }
  }
  useEffect(() => {
    setIsChecked(labels.is_selected)
  }, [labels])  
  return (
    <>
    
            <FormGroup
              sx={{ backgroundColor: index % 6 < 3 &&  "#F5F6F8" , width: isMobile && "100% !important"}}
              className="section__checks__list"
            >
              <FormControlLabel
                control={<Checkbox checked={isChecked} onChange={ handleCheckBox(labels.value,labels.uuid) } className="section__checks__table" />}
                label={
                  <>
                      {labels?.value?.length > 15 ? (
                                    <BootstrapTooltip title={<h1 className="font-13">{labels?.value}</h1>}>
                                      <Typography className="section__checks__table">{`${labels?.value.substring(11, 0)}...`}</Typography>
                                    </BootstrapTooltip>
                                  ) : (
                                    <Typography className="section__checks__table">{labels?.value}</Typography>
                                  )}
                  </>
                }
              />
            </FormGroup>
    </>
  );
};

export default Checks;
