import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// mui imports
import { styled } from '@mui/material/styles';
import axios from 'axios'; //API calls
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import { apiCall } from '../../utils/action';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Settings from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ForumIcon from '@mui/icons-material/Forum';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Preloader from '../../helpers/Preloader';
import { API_CONSTANTS } from '../../config/api-constants';
// custom style imports
import '../../../assets/styles/containers/_header.scss';
// image imports
import Logo from '../../../assets/images/logo-horizontal.png';
import LogoVertical from '../../../assets/images/logo.png';
import CircleIcon from '@mui/icons-material/Circle';
// component imports
import Navbar from './Navbar';
import toast from 'react-hot-toast';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,

    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    'color': 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const Header = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    // const isTaborMobile = useMediaQuery('(min-width:900px)');
    const isQueryBreakPoint = useMediaQuery('(max-width:750px');
    const [showLoader, setShowLoader] = useState();
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    let navigate = useNavigate();

    // authentication
    const roleId = sessionStorage.getItem('role_id');
    let permission = roleId === '7' ? false : true;
    let ptPermission = roleId === '4' ? false : true;

    let PTadminFlag = roleId === '2' || roleId === '3' || roleId === '4' ? true : false;

    let userName = sessionStorage.getItem('company_name')?.split(' ');
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const signOut = () => {
        setShowLoader(true);
        handleMenuClose();
        const formdata = { token: sessionStorage.getItem('token') };
        apiCall(
            (response) => {
                console.log('LOGOUT', response);
                if (response.data.success) {
                    toast.success(response.data.message);
                    sessionStorage.clear();
                    navigate('/');
                    setShowLoader(false);
                } else {
                    console.log('else', response.data.message);
                    // nedd to handle the unauthorised condition here fro now the response toast is commented
                    // toast.error(response.data.message);                    
                    sessionStorage.clear();
                    navigate('/');
                    setShowLoader(false);
                }
            },
            formdata,
            'LOGOUT',
        );
    };
    // const signOut = () => {
    //     setShowLoader(true);
    //     handleMenuClose();
    //     const formdata = { token: sessionStorage.getItem('token') };
    //     axios
    //         .post(`${API_CONSTANTS.LOGOUT}`, formdata)
    //         .then((response) => {
    //             toast.success(response.data.message);
    //             sessionStorage.clear();
    //             navigate('/');
    //             setShowLoader(false);
    //         })
    //         .catch(function (error) {
    //             if (error.response) {
    //                 // Request made and server responded
    //                 if (error.response.data.success === false) {
    //                     toast.error(error.response.data.message);
    //                     setShowLoader(false);
    //                 }
    //                 console.log(error.response.headers);
    //             } else if (error.request) {
    //                 // The request was made but no response was received
    //                 console.log('no response', error.request);
    //             } else {
    //                 // Something happened in setting up the request that triggered an Error
    //                 console.log('Error', error.message);
    //             }
    //         });
    // };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            className="profile-menuitem"
            id={menuId}
            keepMounted
            open={isMenuOpen}
            onClose={handleMenuClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    'overflow': 'visible',
                    'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    'mt': -1,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 34,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            {!PTadminFlag && (
                <MenuItem id="settings" name="settings"
                    onClick={() => {
                        handleMenuClose();
                        navigate('/settings');
                    }}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
            )}
            {!permission && (
                <MenuItem id="my__pt_profile" name="my_pt_profile"
                    onClick={() => {
                        handleMenuClose();
                        navigate('/my-profile');
                    }}>
                    <ListItemIcon>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    My Profile
                </MenuItem>
            )}
            {!ptPermission && (
                <MenuItem id="my_profile" name="my_profile"
                    onClick={() => {
                        handleMenuClose();
                        navigate('/profile');
                    }}>
                    <ListItemIcon>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    My Profile
                </MenuItem>
            )}
            {/* {permission && (
                <MenuItem
                    onClick={() => {
                        handleMenuClose();
                        navigate('/settings/team');
                    }}>
                    <ListItemIcon>
                        <ReceiptIcon fontSize="small" />
                    </ListItemIcon>
                    Team
                </MenuItem>
            )} */}
            {/* <MenuItem id="contact_us" name="contact_us"
            // onClick={() => { handleMenuClose();}}
            >
                <ListItemIcon>
                    <ForumIcon fontSize="small" />
                </ListItemIcon>
                Contact us
            </MenuItem> */}
            <Divider />
            <MenuItem onClick={signOut} id="sign_out" name="sign_out">
                <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                Sign out
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}>
            <MenuItem>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="error">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            {/* <MenuItem>
                <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                    <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem> */}
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton size="large" aria-label="account of current user" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
                    <AccountCircle />
                </IconButton>
                <KeyboardArrowDownIcon />
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );
    return (
        <div className="header-fixed">
            <Preloader showPreloader={showLoader} />
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" style={{ backgroundColor: "#0f75bc" }}>
                    <Toolbar>
                        <IconButton id="pt_logo" name="pt_logo" size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }} className="main-logo" onClick={() => { PTadminFlag ? navigate('/company') : navigate('/dashboard') }} disableRipple>
                            <img style={{ width: '50px' }} src={"https://dlil96nns7nd5.cloudfront.net/images/isn-white-transparent-140x70.png"} alt="logo" />
                        </IconButton>
                        <Box sx={{ flexGrow: 1 }} />
                        {/* Searchbar for large Screen and hidden in small screen */}
                        <Box className="header-search-wrapper">
                            {/* <Search className="header-search-bar">
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
                            </Search> */}
                        </Box>
                        {props?.narrativeCompany && <Box>
                            {/* <IconButton id="notification" name="notification" size="large" aria-label="show 17 new notifications" color="inherit" className="notification-icon" disableRipple>
                                <Badge badgeContent={17} color="error" className="notification-badge">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton> */}
                            <IconButton sx={{ gap: "3px" }} size="large" onClick={() => navigate("/library?key=900")} >
                                {/* <Avatar sx={{ bgcolor: 'white' }}>
                                    <PersonIcon className="navbar-profile-icon" />
                                </Avatar> */}
                                <CircleIcon sx={{ fontSize: "5px", color: "white" }} />
                                <Typography sx={{ marginRight: '15px', color: 'White', fontWeight: 900 }}>Comment Library</Typography>
                                {/* <KeyboardArrowDownIcon sx={{ fontSize: '18px', marginTop: '2px' }} /> */}
                            </IconButton>
                        </Box>}
                    </Toolbar>
                </AppBar>
                {/* {renderMobileMenu}
                {renderMenu} */}
            </Box>
            {/* <Navbar permission={permission} PTadminFlag={PTadminFlag} roleId={roleId} ptPermission={ptPermission} /> */}
        </div>
    );
};

export default Header;
