import React, { useEffect } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import './_index.scss'


const DateSelector = (props) => {
  const {SwitchDate, isMobile, GetInspectionDateWise, reCheck, handleCheck} = props;
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [count, setCount] = React.useState(0)

  const setDate = (newDate) => {
    const date = newDate || new Date();
    setSelectedDate(
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    )
    handleCheck()
  };

  const getPreviousDate = () => {
    const currentDayInMilli = new Date(selectedDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const previousDayInMilli = currentDayInMilli - oneDay;
    const previousDate = new Date(previousDayInMilli);
    setCount(count !== 0 && count-1)
    setDate(previousDate);
  };

  const getNextDate = () => {
    const currentDayInMilli = new Date(selectedDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const nextDayInMilli = currentDayInMilli + oneDay;
    const nextDate = new Date(nextDayInMilli);
    setCount(count <= 4 && count+1)
    setDate(nextDate);
  };

  const date = moment(selectedDate).format("dddd" + ", " + "MMM Do");

  useEffect(() => {
    GetInspectionDateWise(moment(selectedDate).format("YYYY-MM-DD"))
  }, [reCheck])
  

  return (
      <Grid sx={{pl : isMobile && '0px !important', mt: isMobile && '5px'}} component="span" className="datepicker">
        <span id={'dateForeward_id'} name={'dateForeward_name'} onClick={getPreviousDate} style={{pointerEvents: count === 0 && 'none', opacity : count === 0 && '0.5', color : count === 0 ? '#3b3b3b' : '#4282e2'}} className="datepicker__buttons">◄</span>
         <Typography component="span" className="datepicker__date">{date}</Typography>
        <span id={'dateBackward_id'} name={'dateBackward_name'}  onClick={getNextDate} style={{pointerEvents: count <= 3 ? 'unset' : 'none', opacity : count <= 3 ? 'unset' : '0.5', color : count <= 3 ? '#4282e2' : '#3b3b3b'}} className="datepicker__buttons">►</span>
      </Grid>
  );
};

export default DateSelector;
