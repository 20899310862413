import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "../../../assets/styles/containers/_commentLibraryModal.scss";
import CLAddCommentModal from "../../common/TemplateUtils/CLAddCommentModal";
import { filterSelectTag, filterSelectTagIntro, preprocessHTMLContent } from "../../common/TemplateUtils/CommentLibraryHelper";
import { addNewNarrativeComment, editNarrativeComment, getCommentLibrarySummaryTagList, getCommentLibraryTagList } from "../../store/action/CommentLibraryAction";
import { timestamp } from "../../utils/services";

function AddCommentDialog(props) {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.commentLibrary);
  const { state, setState } = props;
  const { openAddDialog, commentTag, commentTitle, commentText, commentSummary, commentSummaryTag, isEdit, comment_id, commentDropdownList, summaryDropdownList } = state;

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openAddDialog: false,
      commentTag: [],
      commentTitle: "",
      commentText: { innerHTML: "" },
      commentSummary: { innerHTML: "" },
      commentSummaryTag: [],
      commentDropdownList: [],
      summaryDropdownList: [],
      isEdit: false,
    }));
  };

  const handleSave = async () => {
    handleClose();
    setState((prevState) => ({ ...prevState, openAddDialog: false, showLoader: true, isEdit: false }));
    let response;
    const newCommentData = {
      title: commentTitle || "",
      comment_text: isEdit ? filterSelectTagIntro(commentText?.innerHTML) || "" : filterSelectTag(commentText?.innerHTML) || "",
      summary_comment_text: isEdit ? filterSelectTagIntro(commentSummary?.innerHTML) || "" : filterSelectTag(commentSummary?.innerHTML) || "",
      comment_dropdowns: commentDropdownList || [],
      summary_comment_dropdowns: summaryDropdownList || [],
      tag_ids: commentTag?.filter((item) => item !== "")?.map((tag) => parseInt(tag.id)) || [],
      summary_ids: commentSummaryTag?.filter((item) => item !== "")?.map((summaryTag) => parseInt(summaryTag.id)) || [],
    };
    if (isEdit) {
      response = await dispatch(editNarrativeComment({ ...newCommentData, comment_id: comment_id, updated_at: timestamp() }));
      if (response?.payload) {
        const newUpdatedList = state.commentListData;
        const index = state.commentListData.findIndex((item) => item.id === comment_id);
        newUpdatedList[index] = { ...newUpdatedList[index], ...newCommentData, tags: commentTag, summaries: commentSummaryTag };
        setState((prevState) => ({ ...prevState, showLoader: false, commentListData: newUpdatedList }));
      }
    } else {
      response = await dispatch(addNewNarrativeComment(newCommentData));
      if (response?.payload) setState((prevState) => ({ ...prevState, showLoader: false, commentListData: [response?.payload, ...storeData.commentLibraryList] }));
    }
  };

  useEffect(() => {
    if (!storeData?.clTagList) {
      dispatch(getCommentLibraryTagList());
    }
    if (!storeData?.clSummaryTagList) {
      dispatch(getCommentLibrarySummaryTagList());
    }
  }, [storeData?.clTagList, storeData?.clSummaryTagList, dispatch]);

  return (
    <Dialog open={openAddDialog} maxWidth="lg" onClose={handleClose} aria-labelledby="category-modal" aria-describedby="category-modal-description">
      <DialogTitle>
        <Grid container display={"flex"}>
          <Grid item xs={11} display={"flex"} alignItems={"center"}>
            <Typography className="add-comment-title">{isEdit ? "Edit" : "New"} comment</Typography>
          </Grid>
          <Grid item xs={1} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
            <CloseIcon className="checkbox_container" onClick={handleClose} />
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginX: "0px 30px !important" }}>
        <CLAddCommentModal state={state} setState={setState} isEdit={isEdit} />
      </DialogContent>
      <Divider />
      <DialogActions className="comment-library-action">
        <Grid container display={"flex"} gap={1} justifyContent={"flex-end"}>
          <Button className="dialog-cancel-btn" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={
              commentTag.length === 0 ||
              (commentTitle?.trim() === "" && (commentText?.htmlText?.trim() === "" || commentText?.htmlText?.trim() === "<p><br></p>" || preprocessHTMLContent(commentText?.htmlText) === ""))
            }
            className="dialog-add-btn"
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default AddCommentDialog;