import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { Grid, Button } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import EditIcon from "@mui/icons-material/Edit";
import { useMediaQuery } from "react-responsive";
import DeleteIcon from "@mui/icons-material/Delete";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableContainer from "@mui/material/TableContainer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { visuallyHidden } from "@mui/utils";
// dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
// styles imports
import "../../../../assets/styles/containers/_teams.scss";
import Sortswitch from "../../../../assets/images/Sortswitch.png";
// common components imports
import Header from "../../../common/header/Header";
import Breadcrumb from "../../../common/header/Breadcrumb";
import Preloader from "../../../helpers/Preloader";
import { apiCall } from "./action";
import {BootstrapTooltip,descendingComparator,getComparator,stableSort,GetCurrentResolution} from "../../../utils/services";

// mock datas
const teamtableHead = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: "FIRST NAME",
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: "LAST NAME",
  },
  { id: "email", numeric: false, disablePadding: false, label: "EMAIL" },
  { id: "phone", numeric: false, disablePadding: false, label: "PHONE" },
  { id: "role_name", numeric: false, disablePadding: false, label: "ROLE" },
  { id: "actions", numeric: false, disablePadding: false, label: "ACTIONS" },
];
//map funtion of table heading
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {teamtableHead.map((headCell) => (
           !props.permission? headCell.label !== "ACTIONS" &&
          <td
            key={headCell.id}
            align={headCell.label === "ACTIONS" ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            className={headCell.label=== "ACTIONS"? 'team-table-head action-column':'team-table-head'}
          >
            <TableSortLabel
              IconComponent={() =>
                headCell.label !== "ACTIONS" ? (
                  <img
                    src={Sortswitch}
                    alt="sort-icon"
                    className="sort-switch"
                  />
                ) : (
                  ""
                )
              }
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                marginTop: GetCurrentResolution() === "mobile" && "21px",
                marginBottom: "10.5px",
                flexDirection: "row-reverse",
                fontSize: "12px",
              }}
            >
              <span className="head-title">
                {headCell.label}{" "}
                {headCell.label === "ROLE" ? (
                  <BootstrapTooltip title={<h1>Team roles</h1>}>
                    <InfoOutlinedIcon />
                  </BootstrapTooltip>
                ) : (
                  ""
                )}
              </span>
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  className="filter-icon"
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </td>:
            <td
            key={headCell.id}
            align={headCell.label === "ACTIONS" ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            className={headCell.label=== "ACTIONS"? 'team-table-head action-column':'team-table-head'}
          >
            <TableSortLabel
              IconComponent={() =>
                headCell.label !== "ACTIONS" ? (
                  <img
                    src={Sortswitch}
                    alt="sort-icon"
                    className="sort-switch"
                  />
                ) : (
                  ""
                )
              }
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                marginTop: GetCurrentResolution() === "mobile" && "21px",
                marginBottom: "10.5px",
                flexDirection: "row-reverse",
                fontSize: "12px",
              }}
            >
              <span className="head-title">
                {headCell.label}{" "}
                {headCell.label === "ROLE" ? (
                  <BootstrapTooltip title={<h1>Team roles</h1>}>
                    <InfoOutlinedIcon />
                  </BootstrapTooltip>
                ) : (
                  ""
                )}
              </span>
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  className="filter-icon"
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </td>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

//render function
export default function Teams() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("calories");
  const [table_data, setTableData] = useState([]);
  // const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [roleList, setRoleList] = useState([]);
  // const [role, setRole] = useState("");
  // const [newTeam, setNewTeam] = useState(false);
  // const [editRole, setEditRole] = useState(false);
  // const [editData, setEditData] = useState({});
  const [showLoader, setShowLoader] = useState();
  const [deleteId, setDeleteId] = useState("");
  const [deleteControl, setDeleteControl] = useState(false);
  let navigate = useNavigate();

  const uuId = sessionStorage.getItem("user_id");
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  let permission;
  permission = roleId === "7" ? false : true;
  let ptPermission = roleId === '4'?false:true;
  !permission && navigate('/dashboard')
  let PTadminFlag = (roleId === '2' || roleId === '3' ||roleId === '4')?true :false
  // Load team members data function and API call
  const GetTeamLists = () => {
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        console.log("GET TEAM LIST", response);
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message); 
          let curentUserData = data?.find(member=> member.id === Number(uuId))
          sessionStorage.setItem("user_name" , `${curentUserData.first_name} ${curentUserData.last_name}`);
          sessionStorage.setItem("role_id" , curentUserData.role_id);
          getRoleLists();
          setTableData(data);
          setShowLoader(false);
        } else {
           toast.error(message);
          console.log("else ", response.data.message);
        }
      },
      formdata,
      "GET_TEAM_LIST"
    );
    // const formdata = { company_id: companyId, user_id: uuId, role_id: roleId }
    // axios
    //   .post(`${API_CONSTANTS.GET_TEAM_LIST}`, formdata)
    //   .then((response) => {
    //     // Request with success code true
    //     setTableData(response.data.data);
    //     setShowLoader(false);
    //   })
    //   .catch(function (error) {
    //     if (error.response) {
    //       // Request made and server responded
    //       if (error.response.data.success === false) {
    //         // toast.error(error.response.data.message);  
    //         console.log(error.response.data.message)         
    //         setShowLoader(false);
    //       }
    //       console.log(error.response.headers);
    //     } else if (error.request) {
    //       // The request was made but no response was received
    //       console.log("no response", error.request);
    //     } else {
    //       // Something happened in setting up the request that triggered an Error
    //       console.log("Error", error.message);
    //     }
    //   });
  };

  // get PT teamlist
  const getPtTeamList = () =>{
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        console.log("GET TEAM LIST", response);
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message); 
          let curentUserData = data?.find(member=> member.id === Number(uuId))
          sessionStorage.setItem("user_name" , `${curentUserData.first_name} ${curentUserData.last_name}`);
          sessionStorage.setItem("role_id" , curentUserData.role_id);
          getPtRoleLists();
          setTableData(data);
          setShowLoader(false);         
        } else {
           toast.error(message);
          console.log("else ", response.data.message);
        }
      },
      formdata,
      "GET_PT_TEAM_LIST"
    );
    
  }
  // get role list function and API call
  const getRoleLists = () => {
    setShowLoader(true);
    const formdata = { user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        console.log("GET ROLE LIST", response);
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          setRoleList(data);
          setShowLoader(false);
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      "GET_ROLE_LIST"
    );
  };

  // get pt role list
  const getPtRoleLists =()=>{    
    setShowLoader(true);
    const formdata = { user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        console.log("GET ROLE LIST", response);
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          setRoleList(data);
          setShowLoader(false);
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      "GET_PT_ROLE_LIST"
    );
  }
  // delete member function and API call
  const deleteTeamMember = (id) => {
    let deleteType = PTadminFlag?'REMOVE_PT_TEAM_MEMBER':'REMOVE_MEMBER'
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: PTadminFlag?id:uuId,
      role_id: roleId,
      member_id: id,
    };
    handleCloseAlert();
    apiCall(
      (response) => {
        console.log("REMOVE MEMBER RESPONSE", response);
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setDeleteControl(!deleteControl);
          setShowLoader(false);
        } else {
          toast.error(message)
          console.log("else ", message);
          setShowLoader(false);
        }
      },
      formdata,
      deleteType
    );
  };

  useEffect(() => {
    if(!PTadminFlag){
      GetTeamLists();
      getRoleLists();
    }
    else{  
      getPtTeamList();
      getPtRoleLists();
    }
      
  }, [deleteControl]);

  // const handleChange = (event) => {
  //   setRole(event.target.value);
  // };



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - table_data.length) : 0;

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  //   modal functions
  // const handleClickOpen = () => {
  //   console.log("clicked");
  //   setOpen(true);
  //   setEditRole(false);
  //   setNewTeam(true);
  // };
  // const handleClickEditOpen = (row) => {
  //   setOpen(true);
  //   setNewTeam(false);
  //   setEditRole(true);
  //   setEditData(row);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // alert modal functions

  const handleClickOpenAlert = (id) => {
    setDeleteId(id);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <>
     
      <Box sx={{ flexGrow: 1 }} className="page-background">
        <Header />
        <Breadcrumb />
        <Preloader showPreloader={showLoader} />
        {/* ------------------delete alert dialog------------------------ */}
        <Dialog
          open={openAlert}
          onClose={handleCloseAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="delete-alert-modal"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete team member?"}
            <CloseIcon className="close-icon" onClick={handleCloseAlert} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="delete-modal-cancel-btn"
              onClick={handleCloseAlert}
            >
              Cancel
            </Button>
            <Button
              className="default-btn"
              variant="contained"
              color="error"
              onClick={() => {deleteTeamMember(deleteId)}}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* ------------------delete alert dialog end --------------*/}
        {showLoader ? (
         ""
        ) : (
        <Box
          sx={{
            width: "100%",
            px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },
          }}
          className="teams-container"
        >
          <Grid item xs={12} className="teams-title-wrapper">
            <div>
              <h3 className="teams-title">Team</h3>
              <p className="teams-subtitle">All people on your team.</p>
            </div>
            {ptPermission && 
            <BootstrapTooltip
              title={
                permission ? '' : <h1>You cannot add team member</h1>
              }
            >
              <span className="disable-btn-wrapper">
                <Button disabled={!permission}
                  className={!permission?"disabled-permision-btn":"inspections-new-button"} 
                   sx={{ textTransform: "none" }}
                   id="add_new_team"
                   name="add_new_team"
                  // onClick={handleClickOpen}
                  onClick={() =>{PTadminFlag?
                    navigate(`/teams/new`, { state: { roleList } })
                    :navigate(`/settings/team/new`, { state: { roleList } })
                  }}
                >
                  Add new &nbsp;&nbsp;+{" "}
                </Button>
              </span>
            </BootstrapTooltip>
            }
          </Grid>

          <Paper
            sx={{ width: "100%", sm: 8, lg: 2, mt: "18px" }}
            className="teams-table-wrapper"
          >
            <TableContainer
              sx={{ overflowX: { sm: "auto", lg: "unset", md: "scroll" } }}
              className={isMobile ? "mobileScroller" : ""}
            >
              <Table
                aria-labelledby="tableTitle"
                size={"small"}
                style={{
                  transform: isMobile && "rotate(180deg)",
                  overflowX: "unset",
                  width: "100",
                }}
                className="teams-table-main"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  //   onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={table_data.length}
                  permission={ptPermission}
                />

                <tbody className="teams-body">
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with: 
                    rows.slice().sort(getComparator(order, orderBy)) */}
                  { table_data.length>0?
                   stableSort(table_data, getComparator(order, orderBy))
                    //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      let currentUser = row.id===Number(uuId)?true:false;
                      return (
                        <tr
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={`${row.client}_${index}`}
                          className="teams-data-table"
                        >
                          <td
                            className="first-name"
                            component="th"
                            id={labelId}
                            padding="none"
                          >
                            <span
                              onClick={() =>{PTadminFlag? ptPermission &&
                                navigate(`/teams/${row.first_name}`, {
                                  state: { id: row.id, edit: true, roleList},
                                })
                                :navigate(`/settings/team/${row.first_name}`, {
                                  state: { id: row.id, edit: true, roleList},
                                })
                              }}
                            >
                              {row.first_name}
                            </span>
                          </td>
                          <td className="insp-status" align="left">
                            <span>{row.last_name}</span>
                          </td>

                          <td align="left" className="teams-email-data">
                            <span>{row.email}</span>
                          </td>

                          <td align="left">
                            <span className="phone-data">{row.phone}</span>
                          </td>

                          <td align="left">
                            <span className="team-role">
                              {row.role_name.includes("Owner") ||
                              row.role_name.includes("Admin") ? (
                                <VerifiedUserIcon className="owner-icon" />
                              ) : (
                                <BusinessCenterIcon className="insp-icon" />
                              )}
                              {row.role_name}
                            </span>
                          </td>

                         {ptPermission && <td className="action-column" align="right">
                            <span className="table-actions">
                              <span>
                                {permission?
                                 (roleId === "6" && row.role_name ==="Owner" || roleId === "3" && row.role_name ==="Palmtech Owner" ) ?
                                 <>
                                   <BootstrapTooltip title={<h1 className="font-13">You cannot edit this record</h1>}>
                                     <EditIcon sx={{ opacity: "0.25" }}/>
                                   </BootstrapTooltip>
                                   <BootstrapTooltip  title={<h1>You cannot delete this record</h1>}>
                                     <DeleteIcon sx={{ color: "red",fontSize: 18, opacity: "0.25",}}/>
                                   </BootstrapTooltip>
                                 </>
                                 :
                                <>
                                  <BootstrapTooltip title={<h1 className="font-13">Edit details</h1>}>
                                    <EditIcon className="edit-icon"
                                      id={`edit_team_${index}`}
                                      onClick={() =>{PTadminFlag?
                                        navigate( `/teams/${row.first_name?.charAt(0)==="#"? row.first_name?.substring(1): row.first_name}`, {state: {id: row.id, edit: true, roleList},})
                                        :navigate( `/settings/team/${row.first_name?.charAt(0)==="#"? row.first_name?.substring(1): row.first_name}`, {state: {id: row.id, edit: true, roleList},})
                                      }} />
                                  </BootstrapTooltip>
                                  <BootstrapTooltip 
                                    title={<h1 className="font-13">{!currentUser?'Delete record':'You cannot delete yourself'}</h1>}>
                                    <DeleteIcon className={!currentUser && 'delete-icon'} id={`delete_team_${index}`}  sx={{ color: "red",fontSize: 18, opacity: "0.25",}}
                                      onClick={!currentUser? () => handleClickOpenAlert(row.id):''} />
                                  </BootstrapTooltip>
                                </>
                                :
                                <>
                                  <BootstrapTooltip title={<h1 className="font-13">You cannot edit record</h1>}>
                                    <EditIcon sx={{ opacity: "0.25" }} />
                                  </BootstrapTooltip>
                                  <BootstrapTooltip title={<h1>You cannot delete record</h1>} >
                                    <DeleteIcon sx={{color: "red",fontSize: 18,opacity: "0.25",}} />
                                  </BootstrapTooltip>
                                </>
                                }
                              </span>
                            </span>
                          </td>}
                        </tr>
                      );
                    }):<div>No datas found</div>}
                  {emptyRows > 0 && (
                    <tr
                      style={{
                        height: 33 * emptyRows,
                      }}
                    >
                      <td colSpan={6} />
                    </tr>
                  )}
                </tbody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
         )} 
      </Box>
    </>
  );
}
