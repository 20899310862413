import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import "../../../assets/styles/containers/_dashboard.scss";
import Grid from "@mui/material/Grid";
import MonthlySummary from "./summary/MonthlySummary";
import DailyRoute from "./todo/DailyRoute";
import UpcommingSection from "./upcomming/UpcommingSection";
import { RequestDashboardCount, RequestUpcommingInspections, RequestDateWiseInspections, RequestCompanyDetails } from "./actions";
import { compareStrings, summaryData } from "../../utils/services";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const DashboardLayout = () => {

  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });
  let navigate = useNavigate();


  const uuId = sessionStorage.getItem("user_id");
  const roleId = sessionStorage.getItem("role_id");
  const companyId = sessionStorage.getItem("company_id");
  const username = sessionStorage.getItem('user_name');

  const [summaryCount, setSummaryCount] = useState([]);
  const [upcommingCount, setUpcommingCount] = useState([]);
  const [dateWiseInspections, setDateWiseInspections] = useState([]);
  const [getDate, setDate] = React.useState('');
  const localDate = new Date();

  const SwitchDate = (date) => setDate(date)
  
  const GetSummaryCount = () => {

    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId, local_date: moment(localDate).format("YYYY-MM-DD HH:mm:ss")  };
    RequestDashboardCount((response) => {
      const { data, message, success } = response.data;
      if (success) {
        setSummaryCount(data)
      } else {
        console.log("else ", message);
      }
    }, formdata);
  };

  const GetUpcommingCount = () => {
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId , local_date: moment(localDate).format("YYYY-MM-DD") };
    RequestUpcommingInspections((response) => {
      const { data, message, success } = response.data;
      if (success) {
        setUpcommingCount(data)
      } else {
        console.log("else ", message);
      }
    }, formdata);
  };

  const GetInspectionDateWise = (date) => {
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId, date:date };
    RequestDateWiseInspections((response) => {
      const { data, message, success } = response.data;
      if (success) {
        setDateWiseInspections(data)
      } else {
        setDateWiseInspections([])
        console.log("else ", message);
      }
    }, formdata);
  };

  // useEffect(() => {
  //     GetInspectionDateWise()
  // }, [getDate])
  

  useEffect(() => {
    GetSummaryCount()
    GetUpcommingCount()
  },[])

  dateWiseInspections?.Inspections_details?.sort(function (a, b) {
    return compareStrings(a.first_name, b.first_name);
  });

  return (
    <Box
      sx={{
        width: "100%",
        px: { xs: "0px", sm: "20px", md: "80px", lg: "80px" },
        py: "44.5px",
      }}
    >
      <Grid
        sx={{
          mb: { xs: "20px", sm: "150px", md: "61px", lg: "61px" },
        }}
      >
        <div style={{ marginLeft : isMobile && '23px'}} id="user_name" name="userName" className="dashboard_title">Welcome, {username}!</div>
        <div style={{ marginLeft : isMobile && '23px'}} id="user_desc" name="userDesc" className="dashboard_description">Here’s everything you need for the day. </div>

        <MonthlySummary
        isMobile={isMobile}
        summaryCount={summaryCount}
        summaryData={summaryData}
        />
        <DailyRoute
        isMobile={isMobile}
        SwitchDate={(date) => SwitchDate(date)}
        dateWiseInspections={dateWiseInspections}
        GetInspectionDateWise = {(date) => GetInspectionDateWise(date)}
        />
        <UpcommingSection
        isMobile={isMobile}
        upcommingCount={upcommingCount}
        navigate={navigate}
        />
      </Grid>
    </Box>
  );
}

export default DashboardLayout;
