import React, { useState, useEffect } from "react";
import "../../../assets/styles/containers/_customDropdown.scss";

/**
 * CustomDropdown component
 *
 * Component that renders a dropdown list of options based on the options passed in as props.
 * It sets the selected value of the dropdown list to the selectedValue prop passed in.
 *
 * @param {Object} props
 * @param {Array} props.options - array of option strings to be displayed in the dropdown list
 * @param {String} props.selectedValue - the selected value of the dropdown list
 */
const CustomDropdown = ({ key, dropdownUuid, commentId, commentUuid, type, options, selectedValue }) => {
  // State to hold the selected option of the dropdown list
  const [selectedOption, setSelectedOption] = useState(selectedValue);

  // Update selectedOption when selectedValue changes
  useEffect(() => {
    setSelectedOption(selectedValue);
  }, [selectedValue]);

  /**
   * Handles selecting an option from the dropdown list
   * @param {Object} event - event object
   */
  const handleSelectOption = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };

  return (
    <select className={`${type}-cl-dropdown`} comment_id={commentId} dropdown_uuid={dropdownUuid} comment_uuid={commentUuid} dropType={type} value={selectedOption} onChange={handleSelectOption}>
      <option value="">---------------</option>
      {Array?.isArray(options) &&
        options?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
    </select>
  );
};

export default CustomDropdown;
